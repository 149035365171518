<template>
  <div class="hello">
    <h1>Ask AI</h1>
    <input v-model="currentMessage" type="text" class="text-input"> <span>
    <button @click="sendMessage(currentMessage)" class="submit-button">Ask</button></span>
    <div class="messageBox">
    <template v-for="(message, index) in messages">
      <div :class="message.from=='user' ? 'messageFromUser' :'messageFromChatGpt'" :key="index" v-html="message.data"></div>
    </template>
    </div>
  </div>
</template>

<script>
import axios from '../utils/axiosinstance';
export default {
  name: "HomeChat",
  data() {
    return {
      messages: [],
      currentMessage: null
    }
  },

  methods: {
    async sendMessage(message) {
      this.messages.push({
        from: 'user',
        data: message
      })
      await axios.post('http://localhost:3000/ask-to-chat-gpt', {
        message: message
      })
      .then((response) => {
        this.messages.push(response.data)
      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  text-align: center;
}
.text-input {
  width: 300px;
  padding: 10px;
}

.submit-button {
  height: 40px;
  background-color: powderblue;
  padding: 10px;
}
.messageBox {
  height: 500px;
  background-color: gainsboro;
  /* margin-left: 10%;
  margin-right: 10%; */
  margin: 0 10% 0 10%;
  margin-top: 20px;
  padding: 5%;
  overflow-y: scroll;
}

.messageFromUser {
  text-align: right;
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 30%;
  margin-left: 70%;
}

.messageFromChatGpt {
  text-align: left;
  background-color: antiquewhite;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  /* width: 30%;
  margin-right: 70%; */
}
</style>