<template>
    <div>
        <div style="clear: both;height: 130px;">
            <div class="newDepartment-header">Add Department</div>
            <!-- <div class="newDepartment-header">{{ action }} {{ industryItemType }}</div> -->
        </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-body">
                          <div>
                            departmentName: <input type="text" v-model="departmentTemp.departmentName" style="margin-bottom: 10px;" class="form-control-alternative department-desc"/>
                          </div>
                          <div>
                            departmentDescription: <input type="text" v-model="departmentTemp.description" style="margin-bottom: 10px;" class="form-control-alternative department-desc"/>
                          </div>
                          <div>
                            departmentEmail: <input type="text" v-model="departmentTemp.email" style="margin-bottom: 10px;" class="form-control-alternative department-desc"/>
                          </div>
                          <div>
                            departmentUserName: <input type="text" id="txtTitle" style="margin-bottom: 10px;" class="form-control-alternative2 department-desc"/>
                          </div>
                          <div>
                            departmentPassword: <input type="text" id="txtDesc" style="margin-bottom: 10px;" class="form-control-alternative department-data"/>
                          </div>
                          <div>
                            departmentPhone: <input type="text" v-model="departmentTemp.phone" style="margin-bottom: 10px;" class="form-control-alternative department-data"/>
                          </div>
                          <div>
                            Role:<select v-model="departmentTemp.role" class="view-select" style="margin-bottom: 10px;">
                              <option
                                v-for="view in viewOptions"
                                :key="view.value"
                                :value="view.value"
                              >
                                {{ view.title }}
                              </option>
                            </select>
                          </div>
                          <div>
                            documents: 
                            <input type="text"
                            v-for="document in documents"
                            :key="document.name"
                            :value="document.name"
                            style="margin-right: 5px;"/>
                          </div>
                          <div>
                            <base-button size="sm" type="default" class="" @click="addTitle($event)">Save</base-button>
                            <base-button size="sm" type="default" class="" @click="backToList()">Cancel</base-button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    
import axios from "../../../utils/axiosinstance";
import { v4 as uuidv4 } from "uuid";
// import Dashboard from '../../Dashboard.vue';

export default {
  components: {},
  // components: { Dashboard },
  // props: ['documents'],
  watch: { 
          departmentID: function(newVal, oldVal) { // watch it
          console.log('departmentID Prop changed: ', newVal, ' | was: ', oldVal)
          }
  },
  data() {
    return {
      departmentID: "",
      industryItemType: "",
      industryItemID: "",
      showModal: false,
      action: "",
      files: '', 
      filespath: '',
      departmentTemp: {},
      selectedrole: "adminOrg",
      viewOptions: [
        {
          title: "AdminOrg",
          value: "adminOrg",
        },
        {
          title: "Manager",
          value: "manager",
        },
        {
          title: "Department",
          value: "department",
        },
      ],
      documents:[
        {
        name:"step by step",
        },
        {
        name:"pdfs",
        },
        {
        name:"notepad",
        },
        {
        name:"flowchart",
        },
        {
        name:"forms",
        },
      ],
    };
  },
  beforeMount() {
      this.departmentID=this.$route.query.departmentID;
      this.industryItemType=this.$route.query.industryItemType;
      this.industryItemID=this.$route.query.industryItemID;
},
  mounted() {
      if (this.departmentID) {
          this.action="Update";
      axios
        .get(
          "/api/department/organizations/" +
            this.authCookie.orgs[0].orgID +
            this.departmentID
        )
        .then(response => {
          this.departmentTemp = response.data.item;          
            this.$set(this.departmentTemp, "departmentID", response.data.item.departmentID);
            this.$set(this.departmentTemp, "userName", response.data.item.userName);
            this.$set(this.departmentTemp, "departmentName", response.data.item.departmentName);
            this.$set(this.departmentTemp, "departmentDescription", response.data.item.description);
            this.$set(this.departmentTemp, "email", response.data.item.email);
            this.$set(this.departmentTemp, "password", response.data.item.password);
            this.$set(this.departmentTemp, "role", response.data.item.role);
            this.$set(this.departmentTemp, "orgID", response.data.item.orgID);
            if(response.data.item.imagePath != undefined)
            {
              this.$set(this.departmentTemp, "imagePath", response.data.item.imagePath); 
              this.filespath = response.data.item.imagePath;
            }
            document.getElementById("txtTitle").value = this.departmentTemp.userName;
            document.getElementById("txtDesc").value = this.departmentTemp.email;

        })
        .catch(reason => {
          console.log(reason);
          this.departmentTemp = [];
        });
    }
    else
    {
        this.action="Add";
    }
    
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj){
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token")
              .substring(2)
              .split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }

  },
  beforeCreate() {
    
  },
  methods: {
    notifyUser(departmentTempNotification){
      console.log(departmentTempNotification);
      var notification = {
          objectId: departmentTempNotification.departmentID,
          type: "department",
          message:  " has created new department " +  departmentTempNotification.departmentName,
      };
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    handleFileUploads(){
      this.files = this.$refs.file.files[0];
    },
    addImage(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadWidgetFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            // console.log(response);
            //app.documentTemp = response.data.item;
            app.filespath = "";
            var path = response.data.fileUrl;
            app.filespath = path.toString();
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    addTitle(e) {
            console.log(e);
            var el = document.getElementById("txtTitle");
            var el1 = document.getElementById("txtDesc");

            this.$set(this.departmentTemp, "userName", el.value);
            this.$set(this.departmentTemp, "password", el1.value);
            this.$set(this.departmentTemp, "createdBy", this.authCookie.userName);
            this.$set(this.departmentTemp, "orgID", this.authCookie.orgs[0].orgID);
            this.$set(this.departmentTemp, "createdBy", this.authCookie.userName);
            this.$set(this.departmentTemp, "updatedBy", this.authCookie.userName);
            this.$set(this.departmentTemp, "documents", this.documents);
            if(this.filespath != '')
            {
              this.$set(this.departmentTemp, "imagePath", this.filespath);
            }
        if (this.departmentID) {
            this.$set(this.departmentTemp, "departmentID", this.departmentID);
            this.$set(this.departmentTemp, "dashboard", []);
            this.updateDepartment();
            //alert("Update");
        }
        else
        {
            this.saveUser();
        }
    },
    backToList(){
      // let app = this;
      //     app.$router.push({
      //     name: "departmentList",
      //   });
      window.location = "/departmentList";
    },
    updateDepartment() {
      let app = this;
        axios
          .put("/api/department", app.departmentTemp)
          .then(function(response) {
            // console.log(response);
            app.departmentTemp = response.data.item;
            //if(app.industryItemType == "reagent")
            alert( "Department Updated Successfully!");
            app.backToList();
          })
          .catch(function(error) {
            console.log(error);
            app.departmentTemp = [];
          });
    },
    saveUser() {
      let app = this;
        axios
          .post("/api/department", app.departmentTemp)
          .then(function(response) {
            app.departmentTemp = response.data.item;
            alert("Department Created Successfully!");
            app.notifyUser(app.departmentTemp);
            window.location = "/departmentList";
            
          })
          .catch(function(error) {
            console.log(error);
            app.departmentTemp = [];
          });
    },
    getId() {
      return uuidv4();
    } 
  }
};

</script>
<style>
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.department-desc{
    width: 250px;
    height: 30px;
    margin-right: 50px;
    border: 1px solid #333;
}
.department-data{
    width: 250px;
    height: 30px;
    margin-right: 50px;
    border: 1px solid #333;
}
.newDepartment-header {
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
}

</style>

