<template>
  <div>
    <div style="clear: both; height: 70px">
      <notepad-header
        :docStatusType="docStatusType"
        :isNotepad="isNotepad"
        :industryItemType="industryItemType"
        :industryItemID="industryItemID"
      ></notepad-header>
      <div class="container-fluid">
      <div class="data-grid col">
      <div class="notepad-list">
        {{ "List of " + docStatusType + " " + isNotepad }}
      </div>
        <ag-grid-vue
          style="height: 600px"
          class="ag-theme-balham"
          :gridOptions="gridOptions"
          :rowData="rowData"
          rowSelection="multiple"
          rowHeight="50"
          pagination="true"
          paginationPageSize="10"
          floatingFilter="true"
        >
        </ag-grid-vue>
      </div>
    </div>
    </div>
    <div v-if="showAuditModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Auditlogs for document - " + title }}
                  <button
                    type="button"
                    class="close"
                    @click="showAuditModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Auditlogs for document</span>
                  <div class="data-table">
                    <ag-grid-vue
                      style="height: 200px"
                      class="ag-theme-balham"
                      :gridOptions="gridDocumentOptions"
                      :rowData="documentLogData"
                      rowSelection="multiple"
                      rowHeight="50"
                      pagination="true"
                      paginationPageSize="10"
                      floatingFilter="true"
                    >
                    </ag-grid-vue>
                  </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showAuditModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import Vue from "vue";
import notepadHeader from "./notepadHeader";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";
import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";

Vue.use(VueClipboard);
export default {
  components: { notepadHeader, AgGridVue },
  directives: {
    "b-tooltip": BTooltipDirective,
  },
  data() {
    return {
      isNotepad: "",
      docStatusType: "",
      industryItemID: "",
      batchfound: false,
      gridOptions: null,
      rowData: null,
      gridDocumentOptions: null,
      documentLogData: null,
      industryItemType: null,
      showAuditModal: false,
    };
  },
  beforeMount() {
    this.isNotepad = this.$route.query.isNotepad;
    this.docStatusType = this.$route.query.docStatusType;
    this.industryItemType = this.$route.query.industryItemType;
    this.industryItemID = this.$route.query.industryItemID;
    this.gridOptions = {
      columnDefs: this.createColDefs(),
    };
    this.gridDocumentOptions = {
      columnDefs: this.createAuditColDefs(),
    };
  },
  mounted() {
    this.loadRowData();
  },
  methods: {
    loadRowData() {
      if (this.docStatusType.toLowerCase() == "batch") {
        this.batchfound = true;
      } else {
        this.batchfound = false;
      }

      let authCookieObj = this.getCookie("auth-token");
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      if(authObj.departments && authObj.departments[0] ){
        var docListURL;
        docListURL =
        "/api/notepad/organizations/" + 
        authObj.orgs[0].orgID + "?status1=" +
        this.docStatusType.toLowerCase();
      if (
        this.docStatusType.toLowerCase() == "batch" ||
        this.docStatusType.toLowerCase() == "live" ||
        this.docStatusType.toLowerCase() == "archived"
      )
        docListURL =
          "/api/notepadBatch/organizations/" + 
          authObj.orgs[0].orgID + 
          "?status1=" +
          this.docStatusType.toLowerCase() +
          "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID;
      axios
        .get(docListURL)
        .then((response) => {
          console.log(response);
          this.rowData = docListURL;
          this.rowData = response.data.items;
        })
        .catch((reason) => {
          console.log(reason);
          this.rowData = [];
        });
      }
      else{
        docListURL =
        "/api/notepad/organizations/" + 
        authObj.orgs[0].orgID + "?status1=" +
        this.docStatusType.toLowerCase();
      if (
        this.docStatusType.toLowerCase() == "batch" ||
        this.docStatusType.toLowerCase() == "live" ||
        this.docStatusType.toLowerCase() == "archived"
      )
        docListURL =
          "/api/notepadBatch/organizations/" + 
          authObj.orgs[0].orgID + 
          "?status1=" +
          this.docStatusType.toLowerCase() +
          "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID;
      axios
        .get(docListURL)
        .then((response) => {
          console.log(response);
          this.rowData = docListURL;
          this.rowData = response.data.items;
        })
        .catch((reason) => {
          console.log(reason);
          this.rowData = [];
        });
      }
    },
    handleRowClick(row) {
      let item = row.data;
      if (
        this.docStatusType.toLowerCase().startsWith("batch") ||
        this.docStatusType.toLowerCase().startsWith("live") ||
        this.docStatusType.toLowerCase().startsWith("archived")
      ) {
        window.location.href =
          "/notepadbatch?batchID=" +
          item.batchID +
          "&docStatusType=" +
          this.docStatusType.toLowerCase() +
          "&isNotepad=" +
          this.isNotepad +
          "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID;
      } else {
        window.location.href =
          "/newNotepad?docid=" +
          item.docID +
          "&docStatusType=" +
          this.docStatusType.toLowerCase() +
          "&isNotepad=" +
          this.isNotepad + "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID;
      }
    },
    viewAuditLogPopup(item) {
      this.showAuditModal = true;
      this.title = item.title;
      this.loadAuditLog(item);
    },
    loadAuditLog(item) {
      var auditLogListURL = "/api/auditLogs?name=document&id=" + item.docID;
      axios
        .get(auditLogListURL)
        .then((response) => {
          console.log(response);
          this.documentLogData = response.data.items;
        })
        .catch((reason) => {
          console.log(reason);
          this.documentLogData = [];
        });
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    createColDefs() {
      var that = this;
      var colDefs = [
        {
          headerName: "TITLE",
          field: "title",
          width: 175, 
          cellStyle: { "white-space": "normal", 'line-height': 1.5 }, 
          autoHeight: true,
          sortable: true,
          filter: true,
          cellRenderer: function (params) {
            console.log(params);
            return (
              '<a href="#" class="title" title="' +
              params.value +
              '">' +
              params.value +
              "</a>"
            );
          },
          onCellClicked: function (params) {
            console.log(params);
            console.log(params.data);
            return that.handleRowClick(params);
          },
        },
        {
          headerName: "STATUS",
          field: "status1",
          sortable: true,
          filter: true,
        },
        {
          headerName: "UPDATED BY",
          field: "updatedBy",
          sortable: true,
          filter: true,
        },
        {
          headerName: "AUDIT LOG",
          template: '<a class="view">View</a>',
          sortable: true,
          filter: true,
          cellRenderer: function (params) {
            console.log(params);
            return '<a href="#" class="title" title="' + params.value + '">' + params.value + '</a>';
          },
          onCellClicked: function(params) {
            console.log(params);
            console.log(params.data);
            return that.viewAuditLogPopup(params.data);
          }
        },
        {
          headerName: "UPDATED TIME",
          field: "updatedTime",
          sortable: true,
          filter: true,
        },
        {
          headerName: "CREATED BY",
          field: "createdBy",
          sortable: true,
          filter: true,
        },
        {
          headerName: "CREATED TIME",
          field: "createdTime",
          sortable: true,
          filter: true,
        },
      ];
      if (this.docStatusType == "batch") {
        colDefs.splice(0, 0, {
          headerName: "BATCH NUMBER",
          field: "batchNumber",
          sortable: true,
          filter: true,
        });
      }
      if (this.docStatusType == "shared") {
        colDefs.splice(1, 0, {
          headerName: "SHARED TO",
          field: "info.email",
          sortable: true,
          filter: true,
        });
      }
      return colDefs;
    },
    createAuditColDefs() {
      // var that = this;
      var auditColDefs = [
        {
          headerName: "OPERATION",
          field: "operation",
          sortable: true,
          filter: true,
        },
        {
          headerName: "DATE",
          field: "date",
          sortable: true,
          filter: true,
        },
        {
          headerName: "BODY",
          field: "updatedBy",
          sortable: false,
          filter: false,
          cellRenderer: function (params) {
            // console.log(params);
            return JSON.stringify(params.data);
          }
        },
      ];
      return auditColDefs;
    },
    
  },

  created() {
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
.sharedHeader {
  width: 200px;
}
.sharedRow {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notepad-list {
  font-weight: 700;
  padding-bottom: 5px;
  text-align: center;
  // top: -70px;
  // position: relative;
  // margin-left: 180px;
  // background-color: #fff;
  // padding-left: 26px;
  // padding-top: 15px;
  // padding-bottom: 5px;
  // border-radius: 0.375rem;
  // width: calc(100% - 270px);
}

.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  // display: flex;
  align-items: center;
  // overflow: auto;
}
a.title {
  color: #000;
}
a.title:hover {
  // color: #000;
  text-decoration: underline !important;
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham .ag-cell {
    line-height: 15px !important; 
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid transparent;
    padding-left: 11px;
    padding-right: 11px;
    display: -webkit-inline-box !important;

}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.mt-7 {
  margin-top: -1rem !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.share-modal {
  max-width: 50%;
}
.view:hover{
  text-decoration: underline;
  cursor: pointer;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value span {
  height:20px;
}
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>