<template>
  <div>
    <div style="clear: both;height: 270px;">
      <document-header :docStatusType= "docStatusType" :templateType = "templateType" :industryItemType = "industryItemType" :industryItemID = "industryItemID"></document-header>
      <div class="doc-header-batch" v-if="documentTemp.title">
        {{
          stripHTML(documentTemp.title)+ " - " + documentTemp.batchNumber
        }}
      </div>
    </div>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col editControlParent">
          <!-- <div id="editorControlListLeft">
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="text-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/text.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Text</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="image-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/image.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Image</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="video-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/video.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Video</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/timer.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Timer</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="formula-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/formula-fx.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Formula</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="qrcode-input"
              draggable="true"
              tabindex="0"
            >
              <img src="/img/pptx/media.jpg" alt="avatar" class="u-photo" />
              <span class="leftIcon">QR</span>
            </div>
          </div> -->
        </div>
        <div class="col editorNew">
          <div class="card shadow">
            <div class="contacts"></div>
            <div class="card-body">
              <!-- <div v-if="documentTemp.title">
                <input
                  type="text"
                  :id="getId()"
                  class="form-control-alternative"
                  v-model="documentTemp.title"
                />
              </div>
              <div
                v-else-if="documentTemp.title"
                v-html="documentTemp.title"
                class="form-control-alternative"
              ></div> -->
              <div
                v-if="documentTemp.title"
                v-html="documentTemp.title"
                class="form-control-alternative"
                v-on:click="handleOverlaysClick"
              ></div>
              <div v-if="!documentTemp.title">
                <!-- <textarea v-model.lazy="tempDocTitle" style="width: 100%"></textarea> -->
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                  v-on:click="handleOverlaysEdit"
                ></div>
                <!-- <base-button
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addTitle($event)"
                >Add Title</base-button>-->
              </div>
              <div
                v-for="(section, sec_index) in documentTemp.sections"
                :key="section.id || section.title"
              >
                <div class="sec-toggle" @click="sectionToggle">
                  <label
                    class="form-control-label"
                    style="margin:7px"
                  >{{("Section " + (sec_index+1) + " : ")}}</label>
                  <label
                    class="form-control-label"
                    v-if="section.description"
                    v-html="section.description"
                  ></label>
                  <label
                    class="form-control-label ctrl-collapse"
                    style="margin-right:15px;display:none;float:right;"
                  >+</label>
                  <label
                    class="form-control-label ctrl-expand"
                    style="margin-right:15px;float:right;"
                  >-</label>
                </div>
                <div class="sec-body">
                  <!-- <div
                    v-if="section.description"
                    v-html="section.description"
                    class="form-control-alternative"
                  ></div>-->
                  <div
                    v-for="(step,step_index) in section.steps"
                    :key="step.id || step.title"
                    style="padding-left:15px ;"
                  >
                    <div
                      class="step-action-btn-group"
                      style="float: right; padding-left: 2px;  margin-left: 5px; color: #2dce89" >
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.datatrendfound"
                        @click="DataTrend($event,step,sec_index,step_index)"
                      >Data Trend</button>
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.crossreffound"
                        @click="crossref($event,step)"
                      >Cross Reference</button><br v-if="step.crossreffound && !step.videofound && !step.qrimagefound && !step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound">  
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.videofound"
                        @click="UploadVideo($event,step)"
                      >Upload Video</button><br v-if="step.videofound && !step.qrimagefound && !step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound"> 
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.qrimagefound"
                        @click="UploadQR($event,step)"
                      >Upload QR Code</button><br v-if="step.qrimagefound && !step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound"> 
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.mediaimagefound"
                        @click="UploadImg($event,step)"
                      >Upload Image</button><br v-if="step.mediaimagefound && !step.formulafound && !step.timer && !step.inputfound"> 
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.formulafound"
                        @click="formulaCalculate($event,step)"
                      >Calculate</button><br v-if="step.formulafound && !step.timer && !step.inputfound"> 
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.timer"
                        @click="startTimer($event,step)"
                      >Start Timer</button> 
                      <span v-if="step.timer && step.spanVisible">{{' '+ step.timermm + ' : ' + step.timerss + ' '}}</span>
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.timer"
                        @click="stopTimer($event,step)"
                      >Stop Timer</button><br v-if="step.timer && !step.inputfound">
                      <button
                        class="btn-default1 btn-sm btn-step-action1"
                        v-if="step.inputfound"
                        @click="setValue($event,step)"
                      >Enter Data</button><br v-if="step.inputfound">
                      <div style="float: right;">
                        <label for="checkbox" style="font-size: 14px;">Executed</label>
                        <input type="checkbox" id="checkbox" style="   margin-left: 5px;" v-model="step.executed" :disabled="step.executed" @click="executeStep($event,step)"/> 
                        <span class="user-audit" v-if="step.executed">{{step.executedBy + " - on " + formatDate(step.executedTime) }}</span><br>
                        <label for="checkbox" style="margin-left: 5px; font-size: 14px;" >Verified</label>
                        <input type="checkbox" id="checkbox" style="    margin-left: 5px;" v-model="step.approved" :disabled="step.approved" @click="approveStep($event, step)"/>
                        <span class="user-audit" v-if="step.approved">{{step.approvedBy + " - on " + formatDate(step.approvedTime) }}</span><br>
                      </div>
                    </div>
                    <label class="form-control-label">Step:</label>
                    <label class="form-control-label">{{sec_index+1 + "." + (step_index+1)}}</label>
                    <div
                      v-if="step.description"
                      v-html="step.description"
                      class="form-control-alternative"
                    ></div>

                    <!-- <div
                      v-for="(step2, step_inner_index) in step.steps"
                      :key="step2.id || step2.title"
                      style="padding-left:30px ;"
                    >
                      <label class="form-control-label">Step Inner:</label>
                      <label
                        class="form-control-label"
                      >{{sec_index+1 + "." + (step_index+1) +"." + (step_inner_index+1) }}</label>
                      <div
                        v-if="step.description"
                        v-html="step.description"
                        class="form-control-alternative"
                      ></div>
                      <label class="form-control-label">Add Step Inner:</label>
                      <div
                        :id="getId()"
                        contenteditable="true"
                        class="ckeditor-x form-control-alternative"
                        @focus="registerEditor($event)"
                      ></div>
                      <button
                        class="btn float-right btn-default btn-sm"
                        @click="addStepInner(step, $event)"
                      >Add Step Inner</button>
                    </div>-->
                  </div>
                  <!-- <div v-if="section">
                    <label class="form-control-label">Add Step:</label>
                    <div
                      :id="getId()"
                      contenteditable="true"
                      class="ckeditor-x form-control-alternative"
                      @focus="registerEditor($event)"
                    ></div>
                    <button
                      class="btn float-right btn-default btn-sm"
                      @click="addStep(section, $event)"
                    >Add Step</button>
                  </div>-->
                </div>
              </div>
              <!-- <div v-if="documentTemp.title">
                <label class="form-control-label">Add Section:</label>
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                ></div>
                <button
                  class="btn float-right btn-default btn-sm"
                  @click="addSection($event)"
                >Add Section</button>
              </div>-->
            </div>
            <!-- <button class="btn float-right btn-default btn-sm" @click="saveDocument()">Save Document</button> -->
          </div>
        </div>
        <div class="col editRightParent" v-if="this.documentTemp.status1 != 'archived'">
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                @click="saveDocument()"
              >SAVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
           <!--    <a href="#!" class="btn btn-info">REVIEW</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
           <!--    <a href="#!" class="btn btn-info">COMMENT</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            <a href="#!" class="btn btn-info" style="display: none">SHARE</a> 
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            <!--   <a href="#!" class="btn btn-info">CHECK</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            <button
                class="btn btn-info"
                @click="archiveBatch($event, step)"
              >FINISH</button>
              <!-- <a href="#!" class="btn btn-info" @click="archiveBatch($event, step)">FINISH</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
           <!--    <a href="#!" class="btn btn-info">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{this.documentTemp}} -->
  <!-- Begin: Image Upload Batch Document -->
    <div v-if="showImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Image" }}
                  <button
                    type="button"
                    class="close"
                    @click="showImageBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Image {{ImageVarName}}:</span>
                  <input type="file" id="file" style="margin-left: 36px; margin-bottom: 10px;" ref="file" name="file-to-upload" v-on:change="handleFileUploads()">
                    <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px;"
                    class="float-right1"
                    @click="addImage($event)"
                    >Add Image</base-button>
                    <p v-if="filespath"><img style="width:100px; height:100px; margin-bottom: 10px;" :src="filespath" alt=""></p>
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceImg('Image')"
                  >Upload</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageBatchModal=false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: Image Upload Document -->

  <!-- Begin: QR Image Upload Batch Document -->
    <div v-if="showQRImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload QR Code" }}
                  <button
                    type="button"
                    class="close"
                    @click="showQRImageBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload QR Code {{QRImageVarName}}:</span>
                    <p class="error">{{ error }}</p>
                    <qrcode-stream style="width:70%" :camera="camera" @decode="onDecode" @init="onInit"></qrcode-stream>
                    <p class="decode-result">Result: <b>{{ result }}</b></p>
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceImg('QR')"
                  >Upload</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showQRImageBatchModal=false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: QR Image Upload Document -->

   <!-- Begin: Video Image Upload Batch Document -->
    <div v-if="showVideoBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Video" }}
                  <button
                    type="button"
                    class="close"
                    @click="showVideoBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Video {{VideoVarName}}:</span>
                  <input type="file" id="filevideo" style="margin-left: 36px; margin-bottom: 10px;" ref="filevideo" name="file-to-upload" v-on:change="handleFileVideoUploads()">
                  <base-button
                  size="sm"
                  type="default"
                  style="margin-bottom: 10px;"
                  class="float-right1"
                  @click="addVideo($event)"
                >Add Video</base-button>
                
                <p v-if="videopath">
                  <video width="320" height="240" controls>
                    <source :src="videopath" :type="videotype">
                    Your browser does not support the video tag.
                  </video></p>
                    
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceImg('VIDEO')"
                  >Upload</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoBatchModal=false"
                  >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: Video Image Upload Document -->

<!-- Begin: Data Tred Batch Document -->
    <div v-if="showDataTredBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Data Trending" }}
                  <button
                    type="button"
                    class="close"
                    @click="showDataTredBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Data Trending</span>
                    
                   <div class="table-responsive">
                      <base-table
                        class="table align-items-center table-flush"
                        :thead-classes="table-equ"
                        tbody-classes="list"
                        :data="documentdataTred"
                      >
                        <template slot="columns">
                          <th>Batch No</th>
                          <th>Variable Name</th>
                          <th>Value</th>
                        </template>

                        <template slot-scope="{row}">
                          <td>
                            <div class="avatar-group">
                              <span class="status">{{row.batchno}}</span>
                            </div>
                          </td>
                          <td>
                            <div class="avatar-group">
                              <span class="status">{{row.varname}}</span>
                            </div>
                          </td>
                          <td>
                            <div class="avatar-group">
                              <span class="status">{{row.varvalue}}</span>
                            </div>
                          </td>
                        </template>
                      </base-table>
                    </div>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: Data Tred Document -->

<!-- Begin: Cross Reference Batch Document -->
    <div v-if="showCrossRefBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Cross Reference" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCrossRefBatchModal=false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <label
                    class="form-control-label"
                    v-html="documentcrossRef"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  <!-- End: Cross Reference Document -->
  </div>
</template>
<script>
/* eslint-disable no-console */

import axios from "../../../utils/axiosinstance";

// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import documentHeader from "@/views/process/documents/documentHeader";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { documentHeader, QrcodeStream },
  // props: ["docid", "templateType", "docStatusType"],
  data() {
    return {
      batchID:"",
      templateType: "",
      docStatusType: "",
      industryItemType: "",
      industryItemID: "",
      documentTemp: {},
      documentdataTred: [],
      documentcrossRef: '',
      temptag: [],
      tagjson:{},
      timermm: "00",
      timerss: "00",
      timerStart: false,
      spanVisible: false,
      oldstring: [],
      showImageBatchModal: false,
      showDataTredBatchModal: false,
      showCrossRefBatchModal: false,
      VideoVarName: '',
      videopath: '',
      videotype: '',
      videopathold: '',
      videotypeold: '',
      showVideoBatchModal: false,
      ImageVarName: '',
      ImageSrc: '',
      showQRImageBatchModal: false,
      QRImageVarName: '',
      QRImageSrc: '',
      result: '',
      oldString: 'Upload QR Code',
      error: '',
      camera: 'auto',
      files: '', 
      filespath: '',
      tempstep: null,
      authCookie: null
    };
  },
  beforeMount(){
      this.batchID=this.$route.query.batchID;
      this.docStatusType=this.$route.query.docStatusType;
      this.templateType=this.$route.query.templateType;
      this.industryItemType = this.$route.query.industryItemType;
      this.industryItemID = this.$route.query.industryItemID;
},
  mounted() {
    if (this.batchID) {
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/batches/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          this.documentTemp = response.data.item;
          this.documentTemp.title = this.documentTemp.title.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');
          this.usagelogtagTemp = {};
          
          //steplock
          this.steplock();

        })
        .catch(reason => {
          console.log(reason);
          this.documentTemp = [];
          this.usagelogtagTemp = {};
        });
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    // this.authCookie = JSON.parse(authCookieObj.substring(2));
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token")
            .substring(2)
            .split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  beforeCreate() {
    //let recaptchaScript = document.createElement("script");
    //recaptchaScript.setAttribute("src", "ckeditor.js");
    //document.head.appendChild(recaptchaScript);
  },
  methods: {
      async onDecode (content) {
      this.result = content;
      this.turnCameraOff();
      await this.timeout(3000);
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    turnCameraOn () {
      this.camera = 'auto'
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
},
    steplock(){
    //steplock
          var steplockfound = false;
          var executedstap = false;
          var stepdeti;
          var stepdetj;
          var disarray = [];
          var i = 0;
          var totalsec = this.documentTemp.sections;
          if(!totalsec) {
            return;
          }
          for(i=0;i<totalsec.length;i++)
          {
            var j = 0;
            var eachstep = totalsec[i].steps
            for(j=0;j<eachstep.length;j++)
            {
                //var v1 = eachstep[j].description.toString();
                var dom = document.createElement('div');
                dom.innerHTML = eachstep[j].description;
                var imgtag = dom.getElementsByTagName('img');
                  if(imgtag != undefined && imgtag.length > 0)
                  {
                    var ki;
                    for(ki=0;ki<imgtag.length;ki++)
                    {
                      //console.log(imgtag[ki]);
                      var imgtagalt = imgtag[ki].getAttribute('alt');
                      //console.log(imgtagalt);
                      if(imgtagalt.toString() == "StepLock")
                      {
                        steplockfound = true;
                        stepdeti = i;
                        stepdetj = j;
                        if(eachstep[j].executed != undefined && eachstep[j].executed == true)
                        {
                          executedstap = true;
                        }
                        //console.log((i+1) +'.'+ (j+1));
                        //console.log(steplockfound);
                      }
                    }
                  }
                if(steplockfound == true)
                {
                    if(i == stepdeti)
                    {
                      if(j > stepdetj)
                      {
                        //console.log('desable');
                        //console.log((i+1) +'.'+ (j+1));
                        disarray.push(((i+1) +'.'+ (j+1)));
                      }
                    }
                    else if(i > stepdeti)
                    {
                      //console.log('desable');
                      //console.log((i+1) +'.'+ (j+1));
                      disarray.push(((i+1) +'.'+ (j+1)));

                    }
                }
                    
            }
          }

          //find div
          //console.log('disarray');
          //console.log(disarray);
          if(disarray.length > 0)
          {
            setTimeout(function() {
              var cuslabel = document.querySelectorAll('label.form-control-label');
              //console.log(cuslabel);
              var li;
              for(li=0;li<cuslabel.length;li++)
              {
                var ji;
                for(ji=0;ji<disarray.length;ji++)
                {
                  if(cuslabel[li].innerHTML.toString() == disarray[ji].toString())
                  {
                    //console.log(cuslabel[li].innerHTML.toString());
                    //console.log(cuslabel[li].parentElement.setAttribute("class","custom-disable"));
                    if(executedstap == true)
                    {
                      if(cuslabel[li].parentElement.getAttribute("class") != undefined)
                      {
                        if(cuslabel[li].parentElement.getAttribute("class").toString() == "custom-disable")
                        {
                          cuslabel[li].parentElement.removeAttribute("class");
                        }
                      }
                      
                    }
                    else
                    {
                      cuslabel[li].parentElement.setAttribute("class","custom-disable");
                    }
                    
                  }
                }
              }
            }, 100);
          }
          
  },
  ReplaceImg(type)
  {
    var i;
    for(i=0;i<this.documentTemp.sections.length;i++)
    {
      var j;
      for(j=0;j<this.documentTemp.sections[i].steps.length;j++)
      {
        if(this.tempstep.uid.toString() == this.documentTemp.sections[i].steps[j].uid.toString())
        {
          //console.log('Replace Fun');
          //console.log(this.documentTemp.sections[i].steps[j].description);
          var dom = document.createElement('div');
          dom.innerHTML = this.documentTemp.sections[i].steps[j].description;
          if(type == "VIDEO")
          {
            var atag = dom.getElementsByTagName('a');
            if(atag != undefined && atag.length > 0)
            {
              //var ki;
              for(ki=0;ki<atag.length;ki++)
              {
                //console.log(atag[ki].innerHTML);
                var oldstr = atag[ki].innerHTML;
                altarray = atag[ki].innerHTML.toString().split('Video: ');
                if(altarray != undefined && altarray.length > 0)
                {
                  if(altarray[0] == "Upload ")
                  {
                    var vtag = dom.getElementsByTagName('video');
                    if(vtag != undefined && vtag.length > 0)
                    {
                      if(this.videopathold != '' && this.videotypeold != '')
                      {
                        this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(this.videopathold,this.videopath);
                        this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(this.videotypeold,this.videotype);
                        this.videopathold = this.videopath; 
                        this.videotypeold = this.videotype;
                      }
                    }
                    else
                    {
                    atag[ki].insertAdjacentHTML('beforeend', '<br> <video id="'+ altarray[1] +'" width="320" height="240" controls><source src="'+ this.videopath +'" type="'+ this.videotype +'">Your browser does not support the video tag.</video>');
                    //console.log('video tag added');
                    //console.log(atag[ki].innerHTML);
                    this.videopathold = this.videopath; 
                    this.videotypeold = this.videotype;
                    this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(oldstr,atag[ki].innerHTML.toString());
                    }
                  }          
                }
              }
            }
          }
          else
          {  
            var imgtag = dom.getElementsByTagName('img');
            if(imgtag != undefined && imgtag.length > 0)
            {
              var ki;
              for(ki=0;ki<imgtag.length;ki++)
              {
                var imgtagalt = imgtag[ki].getAttribute('alt');
                var imgtagsrc = imgtag[ki].getAttribute('src');
                var altarray = imgtagalt.split('_');
                var varName = null;
                if(altarray != undefined && altarray.length > 0)
                {
                  if(altarray[0] == "mediaImage" && type == "Image")
                  {
                    varName = altarray[1];
                    if (varName == this.ImageVarName && this.filespath != '')
                    {
                      this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace("Upload Image","");   
                        imgtag[ki].setAttribute('src',this.filespath);
                        this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(imgtagsrc,this.filespath); 
                    }
                  }
                  //console.log("QR");
                  //console.log(altarray[0]);
                  if(altarray[0] == "qrImage" && type == "QR")
                  {
                    varName = altarray[1];
                    if (varName == this.QRImageVarName && this.result != '')
                    {
                      this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(this.oldString,this.result);   
                        this.oldString = this.result;
                        //imgtag[ki].setAttribute('src',this.filespath);
                        //this.documentTemp.sections[i].steps[j].description = this.documentTemp.sections[i].steps[j].description.toString().replace(imgtagsrc,this.filespath); 
                    }
                  }
                } 
              }
            }
          }
          
        }
      }
    }
    if(type == "Image")
    {
      this.ImageVarName = '';
      this.ImageSrc = '';
      this.filespath = '';
      this.showImageBatchModal = false;
    } 
    else if(type == "QR")
    {
      this.showQRImageBatchModal = false;
      this.QRImageVarName = '';
    } 
    else if(type == "VIDEO")
    {
      this.showVideoBatchModal = false;
      this.VideoVarName = '';
      this.videopath = '';
      this.videotype = '';
    }
    
  },
  crossref(e, step)
  {
    console.log(e);
    console.log(step);
    this.documentcrossRef = 'No Data Found';
    var dom = document.createElement('div');
    dom.innerHTML = step.description;
      var atag = dom.getElementsByTagName('a');
        if(atag != undefined && atag.length > 0)
        {
          var ki;
          for(ki=0;ki<atag.length;ki++)
          {
            if(atag[ki].innerHTML.toString().indexOf('ross Reference / Document Title =',0) > 0)
            {
              var altarray = atag[ki].innerHTML.toString().split('/');
              if(altarray != undefined && altarray.length > 0)
              {
                var DocTitle = altarray[1].split('=')[1].toString().trim();
                var secid = altarray[2].split('=')[1].toString().trim();
                var stepid = altarray[3].split('=')[1].toString().trim();
                console.log(DocTitle);
                secid = secid-1;
                stepid = (stepid.split('.')[1])-1;
                console.log(secid);
                console.log(stepid);
              }

              axios
              .get(
                "/api/organizations/" +
                  this.authCookie.orgs[0].orgID +
                  "/crossref/" +
                  DocTitle
              )
              .then(response => {
                this.documentcrossRef = response.data.item.sections[secid].steps[stepid].description;
                console.log(this.documentcrossRef);
                this.showCrossRefBatchModal = true;
               
              })
              .catch(reason => {
                console.log(reason);
                this.documentcrossRef = 'No Data Found';
              });
            
              

            }

          }
        }
  },
  UploadVideo(e, step)
  {
    console.log(e);
    console.log(step);
    this.tempstep = step;
    this.videopath ='';
    this.videotype = '';
    var dom = document.createElement('div');
          dom.innerHTML = step.description;
          var atag = dom.getElementsByTagName('a');
          if(atag != undefined && atag.length > 0)
          {
            var ki;
            for(ki=0;ki<atag.length;ki++)
            {
              console.log(atag[ki].innerHTML);
              var altarray = atag[ki].innerHTML.toString().split('Video: ');
              if(altarray != undefined && altarray.length > 0)
              {
                if(altarray[0] == "Upload ")
                {
                  var vtag = dom.getElementsByTagName('video');
                    if(vtag != undefined && vtag.length > 0)
                    {
                      this.VideoVarName = vtag[0].getAttribute('id');
                      this.showVideoBatchModal = true;
                    }
                    else
                    {
                      var varName = altarray[1];
                      this.VideoVarName = varName;
                      this.showVideoBatchModal = true;
                    }
                  
                }          
              }
            }
          }
  },
  UploadQR(e, step)
  {
    console.log(e);
    console.log(step);
    this.tempstep = step;
    var dom = document.createElement('div');
      dom.innerHTML = step.description;
      var imgtag = dom.getElementsByTagName('img');
        if(imgtag != undefined && imgtag.length > 0)
        {
          var ki;
          for(ki=0;ki<imgtag.length;ki++)
          {
            var imgtagalt = imgtag[ki].getAttribute('alt');
            var altarray = imgtagalt.split('_');
            var varName = null;
            if(altarray != undefined && altarray.length > 0)
            {
              if(altarray[0] == "qrImage")
              {
                varName = altarray[1];
                this.QRImageVarName = varName;
                this.QRImageSrc = imgtagsrc;
                this.showQRImageBatchModal = true;
                this.turnCameraOn();
                this.result = "";
              }
            }
            var imgtagsrc = imgtag[ki].getAttribute('src');
            //console.log(imgtagalt);
          }
        }
    
  },
  UploadImg(e, step)
  {
    console.log(e);
    console.log(step);
    this.tempstep = step;
    var dom = document.createElement('div');
      dom.innerHTML = step.description;
      var imgtag = dom.getElementsByTagName('img');
        if(imgtag != undefined && imgtag.length > 0)
        {
          var ki;
          for(ki=0;ki<imgtag.length;ki++)
          {
            var imgtagalt = imgtag[ki].getAttribute('alt');
            var altarray = imgtagalt.split('_');
            var varName = null;
            if(altarray != undefined && altarray.length > 0)
            {
              if(altarray[0] == "mediaImage")
              {
                varName = altarray[1];
                this.ImageVarName = varName;
                this.ImageSrc = imgtagsrc;
                this.showImageBatchModal = true;
              }
            }
            var imgtagsrc = imgtag[ki].getAttribute('src');
            //console.log(imgtagalt);
          }
        }
    
  },
  handleFileUploads(){
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    handleFileVideoUploads(){
      this.files = this.$refs.filevideo.files[0];
      //console.log(this.files);
    },
    addVideo(e)
    {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadVideosFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            console.log(response);
            //app.documentTemp = response.data.item;
            app.videopath = "";
            app.videotype = "";
            var path = response.data.fileUrl;
            //console.log(response.data.fileUrl);
            //console.log(response.data.fileName);
            //console.log(response.data.uploaded);
            //var type = response.data.fileName.split(".")[1];
            //if(type == "mp4")
            //{
              app.videopath = path.toString();
              app.videotype = response.data.type.toString();
              console.log(app.videopath);
              console.log(app.videotype);
            //}
            //else
            //{
              //app.filespath = path.toString();
            //}
            
            alert("Video Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });

    },
    addImage(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadWidgetFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            console.log(response);
            //app.documentTemp = response.data.item;
            app.filespath = "";
            var path = response.data.fileUrl;
            //console.log(response.data.fileUrl);
            //console.log(response.data.fileName);
            //console.log(response.data.uploaded);

            app.filespath = path.toString();
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });
    },
  formulaCalculate(e, step)
  {
    console.log(e);
    console.log(step);
    //var newstring = null;
    //console.log('old');
    //console.log(this.oldstring);
    if(this.oldstring != null && this.oldstring.length > 0)
    {
      var i;
      for(i=0;i<this.oldstring.length;i++)
      {
        step.description = step.description.toString().replace(this.oldstring[i],"");
      }
    }
    var dom = document.createElement('div');
          dom.innerHTML = step.description;
          var atag = dom.getElementsByTagName('a');
          if(atag != undefined && atag.length > 0)
          {
            var ki;
            for(ki=0;ki<atag.length;ki++)
            {
              console.log(atag[ki].innerHTML);
              var text = atag[ki].innerHTML.toString().split('=');
              if(text != undefined && text[1] != undefined)
              {
                var ResultText = "Result : " + text[1].toString();
                while(text[1].toString().search("{") > 0)
                {
                  var varname = text[1].toString().substring((text[1].toString().search("{")+1),(text[1].toString().search("}")));
                  var orgtext = text[1].toString().substring((text[1].toString().search("{")),(text[1].toString().search("}")+1));
                  //console.log(varname);
                  //console.log(orgtext);
                  var val1 = prompt("Enter Value for "+ varname, "");
                  text[1] = text[1].toString().replace(orgtext,val1);
                  ResultText = ResultText.toString().replace(orgtext,val1);
                }
                //this.$set(step, "formulaText", text[1]);
                console.log(text[1]);
                var ResultVal = eval(text[1]);
                //console.log(ResultVal);
                this.oldstring[ki] = "<br>" + ResultText + " = " + ResultVal;
                //console.log('new');
                //console.log(this.oldstring);
                step.description = step.description.toString().replace(atag[ki].innerHTML.toString(),atag[ki].innerHTML.toString() + this.oldstring[ki])
                //step.description = step.description + ResultText + " = " + ResultVal;
                //step.description = step.description.toString().replace(atag[ki].innerHTML.toString(),ResultVal);
              }
            }
          }
          //step.formulafound = false;

  },
  startTimer(e, step)
  {
    this.timerStart = true;
    this.spanVisible = true;
    this.timermm = 0;
    this.timerss = 0;
    this.$set(step, "timermm", this.timermm);
    this.$set(step, "timerss", this.timerss);
    this.$set(step, "timerStart", this.timerStart);
    this.$set(step, "spanVisible", this.spanVisible);
    console.log(e);
    //console.log("Start Timer");
    //console.log(step);
    this.countDownTimer(step);
  },
  stopTimer(e, step)
  {
    console.log(e);
    //console.log(step);
    this.timerStart = false;
    step.timerStart = false;
    //console.log(this.timermm + ' : ' + this.timerss);
    this.countDownTimer(step);
  },
  countDownTimer(step)
  {
    //console.log('countDownTimer');
    //console.log(step);
    //console.log(step.timerStart);
    if(step.timerStart)
    {
      setTimeout(() => {
        step.timerss += 1
        if( step.timerss == 60)
        {
           step.timerss = 0;
           step.timermm += 1;
        }
        this.countDownTimer(step);
      }, 1000)
    }

  },
  DataTrend(e, step,sec_index,step_index)
  {
    console.log(e);
    console.log(step);
    console.log(sec_index);
    console.log(step_index);
    console.log(this.documentTemp.batchID);
    axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.documentTemp.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = '';
          this.documentdataTred = [];
          
            var i;
            for(i=0;i<temp.length;i++)
            {
              var j
              batchNo = temp[i].batchNumber;
              for(j=0;j<temp[i].sections[sec_index].steps[step_index].dataTrandings.length;j++)
              {
                temptable = {};
                temptable.batchno = batchNo;
                temptable.varname = temp[i].sections[sec_index].steps[step_index].dataTrandings[j].varname;
                temptable.varvalue = temp[i].sections[sec_index].steps[step_index].dataTrandings[j].varvalue;      
                this.$set(this.documentdataTred, this.documentdataTred.length, temptable);
              }

            }
          
          this.showDataTredBatchModal = true;
        })
        .catch(reason => {
          console.log(reason);
          this.documentdataTred = [];
        });

  },
  setValue(e, step)
    {
      //console.log('set value');
      //console.log(step.description);
      //var DataTrandobj = [];
      
      var dom = document.createElement('div');
      dom.innerHTML = step.description;

      
      var atag = dom.getElementsByTagName('a');
      if(atag != undefined && atag.length > 0)
        {
          var i;
          for(i=0;i<atag.length;i++)
          {
            var ainnerHtml = atag[i].innerHTML;
            if (ainnerHtml.toString().indexOf('[DataTrend]',0) > 0)
            {
              var temparray = ainnerHtml.toString().split(':____');
              var VarName = temparray[0];
              console.log(VarName);
              var pValText = prompt("Please Enter Value for " + VarName + ": ", "");
              if (!pValText) return;
              if(step.dataTrandings != undefined && step.dataTrandings.length > 0)
              {
                var j;
                for(j=0;j<step.dataTrandings.length;j++)
                {
                  if(step.dataTrandings[j].varname == VarName)
                  {
                    step.dataTrandings[j].varvalue = pValText;
                    step.dataTrandings[j].batchno = this.documentTemp.batchNumber;

                    //var dataTred = {};
                    //dataTred.uid = uuidv4();
                    //dataTred.varname = VarName;
                    //dataTred.varvalue = pValText;
                    //dataTred.batchno = this.documentTemp.batchNumber;
                    //dataTred.docid = this.documentTemp.docID;
                    //dataTred.sectionid = sec_index;
                    //dataTred.stepid = step_index;
                    //this.$set(DataTrandobj, DataTrandobj, dataTred);
                  }
                }
              }
              var orgtext = ainnerHtml.toString();
              var newtext =  orgtext.replace('____',pValText);
              //console.log('New OBJ');
              //console.log(DataTrandobj);
              step.description = step.description.toString().replace(orgtext,newtext);
            }
            else if(ainnerHtml.toString().indexOf(':____',0) > 0)
            {
              temparray = ainnerHtml.toString().split(':____');
              VarName = temparray[0];
              console.log(VarName);
              pValText = prompt("Please Enter Value for " + VarName + ": ", "");
              if (!pValText) return;
              orgtext = ainnerHtml.toString();
              newtext =  orgtext.replace('____',pValText);
              console.log(orgtext);
              console.log(newtext);
              step.description = step.description.toString().replace(orgtext,newtext);
            }
            else if(ainnerHtml.toString().indexOf(':__________',0) > 0)
            {
              temparray = ainnerHtml.toString().split(':__________');
              VarName = temparray[0];
              console.log(VarName);
              pValText = prompt("Please Enter Value for " + VarName + ": ", "");
              if (!pValText) return;
              orgtext = ainnerHtml.toString();
              newtext =  orgtext.replace('__________',pValText);
              console.log(orgtext);
              console.log(newtext);
              step.description = step.description.toString().replace(orgtext,newtext);

            }
          }

        }

      

    },
  executeStep( e, step)
    {
      //usagelog
      //alert('Title ' + this.documentTemp.title);
      //alert('Batch Number ' + this.documentTemp.batchNumber);
      //var el = e.target.parentElement.parentElement.querySelector(".form-control-label");
      var el = e.target.parentElement.parentElement.querySelectorAll("label.form-control-label");
      //var el = el1.getElementsByClassName(".form-control-label");
      var steptext;
      if(el != undefined && el[0]!= undefined && el[1] != undefined)
      {
        steptext = el[0].innerText.toString() + el[1].innerText.toString();
        //alert(el[0].innerText.toString() + el[1].innerText.toString());

      }
      //alert(steptext);
      var tagel = e.target.parentElement.parentElement.querySelector(".form-control-alternative");
      var u = undefined;
      if(tagel != null)
      {
        u = tagel.getElementsByTagName('u');
      }
      //console.log(u);
          var i;
          if(u != undefined)
          {
            //var temptag = [];
            for(i=0;i<u.length;i++)
            {
              var itag = u[i].getElementsByTagName('em');
                if(itag[0]!= undefined && itag[0].innerText != undefined)
                {
                  u[i].setAttribute('id',itag[0].innerText.toString().split(',')[0]);
                  var tagid = itag[0].innerText.toString().split(',')[0];
                  var tagname = itag[0].innerText.toString().split(',')[2];
                  //alert(tagid + ': ' + tagname );
                  //var tagjson = {};
                  this.tagjson.title = this.documentTemp.title;
                  this.tagjson.batchNumber = this.documentTemp.batchNumber;
                  this.tagjson.orgID = this.authCookie.orgs[0].orgID;
                  this.tagjson.stepNo = steptext;
                  this.tagjson.equID = tagid;
                  this.tagjson.equName = tagname;
                  this.tagjson.executedBy = this.authCookie.userName;
                  this.tagjson.executedTime = new Date();
                  //console.log(tagjson);
                  this.temptag.push(this.tagjson); 
                  
                }
            }
          }

      console.log(step);
      if(step.timer != undefined)
      {
        step.description = step.description + ' Timer(MM:SS) = ' + step.timermm + ' : ' + step.timerss ;
        this.$set(step, "timer", false);
      }
      if(step.inputfound != undefined)
      {
        this.$set(step, "inputfound", false);
      }
      if(step.qrimagefound != undefined)
      {
        this.$set(step, "qrimagefound", false);
      }
      if(step.videofound != undefined)
      {
        this.$set(step, "videofound", false);
      }
      if(step.mediaimagefound != undefined)
      {
        this.$set(step, "mediaimagefound", false);
      }
      if(step.formulafound != undefined)
      {
        this.$set(step, "formulafound", false);
      }
      this.$set(step, "executed", true);
      this.$set(step, "executedBy", this.authCookie.userName);
      this.$set(step, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(step, "executedTime", new Date());
      console.log(step);
    },
    approveStep( e, step)
    {
     this.$set(step, "approved", true);
      this.$set(step, "approvedBy", this.authCookie.userName);
      this.$set(step, "approvedByOrg",this.authCookie.orgs[0].orgID);
      this.$set(step, "approvedTime", new Date());
    },
    sectionToggle(e) {
      var secBody = e.target.parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "none";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "block";
      } else {
        secBody.style.display = "none";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "block";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "none";
      }
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach(sec => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach(step => {
          this.deleteAttr(step);
        });
      }
    },
    archiveBatch() {
      let app = this;
      this.deleteAttr(this.documentTemp);
      this.documentTemp.status1 = "archived";
      axios
        .put("/api/batches", this.documentTemp)
        .then(function(response) {
          console.log(response);
          alert("Batch archived successfully!");
        })
        .catch(function(error) {
          console.log(error);
          app.documentTemp = [];
        });
    },
    saveDocument() {
      this.steplock();
      let app = this;
      this.deleteAttr(this.documentTemp);
      if (!this.documentTemp.batchID) {
        axios
          .post("/api/batches", app.documentTemp)
          .then(function(response) {
            console.log(response);
            app.documentTemp = response.data.item;
            alert("Document Created Successfully!");
          })
          .catch(function(error) {
            console.log(error);
            app.documentTemp = [];
          });
      } else {
        app.documentTemp.updatedBy =
          app.documentTemp.updatedBy || this.authCookie.userName;
        axios
          .put("/api/batches", app.documentTemp)
          .then(function(response) {
            console.log(response);
            app.documentTemp = response.data.item;
            alert("Document Saved Successfully!");
          })
          .catch(function(error) {
            console.log(error);
            app.documentTemp = [];
          });
      }

            console.log(this.temptag);
            var c
            for(c=0;c<this.temptag.length;c++)
            {
              axios
              .post("/api/addusagelog", this.temptag[c])
              .then(function(response) {
                console.log(response);
                //app.usagelogtagTemp = response.data.item;
                //alert("Usage Log Added Successfully!");
              })
              .catch(function(error) {
                console.log(error);
                //this.usagelogtagTemp = {};
              });
            }
            this.temptag = [];
            this.tagjson = {};

          


    },
    handleOverlaysClick(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);
      }
    },
    handleOverlaysEdit(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);

        var pValTime = prompt(
          "Attach Time needed for a particular STEP",
          e.target.innerText
        );
        if (!pValTime) return;

        e.target.innerText = pValTime;
      }
    },
    getEditorInstance(el) {
      if (window.CKEDITOR.instances[el.id]) {
        return window.CKEDITOR.instances[el.id];
      }

      if (
        el["title"] &&
        el["title"].split(",").length == 2 &&
        window.CKEDITOR.instances[el["title"].split(",")[1].trim()]
      ) {
        return window.CKEDITOR.instances[el["title"].split(",")[1].trim()];
      }
      return null;
    },
    registerEditor(e) {
      if (window.CKEDITOR.instances[e.target.id]) {
        return;
      }

      if (!e.target["title"] || e.target["title"].split(",").length != 2) {
        console.dir("unregistered editor: " + e.target.id);
        window.CKEDITOR.inline(e.target.id);
        return;
      }

      var editorId = e.target["title"].split(",")[1].trim();
      if (!window.CKEDITOR.instances[editorId]) {
        console.dir("unregistered editor: " + editorId);
        window.CKEDITOR.inline(editorId);
      }
    },
    getId() {
      return uuidv4();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
  }
};
</script>
<style>
#editorControlListLeft
{
      height: 550px;
    overflow: scroll;
    width: 65 px;
}
.user-audit{
  margin-left: 5px;
  color: darkgray !important;
}
.editControlParent {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNew {
  width: calc(100% - 320px);
  margin-left: 70px;
  margin-right: 120px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.sec-toggle {
  background-color: #0001;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadow {
  border: 1px solid #dadce0 !important;
  padding: 6px;
  margin-top: 100px;
}
.doc-header-batch {
  font-weight: 700;
  top: -100px;
  position: relative;
  left: 110px;
  text-align: center;
  font-size: 20px;
  background-color: #ffff;
  margin-right: 95px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
  width: calc(100% - 270px);
}
.custom-disable {
  opacity: 0.4;
}
.custom-disable input[type=checkbox]{
  display: none;
}
input[type=checkbox]{
  display: inline;
}
.custom-disable button{
  display: none;
}
button{
  display: inline;
}
.btn-default1{
    margin-left: 5px;
    color: #fff;
    background-color: #172b4d;
    border-color: #172b4d;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
</style>
