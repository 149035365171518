<template>
  <div>
    <div style="clear: both">
      <form-header></form-header>
      <!-- <div class="new-form">Create New Document</div> -->
    </div>

    <div class="container-fluid new-button">
      <div class="row">
        <div class="new-form" v-if="formsTemp.title && !isClonedDocument">
        {{
          stripHTML(formsTemp.title) +
          " - " +
          capitalizeFirstLetter(formsTemp.status1)
        }}
      </div>
      <div class="new-form" v-else>
        {{ capitalizeFirstLetter(formsTemp.status1) }}
      </div>
        <div class="col editorNewForms">
          <div class="card shadowForms d-flex">
            <div class="card-body">
              <div v-if="formsTemp.title">
                <textarea
                  :id="getId()"
                  class="form-control-alternative add-form"
                  v-model="formsTemp.title"
                  style="width: 75%; height: 35px"
                ></textarea>
              </div>
              <div
                v-else-if="formsTemp.title"
                v-html="formsTemp.title"
                placeholder="Enter title here..."
                class="form-control-alternative add-form"
              ></div>
              <div v-if="!formsTemp.title">
                <textarea
                  :id="getId()"
                  class="form-control-alternative add-form"
                  style="width: 70%; height: 35px"
                ></textarea>
                <base-button
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addTitle($event)"
                  >Add Title</base-button
                >
              </div>
              <div
                v-for="(section, sec_index) in formsTemp.sections"
                :key="section.uid"
              >
                <div class="sec-toggle">
                  <label class="form-control-label">{{
                    "Section " + (sec_index + 1) + " : "
                  }}</label>
                </div>
                <div class="sec-body" style="display: block">
                  <div
                    v-for="(step, step_index) in section.steps"
                    :key="step.uid"
                  >
                  <!-- style="padding-left: 43px" -->
                    <div
                      :id="step.uid"
                      :data-secid="section.uid"
                      class="step-body"
                    >
                      <label class="form-control-label">Form:</label>
                      <label class="form-control-label">{{
                        sec_index + 1 + "." + (step_index + 1)
                      }}</label>
                      <div class="table">
                        <button
                          class="btn btn-secondary"
                          @click="addRows(section, sec_index, step, step_index, $event)"
                        >
                          Add
                        </button>
                        <table
                          class="table table-responsive"
                          cellspacing="2"
                          cellpadding="5"
                          id="data_table"
                          border="1"
                          style="margin-top: 10px"
                        >
                          <thead>
                            <tr>
                              <th scope="col" class="fontsize">Id</th>
                              <!-- <draggable tag="tbody" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid"> -->
                                <td scope="row" class="fontsize" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">{{ meta_index + 1 }}</td>
                              <!-- </draggable> -->
                            </tr>
                            <tr>
                              <th scope="col" class="fontsize">Data Description</th>
                                <td class="fontsize" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">
                                  <!-- <span v-if="meta.editmode"> -->
                                    <span>
                                      <input
                                        type="text"
                                        v-model="meta.description"
                                      />
                                    </span>
                                    <!-- <span v-else>{{ meta.description }}</span> -->
                                </td>
                            </tr>
                            <!-- <tr>
                              <th scope="col" class="fontsize">Type</th>
                                <td class="fontsize" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">
                                  <span v-if="meta.editmode">
                                    <select v-model="meta.type" required>
                                      <option
                                        v-for="option in type"
                                        :key="option.id"
                                      >
                                        {{ option.val }}
                                      </option>
                                    </select>
                                  </span>
                                  <span v-else>{{ meta.type }}</span>
                                </td>
                            </tr>
                            <tr>
                              <th class="fontsize" scope="col">Action</th>
                                <td class="fontsize" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">
                                  <span v-if="!meta.editmode"
                                    ><button @click="edit(meta)" class="fontsize">
                                      Edit
                                    </button></span
                                  >
                                  <span v-else
                                    ><button @click="save(meta, $event)" class="fontsize">
                                      Save
                                    </button></span
                                  >
                                  <button
                                    @click="deleteRow(step, step_index, meta, meta_index, $event)"
                                    class="delete fontsize"
                                  >
                                    Delete
                                  </button>
                                </td>
                            </tr> -->
                          </thead>
                            <!-- <draggable tag="tbody" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">
                              <tr>
                                <td scope="row">{{ meta_index + 1 }}</td>
                                <td>
                                  <span v-if="meta.editmode">
                                    <input
                                      type="text"
                                      v-model="meta.description"
                                    />
                                  </span>
                                  <span v-else>{{ meta.description }}</span>
                                </td>
                                <td>
                                  <span v-if="meta.editmode">
                                    <select v-model="meta.type" required>
                                      <option
                                        v-for="option in type"
                                        :key="option.id"
                                      >
                                        {{ option.val }}
                                      </option>
                                    </select>
                                  </span>
                                  <span v-else>{{ meta.type }}</span>
                                </td>
                                <td>
                                  <span v-if="!meta.editmode"
                                    ><button @click="edit(meta)">
                                      Edit
                                    </button></span
                                  >
                                  <span v-else
                                    ><button @click="save(meta, $event)">
                                      Save
                                    </button></span
                                  >
                                  <button
                                    @click="deleteRow(step, step_index, meta, meta_index, $event)"
                                    class="delete"
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            </draggable> -->
                        </table>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      style="margin: 7px"
                      class="btn float-right btn-default btn-sm"
                      @click="addStep(section, $event)"
                    >
                      Add Form
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="formsTemp.title">
                <button
                  style="margin: 7px"
                  class="btn float-right btn-default btn-sm"
                  @click="addSection($event)"
                >
                  Add Section
                </button>
              </div>
              <!-- JSON.stringify{{formsTemp}}; -->
            </div>
          </div>
        </div>
        <div class="col editRightParent">
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="isApprove()"
                @click="showLaunchBatchModal = true"
              >
                LAUNCH
              </button>
            </div>
            <div
              v-if="formsTemp.status1 != 'new' && formsTemp.status1 != 'batch'"
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-clone-modal"
                @click="showCloneModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                CLONE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="!isApprove() && !isLibrary()"
                @click="saveDocument()"
              >
                SAVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showShareModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                {{
                  formsTemp.status1 &&
                  formsTemp.status1.toLowerCase() == "shared"
                    ? "ADD COLLABRATOR"
                    : "SHARE"
                }}
              </button>
            </div>

            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--  <a href="#!" class="btn btn-info" v-if="isEditable()">CHECK</a> -->
            </div>
            <!-- v-if="!isClonedDocument" -->
            <div
              v-if="formsTemp.isForms && formsTemp.status1 == 'draft'"
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showApproveModal = true"
                class="btn btn-info"
                v-if="isEditable()"
              >
                APPROVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!-- <a href="#!" class="btn btn-info" v-if="isEditable()">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Begin: Launch Batch Document -->
    <div v-if="showLaunchBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Launch Batch Document - '" +
                    stripHTML(formsTemp.title) +
                    "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showLaunchBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(formsTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Launch Batch Document</span>
                  <textarea
                    v-model="batchDocNumber"
                    placeholder="Enter batch #"
                    style="width: 100%"
                  ></textarea>
                  <button
                    v-if="finalarray.length == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="launchBatch()"
                  >
                    Launch Batch
                  </button>
                  <button
                    v-if="finalarray.length == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="setToDefault()"
                  >
                    Cancel
                  </button>

                  <div
                    v-if="finalarray.length > 0"
                    style="max-height: calc(100vh - 210px); overflow-y: auto"
                  >
                    <span>Set Variable values:</span>
                    <div v-for="section in finalarray" v-bind:key="section.uid">
                      <table>
                        <tr>
                          <td style="width: 20%">
                            Step:
                            <label
                              class="form-control-label"
                              v-if="section.sectionnum"
                              v-html="section.sectionnum"
                            ></label>
                          </td>
                          <td style="width: 35%">
                            Variable Name:
                            <label
                              class="form-control-label"
                              v-if="section.variablename"
                              v-html="section.variablename"
                            ></label>
                          </td>
                          <td style="width: 50%">
                            Value:
                            <input
                              type="text"
                              class="form-control-alternative add-form"
                              v-bind:value="section.defaultvalue"
                              v-on:input="onValueChange(section, $event)"
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="launchBatch()"
                    >
                      Launch Batch
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="setToDefault()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Launch Batch Document -->

    <!-- Begin: Share Document -->
    <div v-if="showShareModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Share Document - '" + stripHTML(formsTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showShareModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(formsTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <!-- <span>Share Document to :</span> -->
                  <div class="tab">
                    <button
                      class="tablinks active"
                      @click="openShare('shareContent', $event)"
                    >
                      Share
                    </button>
                    <button
                      class="tablinks"
                      @click="openShare('libraryContent', $event)"
                    >
                      Library
                    </button>
                  </div>

                  <div
                    id="shareContent"
                    class="tabcontent"
                    style="display: block"
                  >
                    <h3>Send to Emails</h3>
                    <textarea
                      v-model="emailAddress"
                      placeholder="Email address (separated by ; )"
                      style="width: 100%"
                    ></textarea>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>

                  <div id="libraryContent" class="tabcontent">
                    <h3>Send to DigitalGxP Public Library</h3>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareLibraryDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Share Document -->
    <!-- Begin: Clone Document -->
    <div v-if="showCloneModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Clone Document - '" + stripHTML(formsTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCloneModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Clone Document</span>
                  <div class="custom-control targetTemplateType">
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="doument"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="document"
                    />Document
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="template"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="template"
                    />Template
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="library"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="library"
                    />Library
                  </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="handleCloneConfirmClick()"
                  >
                    Clone
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCloneModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Clone Document -->
    <!-- Begin: Comment-->
    <div v-if="showCommentModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog comment-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Add Comment for Step : '" + commentTitle + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCommentModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Comments display-->
                <div>
                  <div
                    class="sec-toggle comment-section"
                    @click="sectionToggleComment"
                  >
                    <label
                      class="form-control-label comment-header ctrl-collapse"
                      style="display: none; width: fit-content"
                      @click="sectionToggleComment"
                      >+ Comments</label
                    >
                    <label
                      class="form-control-label comment-header ctrl-expand"
                      style="width: fit-content"
                      @click="sectionToggleComment"
                      >- Comments</label
                    >
                  </div>
                  <div class="sec-body">
                    <div
                      v-for="(comment, comment_index) in this.currentStep
                        .comments"
                      :key="comment.id || comment_index"
                      style="padding-left: 15px"
                      class="comment-text"
                    >
                      <span class="comment-user">{{ comment.createdBy }}</span>
                      {{
                        comment.description +
                        " - on " +
                        formatDate(comment.createdTime)
                      }}
                    </div>
                  </div>
                </div>

                <div class="modal-body">
                  <span>Comments</span>
                  <textarea
                    v-model="commentDescription"
                    placeholder="Enter your comment"
                    style="width: 100%"
                  ></textarea>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="addComment()"
                  >
                    Add Comment
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCommentModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Comment -->

    <!-- Begin: Approve Document -->
    <div v-if="showApproveModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Approve Document - '" + stripHTML(formsTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showApproveModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(formsTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Please confirm!</span>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="approveDocument()"
                  >
                    Approve
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showApproveModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Approve Document -->

    <!-- {{this.formsTemp}} -->
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    @click="showModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title">Modal title</h4>
                </div>
                <div class="modal-body">modal body</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTableModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Grid Title: Client Info
                  <button
                    type="button"
                    class="close"
                    @click="showTableModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <button class="btn btn-secondary" @click="addRows">
                      Add
                    </button>
                    <!-- <button class="btn btn-secondary" @click="replace">
                      Replace
                    </button> -->
                  </div>
                  <table
                    class="table"
                    cellspacing="2"
                    cellpadding="5"
                    id="data_table"
                    border="1"
                    style="margin-top: 10px"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">ColumnName</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="updateColumnDefs" tag="tbody">
                      <tr
                        v-for="(item, index) in meta"
                        :key="index"
                      >
                        <td scope="row">{{ index + 1 }}</td>
                        <td>
                          <span v-if="item.editmode">
                            <input type="text" v-model="item.description" />
                          </span>
                          <span v-else>{{ item.description }}</span>
                        </td>
                        <!-- <td>{{ item.type }}</td> -->
                        <td>
                          <span v-if="item.editmode">
                            <select v-model="item.type" required>
                              <option v-for="option in type" :key="option.id">
                                {{ option.val }}
                              </option>
                            </select>
                          </span>
                          <span v-else>{{ item.type }}</span>
                        </td>
                        <td>
                          <span v-if="!item.editmode"
                            ><button @click="edit(item)">Edit</button></span
                          >
                          <span v-else
                            ><button @click="save(item)">Save</button></span
                          >
                          <button @click="deleteRow(index)" class="delete">
                            Delete
                          </button>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="submitTable()"
                  >
                    Submit
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTable($event)"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTableModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTextModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Textarea Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTextModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the textarea
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextarea"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showImageModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Image Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showImageModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="text"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Image:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="image/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <img
                        style="width: 100px; height: 100px; margin-bottom: 10px"
                        :src="filespath"
                        alt=""
                      />
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteImage()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showVideoModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Video Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showVideoModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="text"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                        v-on:change="handleFileUploadsDisable()"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Video:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="video/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <video width="320" height="240" controls>
                        <source :src="filespath" :type="videotype" />
                      </video>
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteVideo()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showDeleteModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Delete Confirmation
                  <button
                    type="button"
                    class="close"
                    @click="showDeleteModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    Please confirm the if you want to delete or cancel?
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTextarea()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showDeleteModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- {{JSON.stringify(formsTemp)}} -->
  </div>
</template>
<script>
/* eslint-disable no-console */
// import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";
import draggable from "vuedraggable";

// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import formHeader from "./formHeader.vue";

export default {
  components: { formHeader, draggable },
  data() {
    return {
      rows: [],
      shareDocId: "",
      isClonedDocument: false,
      showModal: false,
      showShareModal: false,
      showCommentModal: false,
      showApproveModal: false,
      showLaunchBatchModal: false,
      formsTemp: {},
      showCloneModal: false,
      showTableModal: false,
      showImageModal: false,
      showVideoModal: false,
      showTextModal: false,
      showDeleteModal: false,
      disable: true,
      dragItem: "",
      clickIndex: -1,
      targetTemplateType: "",
      isDelete: false,
      finaljson: {},
      finalarray: [],
      authCookie: null,
      editIds: [],
      batchDocNumber: "",
      emailAddress: "",
      currentStep: "",
      commentTitle: "",
      commentDescription: "",
      files: "",
      fileUrl: "",
      filespath: "",
      videopath: "",
      videotype: "",
      newComments: [],
      gridOptions: null,
      isForms: "",
      updateColumnDefs: [],
      rowData: [],
      updateSecId: "",
      updateStepId: "",
      metaId: "",
      type: [
        { id: "23", val: "Text" },
        { id: "13", val: "Number" },
        { id: "4", val: "Image" },
        { id: "5", val: "Video" },
      ],
    };
  },
  beforeMount() {
    this.shareDocId = this.$route.query.shareDocId;
    this.isClonedDocument = this.$route.query.isClonedDocument;
    this.formsTemp.docID = this.$route.query.docid;
    this.formsTemp.status1 = this.$route.query.docStatusType;
    this.formsTemp.isForms = this.$route.query.isForms;

    if (!this.$route.query.docid) {
      this.formsTemp.industryItemType = this.$route.query.industryItemType;
      this.formsTemp.industryItemID = this.$route.query.industryItemID;
    }
  },
  mounted() {
    console.log(
      " docid: " +
        this.formsTemp.docID +
        " docStatusType: " +
        this.formsTemp.status1 +
        " isForms: " +
        this.formsTemp.isForms +
        " iscloned : " +
        this.isClonedDocument
    );
    if (!this.formsTemp.docID && !this.shareDocId) {
      let sharedKey = this.$route.params.key;
      if (sharedKey) {
        this.shareDocId = sharedKey;
      }
    }
    if (this.formsTemp.docID) {
      axios
        .get(
          "/api/forms/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.formsTemp.docID
        )
        .then((response) => {
          console.log(response);
          if (this.isClonedDocument) {
            let targetTemplateType = this.formsTemp.isForms;
            this.formsTemp = {};
            this.formsTemp.title = response.data.item.title;
            this.formsTemp.sections = response.data.item.sections;
            this.formsTemp.status1 = "new";
            this.formsTemp.isForms = targetTemplateType;
          } else {
            this.formsTemp = response.data.item;
          }

          //console.log('Tru');
          var i = 0;
          var totalsec = this.formsTemp.sections ? this.formsTemp.sections : [];
          //var latestarray = [];
          for (i = 0; i < totalsec.length; i++) {
            var j = 0;
            var eachstep = totalsec[i].steps;
            for (j = 0; j < eachstep.length; j++) {
              //var v1 = eachstep[j].description.toString();
              var dom = document.createElement("div");
              dom.innerHTML = eachstep[j].description;
              var atag = dom.getElementsByTagName("a");
              if (atag != undefined && atag.length > 0) {
                var k;
                for (k = 0; k < atag.length; k++) {
                  if (
                    !(
                      atag[k].innerHTML.toString().indexOf("Formula = ", 0) > 0
                    ) &&
                    atag[k].innerHTML.toString().split(",")[1] != undefined
                  ) {
                    this.finaljson = {};
                    //console.log(atag[k].innerHTML);
                    this.finaljson.uid = uuidv4();
                    this.finaljson.sectionnum = i + 1 + "." + (j + 1);
                    this.finaljson.variablename = atag[k].innerHTML
                      .toString()
                      .split(",")[0]
                      .substring(
                        1,
                        atag[k].innerHTML.toString().split(",")[0].length
                      );
                    this.finaljson.defaultvalue = atag[k].innerHTML
                      .toString()
                      .split(",")[1]
                      .substring(
                        0,
                        atag[k].innerHTML.toString().split(",")[1].length - 1
                      );
                    this.finaljson.oldvalue = atag[k].innerHTML
                      .toString()
                      .split(",")[1]
                      .substring(
                        0,
                        atag[k].innerHTML.toString().split(",")[1].length - 1
                      );
                    this.finalarray.push(this.finaljson);
                    //this.finalarray = this.finalarray.concat(this.finalarray);
                    //console.log('i ' + i + ' j ' + j + ' k ' + k);
                    //console.log(this.finaljson);
                    //console.log('final array');
                    //console.log(this.finalarray);
                  }
                }
              }
              //this.finalarray.push(this.finaljson);
            }
            //this.finalarray.push(this.finaljson);
          }
          //this.finalarray.push(this.finaljson);

          //var v1 = this.formsTemp.sections[0].steps[2].description.toString();
          //console.log('final array');
          //console.log(this.finalarray);
          //v2.innerHTML.toString());

          this.formsTemp.title = this.formsTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + this.formsTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          this.formsTemp = [];
        });
    } else if (this.shareDocId) {
      console.log(" Opening from Share id :" + this.shareDocId);
      var that = this;
      axios
        .get("/api/sharedDocuments?key=" + this.shareDocId)
        .then((response) => {
          that.formsTemp = response.data.item;
          that.formsTemp.docID = response.data.item.docID;
          that.formsTemp.status1 = response.data.item.status1;

          that.formsTemp.title = that.formsTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + that.formsTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          that.formsTemp = [];
        });
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj) {
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }
  },
  methods: {
    notifyUser(formsTempNotification){
      console.log(formsTempNotification);
      var notification = {
          objectId: formsTempNotification.docID,
          type: "forms",
          status: formsTempNotification.status1,
          message:  " has " + formsTempNotification.status1  + " the forms document " + formsTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    notifyUserBatch(formsTempNotification){
      console.log(formsTempNotification);
      var notification = {
          objectId: formsTempNotification.docID,
          type: "forms",
          status: formsTempNotification.status1,
          message:  " has " + " launched " + formsTempNotification.status1  + " forms  document " + formsTempNotification.batchNumber + " for " + formsTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
    },
    onFocushandle(step, event) {
      var el =
        event.target.parentElement.parentElement.querySelector(".form-control");
      this.$set(step, "description", el.innerHTML);
    },
    onClickDragTable(stepId) {
      var element = document.getElementById("table-grid_" + stepId);
      element.style.display = "block";
    },
    deleteRow(step, meta, meta_index) {
      step.meta.splice(meta_index, 1);
    },
    edit(obj) {
      this.$set(obj, "editmode", true);
      console.log("edit");
    },
    save(obj) {
      this.$set(obj, "editmode", false);
      // if (sec && sec[0]) {
      //   for (let i = 0; i < sec[0].steps.length; i++) {
      //     let step = sec[0].steps[i];
      //     if (this.updateStepId == step.uid) {
      //       if (!step[0].meta) {
      //         step[0].meta = [];
      //       }
      //     }
      //   }
      // }

    },
    drop(event) {
      const dragControl = event.dataTransfer.getData("data-class-name");
      // var stepBody = event.target.parentElement.parentElement.querySelector(".step-body");
      var stepBody = event.target.closest('div[class^="step-body"]');
      // console.dir(
      //   " stepBody.id " + stepBody.id + " secid " + stepBody.dataset.secid
      // );
      this.updateSecId = stepBody.dataset.secid;

      this.updateStepId = stepBody.id;

      if (dragControl == "table-input") {
        // this.dragItem = 'dragDrop';
        this.showTableModal = true;
        this.updateColumnDefs = [];
      } else if (dragControl == "text-input") {
        this.showTextModal = true;
      } else if (dragControl == "image-input") {
        this.showImageModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      } else if (dragControl == "video-input") {
        this.showVideoModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      }
      event.preventDefault();
    },
    startDrag(event) {
      var contact = event.target.parentElement.getAttribute("data-class-name");
      event.dataTransfer.setData("data-class-name", contact);
    },
    dragOver(event) {
      event.preventDefault();
    },
    addRows(section, sec_index, step) {
      if (!step.meta) {
        this.$set(step, "meta", []);
      }
      let existingLength = step.meta.length;
      var tableData = {};
      tableData.description = "";
      tableData.id = existingLength + 1;
      tableData.type = "";
      this.$set(step.meta, existingLength, tableData);
    },
    dblClickTable(secid, stepId, meta, event) {
      // event.preventDefault();
      console.dir(event);
      var stepBody =
        event.currentTarget.parentElement.parentElement.parentElement.querySelector(
          ".step-body"
        );
      console.dir(
        " stepBody.id " + stepBody.id + " secid " + stepBody.dataset.secid
      );
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      this.metaId = meta.uid;
      //this.updateColumnDefs = [];
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0] && step[0].meta) {
          this.updateColumnDefs = [];
          for (var i = 0; i < meta.columnDefs.length; i++) {
            let colHeader = {};
            let obj = meta.columnDefs[i];
            colHeader.columnName = obj.headerName;
            colHeader.type = obj.type;
            (colHeader.id = i + 1), this.updateColumnDefs.push(colHeader);
          }
        }
      }
      this.isDelete = true;
      this.showTableModal = true;
    },
    submitTable(e) {
      console.log(e);
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0]) {
          let gridColumnDefs = [];
          for (var i = 0; i < this.updateColumnDefs.length; i++) {
            let colHeader = {};
            let obj = this.updateColumnDefs[i];
            colHeader.headerName = obj.columnName;
            colHeader.field = obj.columnName;
            colHeader.sortable = true;
            colHeader.filter = true;
            colHeader.editable = true;
            colHeader.type = obj.type;
            gridColumnDefs.push(colHeader);
          }
          if (!step[0].meta) {
            step[0].meta = [];
          }
          for (var j = 0; j < step[0].meta.length; j++) {
            if (step[0].meta[j].uid == this.metaId) {
              this.$set(step[0].meta, j, {
                columnDefs: gridColumnDefs,
                rowData: this.rowData,
                type: "table",
                uid: uuidv4(),
              });
              this.$set(sec[0], 0, step[0]);
              this.showTableModal = false;
              // this.metaId = null;
              return;
            }
          }
          this.$set(step[0].meta, step[0].meta.length, {
            columnDefs: gridColumnDefs,
            rowData: this.rowData,
            type: "table",
            uid: uuidv4(),
          });
          this.$set(sec[0], 0, step[0]);
        }
      }
      this.showTableModal = false;
      this.updateColumnDefs = [];
    },
    showTextarea(e) {
      console.dir(e);
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0]) {
          if (!step[0].meta) {
            step[0].meta = [];
          }
          this.$set(step[0].meta, 0, { type: "text" });
          this.$set(sec[0], 0, step[0]);
        }
      }
      this.showTextModal = false;
    },
    dblClickTextarea(event) {
      console.dir(event);
      var stepBody =
        event.currentTarget.parentElement.parentElement.parentElement.parentElement.querySelector(
          ".step-body"
        );
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0] && step[0].meta && step[0].meta[0]) {
          this.showDeleteModal = true;
        }
      }
    },
    deleteTable(event) {
      console.dir(event);
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0]) {
          if (step[0].meta) {
            for (var j = 0; j < step[0].meta.length; j++) {
              if (step[0].meta[j].uid == this.metaId) {
                step[0].meta.splice(j, 1);
              }
            }
          }
        }
      }
      this.showTableModal = false;
    },
    dblClickImage(event) {
      console.dir(event);
      var stepBody =
        event.currentTarget.parentElement.parentElement.parentElement.querySelector(
          ".step-body"
        );
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0] && step[0].meta && step[0].meta[0]) {
          this.isDelete = true;
          this.showImageModal = true;
        }
      }
    },
    deleteTextarea() {
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0]) {
          if (step[0].meta) {
            delete step[0].meta;
          }
        }
      }
      this.showDeleteModal = false;
    },
    deleteImage() {
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0]) {
          if (step[0].meta) {
            delete step[0].meta;
          }
        }
      }
      this.isDelete = true;
      this.showImageModal = false;
    },
    dblClickVideo(event) {
      console.dir(event);
      var stepBody =
        event.currentTarget.parentElement.parentElement.parentElement.querySelector(
          ".step-body"
        );
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0] && step[0].meta && step[0].meta[0]) {
          this.isDelete = true;
          this.showVideoModal = true;
        }
      }
    },
    deleteVideo() {
      var sec = this.formsTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        if (step && step[0]) {
          if (step[0].meta) {
            delete step[0].meta;
          }
        }
      }
      this.showVideoModal = false;
    },
    handleFileUploads() {
      // this.fileUrl =  URL.createObjectURL(this.$refs.file.files[0]);
      this.files = this.$refs.file.files[0];
      console.log(this.files);
      this.disable = false;
    },
    uploadImage(e) {
      console.dir(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          app.filespath = "";
          var path = response.data.fileUrl;

          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage(e) {
      console.dir(e);
      let app = this;
      var sec = app.formsTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (step && step[0]) {
          if (!step[0].meta) {
            step[0].meta = [];
          }

          app.$set(step[0].meta, 0, {
            imagePath: app.filespath,
            type: "image",
          });
          app.$set(sec[0], 0, step[0]);
        }
      }
      app.showImageModal = false;
    },
    previewVideo(e) {
      console.dir(e);
      let app = this;
      var sec = app.formsTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (step && step[0]) {
          if (!step[0].meta) {
            step[0].meta = [];
          }
          app.$set(step[0].meta, 0, {
            videoPath: app.filespath,
            type: "video",
          });
          app.$set(sec[0], 0, step[0]);
        }
      }
      app.showVideoModal = false;
    },
    isEditable() {
      // console.log(this.formsTemp.status1);
      return (
        !this.formsTemp.status1 ||
        ["approved", "library", "shared", "archived", "template"].indexOf(
          this.formsTemp.status1.toLowerCase()
        ) < 0
      );
    },
    isApprove() {
      return (
        this.formsTemp.status1 &&
        ["approved"].indexOf(this.formsTemp.status1.toLowerCase()) >= 0
      );
    },
    isLibrary() {
      return (
        this.formsTemp.status1 &&
        ["template"].indexOf(this.formsTemp.status1.toLowerCase()) >= 0
      );
    },
    isShare() {
      return (
        !(this.authCookie && this.authCookie.userName) &&
        this.formsTemp.status1 &&
        ["shared"].indexOf(this.formsTemp.status1.toLowerCase()) >= 0
      );
    },

    capitalizeFirstLetter(strVal) {
      if (strVal) return strVal.charAt(0).toUpperCase() + strVal.slice(1);
      return "";
    },
    assignHTML() {
      setTimeout(function () {
        var u = document.getElementsByTagName("u");
        var i;
        if (u != undefined) {
          for (i = 0; i < u.length; i++) {
            if (u[i].getAttribute("id") == null) {
              var itag = u[i].getElementsByTagName("em");
              if (itag[0] != undefined && itag[0].innerText != undefined) {
                u[i].setAttribute(
                  "id",
                  itag[0].innerText.toString().split(",")[0]
                );
                u[i].setAttribute("class", "tooltip1");
                //console.log('try');
                //console.log(itag[0].innerText.toString().split(',')[4]);
                if (itag[0].innerText.toString().split(",")[4] != "") {
                  u[i].insertAdjacentHTML(
                    "beforeend",
                    '<span class="tooltiptextimg">Type: ' +
                      itag[0].innerText.toString().split(",")[1] +
                      "<br> Description: " +
                      itag[0].innerText.toString().split(",")[3] +
                      '<br> Image: <img style="width:30px; height:30px; margin-bottom: 10px;" src=' +
                      itag[0].innerText.toString().split(",")[4] +
                      "> </span>"
                  );
                } else {
                  u[i].insertAdjacentHTML(
                    "beforeend",
                    '<span class="tooltiptext">Type: ' +
                      itag[0].innerText.toString().split(",")[1] +
                      "<br> Description: " +
                      itag[0].innerText.toString().split(",")[3] +
                      "</span>"
                  );
                }
              }
            }
          }
        }
      }, 100);
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
    sectionToggle(e) {
      let parentElement = e.target.parentElement;
      var secBody = parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        // parentElement.querySelector(".ctrl-collapse").style.display = "none";
        // parentElement.querySelector(".ctrl-expand").style.display = "block";
      } else {
        secBody.style.display = "none";
        // parentElement.querySelector(".ctrl-collapse").style.display = "block";
        // parentElement.querySelector(".ctrl-expand").style.display = "none";
      }
    },
    async saveDocument() {
      let app = this;
      this.deleteAttr(this.formsTemp);
      if (!this.formsTemp.docID) {
        if (app.isClonedDocument) {
          app.formsTemp.status1 = "draft";
        }
        try {
          const { data } = await axios.post("/api/forms", app.formsTemp);
          // .then(function (response) {
          console.log(data);
          app.formsTemp = data.item;
          alert("form Created Successfully!");
          window.location =
            "/newForms?docid=" +
            app.formsTemp.docID +
            "&isForms=" +
            app.formsTemp.isForms +'&docStatusType='+ app.formsTemp.status1;
        } catch (error) {
          console.log(error);
        }
      } else {
        app.formsTemp.updatedBy =
          app.formsTemp.updatedBy || this.authCookie.userName;

        try {
          const { data } = await axios.put("/api/forms", {
            forms: app.formsTemp,
            changes: { comment: this.newComments },
          });

          console.log(data);
          app.formsTemp = data.item;
          // console.log("Document Saved Successfully!");
          alert("form Saved Successfully!");
          if(app.formsTemp.status1 == 'approved') {
              app.notifyUser(app.formsTemp);
            }
          window.location =
            "/newForms?docid=" +
            app.formsTemp.docID +
            "&isForms=" +
            app.formsTemp.isForms +'&docStatusType='+ app.formsTemp.status1;
        } catch (error) {
          console.log(error);
          app.formsTemp = [];
        }
      }
    },
    handleCloneConfirmClick() {
      this.showCloneModal = false;
      window.location.href =
        "/artifact/clone?docid=" +
        this.formsTemp.docID +
        "&isClonedDocument=true&isForms=" +
        this.targetTemplateType +
        "&docStatusType=" +
        this.formsTemp.status1 +
        "&industryItemType=" +
        this.formsTemp.industryItemType +
        "&industryItemID=" +
        this.formsTemp.industryItemID;
      console.log("cloneDoc");
    },
    getId() {
      return uuidv4();
    },
    addStep(sectionParent) {
      if (!sectionParent.steps) {
        this.$set(sectionParent, "steps", []);
      }
      var step = {};
      step.uid = uuidv4();
      step.description = "";
      step.dataTrandings = [];
      step.steps = [];
      step.meta = [];
      step.rows = [];
      // step.meta[0] = "";
      this.$set(sectionParent.steps, sectionParent.steps.length, step);
    },
    addSection() {
      if (!this.formsTemp.sections) {
        this.$set(this.formsTemp, "sections", []);
      }
      var sec = {};
      sec.uid = uuidv4();
      sec.description = "";
      sec.steps = [];
      this.$set(this.formsTemp.sections, this.formsTemp.sections.length, sec);
    },
    addTitle(e) {
      var el = e.target.parentElement.querySelector(
        ".form-control-alternative"
      );
      this.$set(this.formsTemp, "title", el.value);
      // this.$set(sectionParent, "steps", []);
      this.$set(this.formsTemp, "status1", "draft");
      this.$set(this.formsTemp, "approvedBy", this.authCookie.userName);
      this.$set(this.formsTemp, "userName", this.authCookie.userName);
      this.$set(this.formsTemp, "orgID", this.authCookie.orgs[0].orgID);
      this.$set(this.formsTemp, "createdBy", this.authCookie.userName);
      this.$set(this.formsTemp, "updatedBy", this.authCookie.userName);

      if (this.filespath != "") {
        this.$set(this.formsTemp, "imagePath", this.filespath);
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    removeSpan(e) {
      //remove span
      var u = e.target.getElementsByTagName("u");
      var i;
      if (u != undefined && u.length > 0) {
        for (i = 0; i < u.length; i++) {
          if (u[i].getAttribute("id") != null) {
            var htmlspan1 = u[i].getElementsByTagName("span");
            if (htmlspan1.length > 0) {
              htmlspan1[0].remove();
            }
          }
        }
      }
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach((sec) => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach((step) => {
          this.deleteAttr(step);
        });
      }
    },
    setToDefault() {
      var i = 0;
      for (i = 0; i < this.finalarray.length; i++) {
        this.finalarray[i].defaultvalue = this.finalarray[i].oldvalue;
      }
      this.showLaunchBatchModal = false;
      //console.log(this.finalarray);
    },
    launchBatch() {
      let app = this;
      app.verify();
      app.showLaunchBatchModal = false;
      this.deleteAttr(this.formsTemp);
      var batchDoc = JSON.parse(JSON.stringify(this.formsTemp));
      //batchDoc.docID = null;
      batchDoc.isForms = this.formsTemp.isForms;
      batchDoc.batchNumber = this.batchDocNumber;
      batchDoc.status1 = "batch";
      axios
        .post("/api/formsBatch", batchDoc)
        .then(function (response) {
          console.log(response);
          app.showShareModal = false;
          alert("Batch started successfully!");
          if(batchDoc.status1 == 'batch') {
              app.notifyUserBatch(batchDoc);
            }
          app.setToDefault();
          window.location =
            "/forms?docStatusType="+ batchDoc.status1 +
            "&isForms=" +
            batchDoc.isForms;
        })
        .catch(function (error) {
          console.log(error);
          app.formsTemp = [];
        });
    },
    verify() {
      var i = 0;
      for (i = 0; i < this.finalarray.length; i++) {
        if (this.finalarray[i].defaultvalue != this.finalarray[i].oldvalue) {
          //console.log('modify');
          var first = this.finalarray[i].sectionnum.toString().split(".")[0];
          var sec = this.finalarray[i].sectionnum.toString().split(".")[1];
          var oldstr =
            "[" +
            this.finalarray[i].variablename.toString() +
            "," +
            this.finalarray[i].oldvalue.toString() +
            "]";
          var newstr =
            this.finalarray[i].variablename.toString() +
            "," +
            this.finalarray[i].defaultvalue.toString();

          console.log(
            this.formsTemp.sections[first - 1].steps[sec - 1].description
              .toString()
              .replace(oldstr, newstr)
          );
          this.formsTemp.sections[first - 1].steps[sec - 1].description =
            this.formsTemp.sections[first - 1].steps[sec - 1].description
              .toString()
              .replace(oldstr, newstr);
        }
      }
    },
    shareDocument() {
      let app = this;
      this.deleteAttr(this.formsTemp);
      if (app.isShare()) {
        this.formsTemp.emailShare = this.emailAddress;
        axios
          .put("/api/forms", app.formsTemp)
          .then(function (response) {
            console.log(response);
            app.formsTemp = response.data.item;
            alert("Document Shared Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.formsTemp = [];
          });
      } else {
        var shareDoc = JSON.parse(JSON.stringify(this.formsTemp));
        // shareDoc.docID = null;
        shareDoc.isForms = this.formsTemp.isForms;
        shareDoc.parentDocID = this.formsTemp.docID;
        shareDoc.status1 = "shared";
        shareDoc.emailShare = this.emailAddress;
        axios
          .post("/api/forms", shareDoc)
          .then(function (response) {
            console.log(response);
            app.showShareModal = false;
            alert("Successfully shared document!");
            if(shareDoc.status1 == 'shared') {
              app.notifyUser(shareDoc);
            }
            window.location =
            "/forms?docStatusType="+ shareDoc.status1 +
            "&isForms=" +
            shareDoc.isForms;
            //app.formsTemp = response.data.item;
          })
          .catch(function (error) {
            console.log(error);
            app.formsTemp = [];
          });
      }
    },
    shareLibraryDocument() {
      let app = this;
      this.deleteAttr(this.formsTemp);
      var shareDoc = JSON.parse(JSON.stringify(this.formsTemp));
      shareDoc.docID = null;
      shareDoc.parentDocID = this.formsTemp.docID;
      shareDoc.status1 = "template";
      axios
        .post("/api/forms", shareDoc)
        .then(function (response) {
          console.log(response);
          alert("Document successfully shared to DigitalGxP Public Library!");
        })
        .catch(function (error) {
          console.log(error);
          app.formsTemp = [];
        })
        .then(function () {
          app.showShareModal = false;
        });
    },
    async approveDocument() {
      this.formsTemp.approvedBy = this.authCookie.email;
      this.formsTemp.status1 = "approved";
      await this.saveDocument();
      console.log("Document Approved Successfully!");

      this.showApproveModal = false;
      window.location =
        "/newForms?docid=" +
        this.formsTemp.docID +
        "&isForms=" +
        this.formsTemp.isForms +
        "&industryItemType=" +
        this.formsTemp.industryItemType +
        "&industryItemID=" +
        this.formsTemp.industryItemID +'&docStatusType='+ this.formsTemp.status1;
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    openShare(shareName, $event) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(shareName).style.display = "block";
      $event.target.className += " active";
    },
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
#editorControlListLeftForms {
  /* height: 550px; */
  overflow: scroll;
  width: 65px;
  height: calc(100vh - 80px);
}
.fontsize{
  font-size: 10px;
}
.data-grid {
  margin-bottom: 18px;
}
.comment-dialog {
  max-width: 65%;
}
.share-modal {
  max-width: 50%;
}
.doc-step:hover {
  background-color: #f8f5fa;
}
// .new-button{
//   margin-top: -23rem;
// }
/* .doc-step:hover ~ .step-action-btn-group,
.step-action-btn-group:hover
{
    display: block;
} */
.doc-step {
  background-color: transparent;
  padding: 12px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.comment-text {
  color: darkgray !important;
}
.comment-section {
  background-color: rgba(0, 0, 0, 0.02);
  padding-left: 10px;
}
.comment-header {
  margin-right: 15px;
  font-size: 13px;
  font-stretch: 100%;
  font-style: italic;
  font-weight: 500;
}
.comment-user {
  color: #385898;
}
div[data-oembed-url] > div > iframe {
  height: auto !important;
  width: auto !important;
}
div[data-oembed-url] > div {
  padding-bottom: 150px !important;
}
.editControlForm {
  width: 50px;
  // top: 100px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  /* height:290px; */
  /* height: calc(100% - 320px); */
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNewForms {
  width: calc(100% - 320px);
  // margin-left: 110px;
  margin-right: 120px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.section-toggle-comment,
.sec-toggle {
  color: #000;
  // text-indent: 10px;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 14px;
}
.section-toggle-comment.sec-toggle.accordion:after,
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadowForms {
  padding: 6px;
  margin: auto;
}
.new-form {
  font-weight: 700;
  text-align: center;
  // margin-left: auto;
  width: calc(100% - 20px);
}
.btn-step-action {
  background-color: inherit;
  border-color: transparent;
  color: inherit;
  z-index: 1001;
  top: -45px;
}
.step-action-btn-group {
  display: block;
}
.commentCountForms {
  position: absolute;
  top: 2px;
  left: 11px;
  color: white;
  font-size: 12px;
}
.btn-comment {
  width: 10px;
  padding-right: 22px;
  font-size: 0.85rem;
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
em {
  display: none;
}
.tooltip1 {
}
.tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -270px;
  margin-top: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltiptextimg {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -285px;
  margin-top: -107px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextimg::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip1:hover .tooltiptextimg {
  visibility: visible;
  opacity: 1;
}
.input-type-numeric {
  visibility: visible;
}
.input-type-text {
  visibility: visible;
}
.radio-buttons {
  margin: 10px 10px;
}
.modal-dialog {
  max-width: 560px;
}
.custom {
  padding: 10px;
  border: 1px solid #aaaaaa;
}
button.delete {
  margin: 5px;
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  display: flex;
  align-items: center;
  overflow: auto;
}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.form {
  margin: 12px;
}
.add-form {
  border: 1px solid #333;
  color: #000;
}
.add-form:focus {
  border-color: #000;
  color: #000;
}
.btn {
  border-radius: 20px;
  width: 130px;
}
.btn-default.disabled,
.btn-default:disabled {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  cursor: not-allowed;
}

@media only screen and (max-width: 1024px) {
  #data_table{
    display: block;
    overflow-x: auto;
  }
}

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
