<template>
  <div>
    <div style="clear: both">
      <notepad-header></notepad-header>
      <!-- <div class="notepad-header">Create New Document</div> -->
      <div class="container-fluid">
      <div class="row">
      <div class="notepad-header" v-if="notepadTemp.title && !isClonedDocument">
        {{
          stripHTML(notepadTemp.title) +
          " - " +
          capitalizeFirstLetter(notepadTemp.status1)
        }}
      </div>
      <div class="notepad-header" v-else>
        {{ capitalizeFirstLetter(notepadTemp.status1) }}
      </div>
    </div>
        <div class="col editControlNotepad" v-if="isEditable()">
          <div id="editorControlListLeftNotepadIcons">
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="text-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/text.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Text</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="grid-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/table.png"
                alt="grid"
                class="u-photo"
              />
              <span class="leftIcon">Grid</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="image-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/image.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Image</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="video-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/video.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Video</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="table-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/table.png"
                alt="table"
                class="u-photo"
              />
              <span class="leftIcon">Table</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/timer.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Timer</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="formula-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/formula-fx.png"
                alt="avatar"
                class="u-photo"
              />
              <span class="leftIcon">Formula</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="qrcode-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/media.jpg" alt="avatar" class="u-photo" />
              <span class="leftIcon"></span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="data-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/data_input.png"
                alt="Data Input"
                class="u-photo"
              />
              <span class="leftIcon">DataInput</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="var-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/variable.png"
                alt="Var Input"
                class="u-photo"
              />
              <span class="leftIcon">VarInput</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="steplock-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/steplock.png"
                alt="StepLock"
                class="u-photo"
              />
              <span class="leftIcon">StepLock</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="verifier-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/verifier.png"
                alt="Verifier"
                class="u-photo"
              />
              <span class="leftIcon">Verifier</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="caution-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/caution.png"
                alt="Caution"
                class="u-photo"
              />
              <span class="leftIcon">Caution</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="crossref-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/crossref.png"
                alt="crossref"
                class="u-photo"
              />
              <span class="leftIcon">CrossRef</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="iot-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/widgets/range.png" alt="range" class="u-photo" />
              <span class="leftIcon">IoT</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="mcq-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/widgets/crossref.png" alt="mcq" class="u-photo" />
              <span class="leftIcon">Mcq</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="skill-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/widgets/trending.png" alt="skill" class="u-photo" />
              <span class="leftIcon">Skill</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="graph-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/graph-g.png"
                alt="graph"
                class="u-photo"
              />
              <span class="leftIcon">Graph</span>
            </div>
          </div>
        </div>
        <div class="col editorNewNotepad">
          <div class="card shadow2 d-flex">
            <div class="card-body">
              <div v-if="notepadTemp.title">
                <textarea
                  :id="getId()"
                  class="form-control-alternative textarea"
                  v-model="notepadTemp.title"
                  style="width: 100%; height: 35px"
                ></textarea>
              </div>
              <!-- <div
                v-else-if="notepadTemp.title"
                v-html="notepadTemp.title"
                placeholder="Enter title here..."
                class="form-control-alternative textarea"
              ></div> -->
              <div v-if="!notepadTemp.title">
                <textarea
                  :id="getId()"
                  class="form-control-alternative textarea"
                  style="width: 100%; height: 35px"
                ></textarea>
                <base-button
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addTitle($event)"
                  >Add Title</base-button
                >
              </div>
              <div class="sec-body" style="display: block">
                <div
                  v-for="(section, sec_index) in notepadTemp.sections"
                  :key="section.uid"
                >
                  <div
                    :id="section.uid"
                    :data-secid="section.uid"
                    class="step-body"
                  >
                    <div class="section">
                      <!-- style="margin: 7px" -->
                      <label class="form-control-label">{{
                        "Page" + (sec_index + 1) + " : "
                      }}</label>
                      <div
                        :id="section.uid"
                        class="form-control-alternative textarea notepad-step"
                        @blur="onFocushandle(section, $event)"
                        @drop="drop"
                        @dragover="dragOver"
                      >
                          <!-- <div id="drag-area" @dragenter="dragenter" v-if="section.meta == ''">
                            <i class="fas fa-share" style="margin: 5px"></i>
                            <header>Drag & Drop leftWidgets</header>
                          </div> -->
                        <div
                          v-for="(meta, meta_index) in section.meta"
                          :key="meta_index.uid"
                          class="data-table"
                          @dblclick="dblClickTable(section.uid, meta, $event)"
                        >
                          <ag-grid-vue
                            v-if="meta && meta.type == 'table'"
                            style="height: 200px"
                            class="ag-theme-balham"
                            :columnDefs="meta.columnDefs"
                            :rowData="meta.rowData"
                            rowSelection="multiple"
                            rowHeight="50"
                            pagination="true"
                            paginationPageSize="10"
                            floatingFilter="true"
                          >
                          </ag-grid-vue>
                          <grid-layout
                          v-if="meta && meta.type == 'grid'"
                          :layout.sync="layout"
                          :col-num="colNum"
                          :row-height="30"
                          :is-draggable="draggable"
                          :is-resizable="resizable"
                          :vertical-compact="true"
                          :use-css-transforms="true"
                        >
                        <grid-item v-for="item in meta.grids"
                            :static="item.static"
                            :x="item.x"
                            :y="item.y"
                            :w="item.w"
                            :h="item.h"
                            :i="item.i"
                            :key="item.i"
                        >
                          <textarea
                            id="message"
                            style="width: 90%"
                            name="message"
                            v-model="item.textarea"
                            placeholder="Enter text here..."
                          ></textarea>
                          <!--<div
                            :id="item.uid"
                            :data-gridid="item.uid"
                            class="grid-item"
                          >
                          <div
                          :id="item.uid"
                          @blur="onFocushandle(section, $event)"
                          @drop="drop"
                          @dragover="dragOver"
                        >
                          <div 
                            v-for="(grid, index) in item.metadata"
                            :key="index"
                            >
                            <textarea
                              id="message"
                              style="width: 90%"
                              name="message"
                              v-model="grid.textarea"
                              placeholder="Enter text here..."
                            ></textarea>
                            </div>
                            </div>
                          </div> -->
                        </grid-item>
                          </grid-layout>
                          <div
                            @dblclick="dblClickTextarea($event)"
                          >
                            <textarea
                              id="message"
                              style="width: 100%"
                              name="message"
                              v-if="meta && meta.type == 'text'"
                              v-model="meta.textarea"
                              placeholder="Enter text here..."
                            ></textarea>
                          </div>
                          <div
                            class="image"
                            v-if="meta && meta.type == 'image'"
                          >
                            <img
                              :src="meta.imagePath"
                              style="width: 200px"
                              @dblclick="dblClickImage($event)"
                              class="img-fluid"
                            />
                          </div>
                          <div
                            class="video"
                            v-if="meta && meta.type == 'video'"
                          >
                            <video
                              width="320"
                              height="240"
                              controls
                              @dblclick="dblClickVideo($event)"
                            >
                              <source :src="meta.videoPath" :type="videotype" />
                            </video>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'timer'">
                            <img style="width: 50px;" :src="meta.description" alt="Timer" class="u-photo"/>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'formula'">
                            <p><a href="javascript:void(0)">Formula {{ meta.formuladatalabel }} = {{ meta.formula }}</a></p>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'mediaImage'">
                            <img style="width: 50px;" :src="meta.description" alt="mediaImage_" class="u-photo"/>Upload Image
                            <p>{{ meta.uploadImage }}</p>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'mediaVideo'">
                            <span><a href="javascript:void(0)">Upload Video: {{ meta.description }}</a></span><br>
                            <span v-if="meta.ResultText"><a href="javascript:void(0)">{{ meta.ResultText }}</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'mediaQRcode'">
                            <img style="width: 50px;" :src="meta.description" alt="qrImage_" class="u-photo"/>Upload QR Code
                            <span v-if="meta.ResultText"><a href="javascript:void(0)">{{ meta.ResultText }}</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'data-input'">
                            <span><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'dataNumberTranding' && meta.datatrendfound && !meta.acceptablerange">
                              <span><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}[DataTrend]</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'dataNumberTranding' && meta.datatrendfound && meta.acceptablerange">
                            <span><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}[DataTrend] [{{meta.minvalue}} - {{meta.maxvalue}}]</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'dataNumberTranding' && !meta.datatrendfound && !meta.acceptablerange">
                            <span><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'dataNumberTranding' && !meta.datatrendfound && meta.acceptablerange">
                            <span><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}[{{meta.minvalue}} - {{meta.maxvalue}}]</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'iot-input'">
                            <p><a href="javascript:void(0)">{{ meta.description }}</a></p>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'mcq-input'">
                            <p><a href="javascript:void(0)">{{ meta.description }}</a></p>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'skill-input'">
                            <img style="width: 50px;" :src="meta.description"  class="u-photo"/>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'var-input'">
                            <p><a href="javascript:void(0)">[{{ meta.variableName }}, {{meta.value}}]</a></p>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'steplock'">
                            <img style="width: 50px;" :src="meta.description"  class="u-photo"/>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'verifierInput'">
                            <p><a href="javascript:void(0)">VERIFIER: {{ meta.description }}</a></p>
                          </div>
                          <div class="drag-controls" v-if="meta && meta.type == 'cautionInput'">
                            <p><a href="javascript:void(0)">CAUTION: {{ meta.description }}</a></p>
                          </div>
                          <div class="drag-controls" v-if="meta && meta.type == 'crossrefInput'">
                              <p><a href="javascript:void(0)">Cross Reference / Document Title = {{ meta.description }}</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="notepadTemp.title">
                <button
                  style="margin: 7px"
                  class="btn float-right btn-default btn-sm"
                  @click="addSection($event)"
                >
                  Add blankPage
                </button>
              </div>
              <!-- JSON.stringify{{notepadTemp}}; -->
            </div>
          </div>
        </div>
        <div class="col editRightParent">
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="isApprove()"
                @click="showLaunchBatchModal = true"
              >
                LAUNCH
              </button>
            </div>
            <div
              v-if="
                notepadTemp.status1 != 'new' && notepadTemp.status1 != 'batch'
              "
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-clone-modal"
                @click="showCloneModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                CLONE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="!isApprove() && !isLibrary()"
                @click="saveDocument()"
              >
                SAVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showShareModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                {{
                  notepadTemp.status1 &&
                  notepadTemp.status1.toLowerCase() == "shared"
                    ? "ADD COLLABRATOR"
                    : "SHARE"
                }}
              </button>
            </div>

            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--  <a href="#!" class="btn btn-info" v-if="isEditable()">CHECK</a> -->
            </div>
            <!-- v-if="!isClonedDocument" -->
            <div
              v-if="notepadTemp.isNotepad && notepadTemp.status1 == 'draft'"
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showApproveModal = true"
                class="btn btn-info"
                v-if="isEditable()"
              >
                APPROVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!-- <a href="#!" class="btn btn-info" v-if="isEditable()">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Begin: Launch Batch Document -->
    <div v-if="showLaunchBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Launch Batch Document - '" +
                    stripHTML(notepadTemp.title) +
                    "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showLaunchBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(notepadTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Launch Batch Document</span>
                  <textarea
                    v-model="batchDocNumber"
                    placeholder="Enter batch #"
                    style="width: 100%"
                  ></textarea>
                  <button
                    v-if="this.notepadTemp.sections == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="launchBatch()"
                  >
                    Launch Batch
                  </button>
                  <button
                    v-if="this.notepadTemp.sections == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="setToDefault()"
                  >
                    Cancel
                  </button>

                  <div v-if="this.notepadTemp.sections" style="max-height: calc(100vh - 210px);">
                    <span>Set Variable values:</span>
                    <div v-for="(section,sec_index) in notepadTemp.sections" v-bind:key="section.uid">
                      <div v-for="(meta, meta_index) in section.meta" v-bind:key="meta_index.uid">
                        <table v-if="meta.type == 'var-input'">
                          <tr>
                          <td style="width:20%">Page: <label class="form-control-label" v-html="sec_index + 1"></label></td>
                          <td style="width:35%">Variable Name: <label class="form-control-label">{{meta.variableName}}</label></td>
                          <td style="width:50%">Value: <input type="text" class="form-control-alternative" v-model="meta.value" v-on:input="onValueChange(section,meta,$event)" /></td>
                        </tr></table>
                      </div>
                    </div>
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="launchBatch()"
                  >Launch Batch</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="setToDefault()"
                  >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Launch Batch Document -->

    <!-- Begin: Share Document -->
    <div v-if="showShareModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Share Document - '" + stripHTML(notepadTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showShareModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(notepadTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <!-- <span>Share Document to :</span> -->
                  <div class="tab">
                    <button
                      class="tablinks active"
                      @click="openShare('shareContent', $event)"
                    >
                      Share
                    </button>
                    <button
                      class="tablinks"
                      @click="openShare('libraryContent', $event)"
                    >
                      Library
                    </button>
                  </div>

                  <div
                    id="shareContent"
                    class="tabcontent"
                    style="display: block"
                  >
                    <h3>Send to Emails</h3>
                    <textarea
                      v-model="emailAddress"
                      placeholder="Email address (separated by ; )"
                      style="width: 100%"
                    ></textarea>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>

                  <div id="libraryContent" class="tabcontent">
                    <h3>Send to DigitalGxP Public Library</h3>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareLibraryDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Share Document -->
    <!-- Begin: Clone Document -->
    <div v-if="showCloneModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Clone Document - '" + stripHTML(notepadTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showCloneModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Clone Document</span>
                  <div class="custom-control targetTemplateType">
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="doument"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="document"
                    />Document
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="template"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="template"
                    />Template
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="library"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="library"
                    />Library
                  </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="handleCloneConfirmClick()"
                  >
                    Clone
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCloneModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Clone Document -->
    <!-- Begin: Comment-->
    <div v-if="showCommentModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog comment-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Add Comment for Step : '" + commentTitle + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCommentModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Comments display-->
                <div>
                  <div
                    class="sec-toggle comment-section"
                    @click="sectionToggleComment"
                  >
                    <label
                      class="form-control-label comment-header ctrl-collapse"
                      style="display: none; width: fit-content"
                      @click="sectionToggleComment"
                      >+ Comments</label
                    >
                    <label
                      class="form-control-label comment-header ctrl-expand"
                      style="width: fit-content"
                      @click="sectionToggleComment"
                      >- Comments</label
                    >
                  </div>
                  <div class="sec-body">
                    <div
                      v-for="(comment, comment_index) in this.currentStep
                        .comments"
                      :key="comment.id || comment_index"
                      style="padding-left: 15px"
                      class="comment-text"
                    >
                      <span class="comment-user">{{ comment.createdBy }}</span>
                      {{
                        comment.description +
                        " - on " +
                        formatDate(comment.createdTime)
                      }}
                    </div>
                  </div>
                </div>

                <div class="modal-body">
                  <span>Comments</span>
                  <textarea
                    v-model="commentDescription"
                    placeholder="Enter your comment"
                    style="width: 100%"
                  ></textarea>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="addComment()"
                  >
                    Add Comment
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCommentModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Comment -->

    <!-- Begin: Approve Document -->
    <div v-if="showApproveModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Approve Document - '" + stripHTML(notepadTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showApproveModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(notepadTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Please confirm!</span>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="approveDocument()"
                  >
                    Approve
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showApproveModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Approve Document -->

    <!-- {{this.notepadTemp}} -->
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    @click="showModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title">Modal title</h4>
                </div>
                <div class="modal-body">modal body</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTableModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  Grid Title: Client Info
                  <button
                    type="button"
                    class="close"
                    @click="showTableModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <button class="btn btn-secondary" @click="addRows">
                      Add
                    </button>
                    <!-- <button class="btn btn-secondary" @click="replace">
                      Replace
                    </button> -->
                  </div>
                  <table
                    class="table"
                    cellspacing="2"
                    cellpadding="5"
                    id="data_table"
                    border="1"
                    style="margin-top: 10px"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">ColumnName</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="updateColumnDefs" tag="tbody">
                      <tr
                        v-for="(item, index) in updateColumnDefs"
                        :key="index"
                      >
                        <td scope="row">{{ index + 1 }}</td>
                        <td>
                          <span v-if="item.editmode">
                            <input type="text" v-model="item.columnName" />
                          </span>
                          <span v-else>{{ item.columnName }}</span>
                        </td>
                        <!-- <td>{{ item.type }}</td> -->
                        <td>
                          <span v-if="item.editmode">
                            <select v-model="item.type" required>
                              <option v-for="option in type" :key="option.id">
                                {{ option.val }}
                              </option>
                            </select>
                          </span>
                          <span v-else>{{ item.type }}</span>
                        </td>
                        <td>
                          <span v-if="!item.editmode"
                            ><button @click="edit(item)">Edit</button></span
                          >
                          <span v-else
                            ><button @click="save(item)">Save</button></span
                          >
                          <button @click="deleteRow(index)" class="delete">
                            Delete
                          </button>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="submitTable()"
                  >
                    Submit
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTable($event)"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTableModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showGridModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  Grid Title: Client Info
                  <button
                    type="button"
                    class="close"
                    @click="showGridModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <button class="btn btn-secondary" @click="addItem">Add an item dynamically</button>
                    <input type="checkbox" v-model="draggable" /> Draggable
                    <input type="checkbox" v-model="resizable" /> Resizable
                  </div>
                  <grid-layout :layout.sync="layout"
                    :col-num="colNum"
                    :row-height="30"
                    :is-draggable="draggable"
                    :is-resizable="resizable"
                    :vertical-compact="true"
                    :use-css-transforms="true"
                  >
                  <grid-item v-for="item in layout"
                      :static="item.static"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :key="item.i"
                  >
                    <!-- <span class="text">{{item.i}}</span> -->
                    <span class="remove" @click="removeItem(item.i)">x</span>
                  </grid-item>
                  </grid-layout>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="submitGrid()"
                  >
                    Submit
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTable($event)"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTableModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTextModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Textarea Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTextModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the textarea
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextarea"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTextareaGridModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  TextareaGrid Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTextareaGridModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the textarea
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextareaGrid"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextareaGridModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showImageModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Image Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showImageModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="text"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Image:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="image/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <img
                        style="width: 100px; height: 100px; margin-bottom: 10px"
                        :src="filespath"
                        alt=""
                      />
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteImage()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showVideoModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Video Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showVideoModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="text"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Video:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="video/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <video width="320" height="240" controls>
                        <source :src="filespath" type="video/mp4" />
                      </video>
                      <!-- <iframe width="420" height="315"
                          :src="filespath">
                      </iframe> -->
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteVideo()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showDeleteModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Delete Confirmation
                  <button
                    type="button"
                    class="close"
                    @click="showDeleteModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    Please confirm the if you want to delete or cancel?
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTextarea()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showDeleteModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTimerModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Timer Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTimerModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the Timer
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTimer($event)"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTimerModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- {{JSON.stringify(notepadTemp)}} -->
  </div>
</template>
<script>
/* eslint-disable no-console */
import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";
import draggable from "vuedraggable";
// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import notepadHeader from "@/views/process/notepad/notepadHeader";
import { GridLayout, GridItem } from "vue-grid-layout";

export default {
  components: { notepadHeader, AgGridVue, draggable, GridLayout, GridItem },
  data() {
    return {
      layout: [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },],
      draggable: true,
      resizable: true,
      colNum: 12,
      index: 0,
      shareDocId: "",
      isClonedDocument: false,
      showTextareaGridModal: false,
      showModal: false,
      showShareModal: false,
      showCommentModal: false,
      showApproveModal: false,
      showLaunchBatchModal: false,
      notepadTemp: {},
      showCloneModal: false,
      showTableModal: false,
      showGridModal: false,
      showImageModal: false,
      showVideoModal: false,
      showTextModal: false,
      showDeleteModal: false,
      showTimerModal: false,
      setVariable: false,
      disable: true,
      dragItem: "",
      clickIndex: -1,
      targetTemplateType: "",
      isDelete: false,
      finaljson: {},
      finalarray: [],
      authCookie: null,
      editIds: [],
      batchDocNumber: "",
      emailAddress: "",
      currentStep: "",
      commentTitle: "",
      commentDescription: "",
      files: "",
      fileUrl: "",
      filespath: "",
      videopath: "",
      videotype: "",
      newComments: [],
      gridOptions: null,
      isNotepad: "",
      updateColumnDefs: [],
      rowData: [],
      updateSecId: "",
      updateStepId: "",
      updategridId: "",
      metaId: "",
      metadataId: "",
      type: [
        { id: "23", val: "Text" },
        { id: "13", val: "Number" },
        { id: "3", val: "DateTime" },
      ],
    };
  },
  beforeMount() {
    this.shareDocId = this.$route.query.shareDocId;
    this.isClonedDocument = this.$route.query.isClonedDocument;
    this.notepadTemp.docID = this.$route.query.docid;
    this.notepadTemp.status1 = this.$route.query.docStatusType;
    this.notepadTemp.isNotepad = this.$route.query.isNotepad;

    if (!this.$route.query.docid) {
      this.notepadTemp.industryItemType = this.$route.query.industryItemType;
      this.notepadTemp.industryItemID = this.$route.query.industryItemID;
    }
  },
  mounted() {
    console.log(
      " docid: " +
        this.notepadTemp.docID +
        " docStatusType: " +
        this.notepadTemp.status1 +
        " isNotepad: " +
        this.notepadTemp.isNotepad +
        " iscloned : " +
        this.isClonedDocument
    );
    if (!this.notepadTemp.docID && !this.shareDocId) {
      let sharedKey = this.$route.params.key;
      if (sharedKey) {
        this.shareDocId = sharedKey;
      }
    }
    if (this.notepadTemp.docID) {
      axios
        .get(
          "/api/notepad/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.notepadTemp.docID
        )
        .then((response) => {
          console.log(response);
          if (this.isClonedDocument) {
            let targetTemplateType = this.notepadTemp.isNotepad;
            this.notepadTemp = {};
            this.notepadTemp.title = response.data.item.title;
            this.notepadTemp.sections = response.data.item.sections;
            this.notepadTemp.status1 = "new";
            this.notepadTemp.isNotepad = targetTemplateType;
          } else {
            this.notepadTemp = response.data.item;
          }

          //console.log('Tru');
          var i = 0;
          var totalsec = this.notepadTemp.sections
            ? this.notepadTemp.sections
            : [];
          //var latestarray = [];
          for (i = 0; i < totalsec.length; i++) {
            var j = 0;
            var eachstep = totalsec[i].meta;
            for (j = 0; j < eachstep.length; j++) {
              //var v1 = eachstep[j].description.toString();
              var dom = document.createElement("div");
              dom.innerHTML = eachstep[j].description;
              var atag = dom.getElementsByTagName("a");
              if (atag != undefined && atag.length > 0) {
                var k;
                for (k = 0; k < atag.length; k++) {
                  if (
                    !(
                      atag[k].innerHTML.toString().indexOf("Formula = ", 0) > 0
                    ) &&
                    atag[k].innerHTML.toString().split(",")[1] != undefined
                  ) {
                    this.finaljson = {};
                    //console.log(atag[k].innerHTML);
                    this.finaljson.uid = uuidv4();
                    this.finaljson.sectionnum = i + 1 + "." + (j + 1);
                    this.finaljson.variablename = atag[k].innerHTML
                      .toString()
                      .split(",")[0]
                      .substring(
                        1,
                        atag[k].innerHTML.toString().split(",")[0].length
                      );
                    this.finaljson.defaultvalue = atag[k].innerHTML
                      .toString()
                      .split(",")[1]
                      .substring(
                        0,
                        atag[k].innerHTML.toString().split(",")[1].length - 1
                      );
                    this.finaljson.oldvalue = atag[k].innerHTML
                      .toString()
                      .split(",")[1]
                      .substring(
                        0,
                        atag[k].innerHTML.toString().split(",")[1].length - 1
                      );
                    this.finalarray.push(this.finaljson);
                    //this.finalarray = this.finalarray.concat(this.finalarray);
                    //console.log('i ' + i + ' j ' + j + ' k ' + k);
                    //console.log(this.finaljson);
                    //console.log('final array');
                    //console.log(this.finalarray);
                  }
                }
              }
              //this.finalarray.push(this.finaljson);
            }
            //this.finalarray.push(this.finaljson);
          }
          //this.finalarray.push(this.finaljson);

          //var v1 = this.notepadTemp.sections[0].steps[2].description.toString();
          //console.log('final array');
          //console.log(this.finalarray);
          //v2.innerHTML.toString());

          this.notepadTemp.title = this.notepadTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + this.notepadTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          this.notepadTemp = [];
        });
    } else if (this.shareDocId) {
      console.log(" Opening from Share id :" + this.shareDocId);
      var that = this;
      axios
        .get("/api/sharedDocuments?key=" + this.shareDocId)
        .then((response) => {
          that.notepadTemp = response.data.item;
          that.notepadTemp.docID = response.data.item.docID;
          that.notepadTemp.status1 = response.data.item.status1;

          that.notepadTemp.title = that.notepadTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + that.notepadTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          that.notepadTemp = [];
        });
    }
    this.index = this.layout.length;
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj) {
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }
  },
  methods: {
    addItem: function () {
        // Add a new item. It must have a unique key!
        this.layout.push({
            x: (this.layout.length * 2) % (this.colNum || 12),
            y: this.layout.length + (this.colNum || 12), // puts it at the bottom
            w: 2,
            h: 2,
            i: this.index.toString(),
            uid: uuidv4(),
        });
        // Increment the counter to ensure key is always unique.
        this.index++;
    },
    removeItem: function (val) {
        const index = this.layout.map(item => item.i).indexOf(val);
        this.layout.splice(index, 1);
    },
    notifyUser(notepadTempNotification){
      console.log(notepadTempNotification);
      var notification = {
          objectId: notepadTempNotification.docID,
          type: "notepad",
          status: notepadTempNotification.status1,
          message:  " has " + notepadTempNotification.status1  + " the notepad document " + notepadTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    notifyUserBatch(notepadTempNotification){
      console.log(notepadTempNotification);
      var notification = {
          objectId: notepadTempNotification.docID,
          type: "notepad",
          status: notepadTempNotification.status1,
          message:  " has " + " launched " + notepadTempNotification.status1  + " notepad  document " + notepadTempNotification.batchNumber + " for " + notepadTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
    },
    onFocushandle(section, event) {
      var el =
        event.target.parentElement.parentElement.querySelector(".form-control");
      this.$set(section, "description", el.innerHTML);
    },
    onClickDragTable(stepId) {
      var element = document.getElementById("table-grid_" + stepId);
      element.style.display = "block";
    },
    deleteRow(index) {
      this.updateColumnDefs.splice(index, 1);
    },
    edit(obj) {
      this.$set(obj, "editmode", true);
      console.log("edit");
    },
    save(obj) {
      this.$set(obj, "editmode", false);
      console.log("save");
    },
    drop(event) {
      const dragControl = event.dataTransfer.getData("data-class-name");
      // if(event.target.parentElement.parentElement.parentElement.querySelector(".step-body")) {
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;

      if (dragControl == "table-input") {
        // this.dragItem = 'dragDrop';
        this.showTableModal = true;
        this.updateColumnDefs = [];
      } 
      if (dragControl == "grid-input") {
        this.showGridModal = true;
        this.layout = [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },];
      }
      else if (dragControl == "text-input") {
        this.showTextModal = true;
      } else if (dragControl == "image-input") {
        this.showImageModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      } else if (dragControl == "video-input") {
        this.showVideoModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      } else if ( dragControl == "time-input") {
        this.showTimerModal = true;
      } else if ( dragControl == "formula-input") {
          this.showFormula(event);
      }else if (dragControl == "qrcode-input") {
          var pValQRCode = prompt("Please select type of Media Input: 1 For Image OR 2 For Video OR 3 For QR Code", "");
            if (!pValQRCode) return;
            if(pValQRCode == 1)
            {
                var pValImgName = prompt("Please Enter name of Image", "");
                if (!pValImgName) return; 
                this.showMediaImage();
            }
            else if(pValQRCode == 2)
            {
                // var pValVideoName = prompt("Please Enter name of Video", "");
                // if (!pValVideoName) return; 
                this.showMediaVideo();
            }
            else if(pValQRCode == 3)
            {
                var pValQRName = prompt("Please Enter name of QR Code", "");
                if (!pValQRName) return; 
                this.showQRVideo();
            }
      }else if (dragControl == "data-input") {
                var pValText = prompt("Please select type of Data Input: 1 For Text OR 2 For Numeric", "");
                if (!pValText) return;
                if(pValText == 1)
                {
                  this.showDatainput();
                }
                if(pValText == 2)
                {
                  this.showDataInputNumberTranding();
                  // this.showDataInputNumber();
                }
      }else if (dragControl == "var-input") {
        this.showVariable();
      }else if (dragControl == "steplock-input") {
        this.showSteplock();
      }
      else if (dragControl == "verifier-input") {
        this.showVerifierInput();
      }
      else if (dragControl == "caution-input") {
        this.showCautionInput();
      }
      else if (dragControl == "crossref-input") {
        this.showCrossrefInput();
      }
      else if (dragControl == "iot-input") {
        this.showIot();
      }
      else if (dragControl == "mcq-input") {
        this.showMcq();
      }
      else if (dragControl == "skill-input") {
        this.showSkillTrend();
      }
      event.preventDefault();
      // }
      
      // else if(event.target.parentElement.parentElement.querySelector(".grid-item")){
      //   var stepGrid = event.target.parentElement.parentElement.querySelector(".grid-item");
      //   this.updategridId = stepGrid.dataset.gridid;
      //   if (dragControl == "text-input") {
      //     this.showTextareaGridModal = true;
      //   }
      // }
    },
    dropGridControls(event){
      const dragControl = event.dataTransfer.getData("data-class-name");
      var stepBody = event.target.parentElement.parentElement.querySelector(".grid-item");
      this.updategridId = stepBody.dataset.gridid;
      if (dragControl == "text-input") {
        this.showTextareaGridModal = true;
      }
    },
    startDrag(event) {
      var contact = event.target.parentElement.getAttribute("data-class-name");
      event.dataTransfer.setData("data-class-name", contact);
      console.log(contact);
      // event.dataTransfer.getData("data-class-name")
    },
    dragenter() {
      document.getElementById("drag-area").style.backgroundColor = "#00800066";
    },
    dragOver(event) {
      event.preventDefault();
    },
    // startDrag(event) {
    //   var contact = event.target.parentElement.getAttribute("data-class-name");
    //   event.dataTransfer.setData("data-class-name", contact);
    // },
    // dragenter() {
    //   document.getElementById("drag-area").style.backgroundColor = "#00800066";
    // },
    // dragOver(event) {
    //   event.preventDefault();
    // },
    addRows() {
      let existingLength = this.updateColumnDefs.length;
      this.updateColumnDefs.push({
        columnName: "",
        id: ++existingLength,
        type: "",
        // order: 1
      });
    },
    dblClickTable(secid, meta, event) {
      // event.preventDefault();
      console.dir(event);
      // var stepBody =
      //   event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector(
      //     ".step-body"
      //   );
      var stepBody = event.target.closest('div[class^="step-body"]');
      console.dir(
        " stepBody.id " + stepBody.id + " secid " + stepBody.dataset.secid
      );
      this.updateSecId = stepBody.dataset.secid;
      // this.updateStepId = stepBody.id;
      this.metaId = meta.uid;
      //this.updateColumnDefs = [];
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0] && step[0].meta) {
        this.updateColumnDefs = [];
        for (var i = 0; i < meta.columnDefs.length; i++) {
          let colHeader = {};
          let obj = meta.columnDefs[i];
          colHeader.columnName = obj.headerName;
          colHeader.type = obj.type;
          (colHeader.id = i + 1), this.updateColumnDefs.push(colHeader);
          // }
        }
      }
      this.isDelete = true;
      this.showTableModal = true;
    },
    submitTable(e) {
      console.log(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0]) {
        let gridColumnDefs = [];
        for (var i = 0; i < this.updateColumnDefs.length; i++) {
          let colHeader = {};
          let obj = this.updateColumnDefs[i];
          colHeader.headerName = obj.columnName;
          colHeader.field = obj.columnName;
          colHeader.sortable = true;
          colHeader.filter = true;
          colHeader.editable = true;
          colHeader.type = obj.type;
          gridColumnDefs.push(colHeader);
        }
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              columnDefs: gridColumnDefs,
              rowData: this.rowData,
              type: "table",
              uid: uuidv4(),
            });
            this.$set(sec[0], 0);
            this.showTableModal = false;
            // this.metaId = null;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          columnDefs: gridColumnDefs,
          rowData: this.rowData,
          type: "table",
          uid: uuidv4(),
        });
        this.$set(sec[0], 0);
        // }
      }
      this.showTableModal = false;
      // this.updateSecId = null;
      // this.updateStepId = null;
      this.updateColumnDefs = [];
      // this.metaId = null;
    },
    showTextarea(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "text",
              uid: uuidv4(),
            });
            this.$set(sec[0], 0);
            this.showTextModal = false;
            // this.metaId = null;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "text",
          uid: uuidv4(),
        });
        this.$set(sec[0], 0);
        // }
      }
      this.showTextModal = false;
    },
    showIot() {
      var pValIot = prompt("Please select type of IoT Input: 1 For Data OR 2 For Video OR 3 For Audio", "");
        if (!pValIot) return;
        var iotValue = 'IOT:' +pValIot;
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "iot-input",
              uid: uuidv4(),
              description: iotValue,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "iot-input",
          uid: uuidv4(),
          description: iotValue,
        });
        this.$set(sec[0], 0);
      }
    },
    showMcq() {
      var options = [];
      var finalOptions = null;
      var pValOption = prompt("Enter a multiple options separated by commas ( , ) :", "");
      if (!pValOption) return;
      let optionNumbers = pValOption.split(",");
      var pValOptionExit = prompt("Enter = operator for Exit", "");
      if (!pValOptionExit) return;
      if(pValOptionExit == "="){
          finalOptions = optionNumbers;
      }
      else{
          finalOptions = optionNumbers + " " + pValOptionExit;
      }
      var res = '  ';
      for (let i = 0; i < finalOptions.length; i++) {
          options.push( i+1  +  "."  + "  " +  finalOptions[i] + res );
          options.join('');
      }
      var mcqData = 'Options = ' + options.join(" , ");
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mcq-input",
              uid: uuidv4(),
              mcq: true,
              description: mcqData,
              value: "",
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mcq-input",
          uid: uuidv4(),
          mcq: true,
          description: mcqData,
          value: "",
        });
        this.$set(sec[0], 0);
      }
    },
    showSkillTrend() {
      var skillDesc = "/img/pptx/widgets/trending.png";
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "skill-input",
              uid: uuidv4(),
              skillfound: true,
              description: skillDesc,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "skill-input",
          uid: uuidv4(),
          skillfound: true,
          description: skillDesc,
          "skills" : [{
                uid: uuidv4(),
                executedUsers: [],
                verifiedUsers: [],
                batchno: '',
                }],   
        });
        this.$set(sec[0], 0);
      }
    },
    dblClickTextarea(event) {
      console.dir(event);
      // var stepBody = event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector(".step-body");
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      // this.updateStepId = stepBody.id;
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0] && step[0].meta && step[0].meta[0]) {
        this.showDeleteModal = true;
        // }
      }
    },
    deleteTable(event) {
      console.dir(event);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0]) {
        if (sec[0].meta) {
          for (var j = 0; j < sec[0].meta.length; j++) {
            if (sec[0].meta[j].uid == this.metaId) {
              sec[0].meta.splice(j, 1);
            }
          }
          // }
        }
      }
      this.showTableModal = false;
    },
    dblClickImage(event) {
      console.dir(event);
      // var stepBody =
      //   event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector(
      //     ".step-body"
      //   );
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      // this.updateStepId = stepBody.id;
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);

        this.isDelete = true;
        this.showImageModal = true;
      }
    },
    deleteTextarea() {
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (sec && step[0]) {
        if (sec[0].meta) {
          // delete sec[0].meta;
          for (var j = 0; j < sec[0].meta.length; j++) {
            if (sec[0].meta[j].uid == this.metaId) {
              sec[0].meta.splice(j, 1);
            }
          }
        }
        // }
      }
      this.showDeleteModal = false;
    },
    deleteImage() {
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0]) {
        if (sec[0].meta) {
          // delete sec[0].meta;
          for (var j = 0; j < sec[0].meta.length; j++) {
            if (sec[0].meta[j].uid == this.metaId) {
              sec[0].meta.splice(j, 1);
            }
          }
        }
        // }
      }
      this.isDelete = true;
      this.showImageModal = false;
    },
    showTimer(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var test = "/img/pptx/timer.png";
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "timer",
              uid: uuidv4(),
              timer: true,
              description: test,
            });
            this.$set(sec[0], 0);
            this.showTimerModal = false;
            // this.metaId = null;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "timer",
          uid: uuidv4(),
          timer: true,
          description: test,
        });
        this.$set(sec[0], 0);
        // }
      }
      this.showTimerModal = false;
    },
    showFormula(e){
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var finalString = null; 
        var pVal1Formula = prompt("For a blank value in the formula to be entered during a batch document, enter {name}. For a constant value, enter the numeric value e.g. 9.5", "");
        if (!pVal1Formula) return;
        var pValFormulaop1 = prompt("Enter Operator for Folmula (+ , - , * , /)", "");
        if (!pValFormulaop1) return;

        var pVal2Formula = prompt("Formula: " + pVal1Formula + " " + pValFormulaop1  + " Enter Value {name} or constant number", "");
        if (!pVal2Formula) return;

        var pValFormulaop2 = prompt("Enter Operator for Folmula (+ , - , * , /) and = for Exit", "");
        if (!pValFormulaop2) return;

        var dataLabel = prompt("Add Data Label for Formula", "");
        if (!dataLabel) return;

        if(pValFormulaop2 == "="){
            finalString = pVal1Formula + " " + pValFormulaop1 + " " + pVal2Formula;
          }
          else{
            finalString = pVal1Formula + " " + pValFormulaop1 + " " + pVal2Formula+ " " + pValFormulaop2;
          }
          
          while(pValFormulaop2 != "=")
          {
            pVal2Formula = prompt("Formula: " +  finalString + " Enter Value {name} or constant number", "");

            finalString = finalString + " " + pVal2Formula;

            pValFormulaop2 = prompt("Enter Operator for Folmula (+ , - , * , /) and = for Exit", "");

            if(pValFormulaop2 != "="){
                finalString = finalString + " " + pValFormulaop2;
            }
          }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "formula",
              uid: uuidv4(),
              formula: finalString,
              formuladatalabel: dataLabel,
              formulafound: true,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "formula",
          uid: uuidv4(),
          formula: finalString,
          formuladatalabel: dataLabel,
          formulafound: true,
        });
        this.$set(sec[0], 0);
      }
    },
    showMediaImage(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var mediaImagepath = "/img/pptx/image-old.png"
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mediaImage",
              uid: uuidv4(),
              mediaimagefound: true,
              description: mediaImagepath,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mediaImage",
          uid: uuidv4(),
          mediaimagefound: true,
          description: mediaImagepath,
        });
        this.$set(sec[0], 0);
      }
    },
    showMediaVideo(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValVideoName = prompt("Please Enter name of Video", "");
      if (!pValVideoName) return; 
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mediaVideo",
              uid: uuidv4(),
              videofound: true,
              description: pValVideoName,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mediaVideo",
          uid: uuidv4(),
          videofound: true,
          description: pValVideoName,
        });
        this.$set(sec[0], 0);
      }
    },
    showQRVideo(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var qrImagepath = "/img/pptx/qr-code.png"
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mediaQRcode",
              uid: uuidv4(),
              qrimagefound: true,
              description: qrImagepath,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mediaQRcode",
          uid: uuidv4(),
          qrimagefound: true,
          description: qrImagepath,
        });
        this.$set(sec[0], 0);
      }
    },
    showDatainput(e){
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var pVarText= null;
      pVarText = prompt("Add Data Label for Text Input", "");
        if (!pVarText) return;
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "data-input",
              uid: uuidv4(),
              inputfound: true,
              description: (pVarText+":__________"),
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "data-input",
          uid: uuidv4(),
          inputfound: true,
          description: pVarText,
          value: "__________",
        });
        this.$set(sec[0], 0);
      }
    },
    showDataInputNumberTranding(e){
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // if (!sec[0].meta) {
        //   sec[0].meta = [];
        // }
        var pVarNumeric= null;
        pVarNumeric = prompt("Add Data Label for Numeric Input", "");
          if (!pVarNumeric) return;
        var pVarDataTreding = prompt("Do you want to Enable Data Tranding? 1 For Yes OR 2 For No", "");
          if (!pVarDataTreding) return;

        var  pvarRange =  prompt("Do you want to add Acceptable Data Range? 1 For Yes OR 2 For No", "");
          if (!pvarRange) return;
          if(pvarRange == 1) {
              var pValMinRange = prompt("Enter minimum value", "");
              if (!pValMinRange) return;

              var pValMaxRange = prompt("Enter maximum value", "");
              if (!pValMaxRange) return;
          }

        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "dataNumberTranding",
              uid: uuidv4(),
              description: pVarNumeric,
              inputfound: true,
              value: "___",
              datatrendfound: pVarDataTreding==1 ? true: false,
              acceptablerange: pvarRange==1 ? true: false,
              "dataTrandings" : [{
                uid: uuidv4(),
                varname: pVarNumeric,
                varvalue: 'NULL',
                batchno: '',}],              
            });
          }
        }
        if(pVarDataTreding ==1 && pvarRange == 1){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          // numberType :  pVarDataTreding,
          inputfound: true,
          value: "___",
          datatrendfound: true,
          acceptablerange: true,
          minvalue: pValMinRange,
          maxvalue: pValMaxRange,
          "dataTrandings" : [{
                uid: uuidv4(),
                varname: pVarNumeric,
                varvalue: 'NULL',
                batchno: '',}], 
        });
        }
        else if(pVarDataTreding == 1){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
          datatrendfound: true,
        });
        }
        else if(pVarDataTreding ==2 && pvarRange == 2){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
        });
        }
        else if(pVarDataTreding == 2 && pvarRange == 1){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
          acceptablerange: true,
          minvalue: pValMinRange,
          maxvalue: pValMaxRange,
        });
        }
        this.$set(sec[0], 0);
      }
    },
    showDataInputNumber(e){
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var pVarNumeric= null;
        pVarNumeric = prompt("Please Enter Input Type Numeric Name", "");
          if (!pVarNumeric) return;
        var pVarDataTreding = prompt("Do you want to Enable Data Tranding? 1 For Yes OR 2 For No", "");
          if (!pVarDataTreding) return;
            if(pVarDataTreding == 2)
            {
              <a href="javascript:void(0)">'+ pVarNumeric + ':____</a>            
            }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "dataNumber",
              uid: uuidv4(),
              inputfound: true,
              description: pVarNumeric,
            });
            this.$set(sec[0], 0);
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumber",
          uid: uuidv4(),
          inputfound: true,
          description: pVarNumeric,
        });
        this.$set(sec[0], 0);
      }
    },
    showVariable(e){
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Variable Name followed by value in the next prompt", "");
        if (!pValText) return;
        var pValDefText = prompt("Enter Variable Default Value needed for a particular PAGE", "");
        if (!pValDefText) return;
        var variable = String("["+ pValText +","+ pValDefText +"]");
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "var-input",
              uid: uuidv4(),
              description: variable,
              variableName: pValText,
              value: pValDefText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "var-input",
          uid: uuidv4(),
          description: variable,
          variableName: pValText,
          value: pValDefText,
        });
        this.$set(sec[0], 0);
      }
    },
    showSteplock(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var test = "/img/pptx/widgets/steplock.png";
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "steplock",
              uid: uuidv4(),
              description: test,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "steplock",
          uid: uuidv4(),
          description: test,
        });
        this.$set(sec[0], 0);
      }
    },
    showVerifierInput(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Verifier Name needed for a particular STEP", "");
          if (!pValText) return;
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "verifierInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "verifierInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(sec[0], 0);
      }
    },
    showCautionInput(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Caution Name needed for a particular STEP", "");
          if (!pValText) return;
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "cautionInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "cautionInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(sec[0], 0);
      }
    },
    showCrossrefInput(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Cross Ref Document Title (copy/paste Document Title)", "");
      if (!pValText) return;
      
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "crossrefInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "crossrefInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(sec[0], 0);
      }
    },
    dblClickVideo(event) {
      console.dir(event);
      // var stepBody =
      //   event.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.querySelector(
      //     ".step-body"
      //   );
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      // this.updateStepId = stepBody.id;
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0] && step[0].meta && step[0].meta[0]) {
        this.isDelete = true;
        this.showVideoModal = true;
        // }
      }
    },
    deleteVideo() {
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0]) {
        if (sec[0].meta) {
          // delete sec[0].meta;
          for (var j = 0; j < sec[0].meta.length; j++) {
            if (sec[0].meta[j].uid == this.metaId) {
              sec[0].meta.splice(j, 1);
            }
          }
        }
        // }
      }
      this.showVideoModal = false;
    },
    handleFileUploads() {
      // this.fileUrl =  URL.createObjectURL(this.$refs.file.files[0]);
      this.files = this.$refs.file.files[0];
      console.log(this.files);
      this.disable = false;
    },
    uploadImage(e) {
      console.dir(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          app.filespath = "";
          var path = response.data.fileUrl;

          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage(e) {
      console.dir(e);
      let app = this;
      var sec = app.notepadTemp.sections.filter(
        (x) => x.uid == app.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        // if (step && step[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            app.$set(sec[0].meta, j, {
              type: "image",
              uid: uuidv4(),
            });
            app.$set(sec[0], 0);
            app.showImageModal = false;
            // this.metaId = null;
            return;
          }
        }
        app.$set(sec[0].meta, sec[0].meta.length, {
          imagePath: app.filespath,
          type: "image",
          uid: uuidv4(),
        });
        app.$set(sec[0], 0);
        // }
      }
      app.showImageModal = false;
    },
    previewVideo(e) {
      console.dir(e);
      let app = this;
      var sec = app.notepadTemp.sections.filter(
        (x) => x.uid == app.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        // if (step && step[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            app.$set(sec[0].meta, j, {
              type: "video",
              uid: uuidv4(),
            });
            app.$set(sec[0], 0);
            app.showVideoModal = false;
            // this.metaId = null;
            return;
          }
        }
        app.$set(sec[0].meta, sec[0].meta.length, {
          videoPath: app.filespath,
          type: "video",
          uid: uuidv4(),
        });
        app.$set(sec[0], 0);
        // }
      }
      app.showVideoModal = false;
    },
    isEditable() {
      // console.log(this.notepadTemp.status1);
      return (
        !this.notepadTemp.status1 ||
        ["approved", "library", "shared", "archived", "template"].indexOf(
          this.notepadTemp.status1.toLowerCase()
        ) < 0
      );
    },
    isApprove() {
      return (
        this.notepadTemp.status1 &&
        ["approved"].indexOf(this.notepadTemp.status1.toLowerCase()) >= 0
      );
    },
    isLibrary() {
      return (
        this.notepadTemp.status1 &&
        ["template"].indexOf(this.notepadTemp.status1.toLowerCase()) >= 0
      );
    },
    isShare() {
      return (
        !(this.authCookie && this.authCookie.userName) &&
        this.notepadTemp.status1 &&
        ["shared"].indexOf(this.notepadTemp.status1.toLowerCase()) >= 0
      );
    },
    // show grid drag and drop controls in each grid
    showGrid(e) {
      console.dir(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "grid",
              uid: uuidv4(),
            });
            this.$set(sec[0], 0);
            this.showGridModal = false;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "grid",
          uid: uuidv4(),
        });
        this.$set(sec[0], 0);
      }
      this.showGridModal = false;
    },
    submitGrid(e) {
      console.log(e);
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "grid",
              uid: uuidv4(),
              grids: this.layout,
            });
            this.$set(sec[0], 0);
            this.showGridModal = false;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          
          type: "grid",
          uid: uuidv4(),
          grids: this.layout,
        });
        this.$set(sec[0], 0);
      }
      this.showGridModal = false;
      this.layout = [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },];
    },
    showTextareaGrid(e) {
      console.dir(e);
      for(var i = 0; i < this.notepadTemp.sections.length; i++){
        for(var j = 0; j < this.notepadTemp.sections[i].meta.length; j++){
            var sec = this.notepadTemp.sections[i].meta[j].grids.filter(
            (x) => x.uid == this.updategridId
            );
            if (sec && sec[0]) {
              for(var k = 0; k < this.notepadTemp.sections[i].meta[j].grids.length; k++){
                if (!this.notepadTemp.sections[i].meta[j].grids[k].metadata) {
                this.notepadTemp.sections[i].meta[j].grids[k].metadata = [];
                }
              for (var l = 0; l < this.notepadTemp.sections[i].meta[j].grids[k].metadata.length; l++) {
                if (this.notepadTemp.sections[i].meta[j].grids[k].metadata[l] == this.metaId) {
                this.$set(this.notepadTemp.sections[i].meta[i].grids[j].metadata, l, {
                  type: "text",
                  uid: uuidv4(),
                });
                this.$set(this.notepadTemp.sections[i].meta[j].grids[k], 0);
                this.showTextareaGridModal = false;
                return;
                }
              }
              this.$set(this.notepadTemp.sections[i].meta[j].grids[k].metadata, this.notepadTemp.sections[i].meta[j].grids[k].metadata.length, {
                type: "text",
                uid: uuidv4(),
              });
			this.$set(this.notepadTemp.sections[i].meta[j].grids[k], 0);
			}
      }
      this.showTextareaGridModal = false;
      }
      }
    },
    dblClickGridTextarea(secid, meta, event){
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.metaId = meta.uid;
      var sec = this.notepadTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta) {
        this.layout = [];
        for (var i = 0; i < meta.grids.length; i++) {
          let colHeader = {};
          let obj = meta.grids[i];
          colHeader.x = obj.x;
          colHeader.y = obj.y;
          colHeader.w = obj.w;
          colHeader.h = obj.h;
          colHeader.i = obj.i;
          (colHeader.id = i + 1), this.layout.push(colHeader);
        }
      }
      this.isDelete = true;
      this.showGridModal = true;
    },
    capitalizeFirstLetter(strVal) {
      if (strVal) return strVal.charAt(0).toUpperCase() + strVal.slice(1);
      return "";
    },
    assignHTML() {
      setTimeout(function () {
        var u = document.getElementsByTagName("u");
        var i;
        if (u != undefined) {
          for (i = 0; i < u.length; i++) {
            if (u[i].getAttribute("id") == null) {
              var itag = u[i].getElementsByTagName("em");
              if (itag[0] != undefined && itag[0].innerText != undefined) {
                u[i].setAttribute(
                  "id",
                  itag[0].innerText.toString().split(",")[0]
                );
                u[i].setAttribute("class", "tooltip1");
                //console.log('try');
                //console.log(itag[0].innerText.toString().split(',')[4]);
                if (itag[0].innerText.toString().split(",")[4] != "") {
                  u[i].insertAdjacentHTML(
                    "beforeend",
                    '<span class="tooltiptextimg">Type: ' +
                      itag[0].innerText.toString().split(",")[1] +
                      "<br> Description: " +
                      itag[0].innerText.toString().split(",")[3] +
                      '<br> Image: <img style="width:30px; height:30px; margin-bottom: 10px;" src=' +
                      itag[0].innerText.toString().split(",")[4] +
                      "> </span>"
                  );
                } else {
                  u[i].insertAdjacentHTML(
                    "beforeend",
                    '<span class="tooltiptext">Type: ' +
                      itag[0].innerText.toString().split(",")[1] +
                      "<br> Description: " +
                      itag[0].innerText.toString().split(",")[3] +
                      "</span>"
                  );
                }
              }
            }
          }
        }
      }, 100);
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
    sectionToggle(e) {
      let parentElement = e.target.parentElement;
      var secBody = parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        // parentElement.querySelector(".ctrl-collapse").style.display = "none";
        // parentElement.querySelector(".ctrl-expand").style.display = "block";
      } else {
        secBody.style.display = "none";
        // parentElement.querySelector(".ctrl-collapse").style.display = "block";
        // parentElement.querySelector(".ctrl-expand").style.display = "none";
      }
    },
    async saveDocument() {
      let app = this;
      this.deleteAttr(this.notepadTemp);
      if (!this.notepadTemp.docID) {
        if (app.isClonedDocument) {
          app.notepadTemp.status1 = "draft";
        }
        try {
          const { data } = await axios.post("/api/notepad", app.notepadTemp);
          // .then(function (response) {
          console.log(data);
          app.notepadTemp = data.item;
          alert("notepad Created Successfully!");
          window.location =
            "/newNotepad?docid=" +
            app.notepadTemp.docID +
            "&isNotepad=" +
            app.notepadTemp.isNotepad +
            "&industryItemType=" +
            app.notepadTemp.industryItemType +
            "&industryItemID=" +
            app.notepadTemp.industryItemID +'&docStatusType='+ app.notepadTemp.status1;
        } catch (error) {
          console.log(error);
        }
      } else {
        app.notepadTemp.updatedBy =
          app.notepadTemp.updatedBy || this.authCookie.userName;

        try {
          const { data } = await axios.put("/api/notepad", {
            notepad: app.notepadTemp,
            changes: { comment: this.newComments },
          });

          console.log(data);
          app.notepadTemp = data.item;
          // console.log("Document Saved Successfully!");
          alert("notepad Saved Successfully!");
          if(app.notepadTemp.status1 == 'approved') {
              app.notifyUser(app.notepadTemp);
            }
          window.location =
            "/newNotepad?docid=" +
            app.notepadTemp.docID +
            "&isNotepad=" +
            app.notepadTemp.isNotepad +
            "&industryItemType=" +
            app.notepadTemp.industryItemType +
            "&industryItemID=" +
            app.notepadTemp.industryItemID;
        } catch (error) {
          console.log(error);
          app.notepadTemp = [];
        }
      }
    },
    handleCloneConfirmClick() {
      this.showCloneModal = false;
      window.location.href =
        "/artifact/clone?docid=" +
        this.notepadTemp.docID +
        "&isClonedDocument=true&isNotepad=" +
        this.targetTemplateType +
        "&docStatusType=" +
        this.notepadTemp.status1 +
        "&industryItemType=" +
        this.notepadTemp.industryItemType +
        "&industryItemID=" +
        this.notepadTemp.industryItemID;
      console.log("cloneDoc");
    },
    getId() {
      return uuidv4();
    },
    addStepInner(stepArr, e) {
      this.addStep(stepArr, e);
    },
    addStep(sectionParent) {
      if (!sectionParent.steps) {
        this.$set(sectionParent, "steps", []);
      }
      var step = {};
      step.uid = uuidv4();
      step.description = "";
      step.dataTrandings = [];
      step.meta = [];
      this.$set(sectionParent.steps, sectionParent.steps.length, step);
    },
    addSection() {
      if (!this.notepadTemp.sections) {
        this.$set(this.notepadTemp, "sections", []);
      }
      var sec = {};
      sec.uid = uuidv4();
      sec.description = "";
      sec.steps = [];
      sec.meta = [];
      this.$set(
        this.notepadTemp.sections,
        this.notepadTemp.sections.length,
        sec
      );
    },
    addTitle(e) {
      var el = e.target.parentElement.querySelector(
        ".form-control-alternative"
      );
      this.$set(this.notepadTemp, "title", el.value);
      // this.$set(sectionParent, "steps", []);
      this.$set(this.notepadTemp, "status1", "draft");
      this.$set(this.notepadTemp, "approvedBy", this.authCookie.userName);
      this.$set(this.notepadTemp, "userName", this.authCookie.userName);
      this.$set(this.notepadTemp, "orgID", this.authCookie.orgs[0].orgID);
      this.$set(this.notepadTemp, "createdBy", this.authCookie.userName);
      this.$set(this.notepadTemp, "updatedBy", this.authCookie.userName);

      if (this.filespath != "") {
        this.$set(this.notepadTemp, "imagePath", this.filespath);
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    removeSpan(e) {
      //remove span
      var u = e.target.getElementsByTagName("u");
      var i;
      if (u != undefined && u.length > 0) {
        for (i = 0; i < u.length; i++) {
          if (u[i].getAttribute("id") != null) {
            var htmlspan1 = u[i].getElementsByTagName("span");
            if (htmlspan1.length > 0) {
              htmlspan1[0].remove();
            }
          }
        }
      }
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach((sec) => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach((step) => {
          this.deleteAttr(step);
        });
      }
    },
    setToDefault() {
      var i = 0;
      for (i = 0; i < this.finalarray.length; i++) {
        this.finalarray[i].defaultvalue = this.finalarray[i].oldvalue;
      }
      this.showLaunchBatchModal = false;
      //console.log(this.finalarray);
    },
    launchBatch() {
      let app = this;
      app.verify();
      app.showLaunchBatchModal = false;
      this.deleteAttr(this.notepadTemp);
      var batchDoc = JSON.parse(JSON.stringify(this.notepadTemp));
      //batchDoc.docID = null;
      batchDoc.isNotepad = this.notepadTemp.isNotepad;
      batchDoc.batchNumber = this.batchDocNumber;
      batchDoc.status1 = "batch";
      axios
        .post("/api/notepadBatch", batchDoc)
        .then(function (response) {
          console.log(response);
          app.showShareModal = false;
          alert("Batch started successfully!");
          if(batchDoc.status1 == 'batch') {
              app.notifyUserBatch(batchDoc);
            }
          app.setToDefault();
          window.location =
            "/Notepad?docStatusType="+ batchDoc.status1 +
            "&isNotepad=" +
            batchDoc.isNotepad;
        })
        .catch(function (error) {
          console.log(error);
          app.notepadTemp = [];
        });
    },
    onValueChange(section,meta,e){
      meta.value = e.target.value;
      console.log(meta.value);
      // this.$set(meta.value, e.target.value);
    },
    verify(){
      //console.log('verify');
      // console.log(this.finalarray);

      var i = 0;
      for(i=0;i<this.notepadTemp.sections.length;i++)
      {
        if(this.notepadTemp.sections[i].defaultvalue != this.notepadTemp.sections[i].oldvalue)
        {
          //console.log('modify');
          var first = this.notepadTemp.sections[i].sectionnum.toString().split('.')[0];
          var sec = this.notepadTemp.sections[i].sectionnum.toString().split('.')[1];
          var oldstr = '[' + this.notepadTemp.sections[i].variablename.toString() + ',' + this.notepadTemp.sections[i].oldvalue.toString() + ']';
          var newstr = this.notepadTemp.sections[i].variablename.toString() + ',' + this.notepadTemp.sections[i].defaultvalue.toString();
          //console.log('oldstr');
          //console.log(oldstr);
          //console.log('newstr');
          //console.log(newstr);
          console.log(this.notepadTemp.sections[first-1].meta[sec-1].description.toString().replace(oldstr,newstr));
          this.notepadTemp.sections[first-1].meta[sec-1].description = this.notepadTemp.sections[first-1].meta[sec-1].description.toString().replace(oldstr,newstr); 
        }

      }
      //this.documentTemp.sections[i].steps[j].description

        
    },
    shareDocument() {
      let app = this;
      this.deleteAttr(this.notepadTemp);
      if (app.isShare()) {
        this.notepadTemp.emailShare = this.emailAddress;
        axios
          .put("/api/notepad", app.notepadTemp)
          .then(function (response) {
            console.log(response);
            app.notepadTemp = response.data.item;
            alert("Document Shared Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.notepadTemp = [];
          });
      } else {
        var shareDoc = JSON.parse(JSON.stringify(this.notepadTemp));
        // shareDoc.docID = null;
        shareDoc.isNotepad = this.notepadTemp.isNotepad;
        shareDoc.parentDocID = this.notepadTemp.docID;
        shareDoc.status1 = "shared";
        shareDoc.emailShare = this.emailAddress;
        axios
          .post("/api/notepad", shareDoc)
          .then(function (response) {
            console.log(response);
            app.showShareModal = false;
            alert("Successfully shared document!");
            //app.notepadTemp = response.data.item;
            if(shareDoc.status1 == 'shared') {
              app.notifyUser(shareDoc);
            }
            window.location =
            "/Notepad?docStatusType="+ shareDoc.status1 +
            "&isNotepad=" +
            shareDoc.isNotepad;
          })
          .catch(function (error) {
            console.log(error);
            app.notepadTemp = [];
          });
      }
    },
    shareLibraryDocument() {
      let app = this;
      this.deleteAttr(this.notepadTemp);
      var shareDoc = JSON.parse(JSON.stringify(this.notepadTemp));
      shareDoc.docID = null;
      shareDoc.parentDocID = this.notepadTemp.docID;
      shareDoc.status1 = "template";
      axios
        .post("/api/notepad", shareDoc)
        .then(function (response) {
          console.log(response);
          alert("Document successfully shared to DigitalGxP Public Library!");
        })
        .catch(function (error) {
          console.log(error);
          app.notepadTemp = [];
        })
        .then(function () {
          app.showShareModal = false;
        });
    },
    async approveDocument() {
      this.notepadTemp.approvedBy = this.authCookie.email;
      this.notepadTemp.status1 = "approved";
      await this.saveDocument();
      console.log("Document Approved Successfully!");

      this.showApproveModal = false;
      window.location =
        "/newNotepad?docid=" +
        this.notepadTemp.docID +
        "&isNotepad=" +
        this.notepadTemp.isNotepad +
        "&industryItemType=" +
        this.notepadTemp.industryItemType +
        "&industryItemID=" +
        this.notepadTemp.industryItemID +'&docStatusType='+ this.notepadTemp.status1;
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    openShare(shareName, $event) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(shareName).style.display = "block";
      $event.target.className += " active";
    },
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;

.layoutJSON {

    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}



#editorControlListLeftNotepadIcons {
  /* height: 550px; */
  overflow: scroll;
  width: 65px;
  height: calc(100vh - 200px);
}
.data-table {
  margin-bottom: 18px;
}
.comment-dialog {
  max-width: 65%;
}
.share-modal {
  max-width: 50%;
}
.notepad-step:hover {
  background-color: #f8f5fa;
}
/* .notepad-step:hover ~ .step-action-btn-group,
.step-action-btn-group:hover
{
    display: block;
} */
.notepad-step {
  background-color: transparent;
  padding: 12px;
  min-height: 300px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.comment-text {
  color: darkgray !important;
}
.comment-section {
  background-color: rgba(0, 0, 0, 0.02);
  padding-left: 10px;
}
.comment-header {
  margin-right: 15px;
  font-size: 13px;
  font-stretch: 100%;
  font-style: italic;
  font-weight: 500;
}
.comment-user {
  color: #385898;
}
div[data-oembed-url] > div > iframe {
  height: auto !important;
  width: auto !important;
}
div[data-oembed-url] > div {
  padding-bottom: 150px !important;
}
.editControlNotepad {
  width: 50px;
  // top: 100px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  /* height:290px; */
  /* height: calc(100% - 320px); */
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNewNotepad {
  width: calc(100% - 320px);
  margin-left: 70px;
  margin-right: 120px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
// .section-toggle-comment,
// {
//   // background-color: #0001;
//   color: #000;
//   // text-indent: 10px;
//   cursor: pointer;
//   width: 100%;
//   border: none;
//   text-align: left;
//   outline: none;
//   font-size: 15px;
// }
// .section-toggle-comment.sec-toggle.accordion:after,
// .sec-toggle.accordion:after {
//   content: "\002B";
//   color: white;
//   font-weight: bold;
//   float: right;
//   margin-left: 5px;
// }
.card.shadow2 {
  //   border: 1px solid #dadce0 !important;
  padding: 6px;
  width: 100%;
  margin: auto;
}
.notepad-header {
  font-weight: 700;
  text-align: center;
  // top: -100px;
  // position: relative;
  // margin-left: 250px;
  // background-color: #ffff;
  // padding-left: 26px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // border-radius: 0.375rem;
  width: calc(100% - 270px);
}
.btn-step-action {
  background-color: inherit;
  border-color: transparent;
  color: inherit;
  z-index: 1001;
  top: -45px;
}
.step-action-btn-group {
  display: block;
}
.commentCount {
  position: absolute;
  top: 2px;
  left: 11px;
  color: white;
  font-size: 12px;
}
.btn-comment {
  width: 10px;
  padding-right: 22px;
  font-size: 0.85rem;
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
em {
  display: none;
}
.tooltip1 {
}
.tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -270px;
  margin-top: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltiptextimg {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -285px;
  margin-top: -107px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextimg::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip1:hover .tooltiptextimg {
  visibility: visible;
  opacity: 1;
}
.input-type-numeric {
  visibility: visible;
}
.input-type-text {
  visibility: visible;
}
.radio-buttons {
  margin: 10px 10px;
}
.modal-dialog.modal-table {
  max-width: 540px;
}
// .modal-dialog {
//   max-width: 560px;
// }
.custom {
  padding: 10px;
  border: 1px solid #aaaaaa;
}
button.delete {
  margin: 5px;
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  display: flex;
  align-items: center;
  overflow: auto;
}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.form {
  margin: 12px;
}
.textarea {
  border: 1px solid #333;
  color: #000;
}
.notepad-step {
  // border: 1px dashed #333;
  color: #000;
}
.textarea:focus {
  border-color: #000;
  color: #000;
}
.btn {
  border-radius: 20px;
  width: 130px;
}
.btn-default.disabled,
.btn-default:disabled {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  cursor: not-allowed;
}
#drag-area {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 270px;
}
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
