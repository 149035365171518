<template>
    <div>
         <div style="clear: both;height: 130px;">
             <div class="sample-header">Add SampleManagement</div>
        </div>
        <div class="container-fluid mt--9">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-body">
                          <div>
                            ID: <input type="text" id="txtTitle" style="margin-left: 64px; margin-bottom: 10px;" class="form-control-alternative2"/>
                          </div>

                          <div>
                            Description: <input type="text" id="txtDesc" style="margin-bottom: 10px;" class="form-control-alternative1"/>
                          </div>
                          
                          <div>
                            Image:<input type="file" id="file" style="margin-left: 36px; margin-bottom: 10px;" ref="file" name="file-to-upload" v-on:change="handleFileUploads()">
                              <base-button
                                  size="sm"
                                  type="default"
                                  style="margin-bottom: 10px;"
                                  class="float-right1"
                                  @click="addImage($event)"
                                >Add Image</base-button>
                                <p v-if="filespath"><img style="width:100px; height:100px; margin-bottom: 10px;" :src="filespath" alt=""></p>
                          </div>
                          
                          <div>
                            <base-button size="sm" type="default" class="" @click="addTitle($event)">Save</base-button>
                            <base-button size="sm" type="default" class="" @click="backToList()">Cancel</base-button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    
import axios from "../../../utils/axiosinstance";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {  },
  props: ["sampleID"],
  watch:  {
        //   equType: function(newVal, oldVal) { // watch it
        //   //this.loadDocument();
        //   console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        //   },
          sampleID: function(newVal, oldVal){ // watch it
          //this.loadDocument();
          console.log('sampleID Prop changed: ', newVal, ' | was: ', oldVal)
          }
  },
  data() {
    return {
      showModal: false,
      action: "",
      files: '', 
      filespath: '',
      sampleManagementTemp: {}
    };
  },
  mounted() {
      if (this.sampleID) {
          this.action="Update";
      axios
        .get(
          "/api/sampleManagement/organizations/" +
            this.authCookie.orgs[0].orgID +
            this.sampleID
        )
        .then(response => {
          // console.log(response);
          this.sampleManagementTemp = response.data.item;
          //this.sampleManagementTemp.title = this.documentTemp.title.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');
          

            this.$set(this.sampleManagementTemp, "sampleID", response.data.item.sampleID);
            this.$set(this.sampleManagementTemp, "title", response.data.item.title);
            this.$set(this.sampleManagementTemp, "description", response.data.item.description);
            this.$set(this.sampleManagementTemp, "type", response.data.item.type);
            this.$set(this.sampleManagementTemp, "orgID", response.data.item.orgID);
            if(response.data.item.imagePath != undefined)
            {
              this.$set(this.sampleManagementTemp, "imagePath", response.data.item.imagePath); 
              this.filespath = response.data.item.imagePath;
            }

            //alert("Title " + this.sampleManagementTemp.title);

            document.getElementById("txtTitle").value = this.sampleManagementTemp.title;
            document.getElementById("txtDesc").value = this.sampleManagementTemp.description;
           

        })
        .catch(reason => {
          console.log(reason);
          this.sampleManagementTemp = [];
        });
    }
    else
    {
        this.action="Add";
    }
    
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj){
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token")
              .substring(2)
              .split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }

  },
  beforeCreate() {
    
  },
  methods: {
    notifyUser(sampleManagementTempNotification){
      console.log(sampleManagementTempNotification);
      var notification = {
          objectId: sampleManagementTempNotification.sampleID,
          type: "sampleManagement",
          status: null,
          message:  " has created sampleManagement document " + sampleManagementTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    handleFileUploads(){
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    addImage(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadWidgetFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            // console.log(response);
            //app.documentTemp = response.data.item;
            app.filespath = "";
            var path = response.data.fileUrl;
            //console.log(response.data.fileUrl);
            //console.log(response.data.fileName);
            //console.log(response.data.uploaded);

            app.filespath = path.toString();
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });
    },
      getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    addTitle(e) {
        //var el = e.target.parentElement.querySelector(".form-control-alternative2");
            //var el1 = e.target.parentElement.querySelector(".form-control-alternative1");
            // var editor = window.CKEDITOR.instances[el.id];
            console.log(e);
            var el = document.getElementById("txtTitle");
            var el1 = document.getElementById("txtDesc");

            this.$set(this.sampleManagementTemp, "title", el.value);
            this.$set(this.sampleManagementTemp, "description", el1.value);
            this.$set(this.sampleManagementTemp, "type", "sampleManagement");
            this.$set(this.sampleManagementTemp, "orgID", this.authCookie.orgs[0].orgID);
            this.$set(this.sampleManagementTemp, "createdBy", this.authCookie.userName);
            this.$set(this.sampleManagementTemp, "updatedBy", this.authCookie.userName);

            if(this.filespath != '')
            {
              this.$set(this.sampleManagementTemp, "imagePath", this.filespath);
            }
        if (this.sampleID) {
            this.$set(this.sampleManagementTemp, "sampleID", this.sampleID);
            this.updateSampleManagement();
            //alert("Update");
        }
        else
        {
            
            this.saveSampleManagement();
            // editor.setData("");

        }
     
    },
    backToList(){
      // let app = this;
      //     app.$router.push({
      //     name: "sampleManagement",  
      //   });
      window.location = "/sampleManagement?industryItemType=sampleManagement";
    },
    updateSampleManagement() {
      let app = this;
        axios
          .put("/api/sampleManagement", app.sampleManagementTemp)
          .then(function(response) {
            // console.log(response);
            app.sampleManagementTemp = response.data.item;
            //if(app.equType == "reagent")
            alert("SampleManagement Updated Successfully!");
            app.backToList();
          })
          .catch(function(error) {
            console.log(error);
            app.sampleManagementTemp = [];
          });
    },
    saveSampleManagement() {
      let app = this;
        axios
          .post("/api/sampleManagement", app.sampleManagementTemp)
          .then(function(response) {
            console.log(response);
            app.sampleManagementTemp = response.data.item;
            alert("SampleManagement Created Successfully!");
            app.notifyUser(app.sampleManagementTemp);
            app.backToList();
          })
          .catch(function(error) {
            console.log(error);
            app.sampleManagementTemp = [];
          });
    },
    getId() {
      return uuidv4();
    } 
  }
};

</script>
<style>
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-control-alternative2{
    width: 250px;
    height: 30px;
    margin-right: 50px;
}
.form-control-alternative1{
    width: 250px;
    height: 30px;
    margin-right: 50px;
}
.sample-header {
  font-weight: 700;
  text-align: center;
  background-color: #ffff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
}

</style>

