<template>
  <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 document-right">
    <div class="row document-right-row">
      <div class="col-sm">
        <router-link class="btn btn-info document-right-btn" :to="'/new'">
        ASSIGN
          <!-- <a href="#!" class="btn btn-info">NEW</a> -->
        </router-link>
      </div> 
    </div> 
    <div class="row document-right-row">
      <div class="col-sm">
        <a href="#!" class="btn btn-info document-right-btn">COMMENT</a>
      </div>
    </div> 
    <div class="row document-right-row">
      <div class="col-sm">
        <a href="#!" class="btn btn-info document-right-btn">DEVIATION</a>
      </div>
    </div> 
    <div class="row document-right-row">
      <div class="col-sm">
        <a href="#!" class="btn btn-info document-right-btn">ALARM</a>
      </div>
    </div> 
    <div class="row document-right-row">
      <div class="col-sm">
        <a href="#!" class="btn btn-info document-right-btn">REVIEW</a>
      </div>
    </div> 
    <div class="row document-right-row">
      <div class="col-sm">
        <a href="#!" class="btn btn-info document-right-btn">EDIT</a>
      </div>
    </div> 
    <div class="row document-right-row">
      <div class="col-sm">
        <a href="#!" class="btn btn-info document-right-btn">CHECK</a>
      </div>
    </div> 
    <div class="row document-right-row">
      <div class="col-sm">
        <a href="#!" class="btn btn-info document-right-btn">RELEASE</a>
      </div>
    </div> 
  </base-header>
</template>
<script>

export default {
  name: "document-action",
  data() {
    return {};
  },
  methods: {}
};
</script>
<style>
.document-right
{
    position: fixed;
    right: 0px;
    bottom: -40px;
}
.document-right-row
{
 margin-bottom: 10px;
}
.document-right-btn
{
    background-color: #4f81bd;
    border-color: #4f81bd;
    width: 120px;
}
</style>