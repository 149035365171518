<template>
    <div>
         <div style="clear: both;height: 130px;">
             <div class="capa-header">Add Capa</div>
        </div>
        <div class="container-fluid mt--9">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="card-body">
                          <div>
                            ID: <input type="text" id="txtTitle" style="margin-left: 64px; margin-bottom: 10px;" class="form-control-alternative2"/>
                          </div>

                          <div>
                            Description: <input type="text" id="txtDesc" style="margin-bottom: 10px;" class="form-control-alternative1"/>
                          </div>
                          
                          <div>
                            Image:<input type="file" id="file" style="margin-left: 36px; margin-bottom: 10px;" ref="file" name="file-to-upload" v-on:change="handleFileUploads()">
                              <base-button
                                  size="sm"
                                  type="default"
                                  style="margin-bottom: 10px;"
                                  class="float-right1"
                                  @click="addImage($event)"
                                >Add Image</base-button>
                                <p v-if="filespath"><img style="width:100px; height:100px; margin-bottom: 10px;" :src="filespath" alt=""></p>
                          </div>
                          
                          <div>
                            <base-button size="sm" type="default" class="" @click="addTitle($event)">Save</base-button>
                            <base-button size="sm" type="default" class="" @click="backToList()">Cancel</base-button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    
import axios from "../../../utils/axiosinstance";
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      capaID: "",
      industryItemType: "",
      industryItemID: "",
      showModal: false,
      action: "",
      files: '', 
      filespath: '',
      capaTemp: {}
    };
  },
  beforeMount() {
    this.capaID=this.$route.query.capaid;
    this.industryItemType=this.$route.query.industryItemType;
    this.industryItemID=this.$route.query.industryItemID;
  },
  mounted() {
      if (this.capaID) {
          this.action="Update";
      axios
        .get(
          "/api/capa/organizations/" +
            this.authCookie.orgs[0].orgID +
            this.capaID
        )
        .then(response => {
          this.capaTemp = response.data.item;
            this.$set(this.capaTemp, "capaID", response.data.item.capaID);
            this.$set(this.capaTemp, "title", response.data.item.title);
            this.$set(this.capaTemp, "description", response.data.item.description);
            this.$set(this.capaTemp, "type", response.data.item.type);
            this.$set(this.capaTemp, "orgID", response.data.item.orgID);
            if(response.data.item.imagePath != undefined)
            {
              this.$set(this.capaTemp, "imagePath", response.data.item.imagePath); 
              this.filespath = response.data.item.imagePath;
            }
            document.getElementById("txtTitle").value = this.capaTemp.title;
            document.getElementById("txtDesc").value = this.capaTemp.description;
        })
        .catch(reason => {
          console.log(reason);
          this.capaTemp = [];
        });
    }
    else
    {
        this.action="Add";
    }
    
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj){
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token")
              .substring(2)
              .split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }

  },
  beforeCreate() {
    
  },
  methods: {
    notifyUser(capaTempNotification){
      console.log(capaTempNotification);
      var notification = {
          objectId: capaTempNotification.capaID,
          type: "capa",
          message:  " has created capa document " + capaTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    handleFileUploads(){
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    addImage(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadWidgetFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            // console.log(response);
            //app.documentTemp = response.data.item;
            app.filespath = "";
            var path = response.data.fileUrl;
            //console.log(response.data.fileUrl);
            //console.log(response.data.fileName);
            //console.log(response.data.uploaded);

            app.filespath = path.toString();
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
            //app.documentTemp = [];
          });
    },
      getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    addTitle(e) {
            console.log(e);
            var el = document.getElementById("txtTitle");
            var el1 = document.getElementById("txtDesc");
            this.$set(this.capaTemp, "title", el.value);
            this.$set(this.capaTemp, "description", el1.value);
            this.$set(this.capaTemp, "type", "capa");
            this.$set(this.capaTemp, "orgID", this.authCookie.orgs[0].orgID);
            this.$set(this.capaTemp, "createdBy", this.authCookie.userName);
            this.$set(this.capaTemp, "updatedBy", this.authCookie.userName);

            if(this.filespath != '')
            {
              this.$set(this.capaTemp, "imagePath", this.filespath);
            }
        if (this.capaID) {
            this.$set(this.capaTemp, "capaID", this.capaID);
            this.updateCapa();
        }
        else
        {
        this.saveCapa();
        }
    },
    backToList(){
      window.location = "/capa";
    },
    updateCapa() {
      let app = this;
        axios
          .put("/api/capa", app.capaTemp)
          .then(function(response) {
            app.capaTemp = response.data.item;
            alert("Capa Updated Successfully!");
            app.backToList();
          })
          .catch(function(error) {
            console.log(error);
            app.capaTemp = [];
          });
    },
    saveCapa() {
      let app = this;
        axios
          .post("/api/capa", app.capaTemp)
          .then(function(response) {
            app.capaTemp = response.data.item;
            alert("Capa Created Successfully!");
            app.notifyUser(app.capaTemp);
            app.backToList();
          })
          .catch(function(error) {
            console.log(error);
            app.capaTemp = [];
          });
    },
    getId() {
      return uuidv4();
    } 
  }
};

</script>
<style>
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-control-alternative2{
    width: 250px;
    height: 30px;
    margin-right: 50px;
}
.form-control-alternative1{
    width: 250px;
    height: 30px;
    margin-right: 50px;
}
.capa-header {
  font-weight: 700;
  text-align: center;
  background-color: #ffff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
}

</style>

