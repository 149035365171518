<template>
  <div>
    <div style="clear: both;height: 130px;">
      <div class="video-header"> Videos</div>
    </div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow7">
            <div class="card-body">
              <div>
                <span>Add videos to the paricular shelf using upload file , tracking and traceability by typing "#"</span>
              </div>
              <table id="images">
                <tr>
                  <th v-for="(heading, heading_index) in headingData" :key="heading_index.uid"
                  :style="getColor()">Shelf {{heading_index+1}}
                      <div>
                      <base-button
                      size="sm"
                      type="default"
                      class="btn btn-info custom-pad-bot"
                      @click="addNewFile(heading_index)"
                      >Upload File</base-button>
                    </div>
                  </th>
                <!-- <th class="file-shelf" v-for="(column, column_index) in tableData" :key="column_index.uid">
                  {{column.shelfHeading}} -->
                </tr>
                <tr>
                  <td class="file-shelf" v-for="(heading, heading_index) in headingData" :key="heading_index.uid">
                    <div v-if="tableMap.has(heading_index)">
                    <div v-for="(shelfFile, shelfFile_index) in tableMap.get(heading_index).shelfFiles" :key="shelfFile_index.uid">
                    <div class="avatar-group">
                    <span class="status" v-if="shelfFile.imagePath">
                        <video width="100" height="50" controls>
                            <source :src="shelfFile.imagePath" type="video/mp4" />
                        </video>
                    </span>
                    </div> 
                    <div @click="handleRowClick(shelfFile)"><span class="image-data" style="cursor:pointer; text-decoration:underline;">Video ID: </span><span class="status">{{shelfFile.title}}</span></div>
                    <div><span class="image-data">Description: </span><span class="status">{{shelfFile.description}}</span></div>
                    </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showTableModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    @click="showTableModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    Shelf Heading: <input  type="text" id="txtShelf">
                  </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="addTitle($event)"
                  >
                    save
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTableModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      </div>
      <div v-if="showuploadfileModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-dialog-scrollable modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    @click="showuploadfileModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  
                </div>
                <div class="modal-body">
                  <div>
                    ID: <input type="text" id="txtTitle" style="margin-left: 64px; margin-bottom: 10px;" class="form-control-alternative2"/>
                  </div>
                  <div>
                    Description: <input type="text" id="txtDesc" style="margin-bottom: 10px;" class="form-control-alternative1"/>
                  </div>
                  <div>
                    Upload Video:<input type="file" id="file" style="margin-left: 36px; margin-bottom: 10px;" ref="file" name="file-to-upload" v-on:change="handleFileUploads()">
                    <base-button
                        size="sm"
                        type="default"
                        style="margin-bottom: 10px;"
                        class="float-right1"
                        @click="addVideo($event)"
                    >Add Video</base-button>
                    <p v-if="filespath">
                        <video width="320" height="240" controls>
                            <source :src="filespath" type="video/mp4"/>
                        </video>
                    </p>
                    </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="adduploadVideo($event)"
                  >
                    save
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showuploadfileModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";
import axios from "../../utils/axiosinstance";


Vue.use(VueClipboard);
export default {
    props: ["type"],
    directives: {
      "b-tooltip": BTooltipDirective
    },
  data() {
    return {
      tableData: [],
      showTableModal: false,
      showuploadfileModal: false,
      videoTemp: {},
      action: "",
      files: '', 
      filespath: '',
      tableMap: new Map(),
      headingData: [1,2,3,4,5,6,7], 
    };
  },
  mounted() {
    this.loadDocument();
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj){
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token")
              .substring(2)
              .split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }

  },
  methods: {
    notifyUser(videoTempNotification){
      console.log(videoTempNotification);
      for(var i = 0; i < videoTempNotification.shelfFiles.length; i++){
      var notification = {
          objectId: videoTempNotification.shelfID,
          type: "videos",
          message:  " has " + " uploaded the video " + " with the video ID " + videoTempNotification.shelfFiles[i].title,
      };
      }
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    getColor() {
      const colors = ["#ff000066", "#ff7f007a", "#ffff004f", "#00ff0059", "#0000ff54", "#4b008263", "#9400d366"];
      const color = colors[Math.floor(Math.random() * colors.length)];
      return {
        backgroundColor: color,
      };
    },
    handleFileUploads(){
      this.files = this.$refs.file.files[0];
    },
    handleRowClick(item) {
      var url = item.imagePath;
      fetch(url, {type: 'video/mp4'})
      .then((response) => response.blob())
      .then((blob) => {
        const _url = window.URL.createObjectURL(blob);
        window.open(_url, '_blank');
      }).catch((err) => {
        console.log(err);
      });
    },
    addVideo(e){
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append('file', this.files);
      axios
          .post("/api/uploadWidgetFiles",formData,
              {
                headers: {
                        'Content-Type': 'multipart/form-data'
                }
              }
          )
          .then(function(response) {
            app.filespath = "";
            var path = response.data.fileUrl;
            app.filespath = path.toString();
            alert("File Uploaded Successfully");
          })
          .catch(function(error) {
            console.log(error);
          });
    },
    addNewFile(shelfIndex) {
      this.videoTemp = {};
      this.$set(this.videoTemp, "shelfIndex", shelfIndex);
      this.getShelf();
      this.showuploadfileModal = true;
    },
    getShelf(){
      if(!(this.tableMap.has(this.videoTemp.shelfIndex))) {
        return;
      }
      var shelfURL= "/api/videos/organizations/" + this.authCookie.orgs[0].orgID +  "/" + this.videoTemp.shelfIndex;
      axios
      .get(shelfURL)
        .then(response => {
          console.log(response);
          this.videoTemp = response.data.item;
        })
        .catch(reason => {
          console.log(reason);
          this.videoTemp = {};
        });
    },
    addNewColumn(){
      this.showTableModal = true;
    },
    addTitle(e){
      console.log(e);
      var el = document.getElementById("txtShelf");
      this.$set(this.videoTemp, "shelfHeading", el.value);
      this.$set(this.videoTemp, "orgID", this.authCookie.orgs[0].orgID);
      this.$set(this.videoTemp, "createdBy", this.authCookie.userName);
      this.$set(this.videoTemp, "updatedBy", this.authCookie.userName);
      this.saveHeader();
    },
    adduploadVideo(e){
      console.log(e);
      var el1 = document.getElementById("txtTitle");
      var el2 = document.getElementById("txtDesc");
      this.$set(this.videoTemp, "orgID", this.authCookie.orgs[0].orgID);
      if(!this.videoTemp.shelfID) {
        this.$set(this.videoTemp, "createdBy", this.authCookie.userName);
      }      
      this.$set(this.videoTemp, "updatedBy", this.authCookie.userName);
      if(!this.videoTemp.shelfFiles) {
      this.$set(this.videoTemp, "shelfFiles", []);
      }
      var file = {};
      file.shelfVideoID = uuidv4();
      file.title = el1.value;
      file.type = "videos";
      file.description = el2.value;
      file.imagePath = this.filespath;
      this.$set(this.videoTemp.shelfFiles, this.videoTemp.shelfFiles.length, file);
      if (this.videoTemp.shelfID) {
          this.updateHeader();
      } else {
        this.saveHeader();
      }
    },
    saveHeader(){
      let app = this;
      axios
          .post("/api/videos", app.videoTemp)
          .then(function(response) {
            app.videoTemp = response.data.item;
            app.showuploadfileModal = false;
            app.loadDocument();
            app.notifyUser(app.videoTemp);
            window.location.href="/videos";
          })
          .catch(function(error) {
            console.log(error);
            app.videoTemp = {};
          });
    },
    updateHeader() {
      let app = this;
        axios
          .put("/api/videos", app.videoTemp)
          .then(function(response) {
            app.videoTemp = response.data.item;
            app.showuploadfileModal = false;
            app.notifyUser(app.videoTemp);
            window.location.href="/videos";
          })
          .catch(function(error) {
            console.log(error);
            app.videoTemp = {};
          });
    },
    loadDocument()
    {
      let authCookieObj = this.getCookie("auth-token");
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      if(authObj.departments && authObj.departments[0]){
        var imageListURL;
      imageListURL = "/api/videos/organizations/" + authObj.orgs[0].orgID;
      axios
        .get(imageListURL)
        .then(response => {
          console.log(response);
          this.tableData = response.data.items;
          this.tableMap = new Map();
          this.tableData.forEach(data => {
          this.tableMap.set(data['shelfIndex'], data);
          });
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
      }
      else{
        imageListURL = "/api/videos/organizations/" + authObj.orgs[0].orgID;
      axios
        .get(imageListURL)
        .then(response => {
          console.log(response);
          this.tableData = response.data.items;
          this.tableMap = new Map();
          this.tableData.forEach(data => {
          this.tableMap.set(data['shelfIndex'], data);
          });
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
      }
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    }
  }
};
</script>
<style>
.status{
  font-size: 12px;
}
#images {
  border-collapse: separate;
  width: 100%;
}

#images td, #images th {
  border: 1px solid #ddd;
  padding: 8px;
}

#images tr:nth-child(even){background-color: #f2f2f2;}

#images tr {background-color: #ddd;}

#images th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}
button.btn.btn.btn-info.custom-pad-bot.btn-default.btn-sm{
  color: #fff !important;
  background-color: #172b4d !important;
  border-color: #172b4d !important;
}
button.btn.btn.btn-info.custom-pad-bot.btn-default.btn-sm:hover{
  color: #fff !important;
  background-color: #172b4d !important;
  border-color: #172b4d !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.image-data{
  font-weight: bold;
  font-size: 11px;
}
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-equ{
  background-color: lightgreen;
  font-size: 14px;
}
.table-rea{
  background-color: lightpink;
  font-size: 14px;
}
.video-header {
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
  text-transform: uppercase;
  color: rgb(23, 43, 77);
  font-weight: 800;
  text-shadow: 2px 2px #172b4d66;
}
.card.shadow7 {
  padding: 6px;
  margin: auto;
}
@media only screen and (max-width: 1024px) {
  #images{
    display: block;
    overflow-x: auto;
  }
}
</style>
