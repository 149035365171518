<template>
  <div>
    <div style="clear: both; height: 270px">
      <notepad-header
        :docStatusType= "docStatusType"
        :isNotepad= "isNotepad"
        :industryItemType= "industryItemType"
        :industryItemID= "industryItemID"
      ></notepad-header>
      <div class="container-fluid">
      <div class="row">
      <div class="notepadbatch-heading" v-if="notepadTemp.title">
        {{ stripHTML(notepadTemp.title) + " - " + notepadTemp.batchNumber }}
      </div>
    </div>
    <button class="btn-default1 btn-sm btn-step-action1 view-batch-data"
        v-if="notepadTemp && notepadTemp.sections && showViewBatchDataButton()"
        @click="viewList()"
        >View Batch Data</button>
        <div class="col editControlParent"></div>
        <div class="col editorNotepadBatch">
          <div class="card shadow5">
            <div class="contacts"></div>
            <div class="card-body">
              <div
                v-if="notepadTemp.title"
                v-html="notepadTemp.title"
                class="form-control-alternative"
                v-on:click="handleOverlaysClick"
              ></div>
              <div v-if="!notepadTemp.title">
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                  v-on:click="handleOverlaysEdit"
                ></div>
              </div>
              <div class="sec-body" style="display: block">
                <div
                  v-for="(section, sec_index) in notepadTemp.sections"
                  :key="section.uid"
                >
                  <div
                    :id="section.uid"
                    :data-secid="section.uid"
                    class="step-body"
                  >

                    <div class="section">
                      <p
                        v-if="section.notApplicable"
                        style="color: red;"
                      >
                        Not Applicable
                      </p>
                      <label class="form-control-label" style="margin: 7px">{{
                        "Page" + (sec_index + 1) + " : "
                      }}</label>
                      <div style="float: right;color: rgb(45, 206, 137);">
                        <label
                            for="checkbox"
                            v-if="section.notApplicable"
                            style="font-size: 14px; color: red"
                            >NA</label
                          >
                          <label
                            for="checkbox"
                            v-else
                            style="font-size: 14px; color: rgb(45, 206, 137)"
                            >NA</label
                          >
                          <input
                            type="checkbox"
                            id="checkbox"
                            style="margin-left: 5px"
                            v-model="section.notApplicable"
                            :disabled="section.notApplicable"
                            @click="notApplicableSection($event, section)"
                          />
                          <span
                            class="user-audit"
                            v-if="section.notApplicable"
                            >{{
                              section.notApplicableBy +
                              " - on " +
                              formatDate(section.notApplicableTime)
                            }}</span
                          ><br />
                        <label for="checkbox" style="font-size: 14px;">Executed</label>
                        <input type="checkbox" id="checkbox" style="margin-left: 5px;" v-model="section.executed" :disabled="section.executed" @click="executeStep($event, section, sec_index)"/> 
                        <span class="user-audit" v-if="section.executed">{{section.executedBy + " - on " + formatDate(section.executedTime) }}</span><br>
                        <label for="checkbox" style="margin-left: 5px; font-size: 14px;" >Verified</label>
                        <input type="checkbox" id="checkbox" style="margin-left: 5px;" v-model="section.approved" :disabled="section.approved" @click="approveStep($event, section, sec_index)"/>
                        <span class="user-audit" v-if="section.approved">{{section.approvedBy + " - on " + formatDate(section.approvedTime) }}</span><br>
                      </div>
                    </div>
                    <div
                        :id="section.uid"
                        class="form-control-alternative textarea notepad-step" style="margin-top: 50px;"
                        @blur="onFocushandle(section, $event)"
                      >
                        <div
                          v-for="(meta, meta_index) in section.meta"
                          :key="meta_index.uid"
                          class="data-table"
                        >
                        <div
                          class="step-action-btn-group"
                          style="float: right; padding-left: 2px;  margin-left: 5px; color: #2dce89" >
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.datatrendfound"
                            @click="DataTrend($event,meta,sec_index,meta_index)"
                          >Data Trend</button>
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="section.crossreffound"
                            @click="crossref($event,section)"
                          >Cross Reference</button><br v-if="section.crossreffound && !section.videofound && !section.qrimagefound && !section.mediaimagefound && !section.formulafound && !section.timer && !section.inputfound">  
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.type =='mediaVideo' && !section.executed"
                            @click="UploadVideo($event,section)"
                          >Upload Video</button><br v-if="meta.videofound && !meta.qrimagefound && !meta.mediaimagefound && !meta.formulafound && !meta.timer && !meta.inputfound"> 
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.type =='mediaQRcode' && !section.executed"
                            @click="UploadQR($event,section)"
                          >Upload QR Code</button><br v-if="meta.qrimagefound && !meta.mediaimagefound && !meta.formulafound && !meta.timer && !meta.inputfound"> 
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.type =='mediaImage' && !section.executed"
                            @click="UploadImg($event,section)"
                          >Upload Image</button><br v-if="meta.mediaimagefound && !meta.formulafound && !meta.timer && !meta.inputfound"> 
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.type =='formula' && !section.executed"
                            @click="formulaCalculate($event, section, meta)"
                          >Calculate</button><br v-if="meta.formulafound && meta.type =='formula'"> 
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.type =='timer' && !section.executed"
                            @click="startTimer($event,section,meta)"
                          >Start Timer</button> 
                          <span v-if="meta.type =='timer' && section.meta.spanVisible && !section.executed">{{' '+ section.meta.timermm + ' : ' + section.meta.timerss + ' '}}</span>
                          <!-- <span>{{'====== meta executed ========= : '+ meta.executed}}</span> -->
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.type == 'timer' && !section.executed"
                            @click="stopTimer($event,section,meta)"
                          >Stop Timer</button><br v-if="meta.timer && !meta.inputfound">
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.inputfound && !section.executed"
                            @click="setValue($event,section,meta)"
                          >Enter Data</button><br v-if="meta.inputfound">
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.mcq && !section.executed"
                            @click="selectOption(meta)"
                          >Select Option</button><br v-if="meta.mcq">
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.skillfound"
                            @click="skillTrend($event,meta,sec_index,meta_index)"
                          >Skill Trend</button>
                          <button
                            class="btn-default1 btn-sm btn-step-action1"
                            v-if="meta.type == 'table' && !section.executed"
                            @click="addRows($event,section,meta)"
                          >Add Rows</button>
                        </div>
                          <ag-grid-vue
                            v-if="meta && meta.type == 'table'"
                            style="height: 200px"
                            class="ag-theme-balham"
                            id="myForm"
                            :columnDefs="meta.columnDefs"
                            :rowData="meta.rowData"
                            rowSelection="multiple"
                            editable=true
                            rowHeight="50"
                            pagination="true"
                            paginationPageSize="10"
                          >
                          </ag-grid-vue>
                          <grid-layout
                          v-if="meta && meta.type == 'grid'"
                          :layout.sync="layout"
                          :col-num="colNum"
                          :row-height="30"
                          :is-draggable="draggable"
                          :is-resizable="resizable"
                          :vertical-compact="true"
                          :use-css-transforms="true"
                        >
                        <grid-item v-for="item in meta.grids"
                            :static="item.static"
                            :x="item.x"
                            :y="item.y"
                            :w="item.w"
                            :h="item.h"
                            :i="item.i"
                            :key="item.i"
                        >
                          <textarea
                            id="message"
                            style="width: 90%"
                            name="message"
                            v-model="item.textarea"
                            placeholder="Enter text here..."
                          ></textarea>
                        </grid-item>
                          </grid-layout>
                          <div
                            @dblclick="dblClickTextarea($event)"
                          >
                            <textarea
                              id="message"
                              style="width: 100%;"
                              name="message"
                              v-if="meta && meta.type == 'text'"
                              v-model="meta.textarea"
                              placeholder="Enter text here..."
                            ></textarea>
                          </div>
                          <div
                            class="image"
                            v-if="meta && meta.type == 'image'"
                          >
                            <img
                              :src="meta.imagePath"
                              style="width: 200px"
                              @dblclick="dblClickImage($event)"
                              class="img-fluid"
                            />
                          </div>
                          <div
                            class="video"
                            v-if="meta && meta.type == 'video'"
                          >
                            <video
                              width="320"
                              height="240"
                              controls
                              @dblclick="dblClickVideo($event)"
                            >
                              <source :src="meta.videoPath" :type="videotype" />
                            </video>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'timer'">
                            <img style="width: 50px;" :src="meta.description" alt="Timer" class="u-photo"/>
                            <p>{{ meta.timerValue }}</p>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'formula'">
                            <span><a href="javascript:void(0)">Formula {{ meta.formuladatalabel }} = {{ meta.formula }}</a></span><br>
                            <span v-if="meta.ResultText"><a href="javascript:void(0)">{{ meta.ResultText }}</a></span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'mediaImage'">
                            <img style="width: 50px;" :src="meta.description" alt="mediaImage_" class="u-photo"/>
                            <span id="upload-button" @click="previewImage($event)">Upload Image</span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'mediaVideo'">
                            <span><a href="javascript:void(0)">Upload Video: {{ meta.description }}</a></span><br>
                            <span v-if="meta.videoUrl">
                              <video
                                  width="300px"
                                  height="200px"
                                  controls
                                >
                                  <source :src="meta.videoUrl" :type="videotype" />
                              </video>
                            </span>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'mediaQRcode'">
                            <img style="width: 50px;" :src="meta.description" alt="qrImage_" class="u-photo"/>Upload QR Code
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'data-input'">
                            <span><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}</a></span>
                          </div>
                          <div
                                class="textarea"
                                v-if="
                                  meta &&
                                  meta.type == 'dataNumberTranding' &&
                                  meta.datatrendfound && !meta.acceptablerange
                                "
                              >
                                <span
                                  ><a href="javascript:void(0)"
                                    >{{ meta.description }}:{{
                                      meta.value
                                    }}[DataTrend]</a
                                  ></span
                                >
                              </div>
                              <div class="textarea" v-if="meta && meta.type == 'dataNumberTranding' && meta.datatrendfound && meta.acceptablerange">
                                  <span v-if="meta.value != '___' && meta.value > meta.maxvalue || meta.value < meta.minvalue">
                                    <a href="javascript:void(0)">{{meta.description}}:<span style="color:red;">{{meta.value}}</span>[DataTrend] [{{meta.minvalue}} - {{meta.maxvalue}}]
                                    <span style="color:red;">outside acceptable range</span>
                                    </a>
                                  </span>
                                <span v-else><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}[DataTrend] [{{meta.minvalue}} - {{meta.maxvalue}}]</a></span>
                              </div>
                              <div class="textarea" v-if="meta && meta.type == 'dataNumberTranding' && !meta.datatrendfound && !meta.acceptablerange">
                              <span><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}</a></span>
                            </div>
                            <div class="textarea" v-if="meta && meta.type == 'dataNumberTranding' && !meta.datatrendfound && meta.acceptablerange">
                              <span v-if="meta.value != '___' && meta.value > meta.maxvalue || meta.value < meta.minvalue">
                                <a href="javascript:void(0)">{{meta.description}}:<span style="color:red;">{{meta.value}}</span>
                                [{{meta.minvalue}} - {{meta.maxvalue}}]<span style="color:red;">outside acceptable range</span>
                                </a>
                              </span>
                              <span v-else><a href="javascript:void(0)">{{meta.description}}:{{meta.value}}[{{meta.minvalue}} - {{meta.maxvalue}}]</a></span>
                            </div>
                            <div class="textarea" v-if="meta && meta.type == 'iot-input'">
                              <p><a href="javascript:void(0)">{{ meta.description }}</a></p>
                            </div>
                            <div class="textarea" v-if="meta && meta.type == 'mcq-input'">
                              <p><a href="javascript:void(0)">{{ meta.description }}</a></p>
                              <p v-if="meta.value != ''">Option Selected:<b style="font-weight:bold;"> {{ meta.value }} </b></p>
                            </div>
                            <div class="textarea" v-if="meta && meta.type == 'skill-input'">
                              <img style="width: 50px;" :src="meta.description"  class="u-photo"/>
                            </div>
                          <div class="textarea" v-if="meta && meta.type == 'var-input'">
                            <p><a href="javascript:void(0)">[{{ meta.variableName }}, {{meta.value}}]</a></p>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'steplock'">
                            <img style="width: 50px;" :src="meta.description"  class="u-photo"/>
                          </div>
                          <div class="textarea" v-if="meta && meta.type == 'verifierInput'">
                            <p><a href="javascript:void(0)">VERIFIER: {{ meta.description }}</a></p>
                          </div>
                          <div class="drag-controls" v-if="meta && meta.type == 'cautionInput'">
                            <p><a href="javascript:void(0)">Caution: {{ meta.description }}</a></p>
                          </div>
                          <div class="drag-controls" v-if="meta && meta.type == 'crossrefInput'">
                            <p><a href="javascript:void(0)">Cross Reference / Document Title = {{ meta.description }}</a></p>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col editRightParent"
          v-if="this.notepadTemp.status1 != 'archived'"
        >
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="saveDocument()">SAVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">REVIEW</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">COMMENT</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <a href="#!" class="btn btn-info" style="display: none">SHARE</a>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--   <a href="#!" class="btn btn-info">CHECK</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="archiveBatch($event, step)">
                FINISH
              </button>
              <!-- <a href="#!" class="btn btn-info" @click="archiveBatch($event, step)">FINISH</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{this.notepadTemp}} -->
    <!-- Begin: Image Upload Batch Document -->
    <div v-if="showImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Image" }}
                  <button
                    type="button"
                    class="close"
                    @click="showImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Image {{ ImageVarName }}:</span>
                  <input
                    type="file"
                    id="file"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="file"
                    name="file-to-upload"
                    v-on:change="handleFileUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addImage($event)"
                    >Add Image</base-button
                  >
                  <p v-if="filespath">
                    <img
                      style="width: 100px; height: 100px; margin-bottom: 10px"
                      :src="filespath"
                      alt=""
                    />
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Image Upload Document -->

    <!-- Begin: QR Image Upload Batch Document -->
    <div v-if="showQRImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload QR Code" }}
                  <button
                    type="button"
                    class="close"
                    @click="showQRImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload QR Code {{ QRImageVarName }}:</span>
                  <p class="error">{{ error }}</p>
                  <qrcode-stream
                    style="width: 70%"
                    :camera="camera"
                    @decode="onDecode"
                    @init="onInit"
                  ></qrcode-stream>
                  <p class="decode-result">
                    Result: <b>{{ result }}</b>
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewQRcode($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showQRImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: QR Image Upload Document -->

    <!-- Begin: Video Image Upload Batch Document -->
    <div v-if="showVideoBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Video" }}
                  <button
                    type="button"
                    class="close"
                    @click="showVideoBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Video {{ VideoVarName }}:</span>
                  <input
                    type="file"
                    id="filevideo"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="filevideo"
                    name="file-to-upload"
                    v-on:change="handleFileVideoUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addVideo($event)"
                    >Add Video</base-button
                  >

                  <p v-if="videopath">
                    <video width="320" height="240" controls>
                      <source :src="videopath" :type="videotype" />
                      Your browser does not support the video tag.
                    </video>
                  </p>

                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Video Image Upload Document -->

    <!-- Begin: Data Tred Batch Document -->
    <div class="overlay-modal" v-if="showDataTredBatchModal"  style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" @click="planetData()">View Graph</button>
      <canvas id="planet-chart"></canvas>

    <div v-if="showDataTredBatchModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Data Trending" }}
                  <button
                    type="button"
                    class="close"
                    @click="showDataTredBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Data Trending</span>

                  <div class="table-responsive">
                    <base-table
                      class="table align-items-center table-flush"
                      tbody-classes="list"
                      :data="documentdataTred"
                    >
                      <template slot="columns">
                        <th>Batch No</th>
                        <th>Variable Name</th>
                        <th>Value</th>
                      </template>

                      <template slot-scope="{ row }">
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.batchno }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.varname }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.varvalue }}</span>
                          </div>
                        </td>
                      </template>
                    </base-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    </div>
    <!-- End: Data Tred Document -->

    <!-- Begin: Cross Reference Batch Document -->
    <div v-if="showCrossRefBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Cross Reference" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCrossRefBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label
                    class="form-control-label"
                    v-html="documentcrossRef"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Cross Reference Document -->

    <!-- skilltrend -->
    <div class="overlay-modal graph-button" v-if="showSkillTredBatchModal" style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" @click="skillData()">Executed Graph</button>
      <button class="btn-default1 btn-sm btn-step-action1" @click="verifiedSkillData()">Verified Graph</button>
      <canvas id="planet-chart"></canvas>
    
      <div v-if="showSkillTredBatchModal">
          <transition name="modal">
            <div class="modal-mask-Batch">
              <div class="modal-wrapper-Batch">
                <div class="modal-dialog share-modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      {{ "Skill Trending" }}
                      <button
                        type="button"
                        class="close"
                        @click="showSkillTredBatchModal=false">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <span>Skill Trending</span>
                        
                      <div class="table-responsive">
                          <base-table
                            class="table align-items-center table-flush"
                            tbody-classes="list"
                            :data="notepadSkill"
                          >
                            <template slot="columns">
                              <th>Batch No</th>
                              <th>Executed User</th>
                              <th>Verified User</th>
                            </template>
                            <template slot-scope="{row}">
                              <td>
                                <div class="avatar-group">
                                  <span class="status">{{row.batchno}}</span>
                                </div>
                              </td>
                            <td>
                                <div class="avatar-group" v-for="(executeduser, executeduser_index) in row.executedUsers" :key="executeduser_index">
                                  <span class="status">{{executeduser.executeduser}}</span>
                                </div>
                              </td>
                              <td>
                                <div class="avatar-group" v-for="(verifieduser, verifieduser_index) in row.verifiedUsers" :key="verifieduser_index">
                                  <span class="status">{{verifieduser.verifieduser}}</span>
                                </div>
                              </td>
                            </template>
                          </base-table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
      </div>
    </div>
    <!-- end skill trend -->

    <div v-if="showViewListBatchModal">
        <transition name="modal">
          <div class="modal-mask-Batch view-data">
            <div class="modal-wrapper-Batch">
              <div class="modal-dialog share-modal">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "View list of data-inputs and formula" }}
                    <button
                      type="button"
                      class="close"
                      @click="showViewListBatchModal=false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush formula-datainput-table-list"
                          tbody-classes="list"
                          :data="documentviewList"
                        >
                          <template slot="columns">
                            <th>Step No</th>
                            <th>Data Label</th>
                            <th>Data</th>
                          </template>
                          <template slot-scope="{row}" v-if="row.type">
                            <td> {{row.stepno}} </td>
                            <td> {{row.datalabel}} </td>
                            <td> {{row.data}} </td>
                          </template>
                        </base-table>
                    </div>
                    <!-- <i class="fa fa-download"></i> -->
                    <button @click="tableToCSV()"
                    class="btn float-right btn-default btn-sm m-2">
                    Export as CSV</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showViewListBatchModal=false"
                  >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";

// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import notepadHeader from "./notepadHeader";
import { QrcodeStream } from "vue-qrcode-reader";
import Chart from 'chart.js';
import { GridLayout, GridItem } from "vue-grid-layout";

export default {
  components: { notepadHeader, QrcodeStream, AgGridVue, GridLayout, GridItem },
  // props: ["docid", "templateType", "docStatusType"],
  data() {
    return {
      layout: [
                { x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), },
            ],
      draggable: true,
      resizable: true,
      colNum: 12,
      batchID: "",
      isNotepad: "",
      docStatusType: "",
      industryItemType: "",
      industryItemID: "",
      notepadTemp: {},
      rowData: [],
      inputRow: {},
      gridOptions: null,
      documentdataTred: [],
      documentviewList: [],
      notepadSkill: [],
      documentcrossRef: "",
      temptag: [],
      tagjson: {},
      timermm: "00",
      timerss: "00",
      timerStart: false,
      spanVisible: false,
      oldstring: [],
      showImageBatchModal: false,
      showDataTredBatchModal: false,
      showCrossRefBatchModal: false,
      showSkillTredBatchModal: false,
      showViewListBatchModal: false,
      showCanvas: false,
      VideoVarName: "",
      videopath: "",
      videotype: "",
      videopathold: "",
      videotypeold: "",
      showVideoBatchModal: false,
      ImageVarName: "",
      ImageSrc: "",
      showQRImageBatchModal: false,
      QRImageVarName: "",
      QRImageSrc: "",
      result: "",
      oldString: "Upload QR Code",
      error: "",
      camera: "auto",
      files: "",
      filespath: "",
      tempstep: null,
      authCookie: null,
      updateSecId: "",
      updateStepId: "",
    };
  },
  beforeMount() {
    this.batchID = this.$route.query.batchID;
    this.docStatusType = this.$route.query.docStatusType;
    this.isNotepad = this.$route.query.isNotepad;
    this.industryItemType = this.$route.query.industryItemType;
    this.industryItemID = this.$route.query.industryItemID;
  },
  mounted() {
    if (this.batchID) {
      axios
        .get(
          "/api/notepadBatch/organizations/" +
            this.authCookie.orgs[0].orgID + "/" +
            this.batchID
        )
        .then((response) => {
          console.log(response);
          this.notepadTemp = response.data.item;
          this.notepadTemp.title = this.notepadTemp.title.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');
          this.usagelogtagTemp = {};

          //steplock
          this.steplock();
        })
        .catch((reason) => {
          console.log(reason);
          this.notepadTemp = [];
          this.usagelogtagTemp = {};
        });
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    // this.authCookie = JSON.parse(authCookieObj.substring(2));
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token").substring(2).split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  beforeCreate() {
    //let recaptchaScript = document.createElement("script");
    //recaptchaScript.setAttribute("src", "ckeditor.js");
    //document.head.appendChild(recaptchaScript);
  },
  methods: {
//     setRowData(newData) {
//   this.rowData = newData;
//   this.gridOptions.api.setRowData(this.rowData);
// },

//  setInputRow(newData) {
//   this.inputRow = newData;
//   this.gridOptions.api.setPinnedTopRowData([this.inputRow]);
// },
    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      await this.timeout(3000);
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    turnCameraOff() {
      this.camera = "off";
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    steplock() {
      //steplock
      var steplockfound = false;
      var executedstap = false;
      var stepdeti;
      var stepdetj;
      var disarray = [];
      var i = 0;
      var totalsec = this.notepadTemp.sections;
      if (!totalsec) {
        return;
      }
      for (i = 0; i < totalsec.length; i++) {
        var j = 0;
        var eachstep = totalsec[i].steps;
        for (j = 0; j < eachstep.length; j++) {
          //var v1 = eachstep[j].description.toString();
          var dom = document.createElement("div");
          dom.innerHTML = eachstep[j].description;
          var imgtag = dom.getElementsByTagName("img");
          if (imgtag != undefined && imgtag.length > 0) {
            var ki;
            for (ki = 0; ki < imgtag.length; ki++) {
              //console.log(imgtag[ki]);
              var imgtagalt = imgtag[ki].getAttribute("alt");
              //console.log(imgtagalt);
              if (imgtagalt.toString() == "StepLock") {
                steplockfound = true;
                stepdeti = i;
                stepdetj = j;
                if (
                  eachstep[j].executed != undefined &&
                  eachstep[j].executed == true
                ) {
                  executedstap = true;
                }
                //console.log((i+1) +'.'+ (j+1));
                //console.log(steplockfound);
              }
            }
          }
          if (steplockfound == true) {
            if (i == stepdeti) {
              if (j > stepdetj) {
                //console.log('desable');
                //console.log((i+1) +'.'+ (j+1));
                disarray.push(i + 1 + "." + (j + 1));
              }
            } else if (i > stepdeti) {
              //console.log('desable');
              //console.log((i+1) +'.'+ (j+1));
              disarray.push(i + 1 + "." + (j + 1));
            }
          }
        }
      }

      //find div
      //console.log('disarray');
      //console.log(disarray);
      if (disarray.length > 0) {
        setTimeout(function () {
          var cuslabel = document.querySelectorAll("label.form-control-label");
          //console.log(cuslabel);
          var li;
          for (li = 0; li < cuslabel.length; li++) {
            var ji;
            for (ji = 0; ji < disarray.length; ji++) {
              if (
                cuslabel[li].innerHTML.toString() == disarray[ji].toString()
              ) {
                //console.log(cuslabel[li].innerHTML.toString());
                //console.log(cuslabel[li].parentElement.setAttribute("class","custom-disable"));
                if (executedstap == true) {
                  if (
                    cuslabel[li].parentElement.getAttribute("class") !=
                    undefined
                  ) {
                    if (
                      cuslabel[li].parentElement
                        .getAttribute("class")
                        .toString() == "custom-disable"
                    ) {
                      cuslabel[li].parentElement.removeAttribute("class");
                    }
                  }
                } else {
                  cuslabel[li].parentElement.setAttribute(
                    "class",
                    "custom-disable"
                  );
                }
              }
            }
          }
        }, 100);
      }
    },
    crossref(e, section) {
      console.log(e);
      console.log(section);
      this.documentcrossRef = "No Data Found";
      var dom = document.createElement("div");
      dom.innerHTML = section.description;
      var atag = dom.getElementsByTagName("a");
      if (atag != undefined && atag.length > 0) {
        var ki;
        for (ki = 0; ki < atag.length; ki++) {
          if (
            atag[ki].innerHTML
              .toString()
              .indexOf("ross Reference / Document Title =", 0) > 0
          ) {
            var altarray = atag[ki].innerHTML.toString().split("/");
            if (altarray != undefined && altarray.length > 0) {
              var DocTitle = altarray[1].split("=")[1].toString().trim();
              var secid = altarray[2].split("=")[1].toString().trim();
              var stepid = altarray[3].split("=")[1].toString().trim();
              console.log(DocTitle);
              secid = secid - 1;
              stepid = stepid.split(".")[1] - 1;
              console.log(secid);
              console.log(stepid);
            }

            axios
              .get(
                "/api/organizations/" +
                  this.authCookie.orgs[0].orgID +
                  "/crossref/" +
                  DocTitle
              )
              .then((response) => {
                this.documentcrossRef =
                  response.data.item.sections[secid].description;
                console.log(this.documentcrossRef);
                this.showCrossRefBatchModal = true;
              })
              .catch((reason) => {
                console.log(reason);
                this.documentcrossRef = "No Data Found";
              });
          }
        }
      }
    },
    UploadVideo(e, section) {
      console.log(e);
      console.log(section);
      this.showVideoBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
    },
    UploadQR(e, section) {
      console.log(e);
      console.log(section);
      this.showQRImageBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      this.tempstep = section;
      var dom = document.createElement("div");
      dom.innerHTML = section.description;
      var imgtag = dom.getElementsByTagName("img");
      if (imgtag != undefined && imgtag.length > 0) {
        var ki;
        for (ki = 0; ki < imgtag.length; ki++) {
          var imgtagalt = imgtag[ki].getAttribute("alt");
          var altarray = imgtagalt.split("_");
          var varName = null;
          if (altarray != undefined && altarray.length > 0) {
            if (altarray[0] == "qrImage") {
              varName = altarray[1];
              this.QRImageVarName = varName;
              this.QRImageSrc = imgtagsrc;
              this.showQRImageBatchModal = true;
              this.turnCameraOn();
              this.result = "";
            }
          }
          var imgtagsrc = imgtag[ki].getAttribute("src");
          //console.log(imgtagalt);
        }
      }
    },
    UploadImg(e, section) {
      console.log(e);
      console.log(section);
      this.showImageBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
    },
    handleFileUploads() {
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    handleFileVideoUploads() {
      this.files = this.$refs.filevideo.files[0];
      //console.log(this.files);
    },
    addVideo(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadVideosFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          app.videopath = "";
          app.videotype = "";
          var path = response.data.fileUrl;
          app.videopath = path.toString();
          app.videotype = response.data.type.toString();
          console.log(app.videopath);
          console.log(app.videotype);
          alert("Video Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addImage(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          app.filespath = "";
          var path = response.data.fileUrl;
          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage(e) {
      console.dir(e);
        let app = this;
      var sec = app.notepadTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (!step.meta) {
          step.meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].mediaimagefound) {
            sec[0].meta[j].imagePath = app.filespath;
            sec[0].meta[j].description = sec[0].meta[j].description.toString().replace(sec[0].meta[j].description,sec[0].meta[j].imagePath);
            document.getElementById("upload-button").innerHTML = "";
            app.$set(sec[0], 0);
            app.showImageBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
        }
      app.showImageBatchModal = false;
    },
    previewVideo(e) {
      console.dir(e);
        let app = this;
      var sec = app.notepadTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (!step.meta) {
          step.meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].videofound) {
            sec[0].meta[j].videoUrl = app.videopath;
            app.$set(sec[0], 0);
            app.showVideoBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
        }
      app.showVideoBatchModal = false;
    },
    previewQRcode(e) {
      console.dir(e);
        let app = this;
      var sec = app.notepadTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (!step.meta) {
          step.meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].qrimagefound) {
            // sec[0].meta[j].videoUrl = app.videopath;
            app.$set(sec[0], 0);
            app.showQRImageBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
        }
      app.showQRImageBatchModal = false;
    },
    formulaCalculate(e, section, meta) {
      console.log(e);
      //console.log(section);
      for( var i = 0; i < section.meta.length; i++){
      if(section.meta[i].uid == meta.uid && section.meta[i].formulafound){
        var ResultValue = eval(section.meta[i].formula);
        //section.meta[i].ResultText = "Result : " + section.meta[i].formula.toString() + " = " + ResultValue;
        this.$set(meta, "ResultText", "Result : " + section.meta[i].formula.toString() + " = " + ResultValue);
      }
      }
    },
    startTimer(e, section) {
      this.timerStart = true;
      this.spanVisible = true;
      this.timermm = 0;
      this.timerss = 0;
      this.$set(section.meta, "timermm", this.timermm);
      this.$set(section.meta, "timerss", this.timerss);
      this.$set(section.meta, "timerStart", this.timerStart);
      this.$set(section.meta, "spanVisible", this.spanVisible);
      console.log(e);
      //console.log("Start Timer");
      //console.log(step);
      this.countDownTimer(section.meta);
    },
    stopTimer(e, section) {
      console.log(e);
      console.log(section);
      this.timerStart = false;
      section.meta.timerStart = false;
      //console.log(this.timermm + ' : ' + this.timerss);
      this.countDownTimer(section.meta);
    },
    countDownTimer(section) {
      //console.log('countDownTimer');
      //console.log(step);
      //console.log(step.timerStart);
      if (section.timerStart) {
        setTimeout(() => {
          section.timerss += 1;
          if (section.timerss == 60) {
            section.timerss = 0;
            section.timermm += 1;
          }
          this.countDownTimer(section);
        }, 1000);
      }
    },
    DataTrend(e, meta,sec_index,meta_index) {
      console.log(e);
      // console.log(section);
      console.log(sec_index);
      console.log(meta);
      console.log(meta_index);
      console.log(this.batchID);
      axios
        .get(
          "/api/notepadBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then((response) => {

          console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = "";
          this.documentdataTred = [];

          var i;
            for(i=0;i<temp.length;i++)
            {
              var j
              batchNo = temp[i].batchNumber;
              for(j=0;j<temp[i].sections[sec_index].meta[meta_index].dataTrandings.length;j++)
              {
                temptable = {};
                temptable.batchno = batchNo;
                temptable.varname = temp[i].sections[sec_index].meta[meta_index].dataTrandings[j].varname;
                temptable.varvalue = temp[i].sections[sec_index].meta[meta_index].dataTrandings[j].varvalue;      
                this.$set(this.documentdataTred, this.documentdataTred.length, temptable);
              }

            }

          this.showDataTredBatchModal = true;
        })
        .catch((reason) => {
          console.log(reason);
          this.documentdataTred = [];
        });
    },
    setValue(e, section, meta) {
      console.dir(e);
      console.log(section);
      let app = this;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = app.notepadTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        // if (step && step[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var VarName = meta.description;
        var pValText = prompt("Please Enter Value for " + VarName + ":","");
        if (!pValText) return;
        if (meta.type == "data-input") {
              meta.value = pValText+"_____";
            }
            else if(meta.type == "dataNumberTranding" && meta.datatrendfound ){
              meta.value = pValText;
              if(meta.dataTrandings != undefined && meta.dataTrandings.length > 0)
              {
                var j;
                for(j=0;j<meta.dataTrandings.length;j++)
                {
                  if(meta.dataTrandings[j].varname == VarName)
                  {
                    meta.dataTrandings[j].varvalue = pValText;
                    meta.dataTrandings[j].batchno = this.notepadTemp.batchNumber;
                  }
                }
              }
            }
            else if(meta.type == "dataNumberTranding" && !meta.datatrendfound ){
              meta.value = pValText;
            }
        app.$set(sec[0], 0);
        }
    },
    selectOption(meta){
      var pValText = prompt(" Enter selected option number only", "" );
      if (!pValText) return;
      meta.value += pValText + " ";
    },
    skillTrend(e, meta,sec_index,meta_index)
    {
      console.log(e);
      console.log(meta);
      console.log(sec_index);
      console.log(meta_index);
      console.log(this.batchID);
      axios
          .get(
          "/api/notepadBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
          )
          .then(response => {
            console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = "";
          this.notepadSkill = [];
              var i;
              for(i=0;i<temp.length;i++)
              {
                var j
                batchNo = temp[i].batchNumber;
                for(j=0;j<temp[i].sections[sec_index].meta[meta_index].skills.length;j++){
                  temptable = {};
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[sec_index].meta[meta_index].skills[j].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[sec_index].meta[meta_index].skills[j].verifiedUsers;
                  this.$set(this.notepadSkill, this.notepadSkill.length, temptable);
                  }
                  }
            this.showSkillTredBatchModal = true;
          })
          .catch(reason => {
            console.log(reason);
            this.notepadSkill = [];
          });
    },
    skillData(){
      axios
        .get(
            "/api/notepadBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempExecuteObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].sections.length; j++){
            for(let k = 0; k < skillTemp[i].sections[j].meta.length; k++){
              for(let l = 0; l < skillTemp[i].sections[j].meta[k].skills.length; l++){
                var executeduser = skillTemp[i].sections[j].meta[k].skills[l].executedUsers[0].executeduser;
                
                if(tempExecuteObj.get(executeduser)) {
                  let count = tempExecuteObj.get(executeduser);
                  tempExecuteObj.set(executeduser, ++count);
                } else {
                  tempExecuteObj.set(executeduser, 1);
                }                
              }
                }
              }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempExecuteObj.keys()),
                      datasets: [
                        {
                          label: "executedUser",
                          data: Array.from(tempExecuteObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.notepadTemp = [];
        });
    },
    verifiedSkillData(){
      axios
        .get(
            "/api/notepadBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempVerifyObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].sections.length; j++){
            for(let k = 0; k < skillTemp[i].sections[j].meta.length; k++){
              for(let l = 0; l < skillTemp[i].sections[j].meta[k].skills.length; l++){
                var verifieduser = skillTemp[i].sections[j].meta[k].skills[l].verifiedUsers[0].verifieduser;
                if(tempVerifyObj.get(verifieduser)) {
                  let count = tempVerifyObj.get(verifieduser);
                  tempVerifyObj.set(verifieduser, ++count);
                } else {
                  tempVerifyObj.set(verifieduser, 1);
                }
              }                
            }
            }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempVerifyObj.keys()),
                      datasets: [
                        {
                          label: "verifieduser",
                          data: Array.from(tempVerifyObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.notepadTemp = [];
        });
    },
    planetData(){
      // console.log(notepadTemp);
      axios
        .get(
          "/api/notepadBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var planetTemp = response.data.item;
          for(let i = 0; i < planetTemp.sections.length; i++){
            for(let j = 0; j < planetTemp.sections[i].meta.length; j++){
              if(planetTemp.sections[i].meta[j].dataTrandings){
              for(let k = 0; k < planetTemp.sections[i].meta[j].dataTrandings.length; k++){
                var variableBatchno = planetTemp.sections[i].meta[j].dataTrandings[k].batchno;
                var variableName = planetTemp.sections[i].meta[j].dataTrandings[k].varname;
                var variableValue = planetTemp.sections[i].meta[j].dataTrandings[k].varvalue;
                console.log(variableValue);
                this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "line",
                    data: {
                      labels: [variableBatchno],
                      datasets: [
                        {
                          label: variableName,
                          data: [variableBatchno],            
                          borderColor: "#36495d",
                          borderWidth: 2,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                              
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Values ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Batch Number ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
                }
              }
            }
          }
        })
        .catch(reason => {
          console.log(reason);
          this.notepadTemp = [];
        });
    },
    showViewBatchDataButton(){
    console.log(this.notepadTemp);
    for( var i = 0; i < this.notepadTemp.sections.length; i++) {
      for( var j = 0; j < this.notepadTemp.sections[i].meta.length; j++ ) {
          console.log(this.notepadTemp.sections[i].meta[j].formulafound);
          if(this.notepadTemp.sections[i].meta[j].formulafound || this.notepadTemp.sections[i].meta[j].datatrendfound || this.notepadTemp.sections[i].meta[j].inputfound){
          return this.notepadTemp.sections[i].meta[j].formulafound || this.notepadTemp.sections[i].meta[j].datatrendfound || this.notepadTemp.sections[i].meta[j].inputfound;
          }
      }
    }
    return false;
    },
    viewList()
    {
      axios
          .get(
            "/api/notepadBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
          .then(response => {
            var temp = response.data.items;
            var temptable = {};
            this.documentviewList = [];
              for(var i=0;i<temp.length;i++){
                for(var j=0;j<temp[i].sections.length;j++){
                for(var k=0;k<temp[i].sections[j].meta.length;k++){
                  temptable = {};
                  if(temp[i].sections[j].meta[k].formulafound){
                  var labelText = temp[i].sections[j].meta[k].formuladatalabel;
                  var formulaResult = temp[i].sections[j].meta[k].ResultText.split(':')[1].split('=')[1];
                  var formula = temp[i].sections[j].meta[k].formulafound;
                  temptable.stepno =  j+1 + "." + (k+1);
                  temptable.datalabel = labelText;
                  temptable.data = formulaResult;
                  temptable.type = formula;
                  }
                  else if(temp[i].sections[j].meta[k].inputfound || temp[i].sections[j].meta[k].datatrendfound){
                    var inputLabel = temp[i].sections[j].meta[k].description;
                    var inputData ;
                    if(temp[i].sections[j].meta[k].inputfound){
                      inputData = temp[i].sections[j].meta[k].value.split('_')[0];
                    }
                    if(temp[i].sections[j].meta[k].datatrendfound){
                      inputData = temp[i].sections[j].meta[k].value;
                    }
                    var inputdata = temp[i].sections[j].meta[k].inputfound || temp[i].sections[j].meta[k].datatrendfound;
                    temptable.stepno =  j+1 + "." + (k+1);
                    temptable.datalabel = inputLabel;
                    temptable.data = inputData;
                    temptable.type = inputdata;
                  }
                  if (temptable && temptable.stepno) {
                  this.$set(this.documentviewList, this.documentviewList.length, temptable);
                  }
                  }              
                }
              }          
            this.showViewListBatchModal = true;
          })
          .catch(reason => {
            console.log(reason);
            this.documentviewList = [];
          });

    },
    tableToCSV(){
    let csv_data = [];
    let rows = document.getElementsByTagName('tr');
    for (let i = 0; i < rows.length; i++) {
        let cols = rows[i].querySelectorAll('td,th');
        let csvrow = [];
        for (let j = 0; j < cols.length; j++) {
            csvrow.push(cols[j].innerHTML);
        }
        csv_data.push(csvrow.join(","));
    }
    csv_data = csv_data.join('\n');
    this.downloadCSVFile(csv_data);
    },
    downloadCSVFile(csv_data) {
      var CSVFile = {};
      CSVFile = new Blob([csv_data], {
          type: "text/csv"
      });
        let temp_link = document.createElement('a');
      temp_link.download = "GfG.csv";
      let url = window.URL.createObjectURL(CSVFile);
      temp_link.href = url;
      temp_link.style.display = "none";
      document.body.appendChild(temp_link);
      temp_link.click();
      document.body.removeChild(temp_link);
    },
    addRows(e, section, meta){
      console.dir(e);
      console.log(section);
      // var x = document.createElement("INPUT");
      //         x.setAttribute("type", "text");
      //         x.setAttribute("name", "textInput");
      //         x.setAttribute("value", "");
      //         document.getElementById("myForm").appendChild(x);
      let app = this;
      var data = {};
          for(var k = 0; k < meta.columnDefs.length; k++){
          if (meta.columnDefs[k].field) {
              data[meta.columnDefs[k].field] = '';
          }
        }
        meta.rowData.push(data);
        app.$set(section, 0);
    },
    executeStep(e, section, sec_index) {
      console.log(section);
      for( var j = 0; j < section.meta.length; j++){
      if (section.meta[j].timer) {
        // section.meta[j].executed = true;
        section.meta[j].description;
        section.meta[j].timerValue = " Timer(MM:SS) = " +
          section.meta.timermm +
          " : " +
          section.meta.timerss;
        this.$set(section.meta[j], "timer", false);
      }
      if(section.meta[j].skillfound){
        this.onChangeExecute(section, sec_index);
      }
      if (section.meta.inputfound != undefined) {
        this.$set(section.meta, "inputfound", false);
      }
      if (section.qrimagefound != undefined) {
        this.$set(section, "qrimagefound", false);
      }
      if (section.videofound != undefined) {
        this.$set(section, "videofound", false);
      }
      if (section.mediaimagefound != undefined) {
        this.$set(section, "mediaimagefound", false);
      }
      if(section.mcq != undefined){
        this.$set(section, "mcq", false);
        }
      // if (section.meta[j].formulafound) {
      //   this.$set(section.meta[j], "formulafound", false);
      // }
      }
      this.$set(section, "executed", true);
      this.$set(section, "executedBy", this.authCookie.userName);
      this.$set(section, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "executedTime", new Date());
      console.log(section);
      
    },
    approveStep(e, section, sec_index) {
      for (var j = 0; j < section.meta.length; j++) {
        if(section.meta[j].skillfound){
          this.onChangeApprove(section, sec_index);
        }
      }
      this.$set(section, "approved", true);
      this.$set(section, "approvedBy", this.authCookie.userName);
      this.$set(section, "approvedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "approvedTime", new Date());
    },
    notApplicableSection(e, section) {
      this.$set(section, "notApplicable", true);
      this.$set(section, "notApplicableBy", this.authCookie.userName);
      this.$set(section, "notApplicableByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "notApplicableTime", new Date());
    },
    onChangeExecute(section, sec_index) {
    console.log(section);
    console.log(sec_index);
    axios
        .get(
          "/api/notepadBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.notepadSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              batchNo = temp[i].batchNumber;
                for(var j=0;j<temp[i].sections[sec_index].meta.length;j++){
                  for(var k=0;k<temp[i].sections[sec_index].meta[j].skills.length;k++){
                  temptable = {};
                  if (this.notepadTemp.batchNumber == batchNo) {
                    var executedUser = {};
                    executedUser.executeduser = this.authCookie.userName;
                    section.meta[j].skills[k].executedUsers.push(executedUser);
                    section.meta[j].skills[k].batchno = this.notepadTemp.batchNumber;
                    temp[i].sections[sec_index].meta[j].skills[k].executedUsers = section.meta[j].skills[k].executedUsers;
                    temp[i].sections[sec_index].meta[j].skills[k].verifiedUsers = section.meta[j].skills[k].verifiedUsers;
                  }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[sec_index].meta[j].skills[k].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[sec_index].meta[j].skills[k].verifiedUsers;
                this.$set(this.notepadSkill, this.notepadSkill.length, temptable);
              }
                }
              }
        })
        .catch(reason => {
          console.log(reason);
          this.notepadSkill = [];
        });
      this.$set(section, "executed", true);
      this.$set(section, "executedBy", this.authCookie.userName);
      this.$set(section, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "executedTime", new Date());
    },
    onChangeApprove(section,sec_index)
    {
      console.log(section);
      console.log(sec_index);
      axios
        .get(
          "/api/notepadBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.notepadSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              batchNo = temp[i].batchNumber;
              for(var j=0;j<temp[i].sections[sec_index].meta.length;j++){
                  for(var k=0;k<temp[i].sections[sec_index].meta[j].skills.length;k++){
                temptable = {};
                if (this.notepadTemp.batchNumber == batchNo) {
                var verifiedUser = {};
                  verifiedUser.verifieduser = this.authCookie.userName;
                  section.meta[j].skills[k].verifiedUsers.push(verifiedUser);
                  section.meta[j].skills[k].batchno = this.notepadTemp.batchNumber;
                  temp[i].sections[sec_index].meta[j].skills[k].executedUsers = section.meta[j].skills[k].executedUsers;
                  temp[i].sections[sec_index].meta[j].skills[k].verifiedUsers = section.meta[j].skills[k].verifiedUsers;
                }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[sec_index].meta[j].skills[k].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[sec_index].meta[j].skills[k].verifiedUsers;
                  this.$set(this.notepadSkill, this.notepadSkill.length, temptable);
              }
              }
            }
        })
        .catch(reason => {
          console.log(reason);
          this.notepadSkill = [];
        });
      this.$set(section, "approved", true);
      this.$set(section, "approvedBy", this.authCookie.userName);
      this.$set(section, "approvedByOrg",this.authCookie.orgs[0].orgID);
      this.$set(section, "approvedTime", new Date());
    },
    sectionToggle(e) {
      var secBody = e.target.parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "none";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "block";
      } else {
        secBody.style.display = "none";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "block";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "none";
      }
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach((sec) => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach((step) => {
          this.deleteAttr(step);
        });
      }
    },
    archiveBatch() {
      let app = this;
      this.deleteAttr(this.notepadTemp);
      this.notepadTemp.status1 = "archived";
      axios
        .put("/api/notepadBatch", this.notepadTemp)
        .then(function (response) {
          console.log(response);
          alert("Batch archived successfully!");
        })
        .catch(function (error) {
          console.log(error);
          app.notepadTemp = [];
        });
    },
    saveDocument() {
      this.steplock();
      let app = this;
      this.deleteAttr(this.notepadTemp);
      if (!this.notepadTemp.batchID) {
        axios
          .post("/api/notepadBatch", app.notepadTemp)
          .then(function (response) {
            console.log(response);
            app.notepadTemp = response.data.item;
            alert("Document Created Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.notepadTemp = [];
          });
      } else {
        app.notepadTemp.updatedBy =
          app.notepadTemp.updatedBy || this.authCookie.userName;
        axios
          .put("/api/notepadBatch", app.notepadTemp)
          .then(function (response) {
            console.log(response);
            app.notepadTemp = response.data.item;
            alert("Document Saved Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.notepadTemp = [];
          });
      }
    },
    handleOverlaysClick(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);
      }
    },
    handleOverlaysEdit(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);

        var pValTime = prompt(
          "Attach Time needed for a particular STEP",
          e.target.innerText
        );
        if (!pValTime) return;

        e.target.innerText = pValTime;
      }
    },
    // getEditorInstance(el) {
    //   if (window.CKEDITOR.instances[el.id]) {
    //     return window.CKEDITOR.instances[el.id];
    //   }

    //   if (
    //     el["title"] &&
    //     el["title"].split(",").length == 2 &&
    //     window.CKEDITOR.instances[el["title"].split(",")[1].trim()]
    //   ) {
    //     return window.CKEDITOR.instances[el["title"].split(",")[1].trim()];
    //   }
    //   return null;
    // },
    // registerEditor(e) {
    //   if (window.CKEDITOR.instances[e.target.id]) {
    //     return;
    //   }

    //   if (!e.target["title"] || e.target["title"].split(",").length != 2) {
    //     console.dir("unregistered editor: " + e.target.id);
    //     window.CKEDITOR.inline(e.target.id);
    //     return;
    //   }

    //   var editorId = e.target["title"].split(",")[1].trim();
    //   if (!window.CKEDITOR.instances[editorId]) {
    //     console.dir("unregistered editor: " + editorId);
    //     window.CKEDITOR.inline(editorId);
    //   }
    // },
    getId() {
      return uuidv4();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
.view-batch-data{
  margin-left: 59rem !important;
  margin-bottom: 10px;
}
.overlay-modal{
    position: fixed;
    z-index: 9998;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1206062b;
}
#planet-chart{
  max-width: 500px;
  margin: 1.75rem auto;
}
#editorControlListLeftNotepadIcons {
  // height: 550px;
  height: calc(100vh - 200px);
  overflow: scroll;
  width: 65px;
}
.user-audit {
  margin-left: 5px;
  color: darkgray !important;
}
.editControlParent {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNotepadBatch {
  width: 90%;
  // margin-left: 70px;
  // margin-right: 120px;
  // flex-basis: auto;
}
button.btn-default1.btn-sm.btn-step-action1.btn-hidden {
  visibility: hidden;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}
.btn{
  width: 130px;
}
.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.sec-toggle {
  background-color: #0001;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadow5 {
  border: 1px solid #dadce0 !important;
  padding: 6px;
  // margin-top: 20px;
}
.notepadbatch-heading {
  font-weight: 700;
  // top: -100px;
  // position: relative;
  // left: 110px;
  text-align: center;
  font-size: 20px;
  // background-color: #ffff;
  // margin-right: 95px;
  // padding-left: 10px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // border-radius: 0.375rem;
  width: calc(100% - 130px);
}
.custom-disable {
  opacity: 0.4;
}
.custom-disable input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] {
  display: inline;
}
.custom-disable button {
  display: none;
}
button {
  display: inline;
}
.btn-default1 {
  margin-left: 5px;
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  // box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.data-table{
  margin-top: 28px;
}
// .textarea{
//   margin-bottom: 15px;
// }
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}

.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}
.ag-theme-balham .ag-cell {
  align-items: center;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value span {
  height:20px;
}
.ag-theme-balham .ag-cell {
    line-height: 15px !important; 
    padding-left: 12px;
    padding-right: 12px;
    // border: 1px solid transparent;
    padding-left: 11px;
    padding-right: 11px;
    display: -webkit-inline-box !important;

}
// .ag-theme-balham .ag-row {
//   border-bottom: 2px solid #808080f5!important;
// }
.ag-header-cell-label .ag-header-cell-text {
    font-size: 15px !important;
}

// grid layout css
.layoutJSON {

    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
