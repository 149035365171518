<template>
  <div>
    <div style="clear: both; height: 70px">
      <template-header
        :docStatusType="docStatusType"
        :type="type"
      ></template-header>
      <div class="container-fluid">
      <div class="data-grid col">
        <div class="template-list">
          {{ "List of " + docStatusType + " " + type }}
        </div>
        <ag-grid-vue
          style="height: 600px"
          class="ag-theme-balham"
          :gridOptions="gridOptions"
          :rowData="rowData"
          rowSelection="multiple"
          rowHeight="50"
          pagination="true"
          paginationPageSize="10"
          floatingFilter="true"
        >
        </ag-grid-vue>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import Vue from "vue";
import templateHeader from "./templateHeader";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";
import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";

Vue.use(VueClipboard);
export default {
  components: { templateHeader, AgGridVue },
  directives: {
    "b-tooltip": BTooltipDirective,
  },
  data() {
    return {
      type: "",
      docStatusType: "",
      batchfound: false,
      gridOptions: null,
      rowData: null,
      gridDocumentOptions: null,
      documentLogData: null,
    };
  },
  beforeMount() {
    this.type = this.$route.query.type;
    this.docStatusType = this.$route.query.docStatusType;
    this.gridOptions = {
      columnDefs: this.createColDefs(),
    };
  },
  mounted() {
    this.loadRowData();
  },
  methods: {
    loadRowData() {
      if (this.docStatusType.toLowerCase() == "batch") {
        this.batchfound = true;
      } else {
        this.batchfound = false;
      }

      let authCookieObj = this.getCookie("auth-token");
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      if(authObj.departments && authObj.departments[0] ){
        var docListURL;
        docListURL =
          "/api/template/organizations/" + 
          authObj.orgs[0].orgID + "?status1=" +
          this.docStatusType.toLowerCase();
        if (
          this.docStatusType.toLowerCase() == "batch" ||
          this.docStatusType.toLowerCase() == "live" ||
          this.docStatusType.toLowerCase() == "archived"
        )
          docListURL =
            "/api/libraryBatch/organizations/" + 
            authObj.orgs[0].orgID + 
            "?status1=" +
            this.docStatusType.toLowerCase();
        axios
          .get(docListURL)
          .then((response) => {
            console.log(response);
            this.rowData = docListURL;
            this.rowData = response.data.items;
          })
          .catch((reason) => {
            console.log(reason);
            this.rowData = [];
          });
      }
      else{
        docListURL =
        "/api/template/organizations/" + 
        authObj.orgs[0].orgID + "?status1=" +
        this.docStatusType.toLowerCase();
      if (
        this.docStatusType.toLowerCase() == "batch" ||
        this.docStatusType.toLowerCase() == "live" ||
        this.docStatusType.toLowerCase() == "archived"
      )
        docListURL =
          "/api/libraryBatch/organizations/" + 
          authObj.orgs[0].orgID + 
          "?status1=" +
          this.docStatusType.toLowerCase();
      axios
        .get(docListURL)
        .then((response) => {
          console.log(response);
          this.rowData = docListURL;
          this.rowData = response.data.items;
        })
        .catch((reason) => {
          console.log(reason);
          this.rowData = [];
        });
    }
    },
    handleRowClick(row) {
      let item = row.data;
      if (
        this.docStatusType.toLowerCase().startsWith("batch") ||
        this.docStatusType.toLowerCase().startsWith("live") ||
        this.docStatusType.toLowerCase().startsWith("archived")
      ) {
        window.location.href =
          "/librarybatch?batchID=" +
          item.batchID +
          "&docStatusType=" +
          this.docStatusType.toLowerCase() +
          "&type=" +
          this.type;
      } else {
        window.location.href =
          "/newTemplate?docid=" +
          item.templateDocID +
          "&docStatusType=" +
          this.docStatusType.toLowerCase() +
          "&type=" +
          this.type;
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    createColDefs() {
      var that = this;
      var colDefs = [
        {
          headerName: "TITLE",
          field: "title",
          width: 175, 
          cellStyle: { "white-space": "normal", 'line-height': 1.5 }, 
          autoHeight: true,
          sortable: true,
          filter: true,
          cellRenderer: function (params) {
            console.log(params);
            return (
              '<a href="#" class="title" title="' +
              params.value +
              '">' +
              params.value +
              "</a>"
            );
          },
          onCellClicked: function (params) {
            console.log(params);
            console.log(params.data);
            return that.handleRowClick(params);
          },
        },
        {
          headerName: "STATUS",
          field: "status1",
          sortable: true,
          filter: true,
        },
        {
          headerName: "UPDATED BY",
          field: "updatedBy",
          sortable: true,
          filter: true,
        },
        {
          headerName: "CREATED BY",
          field: "createdBy",
          sortable: true,
          filter: true,
        },
        {
          headerName: "CREATED TIME",
          field: "createdTime",
          sortable: true,
          filter: true,
        },
      ];
      if (this.docStatusType == "shared") {
        colDefs.splice(1, 0, {
          headerName: "SHARED TO",
          field: "info.email",
          sortable: true,
          filter: true,
        });
      }
      return colDefs;
    },
  },

  created() {
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
.sharedHeader {
  width: 200px;
}
.sharedRow {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.template-list {
  font-weight: 600;
  text-align: center;
  padding-bottom: 5px;
}

.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  // display: flex;
  align-items: center;
  // overflow: auto;
}
a.title {
  color: #000;
}
a.title:hover {
  // color: #000;
  text-decoration: underline !important;
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham .ag-cell {
    line-height: 15px !important; 
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid transparent;
    padding-left: 11px;
    padding-right: 11px;
    display: -webkit-inline-box !important;

}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.mt-7 {
  margin-top: -1rem !important;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.share-modal {
  max-width: 50%;
}
.view:hover{
  text-decoration: underline;
  cursor: pointer;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value span {
  height:20px;
}
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>