<template>
  <div>
    <div style="clear: both">
      <pdf-header></pdf-header>
    </div>
      <!-- <div class="pdf-header">Create New Document</div> -->
        <div class="new-pdf" v-if="pdfTemp.title && !isClonedDocument">
          {{
            stripHTML(pdfTemp.title) +
            " - " +
            capitalizeFirstLetter(pdfTemp.status1)
          }}
        </div>
        <div class="new-pdf" v-else>
          {{ capitalizeFirstLetter(pdfTemp.status1) }}
        </div>
        <div class="container-fluid">
        <div class="row">
        <div class="col editControlPdf" v-if="isEditable()">
          <div id="editorControlListLeftPdfIcons">
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="text-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/text.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Text</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="grid-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/table.png"
                alt="grid"
                class="u-photo"
              />
              <span class="leftIcon">Grid</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="image-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/image.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Image</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="video-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/video.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Video</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="table-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/table.png"
                alt="table"
                class="u-photo"
              />
              <span class="leftIcon">Table</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/timer.png" alt="avatar" class="u-photo" />
              <span class="leftIcon">Timer</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="formula-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/formula-fx.png"
                alt="avatar"
                class="u-photo"
              />
              <span class="leftIcon">Formula</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="qrcode-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/media.jpg" alt="avatar" class="u-photo" />
              <span class="leftIcon"></span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="data-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/data_input.png"
                alt="Data Input"
                class="u-photo"
              />
              <span class="leftIcon">DataInput</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="var-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/variable.png"
                alt="Var Input"
                class="u-photo"
              />
              <span class="leftIcon">VarInput</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="steplock-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/steplock.png"
                alt="StepLock"
                class="u-photo"
              />
              <span class="leftIcon">StepLock</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="verifier-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/verifier.png"
                alt="Verifier"
                class="u-photo"
              />
              <span class="leftIcon">Verifier</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="caution-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/caution.png"
                alt="Caution"
                class="u-photo"
              />
              <span class="leftIcon">Caution</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="crossref-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img
                src="/img/pptx/widgets/crossref.png"
                alt="crossref"
                class="u-photo"
              />
              <span class="leftIcon">CrossRef</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="iot-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/widgets/range.png" alt="range" class="u-photo" />
              <span class="leftIcon">IoT</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="mcq-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/widgets/crossref.png" alt="mcq" class="u-photo" />
              <span class="leftIcon">Mcq</span>
            </div>
            <div
              class="contact ck-overlay"
              data-contact="0"
              data-name="time"
              data-class-name="skill-input"
              draggable="true"
              tabindex="0"
              @dragstart="startDrag($event)"
            >
              <img src="/img/pptx/widgets/trending.png" alt="skill" class="u-photo" />
              <span class="leftIcon">Skill</span>
            </div>
          </div>
        </div>
        <div class="col editorNewPdf">
          <div class="card shadowPdf d-flex">
            <div class="card-body">
              <div v-if="pdfTemp.title">
                <textarea
                  :id="getId()"
                  class="form-control-alternative textarea"
                  v-model="pdfTemp.title"
                  style="width: 100%; height: 35px"
                ></textarea>
              </div>
              <div
                v-else-if="pdfTemp.title"
                v-html="pdfTemp.title"
                placeholder="Enter title here..."
                class="form-control-alternative textarea"
              ></div>
              <div v-if="!pdfTemp.title">
                <textarea
                  :id="getId()"
                  class="form-control-alternative textarea"
                  style="width: 100%; height: 35px"
                ></textarea>
                <base-button
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addTitle($event)"
                  >Add Title</base-button
                >
              </div>
              <div class="section-body">
                <div
                  v-for="(section, sec_index) in pdfTemp.sections"
                  :key="section.uid"
                >
                  <div
                    :id="section.uid"
                    :data-secid="section.uid"
                    class="step-body"
                  >
                    <div class="section">
                      <!-- style="margin: 7px" -->
                      <label class="form-control-label" style="display: none">{{
                        "Page" + (sec_index + 1) + " : "
                      }}</label>
                      <div class="row">
                      <div id="drag" class="col" style="display: flex">
                      <div
                        :id="section.uid"
                        class="form-control-alternative textarea pdf-step pdf-preview"
                        @blur="onFocushandle(section, $event)"
                        v-if="section.pdfupload"
                      >
                      
                        <div id="upload-pdf">
                          <input
                            type="file"
                            style="margin-bottom: 10px"
                            ref="file"
                            name="pdf-file-to-upload"
                            accept="application/pdf,application/vnd.ms-excel"
                            v-on:change="handleChange()"
                          />
                          <button
                            class="btn float-right btn-default btn-sm m-2"
                            @click="uploadPdf($event)"
                          >
                            Upload
                          </button>
                        </div>
                          <div
                            v-for="(meta, meta_index) in section.meta"
                            :key="meta_index.uid"
                          >                            
                            <div class="app-header"
                              v-if="meta && meta.type == 'pdf'"
                              >
                              <span v-if="meta.showAllPages">
                                {{ meta.pageCount }} page(s)
                              </span>
                              <!-- <span v-else>
                                <button :disabled="meta.page <= 1" @click="pageDecrementing(section, sec_index, meta)">
                                  ❮
                                </button>
                                {{ meta.page }} /
                                {{ meta.pageCount ? meta.pageCount : pageCount }}
                                <button
                                  :disabled="meta.page >= meta.pageCount"
                                  @click="pageIncrementing(section, sec_index, meta)"
                                >
                                  ❯
                                </button>
                              </span> -->
                              <label class="right">
                                <!-- <input v-model="meta.showAllPages" type="checkbox" @click="showAllPages(section, sec_index, meta, meta_index)"/> -->
                                Show all pages
                              </label>
                            </div>
                            <div class="app-content">
                                <vue-pdf-embed
                                  v-if="meta && meta.type == 'pdf'"
                                  ref="pdfRef"
                                  :source="meta.pdfPath"
                                  :page="meta.page"
                                  @password-requested="handlePasswordRequest"
                                  @rendered="handleDocumentRender(section, sec_index, meta, meta_index)"
                                />
                            </div>
                        <!-- style="height: 524px; width: 335px; margin-top: 110px; border: 1px solid #00000026;" -->
                        </div>
                      </div>
                      </div>
                      <div id="data" class="col" style="display: flex"
                            v-for="(meta, meta_index) in section.meta"
                            :key="meta_index.uid"
                          >
                          <div v-if="meta.pdfPath && !meta.addblankpage">
                              <button
                                class="btn float-right btn-default btn-sm"
                                @click="addpage(section, sec_index, meta, meta_index)"
                              >
                                Add DigitalPage
                              </button>
                          </div>
                        <div
                            :id="section.uid"
                            class="form-control-alternative textarea pdf-step pdf-drag"
                            @blur="onFocushandle(section, $event)"
                            @drop="drop($event)"
                            @dragover="dragOver"
                            v-if="meta.addblankpage">
                            <div
                            v-for="(metadata, metadata_index) in meta.metadata"
                            :key="metadata_index.uid"
                            >
                            <div class="drag-controls" @dblclick="dblClickTable(section.uid, meta, metadata, $event)" 
                              v-if="metadata && metadata.type == 'table'">
                              <ag-grid-vue
                                style="height: 200px"
                                class="ag-theme-balham"
                                :columnDefs="metadata.columnDefs"
                                :rowData="metadata.rowData"
                                rowSelection="multiple"
                                rowHeight="50"
                                pagination="true"
                                paginationPageSize="10"
                                floatingFilter="true"
                              >
                              </ag-grid-vue>
                            </div>
                            <div class="drag-controls" @dblclick="dblClickTable(section.uid, meta, metadata, $event)" 
                              v-if="metadata && metadata.type == 'grid'">
                              <grid-layout
                              :layout.sync="layout"
                              :col-num="colNum"
                              :row-height="30"
                              :is-draggable="draggable"
                              :is-resizable="resizable"
                              :vertical-compact="true"
                              :use-css-transforms="true"
                            >
                              <grid-item v-for="item in metadata.grids"
                                  :static="item.static"
                                  :x="item.x"
                                  :y="item.y"
                                  :w="item.w"
                                  :h="item.h"
                                  :i="item.i"
                                  :key="item.i"
                              >
                                <textarea
                                  id="message"
                                  style="width: 90%"
                                  name="message"
                                  v-model="item.textarea"
                                  placeholder="Enter text here..."
                                ></textarea>
                              </grid-item>
                              </grid-layout>
                            </div>
                            <div 
                              class="drag-controls"
                              @dblclick="dblClickTextarea(section.uid, meta, metadata, $event)"
                              v-if="metadata && metadata.type == 'text'"
                            >
                              <textarea
                                id="message"
                                style="width: 100%"
                                name="message"
                                v-model="metadata.textarea"
                                placeholder="Enter text here..."
                              ></textarea>
                            </div>
                            <div class="drag-controls"
                              @dblclick="dblClickImage(section.uid, meta, metadata, $event)"
                              v-if="metadata && metadata.type == 'image'"
                            >
                              <img
                                :src="metadata.imagePath"
                                style="width: 200px"
                                class="img-fluid"
                              />
                            </div>
                            <div class="drag-controls"
                              @dblclick="dblClickVideo(section.uid, meta, metadata, $event)"
                              v-if="metadata && metadata.type == 'video'"
                            >
                              <video
                                width="320"
                                height="240"
                                controls
                              >
                                <source :src="metadata.videoPath" :type="videotype" />
                              </video>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'timer'">
                              <img style="width: 50px;" :src="metadata.description" alt="Timer" class="u-photo"/>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'formula'">
                              <p><a href="javascript:void(0)">Formula {{ metadata.formuladatalabel }} = {{ metadata.formula }}</a></p>
                            </div>
                            <div class="textdrag-controls" v-if="metadata && metadata.type == 'mediaImage'">
                              <img style="width: 50px;" :src="metadata.description" alt="mediaImage_" class="u-photo"/>Upload Image
                              <p>{{ metadata.uploadImage }}</p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'mediaVideo'">
                              <span><a href="javascript:void(0)">Upload Video: {{ metadata.description }}</a></span><br>
                              <span v-if="metadata.ResultText"><a href="javascript:void(0)">{{ metadata.ResultText }}</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'mediaQRcode'">
                              <img style="width: 50px;" :src="metadata.description" alt="qrImage_" class="u-photo"/>Upload QR Code
                              <span v-if="metadata.ResultText"><a href="javascript:void(0)">{{ metadata.ResultText }}</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'data-input'">
                              <span><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'dataNumberTranding' && metadata.datatrendfound && !metadata.acceptablerange">
                              <span><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}[DataTrend]</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'dataNumberTranding' && metadata.datatrendfound && metadata.acceptablerange">
                              <span><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}[DataTrend] [{{metadata.minvalue}} - {{metadata.maxvalue}}]</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'dataNumberTranding' && !metadata.datatrendfound && !metadata.acceptablerange">
                              <span><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'dataNumberTranding' && !metadata.datatrendfound && metadata.acceptablerange">
                              <span><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}[{{metadata.minvalue}} - {{metadata.maxvalue}}]</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'var-input'">
                              <p><a href="javascript:void(0)">[{{ metadata.variableName }}, {{metadata.value}}]</a></p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'iot-input'">
                              <p><a href="javascript:void(0)">{{ metadata.description }}</a></p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'mcq-input'">
                              <p><a href="javascript:void(0)">{{ metadata.description }}</a></p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'skill-input'">
                              <img style="width: 50px;" :src="metadata.description"  class="u-photo"/>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'steplock'">
                              <img style="width: 50px;" :src="metadata.description"  class="u-photo"/>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'verifierInput'">
                              <p><a href="javascript:void(0)">VERIFIER: {{ metadata.description }}</a></p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'cautionInput'">
                              <p><a href="javascript:void(0)">CAUTION: {{ metadata.description }}</a></p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'crossrefInput'">
                              <p><a href="javascript:void(0)">Cross Reference / Document Title = {{ metadata.description }}</a></p>
                            </div>
                            </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="pdfTemp.title && !pdfTemp.sections">
                <button
                  style="margin: 7px"
                  class="btn float-right btn-default btn-sm"
                  @click="adduploadpdf($event)"
                >
                  Add UploadPdf
                </button>
              </div>
              <!-- JSON.stringify{{pdfTemp}}; -->
            </div>
          </div>
        </div>
        <div class="col editRightParent">
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="isApprove()"
                @click="showLaunchBatchModal = true"
              >
                LAUNCH
              </button>
            </div>
            <div
              v-if="
                pdfTemp.status1 != 'new' && pdfTemp.status1 != 'batch'
              "
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-clone-modal"
                @click="showCloneModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                CLONE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="!isApprove() && !isLibrary()"
                @click="saveDocument()"
              >
                SAVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showShareModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                {{
                  pdfTemp.status1 &&
                  pdfTemp.status1.toLowerCase() == "shared"
                    ? "ADD COLLABRATOR"
                    : "SHARE"
                }}
              </button>
            </div>

            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--  <a href="#!" class="btn btn-info" v-if="isEditable()">CHECK</a> -->
            </div>
            <!-- v-if="!isClonedDocument" -->
            <div
              v-if="pdfTemp.isPdf && pdfTemp.status1 == 'draft'"
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showApproveModal = true"
                class="btn btn-info"
                v-if="isEditable()"
              >
                APPROVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!-- <a href="#!" class="btn btn-info" v-if="isEditable()">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Begin: Launch Batch Document -->
    <div v-if="showLaunchBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Launch Batch Document - '" +
                    stripHTML(pdfTemp.title) +
                    "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showLaunchBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Launch Batch Document</span>
                  <textarea
                    v-model="batchDocNumber"
                    placeholder="Enter batch #"
                    style="width: 100%"
                  ></textarea>
                  <button
                    v-if="this.pdfTemp.sections == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="launchBatch()"
                  >
                    Launch Batch
                  </button>
                  <button
                    v-if="this.pdfTemp.sections == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="setToDefault()"
                  >
                    Cancel
                  </button>

                  <div v-if="this.pdfTemp.sections" style="max-height: calc(100vh - 210px);">
                    <!-- <span>Set Variable values:</span> -->
                    <div v-for="(section,sec_index) in pdfTemp.sections" v-bind:key="section.uid">
                      <div v-for="(metadata, metadata_index) in section.meta[0].metadata" v-bind:key="metadata_index.uid">
                        <table v-if="metadata.type == 'var-input'">
                          <span>Set Variable values:</span>
                          <tr>
                          <td style="width:20%">Page: <label class="form-control-label" v-html="sec_index + 1"></label></td>
                          <td style="width:35%">Variable Name: <label class="form-control-label">{{metadata.variableName}}</label></td>
                          <td style="width:50%">Value: <input type="text" class="form-control-alternative" v-model="metadata.value" v-on:input="onValueChange(section,metadata,$event)" /></td>
                        </tr></table>
                      </div>
                    </div>
                    <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="launchBatch()"
                  >Launch Batch</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="setToDefault()"
                  >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Launch Batch Document -->

    <!-- Begin: Share Document -->
    <div v-if="showShareModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Share Document - '" + stripHTML(pdfTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showShareModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(pdfTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <!-- <span>Share Document to :</span> -->
                  <div class="tab">
                    <button
                      class="tablinks active"
                      @click="openShare('shareContent', $event)"
                    >
                      Share
                    </button>
                    <button
                      class="tablinks"
                      @click="openShare('libraryContent', $event)"
                    >
                      Library
                    </button>
                  </div>

                  <div
                    id="shareContent"
                    class="tabcontent"
                    style="display: block"
                  >
                    <h3>Send to Emails</h3>
                    <textarea
                      v-model="emailAddress"
                      placeholder="Email address (separated by ; )"
                      style="width: 100%"
                    ></textarea>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>

                  <div id="libraryContent" class="tabcontent">
                    <h3>Send to DigitalGxP Public Library</h3>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareLibraryDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Share Document -->
    <!-- Begin: Clone Document -->
    <div v-if="showCloneModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Clone Document - '" + stripHTML(pdfTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showCloneModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Clone Document</span>
                  <div class="custom-control targetTemplateType">
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="doument"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="document"
                    />Document
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="template"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="template"
                    />Template
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="library"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="library"
                    />Library
                  </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="handleCloneConfirmClick()"
                  >
                    Clone
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCloneModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Clone Document -->
    <!-- Begin: Comment-->
    <div v-if="showCommentModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog comment-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Add Comment for Step : '" + commentTitle + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCommentModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <!-- Comments display-->
                <div>
                  <div
                    class="sec-toggle comment-section"
                    @click="sectionToggleComment"
                  >
                    <label
                      class="form-control-label comment-header ctrl-collapse"
                      style="display: none; width: fit-content"
                      @click="sectionToggleComment"
                      >+ Comments</label
                    >
                    <label
                      class="form-control-label comment-header ctrl-expand"
                      style="width: fit-content"
                      @click="sectionToggleComment"
                      >- Comments</label
                    >
                  </div>
                  <div class="section-body">
                    <div
                      v-for="(comment, comment_index) in this.currentStep
                        .comments"
                      :key="comment.id || comment_index"
                      style="padding-left: 15px"
                      class="comment-text"
                    >
                      <span class="comment-user">{{ comment.createdBy }}</span>
                      {{
                        comment.description +
                        " - on " +
                        formatDate(comment.createdTime)
                      }}
                    </div>
                  </div>
                </div>

                <div class="modal-body">
                  <span>Comments</span>
                  <textarea
                    v-model="commentDescription"
                    placeholder="Enter your comment"
                    style="width: 100%"
                  ></textarea>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="addComment()"
                  >
                    Add Comment
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCommentModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Comment -->

    <!-- Begin: Approve Document -->
    <div v-if="showApproveModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Approve Document - '" + stripHTML(pdfTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showApproveModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(pdfTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Please confirm!</span>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="approveDocument()"
                  >
                    Approve
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showApproveModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Approve Document -->

    <!-- {{this.pdfTemp}} -->
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    @click="showModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 class="modal-title">Modal title</h4>
                </div>
                <div class="modal-body">modal body</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTableModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  Grid Title: Client Info
                  <button
                    type="button"
                    class="close"
                    @click="showTableModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <button class="btn btn-secondary" @click="addRows">
                      Add
                    </button>
                    <!-- <button class="btn btn-secondary" @click="replace">
                      Replace
                    </button> -->
                  </div>
                  <table
                    class="table"
                    cellspacing="2"
                    cellpadding="5"
                    id="data_table"
                    border="1"
                    style="margin-top: 10px"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">ColumnName</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="updateColumnDefs" tag="tbody">
                      <tr
                        v-for="(item, index) in updateColumnDefs"
                        :key="index"
                      >
                        <td scope="row">{{ index + 1 }}</td>
                        <td>
                          <span v-if="item.editmode">
                            <input type="text" v-model="item.columnName" />
                          </span>
                          <span v-else>{{ item.columnName }}</span>
                        </td>
                        <!-- <td>{{ item.type }}</td> -->
                        <td>
                          <span v-if="item.editmode">
                            <select v-model="item.type" required>
                              <option v-for="option in type" :key="option.id">
                                {{ option.val }}
                              </option>
                            </select>
                          </span>
                          <span v-else>{{ item.type }}</span>
                        </td>
                        <td>
                          <span v-if="!item.editmode"
                            ><button @click="edit(item)">Edit</button></span
                          >
                          <span v-else
                            ><button @click="save(item)">Save</button></span
                          >
                          <button @click="deleteRow(index)" class="delete">
                            Delete
                          </button>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="submitTable()"
                  >
                    Submit
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTable($event)"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTableModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  <div v-if="showGridModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  Grid Title: Client Info
                  <button
                    type="button"
                    class="close"
                    @click="showGridModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <button class="btn btn-secondary" @click="addItem">Add an item dynamically</button>
                    <input type="checkbox" v-model="draggable" /> Draggable
                    <input type="checkbox" v-model="resizable" /> Resizable
                  </div>
                  <grid-layout :layout.sync="layout"
                    :col-num="colNum"
                    :row-height="30"
                    :is-draggable="draggable"
                    :is-resizable="resizable"
                    :vertical-compact="true"
                    :use-css-transforms="true"
                  >
                  <grid-item v-for="item in layout"
                      :static="item.static"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :key="item.i"
                  >
                    <!-- <span class="text">{{item.i}}</span> -->
                    <span class="remove" @click="removeItem(item.i)">x</span>
                  </grid-item>
                  </grid-layout>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="submitGrid()"
                  >
                    Submit
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showGridModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTextModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Textarea Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTextModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the textarea
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextarea"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTextareaGridModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  TextareaGrid Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTextareaGridModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the textarea
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextareaGrid"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextareaGridModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showImageModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Image Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showImageModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="text"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Image:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="image/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <img
                        style="width: 100px; height: 100px; margin-bottom: 10px"
                        :src="filespath"
                        alt=""
                      />
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteImage()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showVideoModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Video Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showVideoModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="url"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Video:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="video/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <video width="320" height="240" controls>
                        <source :src="filespath" type="video/mp4" />
                      </video>
                      <!-- <iframe width="420" height="315"
                          :src="filespath">
                      </iframe> -->
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteVideo()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showDeleteModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Delete Confirmation
                  <button
                    type="button"
                    class="close"
                    @click="showDeleteModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    Please confirm the if you want to delete or cancel?
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTextarea($event)"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showDeleteModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTimerModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Timer Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTimerModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the Timer
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTimer($event)"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTimerModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- {{JSON.stringify(pdfTemp)}} -->
  </div>
</template>
<script>
/* eslint-disable no-console */
import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import draggable from "vuedraggable";
// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import pdfHeader from "./pdfHeader";
import { GridLayout, GridItem } from "vue-grid-layout";

export default {
  components: { pdfHeader, AgGridVue, draggable, VuePdfEmbed, GridLayout, GridItem },
  data() {
    return {
      layout: [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },],
      draggable: true,
      resizable: true,
      colNum: 12,
      index: 0,
      shareDocId: "",
      isClonedDocument: false,
      showModal: false,
      showTextareaGridModal: false,
      showShareModal: false,
      showCommentModal: false,
      showApproveModal: false,
      showLaunchBatchModal: false,
      pdfTemp: {},
      pageCount: 1,
      mySource: "",
      showCloneModal: false,
      showTableModal: false,
      showGridModal: false,
      showImageModal: false,
      showVideoModal: false,
      showTextModal: false,
      showDeleteModal: false,
      showTimerModal: false,
      setVariable: false,
      disable: true,
      dragItem: "",
      clickIndex: -1,
      targetTemplateType: "",
      isDelete: false,
      finaljson: {},
      finalarray: [],
      authCookie: null,
      editIds: [],
      batchDocNumber: "",
      emailAddress: "",
      currentStep: "",
      commentTitle: "",
      commentDescription: "",
      files: "",
      fileUrl: "",
      filespath: "",
      videopath: "",
      videotype: "",
      newComments: [],
      gridOptions: null,
      isPdf: "",
      updateColumnDefs: [],
      rowData: [],
      updateSecId: "",
      updateStepId: "",
      metadataId: "",
      type: [
        { id: "23", val: "Text" },
        { id: "13", val: "Number" },
        { id: "3", val: "DateTime" },
      ],
    };
  },
  beforeMount() {
    this.shareDocId = this.$route.query.shareDocId;
    this.isClonedDocument = this.$route.query.isClonedDocument;
    this.pdfTemp.docID = this.$route.query.docid;
    this.pdfTemp.status1 = this.$route.query.docStatusType;
    this.pdfTemp.isPdf = this.$route.query.isPdf;

    if (!this.$route.query.docid) {
      this.pdfTemp.industryItemType = this.$route.query.industryItemType;
      this.pdfTemp.industryItemID = this.$route.query.industryItemID;
    }
  },
  mounted() {
    console.log(
      " docid: " +
        this.pdfTemp.docID +
        " docStatusType: " +
        this.pdfTemp.status1 +
        " isPdf: " +
        this.pdfTemp.isPdf +
        " iscloned : " +
        this.isClonedDocument
    );
    if (!this.pdfTemp.docID && !this.shareDocId) {
      let sharedKey = this.$route.params.key;
      if (sharedKey) {
        this.shareDocId = sharedKey;
      }
    }
    if (this.pdfTemp.docID) {
      axios
        .get(
          "/api/pdf/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.pdfTemp.docID
        )
        .then((response) => {
          console.log(response);
          if (this.isClonedDocument) {
            let targetTemplateType = this.pdfTemp.isPdf;
            this.pdfTemp = {};
            this.pdfTemp.title = response.data.item.title;
            this.pdfTemp.sections = response.data.item.sections;
            this.pdfTemp.status1 = "new";
            this.pdfTemp.isPdf = targetTemplateType;
          } else {
            this.pdfTemp = response.data.item;
          }

          //console.log('Tru');
          var i = 0;
          var totalsec = this.pdfTemp.sections
            ? this.pdfTemp.sections
            : [];
          //var latestarray = [];
          for (i = 0; i < totalsec.length; i++) {
            var j = 0;
            var eachstep = totalsec[i].meta;
            for (j = 0; j < eachstep.length; j++) {
              //var v1 = eachstep[j].description.toString();
              var dom = document.createElement("div");
              dom.innerHTML = eachstep[j].description;
              var atag = dom.getElementsByTagName("a");
              if (atag != undefined && atag.length > 0) {
                var k;
                for (k = 0; k < atag.length; k++) {
                  if (
                    !(
                      atag[k].innerHTML.toString().indexOf("Formula = ", 0) > 0
                    ) &&
                    atag[k].innerHTML.toString().split(",")[1] != undefined
                  ) {
                    this.finaljson = {};
                    //console.log(atag[k].innerHTML);
                    this.finaljson.uid = uuidv4();
                    this.finaljson.sectionnum = i + 1 + "." + (j + 1);
                    this.finaljson.variablename = atag[k].innerHTML
                      .toString()
                      .split(",")[0]
                      .substring(
                        1,
                        atag[k].innerHTML.toString().split(",")[0].length
                      );
                    this.finaljson.defaultvalue = atag[k].innerHTML
                      .toString()
                      .split(",")[1]
                      .substring(
                        0,
                        atag[k].innerHTML.toString().split(",")[1].length - 1
                      );
                    this.finaljson.oldvalue = atag[k].innerHTML
                      .toString()
                      .split(",")[1]
                      .substring(
                        0,
                        atag[k].innerHTML.toString().split(",")[1].length - 1
                      );
                    this.finalarray.push(this.finaljson);
                    //this.finalarray = this.finalarray.concat(this.finalarray);
                    //console.log('i ' + i + ' j ' + j + ' k ' + k);
                    //console.log(this.finaljson);
                    //console.log('final array');
                    //console.log(this.finalarray);
                  }
                }
              }
              //this.finalarray.push(this.finaljson);
            }
            //this.finalarray.push(this.finaljson);
          }
          //this.finalarray.push(this.finaljson);

          //var v1 = this.pdfTemp.sections[0].steps[2].description.toString();
          //console.log('final array');
          //console.log(this.finalarray);
          //v2.innerHTML.toString());

          this.pdfTemp.title = this.pdfTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + this.pdfTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          this.pdfTemp = [];
        });
    } else if (this.shareDocId) {
      console.log(" Opening from Share id :" + this.shareDocId);
      var that = this;
      axios
        .get("/api/sharedDocuments?key=" + this.shareDocId)
        .then((response) => {
          that.pdfTemp = response.data.item;
          that.pdfTemp.docID = response.data.item.docID;
          that.pdfTemp.status1 = response.data.item.status1;

          that.pdfTemp.title = that.pdfTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + that.pdfTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          that.pdfTemp = [];
        });
    }
    this.index = this.layout.length;
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj) {
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }
  },
  methods: {
    // changeDivHeight() {
    //         var a = $("#drag").innerHeight();
    //         var b = $("#data").innerHeight();
    //         if( a > b ) {
    //             $("#drag").height( a );
    //         } else {
    //             $("#data").height( b );
    //         }
    //     },
    addItem: function () {
        // Add a new item. It must have a unique key!
        this.layout.push({
            x: (this.layout.length * 2) % (this.colNum || 12),
            y: this.layout.length + (this.colNum || 12), // puts it at the bottom
            w: 2,
            h: 2,
            i: this.index.toString(),
            uid: uuidv4(),
        });
        // Increment the counter to ensure key is always unique.
        this.index++;
    },
    removeItem: function (val) {
        const index = this.layout.map(item => item.i).indexOf(val);
        this.layout.splice(index, 1);
    },
    showGrid(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "grid",
              uid: uuidv4(),
            });
            this.$set(sec[0], 0);
            this.showGridModal = false;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "grid",
          uid: uuidv4(),
        });
        this.$set(sec[0], 0);
      }
      this.showGridModal = false;
    },
    submitGrid(e) {
      console.log(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata[j].uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "grid",
              uid: uuidv4(),
              grids: this.layout,
            });
            this.$set(sec[0], 0);
            this.showGridModal = false;
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "grid",
          uid: uuidv4(),
          grids: this.layout,
        });
        this.$set(sec[0], 0);
      }
      this.showGridModal = false;
      this.layout = [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },];
    },
    notifyUser(pdfTempNotification){
      console.log(pdfTempNotification);
      var notification = {
          objectId: pdfTempNotification.docID,
          type: "pdf",
          status: pdfTempNotification.status1,
          message:  " has " + pdfTempNotification.status1  + " the pdf document " + pdfTempNotification.title,
          // notifibatch:  " has " + " launched " + pdfTempNotification.status1  + " pdf  document " + pdfTempNotification.batchNumber + " for " + pdfTempNotification.title
      };
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    notifyUserBatch(pdfTempNotification){
      console.log(pdfTempNotification);
      var notification = {
          objectId: pdfTempNotification.docID,
          type: "pdf",
          status: pdfTempNotification.status1,
          message:  " has " + " launched " + pdfTempNotification.status1  + " pdf  document " + pdfTempNotification.batchNumber + " for " + pdfTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
    },
    handleChange() {
      this.files = event.target.files[0];
      console.log(this.files);
      //this.files = this.$refs.pdfFile.files[0];
    },
    uploadPdf(e) {
      console.dir(e);
      let app = this;
      var stepBody = event.target.closest('div[class^="step-body"]');
      app.updateSecId = stepBody.dataset.secid;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          app.mySource = "";
          var path = response.data.fileUrl;
          app.mySource = path.toString();
          var sec = app.pdfTemp.sections.filter((x) => x.uid == app.updateSecId);
          if (sec && sec[0]) {
            if (!sec[0].meta) {
              sec[0].meta = [];
            }
            app.$set(sec[0].meta, sec[0].meta.length, {
              pdfPath: app.mySource,
              type: "pdf",
              uid: uuidv4(),
            });
            app.$set(sec[0], 0);
          }
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
        // app.mySource = null;
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? "Enter password again" : "Enter password"));
    },
    handleDocumentRender(section, sec_index, meta, meta_Index) {
      document.getElementById("upload-pdf").style.display = "none";
      if(meta.showAllPages == null || meta.showAllPages) {
        for (var k = 0; k < this.$refs.pdfRef.length; k++){
            if(meta.pdfPath == this.$refs.pdfRef[k]._props.source){
              meta.pageCount = this.$refs.pdfRef[k].pageCount;
              meta.showAllPages = true;
              meta.page = null;
              // if(!meta.metadata) {
              //   meta.metadata = [];
              // }
              // this.$set(section, "pdfupload", false);
              this.$set(section, meta_Index, meta);
              this.$set(this.pdfTemp.sections, sec_index, section);
            }
        }
      }
      // this.changeDivHeight();
    },
    showAllPages(section, sec_index, meta, meta_Index) {
      meta.showAllPages = false;
      meta.page = 1;
      this.$set(section, meta_Index, meta);
      this.$set(this.pdfTemp.sections, sec_index, section);
    },
    pageIncrementing(section, sec_index, meta){
      meta.page++;
      
      this.$set(section, meta);
      this.$set(this.pdfTemp.sections, sec_index, section);
    },
    pageDecrementing(section, sec_index, meta){
      meta.page--;
      this.$set(section, meta);
      this.$set(this.pdfTemp.sections, sec_index, section);
    },
    onFocushandle(section, event) {
      var el =
        event.target.parentElement.parentElement.querySelector(".form-control");
      this.$set(section, "description", el.innerHTML);
    },
    onClickDragTable(stepId) {
      var element = document.getElementById("table-grid_" + stepId);
      element.style.display = "block";
    },
    deleteRow(index) {
      this.updateColumnDefs.splice(index, 1);
    },
    edit(obj) {
      this.$set(obj, "editmode", true);
      console.log("edit");
    },
    save(obj) {
      this.$set(obj, "editmode", false);
      console.log("save");
    },
    drop(e) {
      const dragControl = e.dataTransfer.getData("data-class-name");
      console.log(dragControl);
      // var stepBody = event.target.parentElement.parentElement.querySelector(".step-body");
      var stepBody = e.target.closest('div[class^="step-body"]');
      // var defaultDrag = document.getElementById("drag-area");
      // defaultDrag.style.display = "none";
      // console.dir(
      //   " stepBody.id " + stepBody.id + " secid " + stepBody.dataset.secid
      // );
      this.updateSecId = stepBody.dataset.secid;

      this.updateStepId = stepBody.id;

      if (dragControl == "table-input") {
        // this.dragItem = 'dragDrop';
        this.showTableModal = true;
        this.updateColumnDefs = [];
      } 
      if (dragControl == "grid-input") {
        this.showGridModal = true;
        this.layout = [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },];
      }
      else if (dragControl == "text-input") {
        this.showTextModal = true;
      } 
      else if (dragControl == "image-input") {
        this.showImageModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      } else if (dragControl == "video-input") {
        this.showVideoModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      } else if (dragControl == "time-input") {
        this.showTimerModal = true;
      } else if (dragControl == "formula-input") {
          this.showFormula();
      }else if (dragControl == "qrcode-input") {
          var pValQRCode = prompt("Please select type of Media Input: 1 For Image OR 2 For Video OR 3 For QR Code", "");
            if (!pValQRCode) return;
            if(pValQRCode == 1)
            {
                var pValImgName = prompt("Please Enter name of Image", "");
                if (!pValImgName) return; 
                this.showMediaImage();
            }
            else if(pValQRCode == 2)
            {
                // var pValVideoName = prompt("Please Enter name of Video", "");
                // if (!pValVideoName) return; 
                this.showMediaVideo();
            }
            else if(pValQRCode == 3)
            {
                var pValQRName = prompt("Please Enter name of QR Code", "");
                if (!pValQRName) return; 
                this.showQRVideo();
            }
      }else if (dragControl == "data-input") {
                var pValText = prompt("Please select type of Data Input: 1 For Text OR 2 For Numeric", "");
                if (!pValText) return;
                if(pValText == 1)
                {
                  this.showDatainput();
                }
                if(pValText == 2)
                {
                  this.showDataInputNumberTranding();
                  // this.showDataInputNumber();
                }
      }else if (dragControl == "var-input") {
        this.showVariable();
      }else if (dragControl == "steplock-input") {
        this.showSteplock(e);
      }
      else if (dragControl == "verifier-input") {
        this.showVerifierInput(e);
      }
      else if (dragControl == "caution-input") {
        this.showCautionInput(e);
      }
      else if (dragControl == "crossref-input") {
        this.showCrossrefInput(e);
      }
      else if (dragControl == "iot-input") {
        this.showIot();
      }
      else if (dragControl == "mcq-input") {
        this.showMcq();
      }
      else if (dragControl == "skill-input") {
        this.showSkillTrend();
      }
      event.preventDefault();
    },
    startDrag(event) {
      var contact = event.target.parentElement.getAttribute("data-class-name");
      event.dataTransfer.setData("data-class-name", contact);
      console.log(contact);
      // event.dataTransfer.getData("data-class-name")
    },
    dragenter() {
      document.getElementById("drag-area").style.backgroundColor = "#00800066";
    },
    dragOver(event) {
      event.preventDefault();
    },
    addRows() {
      let existingLength = this.updateColumnDefs.length;
      this.updateColumnDefs.push({
        columnName: "",
        id: ++existingLength,
        type: "",
        // order: 1
      });
    },
    dblClickTable(secid, meta, metadata, event) {
      console.dir(event);
      var stepBody = event.target.closest('div[class^="step-body"]');
      console.dir(
        " stepBody.id " + stepBody.id + " secid " + stepBody.dataset.secid
      );
      this.updateSecId = stepBody.dataset.secid;
      this.metadataId = metadata.uid;
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        this.updateColumnDefs = [];
        for (var i = 0; i < sec[0].meta[0].metadata.length; i++) {
          if(sec[0].meta[0].metadata[i].uid == this.metadataId){
          for (var j = 0; j < sec[0].meta[0].metadata[i].columnDefs.length; j++) {
          let colHeader = {};
          let obj = sec[0].meta[0].metadata[i].columnDefs[j];
          colHeader.columnName = obj.headerName;
          colHeader.type = obj.type;
          (colHeader.id = j + 1), this.updateColumnDefs.push(colHeader);
        }
          }
        }
      }
      this.isDelete = true;
      this.showTableModal = true;
    },
    submitTable(e) {
      console.log(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        // var step = sec[0].steps.filter((y) => y.uid == this.updateStepId);
        // if (step && step[0]) {
        let gridColumnDefs = [];
        for (var i = 0; i < this.updateColumnDefs.length; i++) {
          let colHeader = {};
          let obj = this.updateColumnDefs[i];
          colHeader.headerName = obj.columnName;
          colHeader.field = obj.columnName;
          colHeader.sortable = true;
          colHeader.filter = true;
          colHeader.editable = true;
          colHeader.type = obj.type;
          gridColumnDefs.push(colHeader);
        }
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata[j].uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              columnDefs: gridColumnDefs,
              rowData: this.rowData,
              type: "table",
              uid: uuidv4(),
            });
            this.$set(sec[0]);
            this.showTableModal = false;
            // this.metadataId = null;
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          columnDefs: gridColumnDefs,
          rowData: this.rowData,
          type: "table",
          uid: uuidv4(),
        });
        this.$set(sec[0]);
        // }
      }
      this.showTableModal = false;
      // this.updateSecId = null;
      // this.updateStepId = null;
      this.updateColumnDefs = [];
      // this.metadataId = null;
    },
    showTextarea() {
      // console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "text",
              uid: uuidv4(),
            });
            this.$set(sec[0]);
            this.showTextModal = false;
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "text",
          uid: uuidv4(),
        });
        this.$set(sec[0]);
      }

        // this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
        //   type: "text",
        //   uid: uuidv4(),
        // });
        // this.$set(sec[0]);
      
      this.showTextModal = false;
    },
    showTextareaGrid(e) {
      console.dir(e);
      for(var i = 0; i < this.notepadTemp.sections.length; i++){
        for(var j = 0; j < this.notepadTemp.sections[i].meta.length; j++){
            var sec = this.notepadTemp.sections[i].meta[j].grids.filter(
            (x) => x.uid == this.updategridId
            );
            if (sec && sec[0]) {
              for(var k = 0; k < this.notepadTemp.sections[i].meta[j].grids.length; k++){
                if (!this.notepadTemp.sections[i].meta[j].grids[k].metadata) {
                this.notepadTemp.sections[i].meta[j].grids[k].metadata = [];
                }
              for (var l = 0; l < this.notepadTemp.sections[i].meta[j].grids[k].metadata.length; l++) {
                if (this.notepadTemp.sections[i].meta[j].grids[k].metadata[l] == this.metaId) {
                this.$set(this.notepadTemp.sections[i].meta[i].grids[j].metadata, l, {
                  type: "text",
                  uid: uuidv4(),
                });
                this.$set(this.notepadTemp.sections[i].meta[j].grids[k], 0);
                this.showTextareaGridModal = false;
                return;
                }
              }
              this.$set(this.notepadTemp.sections[i].meta[j].grids[k].metadata, this.notepadTemp.sections[i].meta[j].grids[k].metadata.length, {
                type: "text",
                uid: uuidv4(),
              });
			this.$set(this.notepadTemp.sections[i].meta[j].grids[k], 0);
			}
      }
      this.showTextareaGridModal = false;
      }
      }
    },
    showIot() {
      var pValIot = prompt("Please select type of IoT Input: 1 For Data OR 2 For Video OR 3 For Audio", "");
        if (!pValIot) return;
        var iotValue = 'IOT:' +pValIot;
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "iot-input",
              uid: uuidv4(),
              description: iotValue,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "iot-input",
          uid: uuidv4(),
          description: iotValue,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showMcq() {
      var options = [];
      var finalOptions = null;
      var pValOption = prompt("Enter a multiple options separated by commas ( , ) :", "");
      if (!pValOption) return;
      let optionNumbers = pValOption.split(",");
      var pValOptionExit = prompt("Enter = operator for Exit", "");
      if (!pValOptionExit) return;
      if(pValOptionExit == "="){
          finalOptions = optionNumbers;
      }
      else{
          finalOptions = optionNumbers + " " + pValOptionExit;
      }
      var res = '  ';
      for (let i = 0; i < finalOptions.length; i++) {
          options.push( i+1  +  "."  + "  " +  finalOptions[i] + res );
          options.join('');
      }
      var mcqData = 'Options = ' + options.join(" , ");
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "mcq-input",
              uid: uuidv4(),
              mcq: true,
              description: mcqData,
              value: "",
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "mcq-input",
          uid: uuidv4(),
          mcq: true,
          description: mcqData,
          value: "",
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showSkillTrend() {
      var skillDesc = "/img/pptx/widgets/trending.png";
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "skill-input",
              uid: uuidv4(),
              skillfound: true,
              description: skillDesc,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "skill-input",
          uid: uuidv4(),
          skillfound: true,
          description: skillDesc,
          "skills" : [{
                uid: uuidv4(),
                executedUsers: [],
                verifiedUsers: [],
                batchno: '',
                }],   
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    dblClickTextarea(secid, meta, metadata, event) {
      console.dir(event);
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.metadataId = metadata.uid;
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        this.showDeleteModal = true;
      }
    },
    deleteTable(event) {
      console.dir(event);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        for (var k = 0; k < sec[0].meta[0].metadata.length; k++){
          if (sec[0].meta[0].metadata[k].uid == this.metadataId) {
            sec[0].meta[0].metadata.splice(k, 1);
          }
        }
      }
      this.showTableModal = false;
    },
    dblClickImage(secid, meta, metadata, event) {
      console.dir(event);
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.metadataId = metadata.uid;
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        this.isDelete = true;
        this.showImageModal = true;
      }
    },
    deleteTextarea(event) {
      console.dir(event);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        for (var k = 0; k < sec[0].meta[0].metadata.length; k++){
          if (sec[0].meta[0].metadata[k].uid == this.metadataId) {
            sec[0].meta[0].metadata.splice(k, 1);
          }
        }
      }
      this.showDeleteModal = false;
    },
    deleteImage(event) {
      console.dir(event);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        for (var k = 0; k < sec[0].meta[0].metadata.length; k++){
          if (sec[0].meta[0].metadata[k].uid == this.metadataId) {
            sec[0].meta[0].metadata.splice(k, 1);
          }
        }
      }
      this.isDelete = true;
      this.showImageModal = false;
    },
    dblClickVideo(secid, meta, metadata, event) {
      console.dir(event);
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.metadataId = metadata.uid;
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        this.isDelete = true;
        this.showVideoModal = true;
      }
    },
    deleteVideo(event) {
      console.dir(event);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0] && sec[0].meta && sec[0].meta[0].metadata) {
        for (var k = 0; k < sec[0].meta[0].metadata.length; k++){
          if (sec[0].meta[0].metadata[k].uid == this.metadataId) {
            sec[0].meta[0].metadata.splice(k, 1);
          }
        }
      }
      this.showVideoModal = false;
    },
    showTimer(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var test = "/img/pptx/timer.png";
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata[j].uid == this.metadataId) {
            this.$set(sec[0].meta, j, {
              type: "timer",
              uid: uuidv4(),
              timer: true,
              description: test,
            });
            this.$set(sec[0], 0);
            this.showTimerModal = false;
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "timer",
          uid: uuidv4(),
          timer: true,
          description: test,
        });
        this.$set(sec[0], 0);
        // }
      }
      this.showTimerModal = false;
    },
    showFormula(){
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var finalString; 
        var pVal1Formula = prompt("For a blank value in the formula to be entered during a batch document, enter {name}. For a constant value, enter the numeric value e.g. 9.5", "");
        if (!pVal1Formula) return;
        var pValFormulaop1 = prompt("Enter Operator for Folmula (+ , - , * , /)", "");
        if (!pValFormulaop1) return;

        var pVal2Formula = prompt("Formula: " + pVal1Formula + " " + pValFormulaop1  + " Enter Value {name} or constant number", "");
        if (!pVal2Formula) return;

        var pValFormulaop2 = prompt("Enter Operator for Folmula (+ , - , * , /) and = for Exit", "");
        if (!pValFormulaop2) return;

        var dataLabel = prompt("Add Data Label for Formula", "");
        if (!dataLabel) return;

        if(pValFormulaop2 == "="){
            finalString = pVal1Formula + " " + pValFormulaop1 + " " + pVal2Formula;
          }
          else{
            finalString = pVal1Formula + " " + pValFormulaop1 + " " + pVal2Formula+ " " + pValFormulaop2;
          }
          
          while(pValFormulaop2 != "=")
          {
            pVal2Formula = prompt("Formula: " +  finalString + " Enter Value {name} or constant number", "");

            finalString = finalString + " " + pVal2Formula;

            pValFormulaop2 = prompt("Enter Operator for Folmula (+ , - , * , /) and = for Exit", "");

            if(pValFormulaop2 != "="){
                finalString = finalString + " " + pValFormulaop2;
            }
          }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "formula",
              uid: uuidv4(),
              formula: finalString,
              formuladatalabel: dataLabel,
              formulafound: true,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "formula",
          uid: uuidv4(),
          formula: finalString,
          formuladatalabel: dataLabel,
          formulafound: true,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showMediaImage(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var mediaImagepath = "/img/pptx/image-old.png"
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "mediaImage",
              uid: uuidv4(),
              mediaimagefound: true,
              description: mediaImagepath,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "mediaImage",
          uid: uuidv4(),
          mediaimagefound: true,
          description: mediaImagepath,
        });
        // this.$set(sec[0], 0);
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showMediaVideo(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
      var pValVideoName = prompt("Please Enter name of Video", "");
      if (!pValVideoName) return; 
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "mediaVideo",
              uid: uuidv4(),
              videofound: true,
              description: pValVideoName,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "mediaVideo",
          uid: uuidv4(),
          videofound: true,
          description: pValVideoName,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showQRVideo(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var qrImagepath = "/img/pptx/qr-code.png"
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "mediaQRcode",
              uid: uuidv4(),
              qrimagefound: true,
              description: qrImagepath,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "mediaQRcode",
          uid: uuidv4(),
          qrimagefound: true,
          description: qrImagepath,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showDatainput(e){
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var pVarText= null;
      pVarText = prompt("Add Data Label for Text Input", "");
        if (!pVarText) return;
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "data-input",
              uid: uuidv4(),
              inputfound: true,
              description: (pVarText+":__________"),
            });
          this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "data-input",
          uid: uuidv4(),
          inputfound: true,
          description: pVarText,
          value: "__________",
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showDataInputNumberTranding(e){
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var pVarNumeric= null;
        pVarNumeric = prompt("Add Data Label for Numeric Input", "");
          if (!pVarNumeric) return;
        var pVarDataTreding = prompt("Do you want to Enable Data Tranding? 1 For Yes OR 2 For No", "");
          if (!pVarDataTreding) return;

        var  pvarRange =  prompt("Do you want to add Acceptable Data Range? 1 For Yes OR 2 For No", "");
          if (!pvarRange) return;
          if(pvarRange == 1) {
              var pValMinRange = prompt("Enter minimum value", "");
              if (!pValMinRange) return;

              var pValMaxRange = prompt("Enter maximum value", "");
              if (!pValMaxRange) return;
          }

        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "dataNumberTranding",
              uid: uuidv4(),
              description: pVarNumeric,
              inputfound: true,
              value: "___",
              datatrendfound: pVarDataTreding==1 ? true: false,
              acceptablerange: pvarRange==1 ? true: false,
              "dataTrandings" : [{
                uid: uuidv4(),
                varname: pVarNumeric,
                varvalue: 'NULL',
                batchno: '',}],              
            });
          }
        }
        if(pVarDataTreding ==1 && pvarRange == 1){
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          inputfound: true,
          value: "___",
          datatrendfound: true,
          acceptablerange: true,
          minvalue: pValMinRange,
          maxvalue: pValMaxRange,
          "dataTrandings" : [{
                uid: uuidv4(),
                varname: pVarNumeric,
                varvalue: 'NULL',
                batchno: '',}], 
        });
        }
        else if(pVarDataTreding ==1){
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          inputfound: true,
          value: "___",
          datatrendfound: true,
          "dataTrandings" : [{
                uid: uuidv4(),
                varname: pVarNumeric,
                varvalue: 'NULL',
                batchno: '',}], 
        });
        }
        else if(pVarDataTreding ==2 && pvarRange == 2){
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
        });
        }
        else if(pVarDataTreding == 2 && pvarRange == 1){
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
          acceptablerange: true,
          minvalue: pValMinRange,
          maxvalue: pValMaxRange,
        });
        }
      this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showDataInputNumber(e){
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var pVarNumeric= null;
        pVarNumeric = prompt("Please Enter Input Type Numeric Name", "");
          if (!pVarNumeric) return;
        var pVarDataTreding = prompt("Do you want to Enable Data Tranding? 1 For Yes OR 2 For No", "");
          if (!pVarDataTreding) return;
            if(pVarDataTreding == 2)
            {
              <a href="javascript:void(0)">'+ pVarNumeric + ':____</a>            
            }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "dataNumber",
              uid: uuidv4(),
              inputfound: true,
              description: pVarNumeric,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "dataNumber",
          uid: uuidv4(),
          inputfound: true,
          description: pVarNumeric,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showVariable(e){
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
      var pValText = prompt("Enter Variable Name followed by value in the next prompt", "");
        if (!pValText) return;
        var pValDefText = prompt("Enter Variable Default Value needed for a particular PAGE", "");
        if (!pValDefText) return;
        var variable = String("["+ pValText +","+ pValDefText +"]");
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "var-input",
              uid: uuidv4(),
              description: variable,
              variableName: pValText,
              value: pValDefText,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "var-input",
          uid: uuidv4(),
          description: variable,
          variableName: pValText,
          value: pValDefText,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showSteplock(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var test = "/img/pptx/widgets/steplock.png";
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "steplock",
              uid: uuidv4(),
              description: test,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "steplock",
          uid: uuidv4(),
          description: test,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showVerifierInput(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
      var pValText = prompt("Enter Verifier Name needed for a particular STEP", "");
          if (!pValText) return;
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "verifierInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "verifierInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showCautionInput(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
      var pValText = prompt("Enter Caution Name needed for a particular STEP", "");
          if (!pValText) return;
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "cautionInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "cautionInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    showCrossrefInput(e) {
      console.dir(e);
      var sec = this.pdfTemp.sections.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
      var pValText = prompt("Enter Cross Ref Document Title (copy/paste Document Title)", "");
      if (!pValText) return;

        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == this.metadataId) {
            this.$set(sec[0].meta[0].metadata, j, {
              type: "crossrefInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(this.pdfTemp.sections, 0, sec[0]);
            return;
          }
        }
        this.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          type: "crossrefInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(this.pdfTemp.sections, 0, sec[0]);
      }
    },
    handleFileUploads() {
      // this.fileUrl =  URL.createObjectURL(this.$refs.file.files[0]);
      this.files = this.$refs.file.files[0];
      console.log(this.files);
      this.disable = false;
    },
    uploadImage(e) {
      console.dir(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          app.filespath = "";
          var path = response.data.fileUrl;

          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage(e) {
      console.dir(e);
      let app = this;
      var sec = app.pdfTemp.sections.filter(
        (x) => x.uid == app.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == app.metadataId) {
            app.$set(sec[0].meta[0].metadata, j, {
              type: "image",
              uid: uuidv4(),
            });
            app.$set(sec[0]);
            app.showImageModal = false;
            return;
          }
        }
        app.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          imagePath: app.filespath,
          type: "image",
          uid: uuidv4(),
        });
        app.$set(sec[0]);
      }
      app.showImageModal = false;
    },
    previewVideo(e) {
      console.dir(e);
      let app = this;
      var sec = app.pdfTemp.sections.filter(
        (x) => x.uid == app.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata.uid == app.metadataId) {
            app.$set(sec[0].meta[0].metadata, j, {
              type: "video",
              uid: uuidv4(),
            });
            app.$set(sec[0]);
            app.showVideoModal = false;
            return;
          }
        }
        app.$set(sec[0].meta[0].metadata, sec[0].meta[0].metadata.length, {
          videoPath: app.filespath,
          type: "video",
          uid: uuidv4(),
        });
        app.$set(sec[0]);
      }
      app.showVideoModal = false;
    },
    isEditable() {
      // console.log(this.pdfTemp.status1);
      return (
        !this.pdfTemp.status1 ||
        ["approved", "library", "shared", "archived", "template"].indexOf(
          this.pdfTemp.status1.toLowerCase()
        ) < 0
      );
    },
    isApprove() {
      return (
        this.pdfTemp.status1 &&
        ["approved"].indexOf(this.pdfTemp.status1.toLowerCase()) >= 0
      );
    },
    isLibrary() {
      return (
        this.pdfTemp.status1 &&
        ["template"].indexOf(this.pdfTemp.status1.toLowerCase()) >= 0
      );
    },
    isShare() {
      return (
        !(this.authCookie && this.authCookie.userName) &&
        this.pdfTemp.status1 &&
        ["shared"].indexOf(this.pdfTemp.status1.toLowerCase()) >= 0
      );
    },

    capitalizeFirstLetter(strVal) {
      if (strVal) return strVal.charAt(0).toUpperCase() + strVal.slice(1);
      return "";
    },
    assignHTML() {
      setTimeout(function () {
        var u = document.getElementsByTagName("u");
        var i;
        if (u != undefined) {
          for (i = 0; i < u.length; i++) {
            if (u[i].getAttribute("id") == null) {
              var itag = u[i].getElementsByTagName("em");
              if (itag[0] != undefined && itag[0].innerText != undefined) {
                u[i].setAttribute(
                  "id",
                  itag[0].innerText.toString().split(",")[0]
                );
                u[i].setAttribute("class", "tooltip1");
                //console.log('try');
                //console.log(itag[0].innerText.toString().split(',')[4]);
                if (itag[0].innerText.toString().split(",")[4] != "") {
                  u[i].insertAdjacentHTML(
                    "beforeend",
                    '<span class="tooltiptextimg">Type: ' +
                      itag[0].innerText.toString().split(",")[1] +
                      "<br> Description: " +
                      itag[0].innerText.toString().split(",")[3] +
                      '<br> Image: <img style="width:30px; height:30px; margin-bottom: 10px;" src=' +
                      itag[0].innerText.toString().split(",")[4] +
                      "> </span>"
                  );
                } else {
                  u[i].insertAdjacentHTML(
                    "beforeend",
                    '<span class="tooltiptext">Type: ' +
                      itag[0].innerText.toString().split(",")[1] +
                      "<br> Description: " +
                      itag[0].innerText.toString().split(",")[3] +
                      "</span>"
                  );
                }
              }
            }
          }
        }
      }, 100);
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
    sectionToggle(e) {
      let parentElement = e.target.parentElement;
      var secBody = parentElement.querySelector(".section-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        // parentElement.querySelector(".ctrl-collapse").style.display = "none";
        // parentElement.querySelector(".ctrl-expand").style.display = "block";
      } else {
        secBody.style.display = "none";
        // parentElement.querySelector(".ctrl-collapse").style.display = "block";
        // parentElement.querySelector(".ctrl-expand").style.display = "none";
      }
    },
    async saveDocument() {
      let app = this;
      this.deleteAttr(this.pdfTemp);
      if (!this.pdfTemp.docID) {
        if (app.isClonedDocument) {
          app.pdfTemp.status1 = "draft";
        }
        try {
          const { data } = await axios.post("/api/pdf", app.pdfTemp);
          // .then(function (response) {
          console.log(data);
          app.pdfTemp = data.item;
          alert("pdf Created Successfully!");
          window.location =
            "/newPdf?docid=" +
            app.pdfTemp.docID +
            "&isPdf=" +
            app.pdfTemp.isPdf +
            "&industryItemType=" +
            app.pdfTemp.industryItemType +
            "&industryItemID=" +
            app.pdfTemp.industryItemID +'&docStatusType='+ app.pdfTemp.status1;
        } catch (error) {
          console.log(error);
        }
      } else {
        app.pdfTemp.updatedBy =
          app.pdfTemp.updatedBy || this.authCookie.userName;

        try {
          const { data } = await axios.put("/api/pdf", {
            pdf: app.pdfTemp,
            changes: { comment: this.newComments },
          });

          console.log(data);
          app.pdfTemp = data.item;
          // console.log("Document Saved Successfully!");
          alert("pdf Saved Successfully!");
          if(app.pdfTemp.status1 == 'approved') {
              app.notifyUser(app.pdfTemp);
            }
          window.location =
            "/newPdf?docid=" +
            app.pdfTemp.docID +
            "&isPdf=" +
            app.pdfTemp.isPdf +
            "&industryItemType=" +
            app.pdfTemp.industryItemType +
            "&industryItemID=" +
            app.pdfTemp.industryItemID;
        } catch (error) {
          console.log(error);
          app.pdfTemp = [];
        }
      }
    },
    handleCloneConfirmClick() {
      this.showCloneModal = false;
      window.location.href =
        "/artifact/clone?docid=" +
        this.pdfTemp.docID +
        "&isClonedDocument=true&isPdf=" +
        this.targetTemplateType +
        "&docStatusType=" +
        this.pdfTemp.status1 +
        "&industryItemType=" +
        this.pdfTemp.industryItemType +
        "&industryItemID=" +
        this.pdfTemp.industryItemID;
      console.log("cloneDoc");
    },
    getId() {
      return uuidv4();
    },
    addStepInner(stepArr, e) {
      this.addStep(stepArr, e);
    },
    addStep(sectionParent) {
      if (!sectionParent.steps) {
        this.$set(sectionParent, "steps", []);
      }
      var step = {};
      step.uid = uuidv4();
      step.description = "";
      step.dataTrandings = [];
      step.steps = [];
      step.meta = [];
      // step.meta[0] = "";
      this.$set(sectionParent.steps, sectionParent.steps.length, step);
    },
    adduploadpdf() {
    console.log("pdf");
      // this.pdfupload = true;
      if (!this.pdfTemp.sections) {
        this.$set(this.pdfTemp, "sections", []);
      }
      var sec = {};
      sec.uid = uuidv4();
      sec.description = "";
      sec.steps = [];
      sec.pdfupload = true;
      sec.meta = [];
      this.$set(
        this.pdfTemp.sections,
        this.pdfTemp.sections.length,
        sec
      );
      console.log(this.pdfTemp);
    },
    addpage(section, sec_index, meta) {
      meta.addblankpage = true;
      this.$set(section, meta);
      this.$set(this.pdfTemp.sections, sec_index, section);
    },
    addTitle(e) {
      var el = e.target.parentElement.querySelector(
        ".form-control-alternative"
      );
      console.log(this.pdfTemp);
      this.$set(this.pdfTemp, "title", el.value);
      this.$set(this.pdfTemp, "status1", "draft");
      this.$set(this.pdfTemp, "approvedBy", this.authCookie.userName);
      this.$set(this.pdfTemp, "userName", this.authCookie.userName);
      this.$set(this.pdfTemp, "orgID", this.authCookie.orgs[0].orgID);
      // if(this.authCookie.departments && this.authCookie.departments[0].departmentID){
      //   this.$set(this.pdfTemp, "departmentID", this.authCookie.departments[0].departmentID);
      // }
      
      this.$set(this.pdfTemp, "createdBy", this.authCookie.userName);
      this.$set(this.pdfTemp, "updatedBy", this.authCookie.userName);
      if (this.filespath != "") {
        this.$set(this.pdfTemp, "imagePath", this.filespath);
        console.log(this.filespath);
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    removeSpan(e) {
      //remove span
      var u = e.target.getElementsByTagName("u");
      var i;
      if (u != undefined && u.length > 0) {
        for (i = 0; i < u.length; i++) {
          if (u[i].getAttribute("id") != null) {
            var htmlspan1 = u[i].getElementsByTagName("span");
            if (htmlspan1.length > 0) {
              htmlspan1[0].remove();
            }
          }
        }
      }
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach((sec) => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach((step) => {
          this.deleteAttr(step);
        });
      }
    },
    setToDefault() {
      var i = 0;
      for (i = 0; i < this.finalarray.length; i++) {
        this.finalarray[i].defaultvalue = this.finalarray[i].oldvalue;
      }
      this.showLaunchBatchModal = false;
      //console.log(this.finalarray);
    },
    launchBatch() {
      let app = this;
      app.verify();
      app.showLaunchBatchModal = false;
      this.deleteAttr(this.pdfTemp);
      var batchDoc = JSON.parse(JSON.stringify(this.pdfTemp));
      //batchDoc.docID = null;
      batchDoc.isPdf = this.pdfTemp.isPdf;
      batchDoc.batchNumber = this.batchDocNumber;
      batchDoc.status1 = "batch";
      axios
        .post("/api/pdfBatch", batchDoc)
        .then(function (response) {
          console.log(response);
          app.showShareModal = false;
          alert("Batch started successfully!");
          if(batchDoc.status1 == 'batch') {
              app.notifyUserBatch(batchDoc);
            }
          app.setToDefault();
          window.location =
            "/pdf?docStatusType="+ batchDoc.status1 +
            "&isPdf=" +
            batchDoc.isPdf;
        })
        .catch(function (error) {
          console.log(error);
          app.pdfTemp = [];
        });
    },
    onValueChange(section,metadata,e){
      metadata.value = e.target.value;
      console.log(metadata.value);
      // this.$set(meta.value, e.target.value);
    },
    verify(){
      //console.log('verify');
      // console.log(this.finalarray);

      var i = 0;
      for(i=0;i<this.pdfTemp.sections.length;i++)
      {
        if(this.pdfTemp.sections[i].defaultvalue != this.pdfTemp.sections[i].oldvalue)
        {
          //console.log('modify');
          var first = this.pdfTemp.sections[i].sectionnum.toString().split('.')[0];
          var sec = this.pdfTemp.sections[i].sectionnum.toString().split('.')[1];
          var oldstr = '[' + this.pdfTemp.sections[i].variablename.toString() + ',' + this.pdfTemp.sections[i].oldvalue.toString() + ']';
          var newstr = this.pdfTemp.sections[i].variablename.toString() + ',' + this.pdfTemp.sections[i].defaultvalue.toString();
          //console.log('oldstr');
          //console.log(oldstr);
          //console.log('newstr');
          //console.log(newstr);
          console.log(this.pdfTemp.sections[first-1].meta[sec-1].description.toString().replace(oldstr,newstr));
          this.pdfTemp.sections[first-1].meta[sec-1].description = this.pdfTemp.sections[first-1].meta[sec-1].description.toString().replace(oldstr,newstr); 
        }

      }
      //this.pdfTemp.sections[i].steps[j].description

        
    },
    shareDocument() {
      let app = this;
      this.deleteAttr(this.pdfTemp);
      if (app.isShare()) {
        this.pdfTemp.emailShare = this.emailAddress;
        axios
          .put("/api/pdf", app.pdfTemp)
          .then(function (response) {
            console.log(response);
            app.pdfTemp = response.data.item;
            alert("pdf Shared Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.pdfTemp = [];
          });
      } else {
        var shareDoc = JSON.parse(JSON.stringify(this.pdfTemp));
        // shareDoc.docID = null;
        shareDoc.isPdf = this.pdfTemp.isPdf;
        shareDoc.parentDocID = this.pdfTemp.docID;
        shareDoc.status1 = "shared";
        shareDoc.emailShare = this.emailAddress;
        axios
          .post("/api/pdf", shareDoc)
          .then(function (response) {
            console.log(response);
            app.showShareModal = false;
            alert("Successfully shared document!");
            if(shareDoc.status1 == 'shared') {
              app.notifyUser(shareDoc);
            }
            window.location =
            "/pdf?docStatusType="+ shareDoc.status1 +
            "&isPdf=" +
            shareDoc.isPdf;
          })
          .catch(function (error) {
            console.log(error);
            app.pdfTemp = [];
          });
      }
    },
    shareLibraryDocument() {
      let app = this;
      this.deleteAttr(this.pdfTemp);
      var shareDoc = JSON.parse(JSON.stringify(this.pdfTemp));
      shareDoc.docID = null;
      shareDoc.parentDocID = this.pdfTemp.docID;
      shareDoc.status1 = "template";
      axios
        .post("/api/pdf", shareDoc)
        .then(function (response) {
          console.log(response);
          alert("Document successfully shared to DigitalGxP Public Library!");
        })
        .catch(function (error) {
          console.log(error);
          app.pdfTemp = [];
        })
        .then(function () {
          app.showShareModal = false;
        });
    },
    async approveDocument() {
      this.pdfTemp.approvedBy = this.authCookie.email;
      this.pdfTemp.status1 = "approved";
      await this.saveDocument();
      console.log("Document Approved Successfully!");
      this.showApproveModal = false;
      window.location =
        "/newPdf?docid=" +
        this.pdfTemp.docID +
        "&isPdf=" +
        this.pdfTemp.isPdf +
        "&industryItemType=" +
        this.pdfTemp.industryItemType +
        "&industryItemID=" +
        this.pdfTemp.industryItemID +'&docStatusType='+ this.pdfTemp.status1;
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    openShare(shareName, $event) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(shareName).style.display = "block";
      $event.target.className += " active";
    },
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;

.layoutJSON {

    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}


#editorControlListLeftPdfIcons {
  /* height: 550px; */
  overflow: scroll;
  width: 65px;
  height: calc(100vh - 200px);
}
.data-table {
  margin-bottom: 18px;
}
.drag-controls{
  padding-bottom: 10px;
}
.comment-dialog {
  max-width: 65%;
}
.share-modal {
  max-width: 50%;
}
.pdf-step:hover {
  background-color: #f8f5fa;
}
.form-control-alternative.textarea.pdf-step.pdf-drag {
    // display: block;
    // float: left;
    // width: 45%;
    width: 100%;
    // margin-left: 40px;
    border: 2px solid #8080804a;
    // margin-top: 37px;
    // margin-right: 10px;
}
.pdf-step {
    background-color: transparent;
    padding: 12px;
    min-height: 800px;
    // width: 100%;
    // margin-top: 30px;
    // margin-left: 10px;
}
.form-control-alternative.textarea.pdf-step.pdf-preview {
  // display: block;
  // float: left;
  // width: 50%;
  border: 2px solid #8080804a;
  // margin-top: 40px;
  // background: #d3d3d32e;
  }
#upload-pdf {
  display: flex;
  margin-bottom: 10px;
}
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 3px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555555b8;
  color: #ddd;
}

.app-content {
  padding: 10px 10px;
}
.right {
  float: right;
}
/* .pdf-step:hover ~ .step-action-btn-group,
.step-action-btn-group:hover
{
    display: block;
} */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
.comment-text {
  color: darkgray !important;
}
.comment-section {
  background-color: rgba(0, 0, 0, 0.02);
  padding-left: 10px;
}
.comment-header {
  margin-right: 15px;
  font-size: 13px;
  font-stretch: 100%;
  font-style: italic;
  font-weight: 500;
}
.comment-user {
  color: #385898;
}
div[data-oembed-url] > div > iframe {
  height: auto !important;
  width: auto !important;
}
div[data-oembed-url] > div {
  padding-bottom: 150px !important;
}
.editControlPdf {
  width: 50px;
  z-index: 1;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  /* height:290px; */
  /* height: calc(100% - 320px); */
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNewPdf {
  width: calc(100% - 320px);
  // margin-left: 110px;
  margin-right: 50px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
// .section-toggle-comment,
// {
//   // background-color: #0001;
//   color: #000;
//   // text-indent: 10px;
//   cursor: pointer;
//   width: 100%;
//   border: none;
//   text-align: left;
//   outline: none;
//   font-size: 15px;
// }
// .section-toggle-comment.sec-toggle.accordion:after,
// .sec-toggle.accordion:after {
//   content: "\002B";
//   color: white;
//   font-weight: bold;
//   float: right;
//   margin-left: 5px;
// }
.card.shadowPdf {
  //   border: 1px solid #dadce0 !important;
  padding: 6px;
  width: 87%;
  margin: auto;
}
.new-pdf {
  font-weight: 700;
  text-align: center;
  width: calc(100% - 110px);
  // top: -100px;
  // position: relative;
  // margin-left: 250px;
  // background-color: #ffff;
  // padding-left: 26px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // border-radius: 0.375rem;
}
.btn-step-action {
  background-color: inherit;
  border-color: transparent;
  color: inherit;
  z-index: 1001;
  top: -45px;
}
.step-action-btn-group {
  display: block;
}
.commentCount {
  position: absolute;
  top: 2px;
  left: 11px;
  color: white;
  font-size: 12px;
}
.btn-comment {
  width: 10px;
  padding-right: 22px;
  font-size: 0.85rem;
}
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
em {
  display: none;
}
.tooltip1 {
}
.tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -270px;
  margin-top: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltiptextimg {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -285px;
  margin-top: -107px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextimg::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip1:hover .tooltiptextimg {
  visibility: visible;
  opacity: 1;
}
.input-type-numeric {
  visibility: visible;
}
.input-type-text {
  visibility: visible;
}
.radio-buttons {
  margin: 10px 10px;
}
// .modal-dialog {
//   max-width: 560px;
// }
.modal-dialog.modal-table {
  max-width: 540px;
}
.custom {
  padding: 10px;
  border: 1px solid #aaaaaa;
}
button.delete {
  margin: 5px;
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  display: flex;
  align-items: center;
  overflow: auto;
}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.form {
  margin: 12px;
}
.form-control-alternative.textarea {
  border: 1px solid #333;
  color: #000;
}
.form-control-alternative.pdf-step {
  // border: 1px dashed #333;
  color: #000;
}
.form-control-alternative.textarea:focus {
  border-color: #000;
  color: #000;
}
.btn {
  border-radius: 20px;
  width: 130px;
}
.btn-default.disabled,
.btn-default:disabled {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  cursor: not-allowed;
}
#drag-area {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 270px;
}
.section-body{
  display: block;
  margin-top: 30px;
}
@media only screen and (max-width: 1024px) {
  #data{
    margin-top:10px;
  }
  .vue-pdf-embed {
    width: 63%;
    overflow: hidden;
}
.card.shadow4 {
    border: 1px solid #dadce0!important;
    padding: 6px;
    width: calc(100% - -155px);
}
.pdf-step {
    background-color: transparent;
    padding: 12px;
    min-height: 800px;
    width: 100%;
}
}

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
