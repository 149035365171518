<template>
    <div>
    <div class="row">
        <div class="col-md-6">
            <button type="button" class="btn btn-primary" style="margin-bottom: 20px;" @click="addProject()">
            Add Project
            </button>
            <form v-if="showForm">
                <div class="form-group row">
                    <label class="col-3 col-form-label">Description:</label>
                    <div class="col-9">
                        <input class="form-control" type="text" placeholder="description" v-model="projectTemp.description">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-3 col-form-label">Team Members:</label>
                    <div class="col-9">
                        <input class="form-control" type="email" placeholder="Email address (separated by ; )" v-model="projectTemp.teamMember">
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-3 col-form-label">Start Date</label>
                    <div class="col-9">
                        <input class="form-control" type="date" v-model="projectTemp.startDate">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-3 col-form-label">End Date:</label>
                    <div class="col-9">
                        <input class="form-control" type="date" v-model="projectTemp.endDate">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-3 col-form-label">Update Frequency:</label>
                    <div class="col-9">
                        <select class="form-control selectpicker" v-model="projectTemp.updateFrequency">
                            <option>weekly</option>
                            <option>bi-weekly</option>
                            <option>monthly</option>
                        </select>
                        <!-- <input class="form-control" type="text" placeholder="weekly, biweekly...monthly" v-model="projectTemp.updateFrequency"> -->
                    </div>
                </div>
                <div class="checkboxes row">
                    <label class="col-3 col-form-label">Tags:</label>
                            <!-- <ul>
                    <li v-for='(lang, index) in langsdata' :key="index">
                        <input type='checkbox' v-bind:value='lang' v-model='languages' @change='updateCheckall()'>{{ lang }}
                    </li>
                    </ul> -->
                <div class="col-9">
                    <input type="checkbox" id="lab" value="Lab" name="lab" v-model="checkedNames" class="checkbox">
                    <label for="lab">Lab</label>
                    <input type="checkbox" id="manufacturing" value="Manufacturing" name="manufacturing" v-model="checkedNames" class="checkbox">
                    <label for="manufacturing">Manufacturing</label>
                    <input type="checkbox" id="qualityControl" value="Quality Control" name="qualityControl" v-model="checkedNames" class="checkbox">
                    <label for="qualityControl">Quality Control</label>
                    <input type="checkbox" id="clinical" value="Clinical" name="clinical" v-model="checkedNames" class="checkbox">
                    <label for="clinical">Clinical</label>
                    <input type="checkbox" id="procurement" value="Procurement" name="procurement" v-model="checkedNames" class="checkbox">
                    <label for="procurement">Procurement</label>
                </div>
                </div>
                <button type="button" class="btn btn-primary" @click="addTitle($event)">Save</button>
                <button type="button" class="btn btn-primary" @click="backToList()">Cancel</button>
            </form>
            <div class="table-responsive" v-else>
                <base-table
                class="table align-items-center table-flush"
                
                tbody-classes="list"
                :data="tableData"
                >
                <template slot="columns">
                    <th>Description</th>
                    <th>Start date</th>
                    <th>Team</th>
                    <th>End date</th>
                </template>

                <template slot-scope="{row}">
                    <td @click="handleRowClick(row)">
                    <div class="avatar-group">
                        <span class="status">{{row.description}}</span>
                    </div>
                    </td>
                    <td>
                    <div class="avatar-group">
                        <span class="status">{{row.startDate}}</span>
                    </div>
                    </td>
                    <td>
                    <div class="avatar-group">
                        <span class="status">{{row.teamMember}}</span>
                    </div>
                    </td>
                    <td>
                    <div class="avatar-group">
                        <span class="status">{{row.endDate}}</span>
                    </div>
                    </td>
                </template>
                </base-table>
            </div>
        </div>
        <div class="col-md-6">
                <div class="tags" v-for="(checkedTag, index) in projectTags" :key="index">
                    <input type="checkbox" class="checkbox" :name="checkedTag.name" :checked="checkedTag.checked" 
                    @click="checkedTag.checked = !checkedTag.checked" @change="filterEventsByTags(checkedTag, checkedTag.checked)">
                    <label :for="checkedTag.name">{{ checkedTag.displayName }}</label>
                </div>
            <FullCalendar :options='calendarOptions' style="height: 800px;">
            </FullCalendar>
        </div>
    </div>
    </div>
</template>

<script>
import axios from "../../utils/axiosinstance";
// fullcalendar
import FullCalendar from "@fullcalendar/vue";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timelinePlugin from '@fullcalendar/timeline';

export default {
    components: { FullCalendar },
    props: ["projectOverviewid"],
    data(){
        return{
            projectTags: [
            {name: "qualityControl", displayName: "Quality Control", checked: true},
            {name: "lab", displayName: "Lab", checked: true},
            {name: "manufacturing", displayName: "Manufacturing", checked: true},
            {name: "clinical", displayName: "Clinical", checked: true},
            {name: "procurement", displayName: "Procurement", checked: true}
            ],
            checked: true,
            checkedNames: [],
            showTable: true,
            showForm: false,
            projectTemp: {},
            authCookie: null,
            projectOverviewID: "",
            tableData: [],
            teamMember: "",
            shareProjectId: "",
            calendarOptions: {
                plugins: [ resourceTimelinePlugin, dayGridPlugin, interactionPlugin, timelinePlugin ],
                initialView: 'resourceTimelineMonth',
                displayEventTime: false,
                editable: true,
                eventLimit: true,
                allDay: true,
                aspectRatio: 1.5,
                headerToolbar: {
                left: "prev,next,today",
                center: "title",
                right: "resourceTimelineMonth,resourceTimelineYear"
                },
                eventDidMount: function(info){
                    console.log(info);
                    if(info.event.extendedProps.labtype == "Manufacturing"){
                        info.el.style = 'border-color: #99FF99';
                    }
                    else if(info.event.extendedProps.labtype == "Lab"){
                        info.el.style = 'border-color: #415eec';
                    }
                    else if(info.event.extendedProps.labtype == "Clinical"){
                        info.el.style = 'border-color: #800080';
                    }
                    else if(info.event.extendedProps.labtype == "Procurement"){
                        info.el.style = 'border-color: #008000';
                    }
                    else if(info.event.extendedProps.labtype == "Quality Control"){
                        info.el.style = 'border-color: #ff0000';
                    }
                    // return filter(info);
                    // var checkboxContainer = "<div class='checkboxContainer'><label for='normal'>Normal</label><input type='checkbox' id='normal' checked><label for='normal'>Event</label><input type='checkbox' id='event' checked><label for='normal'>Whisper</label><input type='checkbox' id='whisper' checked></div>";
                    // document.getElementsByClassName('fc-toolbar').after(checkboxContainer);
                    // document.getElementsByClassName('fc-next-button').addEventListener('click', function() {
                    //         this.calendarOptions.prev();
                    //         console.log("calendarOptions");
                    //     });
                    if(info.view.type == "resourceTimelineMonth"){
                        const diffTime = Math.abs(new Date(info.event.end) - new Date(info.event.start));
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        var circleCount = 0; 
                        if(info.event.extendedProps.frequency === 'weekly'){
                            circleCount = Math.floor(diffDays/7);
                        }
                        else if(info.event.extendedProps.frequency === 'bi-weekly'){
                            circleCount = Math.floor(diffDays/14);
                        }
                        else if(info.event.extendedProps.frequency === 'monthly'){
                            circleCount = Math.floor(diffDays/30);
                        }
                        const parentDiv = document.createElement('div');
                        parentDiv.classList.add("parent-div");
                        let startDateWithTime = new Date(info.event.start);
                        let startDate = startDateWithTime.toISOString().split('T')[0];
                        let startDateElementColumn = document.querySelectorAll('[data-date="'+ startDate +'"]');
                        let startDateElementColumnLeft = 0;
                        if(startDateElementColumn && startDateElementColumn.length > 1) {
                            startDateElementColumnLeft = startDateElementColumn[1].offsetLeft;
                        }
                        for(var i = 0; i < circleCount; i++) {
                            const circleDiv = document.createElement('div');
                            circleDiv.classList.add("circle");
                            if(info.event.extendedProps.frequency === 'weekly'){
                                startDateWithTime.setDate(startDateWithTime.getDate() + 7);
                            }
                            else if(info.event.extendedProps.frequency === 'bi-weekly'){
                                startDateWithTime.setDate(startDateWithTime.getDate() + 14);
                            }
                            else if(info.event.extendedProps.frequency === 'monthly'){
                                startDateWithTime.setDate(startDateWithTime.getDate() + 30);
                            }
                            let circleAddDate = startDateWithTime.toISOString().split('T')[0];
                            circleDiv.setAttribute("id", info.event.id+ "_" +circleAddDate);
                            circleDiv.innerHTML = i + 1;
                            let circleAddDateElementColumn = document.querySelectorAll('[data-date="'+ circleAddDate +'"]');
                            
                            if (circleAddDateElementColumn && circleAddDateElementColumn.length > 1) {
                                let circleAddDateElementColumnLeft = circleAddDateElementColumn[1].offsetLeft;
                                let circlespace = circleAddDateElementColumnLeft - startDateElementColumnLeft;
                                circleDiv.setAttribute("style", "left: "+ circlespace + "px");
                            }
                            var date = new Date();
                            var todayDate = date.toISOString().split('T')[0];
                            // var resultt = new Date(info.event.start);
                            // var startDate = resultt.toISOString().split('T')[0];
                            if( circleAddDate === todayDate) {
                            const i = document.createElement('i');
                            i.classList.add('fa', 'fa-bell', 'bellcolor', 'dotted');
                            i.onclick = function(){
                                let text = prompt("Please enter text", "");
                                if (text != null) {
                                i.classList.remove('fa', 'fa-bell', 'bellcolor');
                                const tooltipDiv = document.createElement('div');
                                tooltipDiv.classList.add("tooltip");
                                tooltipDiv.innerHTML = 'Event: ' + text
                                circleDiv.appendChild(tooltipDiv);
                                }
                            }
                            circleDiv.appendChild(i);
                            // tooltipDiv.innerHTML = 'Event: ' + info.event.title
                            }
                            parentDiv.appendChild(circleDiv);
                        }
                        info.el.appendChild(parentDiv);
                    }
                },
                events: [],
                resourceLabelText: "Rooms",
                resources: [],
                views: {
                resourceTimelineMonth: {
                    type: 'resourceTimeline',
                    slotDuration: { days: 1 },
                    slotLabelFormat: [{ day: "2-digit" }],
                    duration: { months: 1 }
                },
                resourceTimelineYear: {
                    type: 'resourceTimeline',
                    slotDuration: { months: 1 },
                    slotLabelFormat: [{ month: "short" }],
                    duration: { years: 1 }
                }
                },
            },
        };
    },
    // beforeMount() {
    //     },
    mounted(){
        console.log(this.calendarOptions);
        this.loadCalenderEvents();
        this.projectList(); 
        if (!this.projectOverviewid && !this.shareProjectId) {
            let sharedKey = this.$route.params.key;
            if (sharedKey){
            this.shareProjectId = sharedKey;
            }
        }
        if (this.projectOverviewid) {
        axios
        .get(
        "/api/projectOverview/organizations/" +
            this.authCookie.orgs[0].orgID +
            this.projectOverviewid
        )
        .then(response => {
        console.log(response);
        this.showTable = false;
        this.showForm = true;
        this.projectTemp = response.data.item;
        this.projectTemp.description = this.projectTemp.description.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');
          //load OGP.ME
        const descriptionEl = document.querySelector('head title');
        descriptionEl.textContent = "Digital GxP - " + this.projectTemp.description;
        })
        .catch(reason => {
        console.log(reason);
        this.projectTemp = [];
        });
        } else if(this.shareProjectId) {
        console.log(" Opening from Share id :" + this.shareProjectId);
        var that = this;
        axios
        .get("/api/sharedProjects?key=" + this.shareProjectId)
        .then(response => {
            that.showTable = false;
            that.showForm = true;
            that.projectTemp = response.data.item;
            that.checkedNames = that.projectTemp.labType;
            // that.projectOverviewID = response.data.item.projectOverviewID;
            // this.projectTemp.description = this.projectTemp.description.replace(/(<([^>]+)>)/ig, '').replace(/[\r\n]/g, '');

          //load OGP.ME
            // const descriptionEl = document.querySelector('head description');
            // descriptionEl.textContent = "Digital GxP - " + that.projectTemp.description;
        })
        .catch(reason => {
            console.log(reason);
            that.projectTemp = [];
        });
    }
    },
    created() {
    let authCookieObj = this.getCookie("auth-token");
    let authObj;
    if (authCookieObj.startsWith("j:")) {
    authObj = JSON.parse(
        decodeURIComponent(
        this.getCookie("auth-token")
            .substring(2)
            .split(";")[0]
        )
    );
    } else {
    authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
    },
    methods: { 
    filterEventsByTags(){
     // var checkboxes = document.querySelectorAll('input[type="checkbox"]:checked');
        let checkedTags = this.projectTags.filter((tag) => tag.checked).map((tag) => tag.displayName);
        console.log("checkedTags: "+ checkedTags)
        var projectList = "/api/projectOverview/organizations/" + this.authCookie.orgs[0].orgID + "?labType=" + checkedTags;
        axios
        .get(projectList)
        .then(response => {
            console.log(response);
            // this.tableData = response.data.items;
            let resources = [];
            let events = [];
            for( var i=0; i < response.data.items.length; i++){
                const title = response.data.items[i].description;
                const id = response.data.items[i].projectOverviewID;
                let resource = {
                    "title": title, 
                    "id": id
                }
                let event = {
                    "id": id,
                    "title": title, 
                    "resourceId": id,
                    "start": new Date(response.data.items[i].startDate), 
                    "end": new Date(response.data.items[i].endDate),
                    "extendedProps": {
                        "frequency" : response.data.items[i].updateFrequency,
                        "labtype" : response.data.items[i].labType,
                    }
                }
                resources.push(resource);
                events.push(event);
            }
            this.calendarOptions.resources = resources;
            this.calendarOptions.events = events;
            })
            .catch(reason => {
                console.log(reason);
                // this.tableData = [];
            });
    },
    promptFunction(){
        let person = prompt("Please enter text", "");
        if (person != null) {
            document.getElementById("demo").innerHTML =
            "Hello " + person + "! How are you today?";
        }
    },
    // calendarOptionsSetting(){}
    loadCalenderEvents(){
        var projectList = "/api/projectOverview/organizations/" + this.authCookie.orgs[0].orgID;
        axios
        .get(projectList)
        .then(response => {
            console.log(response);
            this.tableData = response.data.items;
            let resources = [];
            let events = [];
            for( var i=0; i < response.data.items.length; i++){
                const title = response.data.items[i].description;
                const id = response.data.items[i].projectOverviewID;
                let resource = {
                    "title": title, 
                    "id": id
                }
                let event = {
                    "id": id,
                    "title": title, 
                    "resourceId": id,
                    "start": new Date(response.data.items[i].startDate), 
                    "end": new Date(response.data.items[i].endDate),
                    "extendedProps": {
                        "frequency" : response.data.items[i].updateFrequency,
                        "labtype" : response.data.items[i].labType,
                    }
                }
                resources.push(resource);
                events.push(event);
            }
            this.calendarOptions.resources = resources;
            this.calendarOptions.events = events;
        })
        .catch(reason => {
            console.log(reason);
            this.tableData = [];
        });
    },
    handleRowClick(item) {
        let app = this;
        app.showTable = false;
        app.showForm = true;
        app.projectTemp = item;
        app.checkedNames = app.projectTemp.labType;
    },
    projectList(){
    var projectList = "/api/projectOverview/organizations/" + this.authCookie.orgs[0].orgID;
        axios
        .get(projectList)
        .then(response => {
            console.log(response);
            this.tableData = response.data.items;
        })
        .catch(reason => {
            console.log(reason);
            this.tableData = [];
        });
    },
    notifyUser(projectTempNotification){
        console.log(projectTempNotification);
        var notification = {
            objectId: projectTempNotification.projectOverviewID,
            type: "projectOverview",
            message:  " has created new project " +  projectTempNotification.description,
        };
        this.$root.$emit('listenNotification', notification);
    },
    backToList(){
        window.location = "/dashboard";
    },
    addTitle(e) {
    console.log(e);
    this.$set(this.projectTemp, "createdBy", this.authCookie.userName);
    this.$set(this.projectTemp, "orgID", this.authCookie.orgs[0].orgID);
    this.$set(this.projectTemp, "approvedBy", this.authCookie.userName);
    this.$set(this.projectTemp, "createdBy", this.authCookie.userName);
    this.$set(this.projectTemp, "updatedBy", this.authCookie.userName);
    this.$set(this.projectTemp, "labType", this.checkedNames);
    if (this.projectOverviewID) {
        this.$set(this.projectTemp, "projectOverviewID", this.projectOverviewID);
    }
    else
    {
        this.saveProject();
    }
    },
    saveProject(){
        let app = this;
        this.projectTemp.emailShare = this.projectTemp.teamMember;
        if (!this.projectTemp.projectOverviewID) {
        axios
        .post("/api/projectOverview", app.projectTemp)
        .then(function(response) {
            console.log(response);
            app.projectTemp = response.data.item;
            app.notifyUser(app.projectTemp);
            window.location = "/dashboard";
        })
        .catch(function(error) {
            console.log(error);
            app.projectTemp = [];
        });
    }
    else {
        app.projectTemp.updatedBy =
        app.projectTemp.updatedBy || this.authCookie.userName;
        this.projectTemp.approvedBy = this.authCookie.email;
        axios
        .put("/api/projectOverview", app.projectTemp)
        // {
        //     projectOverview: app.projectTemp,
        // })
        .then(function(response) {
            app.projectTemp = response.data.item;
            window.location = "/dashboard";
        })
        .catch(function(error) {
            console.log(error);
            app.projectTemp = [];
        });
    }
    },
    addProject(){
        this.showTable = false;
        this.showForm = true;
    },
    getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
        return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
    );
    },
    },
}
</script>

<style>
table.fc-datagrid-header.fc-scrollgrid-sync-table {
    display: none !important;
}

table.fc-datagrid-body.fc-scrollgrid-sync-table {
    display: none;
}

.fc-resourceTimelineMonth-view.fc-view.fc-resource-timeline.fc-resource-timeline-flat.fc-timeline.fc-timeline-overlap-enabled {
    overflow-x: auto !important;
}

.fc .fc-scrollgrid, .fc .fc-scrollgrid table {
    table-layout: auto !important;
    width: 100% !important;
}

.fc-h-event {
    background-color: inherit;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 10px solid var(--fc-event-border-color);
    /* display: block; */
}
.fc-h-event .fc-event-main {
    color: #0000ffc2;
}
.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #000;
    border: 2px solid #000;
    color: #fff;
    text-align: center;
    line-height: 18px;
    /* margin-bottom: -16px; */
    margin-top: 6px;
    position: absolute;
}
.parent-div{
    display: flex;
    justify-content: space-between;
}
.fc-direction-ltr .fc-timeline-event:not(.fc-event-end)::after, .fc-direction-rtl .fc-timeline-event:not(.fc-event-start)::before {
    border-right: 0px;
    content: none !important;
}


.circle .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    font-size: 8px;
    /* bottom: 125%; */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.circle .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.circle:hover .tooltip {
    visibility: visible;
    opacity: 1;
}


/* .fc-scroller.fc-scroller-liquid-absolute {
    overflow: visible !important;
} */

.dotted{
    border-left: 2px dotted green;
    height: 350px;
}
.bellcolor{
    color: red;
}
.checkbox{
    margin: 10px;
}
.fc-license-message {
    display: none !important;
}
.tags{
    display: inline-block;
}
</style>
