<template>
  <div>
    <div
      v-if="
        industryItemType &&
        industryItemType != 'undefined' &&
        industryItemType != 'null'
      "
      class="title-heading"
    >
      {{ industryItemType + " " + templateType }}
    </div>
    <div v-else class="title-heading">
      {{ templateType }}
    </div>
    <div
      v-if="
        industryItemType != 'undefined' &&
        industryItemType &&
        industryItemType != 'null' &&
        industryItemType == 'equipment'
      "
      class="equipment-header"
    >
      <p class="equipment-toggle">
        <label class="form-control-label" style="margin: 7px"
          >Equipment Details :
        </label>
        <label class="form-control-label equipment"> ID : </label>
        <label class="form-control-label equipment">{{ equipmentTemp.equID }} </label>
        <label class="form-control-label equipment"> Title : </label>
        <label class="form-control-label equipment">{{ equipmentTemp.title }} </label>
        <label class="form-control-label equipment"> Description : </label>
        <label class="form-control-label equipment"
          >{{ equipmentTemp.description }}
        </label>
      </p>
    </div>
    <div
      v-if="
        industryItemType != 'undefined' &&
        industryItemType &&
        industryItemType != 'null' &&
        industryItemType == 'reagent'
      "
      class="equipment-header"
    >
      <p class="equipment-toggle">
        <label class="form-control-label" style="margin: 7px"
          >Reagent Details :
        </label>
        <label class="form-control-label equipment"> ID : </label>
        <label class="form-control-label">{{ reagentTemp.reagentID }} </label>
        <label class="form-control-label equipment"> title : </label>
        <label class="form-control-label">{{ reagentTemp.title }} </label>
        <label class="form-control-label equipment"> description : </label>
        <label class="form-control-label">{{ reagentTemp.description }} </label>
      </p>
    </div>
    <div
      v-if="
        industryItemType &&
        industryItemType != 'undefined' &&
        industryItemType != 'null' &&
        industryItemType == 'sampleManagement'
      "
      class="equipment-header"
    >
      <p class="equipment-toggle">
        <label class="form-control-label" style="margin: 7px"
          >SampleManagement Details :
        </label>
        <label class="form-control-label equipment"> ID : </label>
        <label class="form-control-label"
          >{{ sampleManagementTemp.sampleID }}
        </label>
        <label class="form-control-label equipment"> title : </label>
        <label class="form-control-label"
          >{{ sampleManagementTemp.title }}
        </label>
        <label class="form-control-label equipment"> description : </label>
        <label class="form-control-label"
          >{{ sampleManagementTemp.description }}
        </label>
      </p>
    </div>
    <div
      v-if="
        industryItemType != 'undefined' &&
        industryItemType &&
        industryItemType != 'null' &&
        industryItemType == 'deviation'
      "
      class="equipment-header"
    >
      <p class="equipment-toggle">
        <label class="form-control-label" style="margin: 7px"
          >Deviation Details :
        </label>
        <label class="form-control-label equipment"> ID : </label>
        <label class="form-control-label"
          >{{ deviationTemp.deviationID }}
        </label>
        <label class="form-control-label equipment"> title : </label>
        <label class="form-control-label">{{ deviationTemp.title }} </label>
        <label class="form-control-label equipment"> description : </label>
        <label class="form-control-label"
          >{{ deviationTemp.description }}
        </label>
      </p>
    </div>
    <div
      v-if="
        industryItemType != 'undefined' &&
        industryItemType &&
        industryItemType != 'null' &&
        industryItemType == 'capa'
      "
      class="equipment-header"
    >
      <p class="equipment-toggle">
        <label class="form-control-label" style="margin: 7px"
          >CAPA Details :
        </label>
        <label class="form-control-label equipment"> ID : </label>
        <label class="form-control-label">{{ capaTemp.capaID }} </label>
        <label class="form-control-label equipment"> title : </label>
        <label class="form-control-label">{{ capaTemp.title }} </label>
        <label class="form-control-label equipment"> description : </label>
        <label class="form-control-label">{{ capaTemp.description }} </label>
      </p>
    </div>
    <div
      v-if="
        industryItemType != 'undefined' &&
        industryItemType &&
        industryItemType != 'null' &&
        industryItemType == 'user'
      "
      class="equipment-header"
    >
      <p class="equipment-toggle">
        <label class="form-control-label" style="margin: 7px"
          >User Details :
        </label>
        <label class="form-control-label equipment"> ID : </label>
        <label class="form-control-label">{{ userTemp.userID }} </label>
        <label class="form-control-label equipment"> title : </label>
        <label class="form-control-label">{{ userTemp.title }} </label>
        <label class="form-control-label equipment"> description : </label>
        <label class="form-control-label">{{ userTemp.description }} </label>
      </p>
    </div>
    <base-header
      v-if="!isSharedPage"
      type="gradient-success"
      class="pb-6 pb-8 pt-5"
    >
      <div class="row">
        <div class="col-sm"></div>
        <div class="col-sm">
          <a
            :href="docStatusTypeHref('batch')"
            v-if="templateType == 'document' || templateType == 'live'"
            class="navbar-brand btn btn-info btn-green"
            ><i class="ni ni-user-run i-golive"></i><span>BATCH RUNS</span></a
          >
          <!-- <a href="/artifact/live/batch" v-if="templateType == 'document'" class="navbar-brand btn btn-info btn-green"><i class="ni ni-user-run i-golive"></i><span>BATCH RUNS</span></a> -->
        </div>
        <div class="col-sm">
          <a :href="onHeaderClick('new')" class="btn btn-info"> NEW </a>
        </div>
        <div class="col-sm">
          <a :href="onHeaderClick('draft')" class="btn btn-info">DRAFTS</a>
        </div>
        <div class="col-sm">
          <a :href="onHeaderClick('approved')" class="btn btn-info">APPROVED</a>
        </div>
        <div class="col-sm">
          <a :href="onHeaderClick('archived')" class="btn btn-info">ARCHIVED</a>
        </div>
        <div class="col-sm">
          <a
            :href="onHeaderClick('shared')"
            v-if="templateType == 'document' || templateType == 'live'"
            class="btn btn-info"
            >SHARED</a
          >
        </div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
      </div>
    </base-header>
  </div>
  <!-- <div v-else>
    <base-header v-if="!isSharedPage" type="gradient-success" class="pb-6 pb-8 pt-5">
      <div class="row">
        <div class="col-sm2"></div>
        <div class="col-sm1">
          <a  href="/artifact/live/batch"  v-if="templateType == 'document' || templateType == 'live'"  class="navbar-brand1 btnm btn-info btn-green"><i class="ni ni-user-run i-golive"></i><span> BATCH RUNS</span></a>
        </div>
        <div class="col-sm1">
            <a href="#" class="btnm btn-info custom-font" @onclick="onHeaderClick('new')">
            NEW
            
            </a>
        </div>
        <div class="col-sm1">
          <a href="#" class="btnm btn-info custom-font" @onclick="onHeaderClick('draft')">DRAFTS</a>
        </div>
        <div class="col-sm1">
          <a href="#" class="btnm btn-info custom-font" @onclick="onHeaderClick('approved')">APPROVED</a>
        </div>
        <div class="col-sm1">
          <a href="#" class="btnm btn-info custom-font" @onclick="onHeaderClick('archived')">ARCHIVED</a>
        </div>
      
        <div class="col-sm1">
          <a href="#" v-if="templateType == 'document' || templateType == 'live'" class="btnm btn-info custom-font" @onclick="onHeaderClick('shared')">SHARED</a>
        </div>
      </div>
    </base-header>
  </div> -->
</template>
<script>
import axios from "../../../utils/axiosinstance";

export default {
  name: "document-header",
  // props: ["docStatusType", "templateType","industryItemType"],
  data() {
    // console.log("template" + this.templateType)
    return {
      docStatusType: "",
      templateType: "",
      industryItemType: "",
      industryItemID: "",
      isSharedPage: false,
      equipmentTemp: {},
      sampleManagementTemp: {},
      deviationTemp: {},
      capaTemp: {},
      reagentTemp: {},
      userTemp: {},
      authObj: {},
    };
  },
  mounted() {
    // this.loadIndustryItemData();
    let authCookieObj = this.getCookie("auth-token");
    // let authObj;
    if (authCookieObj.startsWith("j:")) {
      this.authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token").substring(2).split(";")[0]
        )
      );
    } else {
      this.authObj = JSON.parse(
        decodeURIComponent(authCookieObj.split(";")[0])
      );
    }
    if (
      this.industryItemID &&
      this.industryItemID != "undefined" &&
      this.industryItemType &&
      this.industryItemType != "undefined"
    ) {
      if (this.industryItemType == "equipment") {
        axios
          .get(
            "/api/equipment/organizations/" +
              this.authObj.orgs[0].orgID +
              "/" +
              this.industryItemID +
              "?industryItemType=" +
              this.industryItemType
          )
          .then((response) => {
            console.log(response);
            this.equipmentTemp = response.data.item;
          });
      } else if (this.industryItemType == "reagent") {
        axios
          .get(
            "/api/reagent/organizations/" +
              this.authObj.orgs[0].orgID +
              "/" +
              this.industryItemID +
              "?industryItemType=" +
              this.industryItemType
          )
          .then((response) => {
            console.log(response);
            this.reagentTemp = response.data.item;
          });
      } else if (this.industryItemType == "sampleManagement") {
        axios
          .get(
            "/api/sampleManagement/organizations/" +
              this.authObj.orgs[0].orgID +
              "/" +
              this.industryItemID +
              "?industryItemType=" +
              this.industryItemType
          )
          .then((response) => {
            console.log(response);
            this.sampleManagementTemp = response.data.item;
          });
      } else if (this.industryItemType == "capa") {
        axios
          .get(
            "/api/capa/organizations/" +
              this.authObj.orgs[0].orgID +
              "/" +
              this.industryItemID +
              "?industryItemType=" +
              this.industryItemType
          )
          .then((response) => {
            console.log(response);
            this.capaTemp = response.data.item;
          });
      } else if (this.industryItemType == "deviation") {
        axios
          .get(
            "/api/deviation/organizations/" +
              this.authObj.orgs[0].orgID +
              "/" +
              this.industryItemID +
              "?industryItemType=" +
              this.industryItemType
          )
          .then((response) => {
            console.log(response);
            this.deviationTemp = response.data.item;
          });
      }
      else if (this.industryItemType == "user") {
        axios
          .get(
            "/api/users/organizations/" +
              this.authObj.orgs[0].orgID +
              "/" +
              this.industryItemID +
              "?industryItemType=" +
              this.industryItemType
          )
          .then((response) => {
            console.log(response);
            this.userTemp = response.data.item;
          });
      }
    }
  },
  beforeMount() {
    this.docStatusType = this.$route.query.docStatusType;
    this.templateType = this.$route.query.templateType;
    this.industryItemType = this.$route.query.industryItemType;
    this.industryItemID = this.$route.query.industryItemID;
    if (window.location.href.toLowerCase().includes("/shareddocument")) {
      this.isSharedPage = true;
    }
  },
  methods: {
    isMobileandTab() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    onHeaderClick(docStatusType) {
      if (docStatusType == "new") {
        return (
          "/artifact/document?docStatusType=" +
          docStatusType +
          "&templateType=" +
          this.templateType +
          "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID
        );
      }
      return (
        "/artifact/doclist?docStatusType=" +
        docStatusType +
        "&templateType=" +
        this.templateType +
        "&industryItemType=" +
        this.industryItemType +
        "&industryItemID=" +
        this.industryItemID
      );
    },
    docStatusTypeHref(docStatusType) {
      if (docStatusType == "batch") {
        return (
          "/artifact/live?docStatusType=" +
          docStatusType +
          "&templateType=" +
          this.templateType +
          "&industryItemType=" +
          this.industryItemType +
          "&industryItemID=" +
          this.industryItemID
        );
      }
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
  },
};
</script>
<style>
.col-sm1 {
  padding: 5px;
}
.col-sm2 {
  padding-left: 10px;
}
.btn {
  width: 130px;
}
.btnm {
  width: 100px;
}
.custom-font {
  font-size: 8px;
}
.btn-green {
  background-color: #2dce89 !important;
}
.btn-green:hover {
  background-color: #2dce89 !important;
}
.i-golive {
  color: #08215d;
}
.title-heading {
  text-align: center;
  text-transform: uppercase;
  margin-top: 15px;
  font-size: 20px;
  color: #000;
}
.equipment-toggle {
  background-color: #80808024;
  margin-top: 15px;
  text-align: center;
}
label.form-control-label.equipment {
  margin-left: 10px;
}

</style>
