<template>
  <div>
    <document-header></document-header>
    <div>
      <div class="container-fluid mt--7 document-left" style="padding-right: 200px !important;">
        <div class="row">
          <div class="col">
            <div class="card shadow">
              <div class="card-body" style="background-color:#E4E4E4">
                <base-gxp-input
                  alternative
                  label="Document Title"
                  placeholder="Title"
                  input-classes="form-control-alternative inputLabel"
                  v-model="documentTemp.title"
                  disabled
                  style="background-color: #DEDEDE;"
                />
                <div v-for="section in documentTemp.sections" :key="section.id || section.title">
                  <label>{{section.id }}</label>
                  <base-gxp-input
                    alternative
                    placeholder="Section"
                    input-classes="form-control-alternative inputLabel"
                    v-model="section.description"
                    disabled
                    style="background-color: #ECEFF5;"
                  >
                    <div
                      v-for="step in section.steps"
                      :key="step.id || step.title"
                      style="padding-left:15px ;"
                    >
                      <label>{{step.id }}</label>
                      <base-gxp-input
                        alternative
                        placeholder="Step"
                        input-classes="form-control-alternative inputLabel"
                        v-model="step.description"
                        disabled
                        style="background-color: #F5F3EA;"
                      />
                      <div
                        v-for="step2 in step.steps"
                        :key="step2.id || step2.title"
                        style="padding-left:30px ;"
                      >
                        <label>{{step2.id }}</label>
                        <base-gxp-input
                          placeholder="StepInner"
                          input-classes="form-control inputLabel"
                          v-model="step2.description"
                          disabled
                        />
                      </div>
                    </div>
                  </base-gxp-input>
                </div>
                <!-- v-for="tab in tabs"
              class="nav-item"
                :key="tab.id || tab.title"-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <document-action></document-action>
    </div>
  </div>
</template>
<script>
// import Vue from "vue";
import documentHeader from "@/views/process/documents/documentHeader";
import documentAction from "@/views/process/documents/documentAction";

import documentDetails from "./../../../../sample-data/document-sample.json";

export default {
  components: { documentHeader, documentAction },
  data() {
    return {
      documentTemp: documentDetails
    };
  },
  methods: {
    sectionLabel(section) {
      return section.title + section.description;
    }
  }
};
</script>
<style>
.inputLabel {
  background-color: inherit !important;
  color: black;
}
.document-left {
  float: left;
}
</style>