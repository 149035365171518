<template>
  <div>
    <div style="clear: both">
      <flowchart-header></flowchart-header>
      <!-- <div class="new-flowchart">Create New Document</div> -->
    </div>

    <div class="container-fluid new-button">
      <div class="row">
        <div class="draft-flowchart" v-if="flowChartTemp.title && !isClonedDocument">
          {{
            stripHTML(flowChartTemp.title) +
            " - " +
            capitalizeFirstLetter(flowChartTemp.status1)
          }}
        </div>
        <div class="new-flowchart" v-else>
          {{ capitalizeFirstLetter(flowChartTemp.status1) }}
        </div>
        
        <div class="col editorNew">
          <div class="card shadow8 d-flex">
            <div class="card-body">
              <div v-if="flowChartTemp.title" style="text-align: center;">
                <textarea
                  :id="getId()"
                  class="form-control-alternative add-flowchart"
                  v-model="flowChartTemp.title"
                  style="width: 75%; height: 35px"
                ></textarea>
              </div>
              <div
                v-else-if="flowChartTemp.title"
                v-html="flowChartTemp.title"
                placeholder="Enter title here..."
                class="form-control-alternative add-flowchart"
              ></div>
              <div v-if="!flowChartTemp.title" style="text-align: center;">
                <textarea
                  :id="getId()"
                  class="form-control-alternative add-flowchart"
                  style="width: 70%; height: 35px"
                ></textarea>
                <base-button
                  size="sm"
                  type="default"
                  class="float-right"
                  @click="addTitle($event)"
                  >Add Title</base-button>
              </div>
              <div id="flowvue" v-if="flowChartTemp.title">
                <div class="float-buttons">
                  <button
                    type="button"
                    style="margin: 3px;"
                    @click="$refs.chart.add({id: +new Date(), x: 10, y: 10, name: 'New', type: 'operation', description: ':)'})"
                  >Add</button>
                  <button type="button" style="margin: 3px;" @click="$refs.chart.remove()">Del</button>
                  <button type="button" style="margin: 3px;" @click="$refs.chart.editCurrent()">Edit</button>
                  <button type="button" style="margin: 3px;" @click="$refs.chart.save()">Save</button>
                </div>
                <flow-chart
                  :nodes="nodes"
                  :connections="connections"
                  @editnode="handleEditNode"
                  @dblclick="handleDblClick"
                  @editconnection="handleEditConnection"
                  @save="handleChartSave"
                  ref="chart"
                ></flow-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="col editRightParent">
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="isApprove()"
                @click="showLaunchBatchModal = true"
              >
                LAUNCH
              </button>
            </div>
            <div
              v-if="flowChartTemp.status1 != 'new' && flowChartTemp.status1 != 'batch'"
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-clone-modal"
                @click="showCloneModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                CLONE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                class="btn btn-info"
                v-if="!isApprove() && !isLibrary()"
                @click="saveFlowChart()"
              >
                SAVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            ></div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showShareModal = true"
                class="btn btn-info"
                v-if="!isLibrary()"
              >
                {{
                  flowChartTemp.status1 &&
                  flowChartTemp.status1.toLowerCase() == "shared"
                    ? "ADD COLLABRATOR"
                    : "SHARE"
                }}
              </button>
            </div>

            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--  <a href="#!" class="btn btn-info" v-if="isEditable()">CHECK</a> -->
            </div>
            <!-- v-if="!isClonedDocument" -->
            <div
              v-if="flowChartTemp.isFlowchart && flowChartTemp.status1 == 'draft'"
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button
                id="show-share-modal"
                @click="showApproveModal = true"
                class="btn btn-info"
                v-if="isEditable()"
              >
                APPROVE
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!-- <a href="#!" class="btn btn-info" v-if="isEditable()">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start : edit flow chart modal -->
    <div v-if="showFlowchartModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Modify Nodes Name
                  <button
                    type="button"
                    class="close"
                    @click="showFlowchartModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <label for="name">Name</label>
                    <input id="name" class="form-control" v-model="editInput.name"/>
                    <label for="name">Type</label>
                    <select class="form-control" id="type" v-model="editInput.type">
                    <option :key="'node-type-' + item.id" :value="item.id"
                            v-for="item in [ { name: 'Start', id: 'start' }, { name: 'End', id: 'end' }, { name: 'Operation', id: 'operation' } ]"
                    >
                        {{item.name}}
                    </option>
                    </select>
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="handleClickSaveConnection"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showFlowchartModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End : edit flowchart modal -->

    <!-- Begin: Launch Batch Document -->
    <div v-if="showLaunchBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Launch Batch Document - '" +
                    stripHTML(flowChartTemp.title) +
                    "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showLaunchBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(flowChartTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Launch Batch Document</span>
                  <textarea
                    v-model="batchDocNumber"
                    placeholder="Enter batch #"
                    style="width: 100%"
                  ></textarea>
                  <button
                    v-if="finalarray.length == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="launchBatch()"
                  >
                    Launch Batch
                  </button>
                  <button
                    v-if="finalarray.length == 0"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="setToDefault()"
                  >
                    Cancel
                  </button>

                  <div
                    v-if="finalarray.length > 0"
                    style="max-height: calc(100vh - 210px); overflow-y: auto"
                  >
                    <span>Set Variable values:</span>
                    <div v-for="section in finalarray" v-bind:key="section.uid">
                      <table>
                        <tr>
                          <td style="width: 20%">
                            meta:
                            <label
                              class="form-control-label"
                              v-if="section.sectionnum"
                              v-html="section.sectionnum"
                            ></label>
                          </td>
                          <td style="width: 35%">
                            Variable Name:
                            <label
                              class="form-control-label"
                              v-if="section.variablename"
                              v-html="section.variablename"
                            ></label>
                          </td>
                          <td style="width: 50%">
                            Value:
                            <input
                              type="text"
                              class="form-control-alternative add-flowchart"
                              v-bind:value="section.defaultvalue"
                              v-on:input="onValueChange(section, $event)"
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="launchBatch()"
                    >
                      Launch Batch
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="setToDefault()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Launch Batch Document -->

    <!-- Begin: Share Document -->
    <div v-if="showShareModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Share Document - '" + stripHTML(flowChartTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showShareModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(flowChartTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <!-- <span>Share Document to :</span> -->
                  <div class="tab">
                    <button
                      class="tablinks active"
                      @click="openShare('shareContent', $event)"
                    >
                      Share
                    </button>
                    <button
                      class="tablinks"
                      @click="openShare('libraryContent', $event)"
                    >
                      Library
                    </button>
                  </div>

                  <div
                    id="shareContent"
                    class="tabcontent"
                    style="display: block"
                  >
                    <h3>Send to Emails</h3>
                    <textarea
                      v-model="emailAddress"
                      placeholder="Email address (separated by ; )"
                      style="width: 100%"
                    ></textarea>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>

                  <div id="libraryContent" class="tabcontent">
                    <h3>Send to DigitalGxP Public Library</h3>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="shareLibraryDocument()"
                    >
                      Share
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="showShareModal = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Share Document -->
    <!-- Begin: Clone Document -->
    <div v-if="showCloneModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Clone Document - '" + stripHTML(flowChartTemp.title) + "'" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCloneModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Clone Document</span>
                  <div class="custom-control targetTemplateType">
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="doument"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="document"
                    />Document
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="template"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="template"
                    />Template
                    <input
                      type="radio"
                      name="targetTemplateType"
                      id="library"
                      class="radio-buttons"
                      v-model="targetTemplateType"
                      value="library"
                    />Library
                  </div>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="handleCloneConfirmClick()"
                  >
                    Clone
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showCloneModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Clone Document -->
    <!-- Begin: Approve Document -->
    <div v-if="showApproveModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  {{
                    "Approve Document - '" + stripHTML(flowChartTemp.title) + "'"
                  }}
                  <button
                    type="button"
                    class="close"
                    @click="showApproveModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <!-- <h4 class="modal-title">{{ "Share Document - '" + stripHTML(flowChartTemp.title) + "'" }} </h4> -->
                </div>
                <div class="modal-body">
                  <span>Please confirm!</span>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="approveDocument()"
                  >
                    Approve
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showApproveModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Approve Document -->

    <div v-if="showGridModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  Grid Title: Client Info
                  <button
                    type="button"
                    class="close"
                    @click="showGridModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <button class="btn btn-secondary" @click="addItem">Add an item dynamically</button>
                    <input type="checkbox" v-model="draggable" /> Draggable
                    <input type="checkbox" v-model="resizable" /> Resizable
                  </div>
                  <grid-layout :layout.sync="layout"
                    :col-num="colNum"
                    :row-height="30"
                    :is-draggable="draggable"
                    :is-resizable="resizable"
                    :vertical-compact="true"
                    :use-css-transforms="true"
                  >
                  <grid-item v-for="item in layout"
                      :static="item.static"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :key="item.i"
                  >
                    <!-- <span class="text">{{item.i}}</span> -->
                    <span class="remove" @click="removeItem(item.i)">x</span>
                  </grid-item>
                  </grid-layout>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="submitGrid()"
                  >
                    Submit
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showGridModal = false"
                  >
                    Cancel
                  </button>
                </div>\
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- start : Text area modal -->
    <div v-if="showTextModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Textarea Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTextModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the textarea
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextarea"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTextModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End : Text area modal -->

    <div v-if="showTableModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-table">
              <div class="modal-content">
                <div class="modal-header">
                  Grid Title: Client Info
                  <button
                    type="button"
                    class="close"
                    @click="showTableModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <button class="btn btn-secondary" @click="addRows">
                      Add
                    </button>
                  </div>
                  <table
                    class="table"
                    cellspacing="2"
                    cellpadding="5"
                    id="data_table"
                    border="1"
                    style="margin-top: 10px"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">ColumnName</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <draggable v-model="updateColumnDefs" tag="tbody">
                      <tr
                        v-for="(item, index) in updateColumnDefs"
                        :key="index"
                      >
                        <td scope="row">{{ index + 1 }}</td>
                        <td>
                          <span v-if="item.editmode">
                            <input type="text" v-model="item.columnName" />
                          </span>
                          <span v-else>{{ item.columnName }}</span>
                        </td>
                        <td>
                          <span v-if="item.editmode">
                            <select v-model="item.type" required>
                              <option v-for="option in type" :key="option.id">
                                {{ option.val }}
                              </option>
                            </select>
                          </span>
                          <span v-else>{{ item.type }}</span>
                        </td>
                        <td>
                          <span v-if="!item.editmode"
                            ><button @click="edit(item)">Edit</button></span
                          >
                          <span v-else
                            ><button @click="save(item)">Save</button></span
                          >
                          <button @click="deleteRow(index)" class="delete">
                            Delete
                          </button>
                        </td>
                      </tr>
                    </draggable>
                  </table>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="submitTable()"
                  >
                    Submit
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteTable($event)"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTableModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- start : show Image modal -->
    <div v-if="showImageModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Image Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showImageModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="text"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Image:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="image/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <img
                        style="width: 100px; height: 100px; margin-bottom: 10px"
                        :src="filespath"
                        alt=""
                      />
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteImage()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End : show Image modal -->

    <div v-if="showVideoModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Video Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showVideoModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header" v-if="isDelete == false">
                    <div class="url">
                      Link to Url:
                      <input
                        type="text"
                        ref="file"
                        id="file"
                        style="
                          margin-left: 5px;
                          margin-bottom: 15px;
                          width: 80%;
                        "
                        v-model="filespath"
                      />
                    </div>
                    <h4>OR</h4>
                    Select Video:<input
                      type="file"
                      id="file"
                      style="margin-left: 5px; margin-bottom: 15px"
                      ref="file"
                      name="file-to-upload"
                      accept="video/*"
                      v-on:change="handleFileUploads()"
                    />
                    <p v-if="filespath">
                      <video width="240" height="240" controls>
                        <source :src="filespath" type="video/mp4" />
                      </video>
                      <!-- <iframe width="420" height="315"
                          :src="filespath">
                      </iframe> -->
                    </p>
                    <button
                      class="btn float-right btn-default btn-sm m-2"
                      @click="uploadImage($event)"
                      v-if="disable == false"
                    >
                      Upload
                    </button>
                    <button
                      class="btn float-right btn-default btn-sm m-2 disabled"
                      @click="uploadImage($event)"
                      v-if="disable == true"
                    >
                      Upload
                    </button>
                  </div>
                  <div v-if="isDelete == true">
                    Please Confirm if you want to Delete or cancel
                  </div>
                  <br />
                  <button
                    v-if="isDelete == false"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Ok
                  </button>
                  <button
                    v-if="isDelete == true"
                    class="btn float-right btn-default btn-sm m-2"
                    @click="deleteVideo()"
                  >
                    Delete
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div v-if="showTimerModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Timer Dialog
                  <button
                    type="button"
                    class="close"
                    @click="showTimerModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    If you click on ok it will show the Timer
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTimer($event)"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showTimerModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
// import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";
import draggable from "vuedraggable";
// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import flowchartHeader from "./flowchartHeader.vue";
import { GridLayout, GridItem } from "vue-grid-layout";
import FlowChart from "flowchart-vue";

export default {
  components: { flowchartHeader, draggable, GridLayout, GridItem, FlowChart },
  data() {
    return {
      nodes: [
        { id: 1, x: 140, y: 270, name: "Start", type: "start" },
        {
          id: 2,
          x: 540,
          y: 270,
          name: "End",
          type: "end",
        },
        {
          id: 3,
          x: 340,
          y: 270,
          name: "Operation",
          type: "operation",
          description: "I'm here",
        },
        {
          id: 4,
          x: 340,
          y: 360,
          name: "Operation",
          type: "operation",
          description: "I'm here",
        },
      ],
      connections: [
        {
          source: { id: 1, position: "right" },
          destination: { id: 3, position: "left" },
          id: 1,
          type: "pass",
        },
        {
          source: { id: 1, position: "right" },
          destination: { id: 4, position: "left" },
          id: 1,
          type: "pass",
        },
        {
          source: { id: 3, position: "right" },
          destination: { id: 2, position: "left" },
          id: 2,
          type: "pass",
        },
        {
          source: { id: 4, position: "right" },
          destination: { id: 2, position: "left" },
          id: 2,
          type: "pass",
        },
      ],
      editInput: '',
      layout: [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },],
      draggable: true,
      resizable: true,
      colNum: 12,
      index: 0,
      emailAddress: "",
      shareDocId: "",
      arrow: true,
      showFlowchartModal: false,
      showTextModal: false,
      showGridModal: false,
      showTableModal: false,
      showTimerModal: false,
      showShareModal: false,
      isClonedDocument: false,
      showCommentModal: false,
      showApproveModal: false,
      showLaunchBatchModal: false,
      showImageModal: false,
      showVideoModal: false,
      flowChartTemp: {},
      showCloneModal: false,
      authCookie: null,
      editIds: [],
      batchDocNumber: "",
      isFlowchart: "",
      notes: [],
      finaljson: {},
      finalarray: [],
      newComments: [],
      updateSecId: "",
      updateStepId: "",
      files: "",
      fileUrl: "",
      filespath: "",
      videopath: "",
      videotype: "",
      disable: true,
      metaId: "",
      gridOptions: null,
      updateColumnDefs: [],
      rowData: [],
      type: [
        { id: "23", val: "Text" },
        { id: "13", val: "Number" },
        { id: "3", val: "DateTime" },
      ],
    };
  },
  beforeMount() {
    this.shareDocId = this.$route.query.shareDocId;
    this.isClonedDocument = this.$route.query.isClonedDocument;
    this.flowChartTemp.flowChartID = this.$route.query.flowChartid;
    this.flowChartTemp.status1 = this.$route.query.docStatusType;
    this.flowChartTemp.isFlowchart = this.$route.query.isFlowchart;
  },
  mounted() {
    if (!this.flowChartTemp.flowChartID && !this.shareDocId) {
      let sharedKey = this.$route.params.key;
      if (sharedKey) {
        this.shareDocId = sharedKey;
      }
    }
    if (this.flowChartTemp.flowChartID) {
      axios
        .get(
          "/api/flowChart/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.flowChartTemp.flowChartID
        )
        .then((response) => {
          console.log(response);
          if (this.isClonedDocument) {
            let targetTemplateType = this.flowChartTemp.isFlowchart;
            this.flowChartTemp = {};
            this.flowChartTemp.title = response.data.item.title;
            this.flowChartTemp.status1 = "new";
            this.flowChartTemp.isFlowchart = targetTemplateType;
          } else {
            this.flowChartTemp = response.data.item;
            this.nodes = response.data.item.nodes;
            this.connections = response.data.item.connections;
          }
          
          this.flowChartTemp.title = this.flowChartTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + this.flowChartTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          this.flowChartTemp = [];
        });
    } else if (this.shareDocId) {
      console.log(" Opening from Share id :" + this.shareDocId);
      var that = this;
      axios
        .get("/api/sharedDocuments?key=" + this.shareDocId)
        .then((response) => {
          that.flowChartTemp = response.data.item;
          that.flowChartTemp.flowChartID = response.data.item.flowChartID;
          that.flowChartTemp.status1 = response.data.item.status1;

          that.flowChartTemp.title = that.flowChartTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");

          //load OGP.ME
          const titleEl = document.querySelector("head title");
          titleEl.textContent = "Digital GxP - " + that.flowChartTemp.title;
        })
        .catch((reason) => {
          console.log(reason);
          that.flowChartTemp = [];
        });
    }
    this.index = this.layout.length;
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj) {
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }
  },
  methods: {
    //start flowchart
    handleChartSave(nodes, connections) {
      console.log(nodes);
      console.log(connections);
      alert('node added successfully')
      let app = this;
      app.flowChartTemp.nodes = nodes;
      app.flowChartTemp.connections = connections;
    },
    handleEditNode(node) {
      console.log(node.description);
      this.showFlowchartModal = true;
      this.editInput = node;
    },
    handleEditConnection(connection) {
      console.log(connection);
    },
    handleDblClick(position) {
      this.$refs.chart.add({
        id: +new Date(),
        x: position.x,
        y: position.y,
        name: "New",
        type: "operation",
      });
    },
    handleClickSaveConnection() {
      for(var i=0; i<this.$refs.chart.nodes.length; i++){
        if(this.$refs.chart.nodes[i].id == this.editInput.id){
        this.$refs.chart.nodes[i].name = this.editInput.name;
        this.$refs.chart.nodes[i].type = this.editInput.type;
        }
      }
      this.showFlowchartModal = false;
    },
    // end flowchart
    startDrag(event) {
      var contact = event.target.parentElement.getAttribute("data-class-name");
      event.dataTransfer.setData("data-class-name", contact);
      console.log(contact);
    },
    dragOver(event) {
      event.preventDefault();
    },
    onClickDragTable(metaId) {
      var element = document.getElementById("table-grid_" + metaId);
      element.style.display = "block";
    },
    deleteRow(index) {
      this.updateColumnDefs.splice(index, 1);
    },
    edit(obj) {
      this.$set(obj, "editmode", true);
      console.log("edit");
    },
    save(obj) {
      this.$set(obj, "editmode", false);
      console.log("save");
    },
    drop(event) {
      const dragControl = event.dataTransfer.getData("data-class-name");
      var stepBody = event.target.closest('div[class^="step-Body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      if (dragControl == "table-input") {
        this.showTableModal = true;
        this.updateColumnDefs = [];
      } 
      if (dragControl == "grid-input") {
        this.showGridModal = true;
        this.layout = [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },];
      }
      else if (dragControl == "text-input") {
        this.showTextModal = true;
      } else if (dragControl == "image-input") {
        this.showImageModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      } else if (dragControl == "video-input") {
        this.showVideoModal = true;
        this.filespath = null;
        this.isDelete = false;
        this.disable = true;
      } else if ( dragControl == "time-input") {
        this.showTimerModal = true;
      } else if ( dragControl == "formula-input") {
          this.showFormula(event);
      }else if (dragControl == "qrcode-input") {
          var pValQRCode = prompt("Please select type of Media Input: 1 For Image OR 2 For Video OR 3 For QR Code", "");
            if (!pValQRCode) return;
            if(pValQRCode == 1)
            {
                var pValImgName = prompt("Please Enter name of Image", "");
                if (!pValImgName) return; 
                this.showMediaImage();
            }
            else if(pValQRCode == 2)
            {
                this.showMediaVideo();
            }
            else if(pValQRCode == 3)
            {
                var pValQRName = prompt("Please Enter name of QR Code", "");
                if (!pValQRName) return; 
                this.showQRVideo();
            }
      }else if (dragControl == "data-input") {
                var pValText = prompt("Please select type of Data Input: 1 For Text OR 2 For Numeric", "");
                if (!pValText) return;
                if(pValText == 1)
                {
                  this.showDatainput();
                }
                if(pValText == 2)
                {
                  this.showDataInputNumberTranding();
                }
      }else if (dragControl == "var-input") {
        this.showVariable();
      }else if (dragControl == "steplock-input") {
        this.showSteplock();
      }
      else if (dragControl == "verifier-input") {
        this.showVerifierInput();
      }
      else if (dragControl == "caution-input") {
        this.showCautionInput();
      }
      else if (dragControl == "crossref-input") {
        this.showCrossrefInput();
      }
      else if (dragControl == "iot-input") {
        this.showIot();
      }
      else if (dragControl == "mcq-input") {
        this.showMcq();
      }
      else if (dragControl == "skill-input") {
        this.showSkillTrend();
      }
      event.preventDefault();
    },
    addItem: function () {
        // Add a new item. It must have a unique key!
        this.layout.push({
            x: (this.layout.length * 2) % (this.colNum || 12),
            y: this.layout.length + (this.colNum || 12), // puts it at the bottom
            w: 2,
            h: 2,
            i: this.index.toString(),
            uid: uuidv4(),
        });
        // Increment the counter to ensure key is always unique.
        this.index++;
    },
    removeItem: function (val) {
        const index = this.layout.map(item => item.i).indexOf(val);
        this.layout.splice(index, 1);
    },
    submitGrid(e) {
      console.log(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "grid",
              uid: uuidv4(),
              grids: this.layout,
            });
            this.$set(sec[0], 0);
            this.showGridModal = false;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "grid",
          uid: uuidv4(),
          grids: this.layout,
        });
        this.$set(sec[0], 0);
      }
      this.showGridModal = false;
      this.layout = [{ x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), static: false },];
    },
    showTextarea(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "text",
              uid: uuidv4(),
            });
            this.$set(sec[0], 0);
            this.showTextModal = false;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "text",
          uid: uuidv4(),
        });
        this.$set(sec[0], 0);
      }
      this.showTextModal = false;
      // for (var i = 0; i < this.flowChartTemp.nodes.length; i++) {
      //   if(!this.flowChartTemp.nodes[i].meta){
      //     this.$set(this.flowChartTemp.nodes[i], "meta", []);
      //   }
      //   this.$set(this.flowChartTemp.nodes[i].meta, this.flowChartTemp.nodes[i].meta.length, {
      //     type: "text",
      //     uid: uuidv4(),
      //   });
      //   this.$set(this.flowChartTemp.nodes, 0, this.flowChartTemp.nodes[i]);
      //   this.showTextModal = false;
      //   return
      //   }
    },
    addRows() {
      let existingLength = this.updateColumnDefs.length;
      this.updateColumnDefs.push({
        columnName: "",
        id: ++existingLength,
        type: "",
        // order: 1
      });
    },
    submitTable(e) {
      console.log(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        let gridColumnDefs = [];
        for (var i = 0; i < this.updateColumnDefs.length; i++) {
          let colHeader = {};
          let obj = this.updateColumnDefs[i];
          colHeader.headerName = obj.columnName;
          colHeader.field = obj.columnName;
          colHeader.sortable = true;
          colHeader.filter = true;
          colHeader.editable = true;
          colHeader.type = obj.type;
          gridColumnDefs.push(colHeader);
        }
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              columnDefs: gridColumnDefs,
              rowData: this.rowData,
              type: "table",
              uid: uuidv4(),
            });
            this.$set(sec[0], 0);
            this.showTableModal = false;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          columnDefs: gridColumnDefs,
          rowData: this.rowData,
          type: "table",
          uid: uuidv4(),
        });
        this.$set(sec[0], 0);
      }
      this.showTableModal = false;
      this.updateColumnDefs = [];
    },
    handleFileUploads() {
      // this.fileUrl =  URL.createObjectURL(this.$refs.file.files[0]);
      this.files = this.$refs.file.files[0];
      console.log(this.files);
      this.disable = false;
    },
    uploadImage(e) {
      console.dir(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          app.filespath = "";
          var path = response.data.fileUrl;

          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage(e) {
      console.dir(e);
      let app = this;
      var sec = app.flowChartTemp.nodes.filter(
        (x) => x.uid == app.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            app.$set(sec[0].meta, j, {
              type: "image",
              uid: uuidv4(),
            });
            app.$set(sec[0], 0);
            app.showImageModal = false;
            return;
          }
        }
        app.$set(sec[0].meta, sec[0].meta.length, {
          imagePath: app.filespath,
          type: "image",
          uid: uuidv4(),
        });
        app.$set(sec[0], 0);
      }
      app.showImageModal = false;
    },
    previewVideo(e) {
      console.dir(e);
      let app = this;
      var sec = app.flowChartTemp.nodes.filter(
        (x) => x.uid == app.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            app.$set(sec[0].meta, j, {
              type: "video",
              uid: uuidv4(),
            });
            app.$set(sec[0], 0);
            app.showVideoModal = false;
            return;
          }
        }
        app.$set(sec[0].meta, sec[0].meta.length, {
          videoPath: app.filespath,
          type: "video",
          uid: uuidv4(),
        });
        app.$set(sec[0], 0);
      }
      app.showVideoModal = false;
    },
    showIot() {
      var pValIot = prompt("Please select type of IoT Input: 1 For Data OR 2 For Video OR 3 For Audio", "");
        if (!pValIot) return;
        var iotValue = 'IOT:' +pValIot;
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "iot-input",
              uid: uuidv4(),
              description: iotValue,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "iot-input",
          uid: uuidv4(),
          description: iotValue,
        });
        this.$set(sec[0], 0);
      }
    },
    showMcq() {
      var options = [];
      var finalOptions = null;
      var pValOption = prompt("Enter a multiple options separated by commas ( , ) :", "");
      if (!pValOption) return;
      let optionNumbers = pValOption.split(",");
      var pValOptionExit = prompt("Enter = operator for Exit", "");
      if (!pValOptionExit) return;
      if(pValOptionExit == "="){
          finalOptions = optionNumbers;
      }
      else{
          finalOptions = optionNumbers + " " + pValOptionExit;
      }
      var res = '  ';
      for (let i = 0; i < finalOptions.length; i++) {
          options.push( i+1  +  "."  + "  " +  finalOptions[i] + res );
          options.join('');
      }
      var mcqData = 'Options = ' + options.join(" , ");
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mcq-input",
              uid: uuidv4(),
              mcq: true,
              description: mcqData,
              value: "",
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mcq-input",
          uid: uuidv4(),
          mcq: true,
          description: mcqData,
          value: "",
        });
        this.$set(sec[0], 0);
      }
    },
    showSkillTrend() {
      var skillDesc = "/img/pptx/widgets/trending.png";
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
        if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "skill-input",
              uid: uuidv4(),
              skillfound: true,
              description: skillDesc,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "skill-input",
          uid: uuidv4(),
          skillfound: true,
          description: skillDesc,
          "skills" : [{
                uid: uuidv4(),
                executedUsers: [],
                verifiedUsers: [],
                batchno: '',
                }],   
        });
        this.$set(sec[0], 0);
      }
    },
    showTimer(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var test = "/img/pptx/timer.png";
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "timer",
              uid: uuidv4(),
              timer: true,
              description: test,
            });
            this.$set(sec[0], 0);
            this.showTimerModal = false;
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "timer",
          uid: uuidv4(),
          timer: true,
          description: test,
        });
        this.$set(sec[0], 0);
      }
      this.showTimerModal = false;
    },
    showFormula(e){
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var finalString = null; 
        var pVal1Formula = prompt("For a blank value in the formula to be entered during a batch document, enter {name}. For a constant value, enter the numeric value e.g. 9.5", "");
        if (!pVal1Formula) return;
        var pValFormulaop1 = prompt("Enter Operator for Folmula (+ , - , * , /)", "");
        if (!pValFormulaop1) return;

        var pVal2Formula = prompt("Formula: " + pVal1Formula + " " + pValFormulaop1  + " Enter Value {name} or constant number", "");
        if (!pVal2Formula) return;

        var pValFormulaop2 = prompt("Enter Operator for Folmula (+ , - , * , /) and = for Exit", "");
        if (!pValFormulaop2) return;

        var dataLabel = prompt("Add Data Label for Formula", "");
        if (!dataLabel) return;

        if(pValFormulaop2 == "="){
            finalString = pVal1Formula + " " + pValFormulaop1 + " " + pVal2Formula;
          }
          else{
            finalString = pVal1Formula + " " + pValFormulaop1 + " " + pVal2Formula+ " " + pValFormulaop2;
          }
          
          while(pValFormulaop2 != "=")
          {
            pVal2Formula = prompt("Formula: " +  finalString + " Enter Value {name} or constant number", "");

            finalString = finalString + " " + pVal2Formula;

            pValFormulaop2 = prompt("Enter Operator for Folmula (+ , - , * , /) and = for Exit", "");

            if(pValFormulaop2 != "="){
                finalString = finalString + " " + pValFormulaop2;
            }
          }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "formula",
              uid: uuidv4(),
              formula: finalString,
              formuladatalabel: dataLabel,
              formulafound: true,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "formula",
          uid: uuidv4(),
          formula: finalString,
          formuladatalabel: dataLabel,
          formulafound: true,
        });
        this.$set(sec[0], 0);
      }
    },
    showMediaImage(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var mediaImagepath = "/img/pptx/image-old.png"
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mediaImage",
              uid: uuidv4(),
              mediaimagefound: true,
              description: mediaImagepath,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mediaImage",
          uid: uuidv4(),
          mediaimagefound: true,
          description: mediaImagepath,
        });
        this.$set(sec[0], 0);
      }
    },
    showMediaVideo(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValVideoName = prompt("Please Enter name of Video", "");
      if (!pValVideoName) return; 
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mediaVideo",
              uid: uuidv4(),
              videofound: true,
              description: pValVideoName,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mediaVideo",
          uid: uuidv4(),
          videofound: true,
          description: pValVideoName,
        });
        this.$set(sec[0], 0);
      }
    },
    showQRVideo(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var qrImagepath = "/img/pptx/qr-code.png"
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "mediaQRcode",
              uid: uuidv4(),
              qrimagefound: true,
              description: qrImagepath,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "mediaQRcode",
          uid: uuidv4(),
          qrimagefound: true,
          description: qrImagepath,
        });
        this.$set(sec[0], 0);
      }
    },
    showDatainput(e){
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var pVarText= null;
      pVarText = prompt("Add Data Label for Text Input", "");
        if (!pVarText) return;
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "data-input",
              uid: uuidv4(),
              inputfound: true,
              description: (pVarText+":__________"),
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "data-input",
          uid: uuidv4(),
          inputfound: true,
          description: pVarText,
          value: "__________",
        });
        this.$set(sec[0], 0);
      }
    },
    showDataInputNumberTranding(e){
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        var pVarNumeric= null;
        pVarNumeric = prompt("Add Data Label for Numeric Input", "");
          if (!pVarNumeric) return;
        var pVarDataTreding = prompt("Do you want to Enable Data Tranding? 1 For Yes OR 2 For No", "");
          if (!pVarDataTreding) return;

        var  pvarRange =  prompt("Do you want to add Acceptable Data Range? 1 For Yes OR 2 For No", "");
          if (!pvarRange) return;
          if(pvarRange == 1) {
              var pValMinRange = prompt("Enter minimum value", "");
              if (!pValMinRange) return;

              var pValMaxRange = prompt("Enter maximum value", "");
              if (!pValMaxRange) return;
          }

        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "dataNumberTranding",
              uid: uuidv4(),
              description: pVarNumeric,
              inputfound: true,
              value: "___",
              datatrendfound: pVarDataTreding==1 ? true: false,
              acceptablerange: pvarRange==1 ? true: false,
              "dataTrandings" : [{
                uid: uuidv4(),
                varname: pVarNumeric,
                varvalue: 'NULL',
                batchno: '',}],              
            });
          }
        }
        if(pVarDataTreding ==1 && pvarRange == 1){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          inputfound: true,
          value: "___",
          datatrendfound: true,
          acceptablerange: true,
          minvalue: pValMinRange,
          maxvalue: pValMaxRange,
          "dataTrandings" : [{
                uid: uuidv4(),
                varname: pVarNumeric,
                varvalue: 'NULL',
                batchno: '',}], 
        });
        }
        else if(pVarDataTreding == 1){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
          datatrendfound: true,
        });
        }
        else if(pVarDataTreding ==2 && pvarRange == 2){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
        });
        }
        else if(pVarDataTreding == 2 && pvarRange == 1){
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumberTranding",
          uid: uuidv4(),
          description: pVarNumeric,
          value: "___",
          inputfound: true,
          acceptablerange: true,
          minvalue: pValMinRange,
          maxvalue: pValMaxRange,
        });
        }
        this.$set(sec[0], 0);
      }
    },
    showDataInputNumber(e){
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var pVarNumeric= null;
        pVarNumeric = prompt("Please Enter Input Type Numeric Name", "");
          if (!pVarNumeric) return;
        var pVarDataTreding = prompt("Do you want to Enable Data Tranding? 1 For Yes OR 2 For No", "");
          if (!pVarDataTreding) return;
            if(pVarDataTreding == 2)
            {
              <a href="javascript:void(0)">'+ pVarNumeric + ':____</a>            
            }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "dataNumber",
              uid: uuidv4(),
              inputfound: true,
              description: pVarNumeric,
            });
            this.$set(sec[0], 0);
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "dataNumber",
          uid: uuidv4(),
          inputfound: true,
          description: pVarNumeric,
        });
        this.$set(sec[0], 0);
      }
    },
    showVariable(e){
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Variable Name followed by value in the next prompt", "");
        if (!pValText) return;
        var pValDefText = prompt("Enter Variable Default Value needed for a particular PAGE", "");
        if (!pValDefText) return;
        var variable = String("["+ pValText +","+ pValDefText +"]");
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "var-input",
              uid: uuidv4(),
              description: variable,
              variableName: pValText,
              value: pValDefText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "var-input",
          uid: uuidv4(),
          description: variable,
          variableName: pValText,
          value: pValDefText,
        });
        this.$set(sec[0], 0);
      }
    },
    showSteplock(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var test = "/img/pptx/widgets/steplock.png";
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "steplock",
              uid: uuidv4(),
              description: test,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "steplock",
          uid: uuidv4(),
          description: test,
        });
        this.$set(sec[0], 0);
      }
    },
    showVerifierInput(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Verifier Name needed for a particular meta", "");
          if (!pValText) return;
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "verifierInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "verifierInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(sec[0], 0);
      }
    },
    showCautionInput(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Caution Name needed for a particular meta", "");
          if (!pValText) return;
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "cautionInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "cautionInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(sec[0], 0);
      }
    },
    showCrossrefInput(e) {
      console.dir(e);
      var sec = this.flowChartTemp.nodes.filter(
        (x) => x.uid == this.updateSecId
      );
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
      var pValText = prompt("Enter Cross Ref Document Title (copy/paste Document Title)", "");
      if (!pValText) return;
      
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].uid == this.metaId) {
            this.$set(sec[0].meta, j, {
              type: "crossrefInput",
              uid: uuidv4(),
              description: pValText,
            });
            this.$set(sec[0], 0);
            return;
          }
        }
        this.$set(sec[0].meta, sec[0].meta.length, {
          type: "crossrefInput",
          uid: uuidv4(),
          description: pValText,
        });
        this.$set(sec[0], 0);
      }
    },
    notifyUser(flowChartTempNotification){
      console.log(flowChartTempNotification);
      var notification = {
          objectId: flowChartTempNotification.flowChartID,
          type: "flowChart",
          status: flowChartTempNotification.status1,
          message:  " has " + flowChartTempNotification.status1  + " the flowChart document " + flowChartTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    notifyUserBatch(flowChartTempNotification){
      console.log(flowChartTempNotification);
      var notification = {
          objectId: flowChartTempNotification.flowChartID,
          type: "flowChart",
          status: flowChartTempNotification.status1,
          message:  " has " + " launched " + flowChartTempNotification.status1  + " flowChart  document " + flowChartTempNotification.batchNumber + " for " + flowChartTempNotification.title,
      };
      this.$root.$emit('listenNotification', notification);
    },
    addTitle(e) {
      var el = e.target.parentElement.querySelector(
        ".form-control-alternative"
      );
      this.$set(this.flowChartTemp, "title", el.value);
      this.$set(this.flowChartTemp, "status1", "draft");
      this.$set(this.flowChartTemp, "approvedBy", this.authCookie.userName);
      this.$set(this.flowChartTemp, "userName", this.authCookie.userName);
      this.$set(this.flowChartTemp, "orgID", this.authCookie.orgs[0].orgID);
      this.$set(this.flowChartTemp, "createdBy", this.authCookie.userName);
      this.$set(this.flowChartTemp, "updatedBy", this.authCookie.userName);
    },
    addNote() {
      console.log(this.flowChartTemp);
      if (!this.flowChartTemp.nodes) {
        this.$set(this.flowChartTemp, "nodes", []);
      }
      var note = {};
      note.uid = uuidv4();
      note.description = "";
      note.createdAt  = new Date();
      note.color  = this.getColor();
      note.dataTrandings = [];
      this.$set(this.flowChartTemp.nodes, this.flowChartTemp.nodes.length, note);

      // const noteBox = document.getElementById('note-box');
      // const rightArrow = document.createElement('div');
      // const leftArrow = document.createElement('div');
      // const bottomArrow = document.createElement('div');
      // rightArrow.id = "arrow-right";
      // rightArrow.addEventListener('click', this.addRightNote);
      // leftArrow.id = "arrow-left";
      // leftArrow.addEventListener('click', this.addLeftNote);
      // bottomArrow.id = "arrow-bottom";
      // bottomArrow.addEventListener('click', this.addBottomNote);
      // noteBox.appendChild(rightArrow);
      // noteBox.appendChild(leftArrow);
      // noteBox.appendChild(bottomArrow);
      // this.nodes.push({
      //   description: "",
      //   createdAt: new Date(),
      //   color: this.getColor(),
      // });
    },
    addRightnodes() {
    const rightBox = document.querySelector('.note-box');

    rightBox.style.width = '200px';
    rightBox.style.height = '100px';

// Position the right box relatively to the box that you are clicking on
    rightBox.style.position = 'relative';
      // if (!this.flowChartTemp.nodes) {
      //   this.$set(this.flowChartTemp, "nodes", []);
      // }
      // var note = {};
      // note.uid = uuidv4();
      // note.description = "";
      // note.createdAt  = new Date();
      // note.color  = this.getColor();
      // var right = [];
       // note.rightstyle = this.getRightStyle();
      // if(note.rightstyle){
      //   note.rightstyle = this.getSecondRightStyle();
      //   note.rightstyle.push(right);
      // }
      // else{
      //   note.rightstyle = this.getRightStyle();
        
      // }
      // this.$set(this.flowChartTemp.nodes, this.flowChartTemp.nodes.length, note);
    },
    addRightNote() {
      if (!this.flowChartTemp.nodes) {
        this.$set(this.flowChartTemp, "nodes", []);
      }
      var note = {};
      note.uid = uuidv4();
      note.description = "";
      note.createdAt  = new Date();
      note.color  = this.getColor();
      note.leftstyle = this.getRightStyle();
      this.$set(this.flowChartTemp.nodes, this.flowChartTemp.nodes.length, note);
      document.getElementById('arrow-right').style.display = 'none';
    },
    addLeftNote() {
      if (!this.flowChartTemp.nodes) {
        this.$set(this.flowChartTemp, "nodes", []);
      }
      var note = {};
      note.uid = uuidv4();
      note.description = "";
      note.createdAt  = new Date();
      note.color  = this.getColor();
      note.leftstyle = this.getLeftStyle();
      document.getElementById('arrow-left').style.display = 'none';
      this.$set(this.flowChartTemp.nodes, this.flowChartTemp.nodes.length, note);
    },
    addBottomNote() {
      if (!this.flowChartTemp.nodes) {
        this.$set(this.flowChartTemp, "nodes", []);
      }
      var note = {};
      note.uid = uuidv4();
      note.description = "";
      note.createdAt  = new Date();
      note.color  = this.getColor();
      note.bottomstyle = this.getBottomStyle();
      this.$set(this.flowChartTemp.nodes, this.flowChartTemp.nodes.length, note);
      document.getElementById('arrow-bottom').style.display = 'none';
      if(note.bottmstyle){
        this.getRightStyle();
      }
    },
    removeNote(index) {
      this.nodes.splice(index, 1);
    },
    getColor() {
      const colors = ["#ff000066", "#ff7f007a", "#ffff004f", "#00ff0059", "#0000ff54", "#4b008263", "#9400d366"];
      const color = colors[Math.floor(Math.random() * colors.length)];
      return {
        backgroundColor: color,
      };
    },
    getRightStyle(){
      return {
      position: 'absolute',
      left: '69%'
    }
    },
    getSecondRightStyle(){
      return {
      position: 'absolute',
      left: '69%',
      bottom: '0'
    }
    },
    getLeftStyle(){
      return {
      position: 'absolute',
      right: '63%'
    }
    },
    getBottomStyle(){
      return {
      position: 'relative',
      // bottom: '65%'
    }
    },
    // saveFlowChart() {
    //   let that = this;
    //   axios
    //     .post("/api/flowChart", that.nodes)
    //     .then(function (response) {
    //       console.log(response);
    //       that.nodes = [];
    //       that.nodes = response.data.items;
    //       alert("flowChart Created Successfully!");
    //       window.location = "/flowChart";
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //       that.nodes = [];
    //     });
    // },
    async saveFlowChart() {
      let app = this;
      this.deleteAttr(this.flowChartTemp);
      if (!this.flowChartTemp.flowChartID) {
        if (app.isClonedDocument) {
          app.flowChartTemp.status1 = "draft";
        }
        try {
          const { data } = await axios.post("/api/flowChart", app.flowChartTemp);
          // .then(function (response) {
          console.log(data);
          // if(app.$refs.chart.nodes || app.$refs.chart.connections){
          //   this.$refs.chart.save();
          // app.flowChartTemp.nodes = app.$refs.chart.nodes;
          // app.flowChartTemp.connections = app.$refs.chart.connections;
          // }
          app.flowChartTemp = data.item;
          alert("flowChart Created Successfully!");
          window.location =
            "/newFlowchart?flowChartid=" +
            app.flowChartTemp.flowChartID +
            "&isFlowchart=" +
            app.flowChartTemp.isFlowchart +'&docStatusType='+ app.flowChartTemp.status1;
        } catch (error) {
          console.log(error);
        }
      } else {
        app.flowChartTemp.updatedBy =
          app.flowChartTemp.updatedBy || this.authCookie.userName;

        try {
          const { data } = await axios.put("/api/flowChart", {
            flowChart: app.flowChartTemp,
            changes: { comment: this.newComments },
          });

          console.log(data);
          app.flowChartTemp = data.item;
          // app.flowChartTemp.nodes = nodes;
          // app.flowChartTemp.connections = connections;
          // console.log("Document Saved Successfully!");
          alert("flowChart Saved Successfully!");
          if(app.flowChartTemp.status1 == 'approved') {
              app.notifyUser(app.flowChartTemp);
            }
          window.location =
            "/newFlowchart?flowChartid=" +
            app.flowChartTemp.flowChartID +
            "&isFlowchart=" +
            app.flowChartTemp.isFlowchart +'&docStatusType='+ app.flowChartTemp.status1;
        } catch (error) {
          console.log(error);
          app.flowChartTemp = [];
        }
      }
    },
    getFlowChartList() {
      let userID = this.authCookie.userID;
      var flowChartListURL = "/api/flowChart?userID=" + userID;
      axios
        .get(flowChartListURL)
        .then((response) => {
          console.log(response);
          this.nodes = response.data.items;
          // if (
          //   response &&
          //   response.data &&
          //   response.data.items &&
          //   response.data.items.length > 0
          // ) {
          //   this.nodes = response.data.items;
          // }

          console.log(response.data.items);
          console.log(response.data.count);
        })
        .catch((reason) => {
          console.log(reason);
          this.nodes = [];
        });
    },
    getFlowChart() {
      let userID = this.authCookie.userID;
      var flowChartID = "/api/flowChart?userID=" + userID;
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].userID +
            "/flowChart/" +
          flowChartID
        )
        .then((response) => {
          console.log(response);
          // this.flowchart-stickyTemp = response.data.item;
          this.nodes = response.data.items;
          console.log(response.data.item);
        })
        .catch((reason) => {
          console.log(reason);
          this.nodes = [];
        });
    },
    isEditable() {
      return (
        !this.flowChartTemp.status1 ||
        ["approved", "library", "shared", "archived", "template"].indexOf(
          this.flowChartTemp.status1.toLowerCase()
        ) < 0
      );
    },
    isApprove() {
      return (
        this.flowChartTemp.status1 &&
        ["approved"].indexOf(this.flowChartTemp.status1.toLowerCase()) >= 0
      );
    },
    isLibrary() {
      return (
        this.flowChartTemp.status1 &&
        ["template"].indexOf(this.flowChartTemp.status1.toLowerCase()) >= 0
      );
    },
    isShare() {
      return (
        !(this.authCookie && this.authCookie.userName) &&
        this.flowChartTemp.status1 &&
        ["shared"].indexOf(this.flowChartTemp.status1.toLowerCase()) >= 0
      );
    },
    capitalizeFirstLetter(strVal) {
      if (strVal) return strVal.charAt(0).toUpperCase() + strVal.slice(1);
      return "";
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
    getId() {
    return uuidv4();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    removeSpan(e) {
      //remove span
      var u = e.target.getElementsByTagName("u");
      var i;
      if (u != undefined && u.length > 0) {
        for (i = 0; i < u.length; i++) {
          if (u[i].getAttribute("id") != null) {
            var htmlspan1 = u[i].getElementsByTagName("span");
            if (htmlspan1.length > 0) {
              htmlspan1[0].remove();
            }
          }
        }
      }
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["nodes"]) {
        obj["nodes"].forEach((note) => {
          this.deleteAttr(note);
        });
      }
    },
    setToDefault() {
      var i = 0;
      for (i = 0; i < this.finalarray.length; i++) {
        this.finalarray[i].defaultvalue = this.finalarray[i].oldvalue;
      }
      this.showLaunchBatchModal = false;
      //console.log(this.finalarray);
    },
    launchBatch() {
      let app = this;
      app.verify();
      app.showLaunchBatchModal = false;
      this.deleteAttr(this.flowChartTemp);
      var batchDoc = JSON.parse(JSON.stringify(this.flowChartTemp));
      //batchDoc.flowChartID = null;
      batchDoc.isFlowchart = this.flowChartTemp.isFlowchart;
      batchDoc.batchNumber = this.batchDocNumber;
      batchDoc.status1 = "batch";
      axios
        .post("/api/flowChartBatch", batchDoc)
        .then(function (response) {
          console.log(response);
          app.showShareModal = false;
          alert("Batch started successfully!");
          if(batchDoc.status1 == 'batch') {
              app.notifyUserBatch(batchDoc);
            }
          app.setToDefault();
          window.location =
            "/flowChart?docStatusType="+ batchDoc.status1 +
            "&isFlowchart=" +
            batchDoc.isFlowchart;
        })
        .catch(function (error) {
          console.log(error);
          app.flowChartTemp = [];
        });
    },
    verify() {
      var i = 0;
      for (i = 0; i < this.finalarray.length; i++) {
        if (this.finalarray[i].defaultvalue != this.finalarray[i].oldvalue) {
          //console.log('modify');
          var first = this.finalarray[i].sectionnum.toString().split(".")[0];
          var sec = this.finalarray[i].sectionnum.toString().split(".")[1];
          var oldstr =
            "[" +
            this.finalarray[i].variablename.toString() +
            "," +
            this.finalarray[i].oldvalue.toString() +
            "]";
          var newstr =
            this.finalarray[i].variablename.toString() +
            "," +
            this.finalarray[i].defaultvalue.toString();

          console.log(
            this.flowChartTemp.nodes[first - 1].meta[sec - 1].description
              .toString()
              .replace(oldstr, newstr)
          );
          this.flowChartTemp.nodes[first - 1].meta[sec - 1].description =
            this.flowChartTemp.nodes[first - 1].meta[sec - 1].description
              .toString()
              .replace(oldstr, newstr);
        }
      }
    },
    shareDocument() {
      let app = this;
      this.deleteAttr(this.flowChartTemp);
      if (app.isShare()) {
        this.flowChartTemp.emailShare = this.emailAddress;
        axios
          .put("/api/flowChart", app.flowChartTemp)
          .then(function (response) {
            console.log(response);
            app.flowChartTemp = response.data.item;
            alert("flowChart Shared Successfully!");

          })
          .catch(function (error) {
            console.log(error);
            app.flowChartTemp = [];
          });
      } else {
        var shareDoc = JSON.parse(JSON.stringify(this.flowChartTemp));
        // shareDoc.flowChartID = null;
        shareDoc.isFlowchart = this.flowChartTemp.isFlowchart;
        shareDoc.parentDocID = this.flowChartTemp.flowChartID;
        shareDoc.status1 = "shared";
        shareDoc.emailShare = this.emailAddress;
        axios
          .post("/api/flowChart", shareDoc)
          .then(function (response) {
            console.log(response);
            app.showShareModal = false;
            alert("Successfully shared document!");
            //app.flowChartTemp = response.data.item;
            if(shareDoc.status1 == 'shared') {
              app.notifyUser(shareDoc);
            }
            window.location =
            "/flowChart?docStatusType="+ shareDoc.status1 +
            "&isFlowchart=" +
            shareDoc.isFlowchart;
          })
          .catch(function (error) {
            console.log(error);
            app.flowChartTemp = [];
          });
      }
    },
    shareLibraryDocument() {
      let app = this;
      this.deleteAttr(this.flowChartTemp);
      var shareDoc = JSON.parse(JSON.stringify(this.flowChartTemp));
      shareDoc.flowChartID = null;
      shareDoc.parentDocID = this.flowChartTemp.flowChartID;
      shareDoc.status1 = "template";
      axios
        .post("/api/flowChart", shareDoc)
        .then(function (response) {
          console.log(response);
          alert("flowChart successfully shared to DigitalGxP Public Library!");
        })
        .catch(function (error) {
          console.log(error);
          app.flowChartTemp = [];
        })
        .then(function () {
          app.showShareModal = false;
        });
    },
    async approveDocument() {
      this.flowChartTemp.approvedBy = this.authCookie.email;
      this.flowChartTemp.status1 = "approved";
      await this.saveFlowChart();
      console.log("flowChart Approved Successfully!");

      this.showApproveModal = false;
      window.location =
        "/newFlowchart?flowChartid=" +
        this.flowChartTemp.flowChartID +
        "&isFlowchart=" +
        this.flowChartTemp.isFlowchart +'&docStatusType='+ this.flowChartTemp.status1;
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    openShare(shareName, $event) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }
      document.getElementById(shareName).style.display = "block";
      $event.target.className += " active";
    },
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;

//flowchartcss
rect.body.start {
  width: 120px !important;
  fill: white;
  stroke-width: 1px;
  height: 60px;
}
rect.body.end {
  width: 120px !important;
  fill: white;
  stroke-width: 1px;
  height: 60px;
}
rect.title {
  height: 55px !important;
  fill: white !important;
  stroke-width: 1px;
  width: 120px;
}
rect.body {
  width: 0px !important;
  fill: white;
  stroke-width: 1px;
  height: 40px;
}
.unselectable:nth-of-type(2) {
    display: none !important;
}
div#chart {
  width: 100% !important;
  height: 600px !important;
  overflow: scroll !important;
}
.float-buttons {
  text-align: center;
  margin-left: 43rem;
}
#svg {
    background-size: 0px !important;
    background-image: linear-gradient(90deg, #dfdfdf 1px, transparent 0), linear-gradient(180deg, #dfdfdf 1px, transparent 0), linear-gradient(90deg, #f1f1f1 1px, transparent 0), linear-gradient(180deg, #f1f1f1 1px, transparent 0);
    background-position: left -1px top -1px, left -1px top -1px, left -1px top -1px, left -1px top -1px;
    width: calc(100vh + 600px);
    margin-top: -20px;
    overflow: scroll;
    height: calc(100vh + 600px);
    position: relative;
    top: 10px;
}
#position {
    display: none !important;
    position: absolute;
    right: 4px;
}
#chart {
    position: relative;
    border: none;
}

// flowchart css

.layoutJSON {

    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}

.ag-theme-balham .ag-cell {
  display: flex;
  align-items: center;
  overflow: auto;
}
.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.editControlFlowchart {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
#editorControlListLeftFlowchartIcons {
  overflow: scroll;
  width: 65px;
  height: calc(100vh - 200px);
}
.add-flowchart {
  border: 1px solid #333;
  color: #000;
}
.add-flowchart:focus {
  border-color: #000;
  color: #000;
}
#arrow-right {
  content: " ";
  position: absolute;
  right: -1.5rem;
  top: 4rem;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid black;
  border-bottom: 15px solid transparent;
}
#arrow-left {
  content: " ";
  position: absolute;
  left: -1.5rem;
  top: 4rem;
  border-top: 15px solid transparent;
  border-right: 15px solid black;
  border-left: none;
  border-bottom: 15px solid transparent;
}
#arrow-bottom {
  content: " ";
  position: absolute;
  // right: 28rem;
  bottom: -1.5rem;
  margin-left: 6rem;
  // bottom: 2.8rem;
  border-top: 15px solid black;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}
#rightbottom-arrow{
  content: " ";
  position: absolute;
  right: 8rem;
  top: 17rem;
  border-top: 15px solid black;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}
#leftbottom-arrow{
  content: " ";
  position: absolute;
  left: 10rem;
  top: 17rem;
  border-top: 15px solid black;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}
#bottom-arrow{
  content: " ";
  position: absolute;
  margin-left: 7rem;
  top: 28.5rem;
  border-top: 15px solid black;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.7);
}
:placeholder {
  color: rgba(0, 0, 0, 0.7);
}
select.bg-option {
  display: none;
}
h1.mt-5.text-2xl {
  display: block;
}
.mb-8 {
  margin-bottom: 2rem !important;
  margin-right: 2rem !important;
}
.relative {
  position: relative;
}
.mt-5 {
  margin-top: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.float-right {
  float: right;
}
.flowchart-sticky {
  width: 35%;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 7rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.flowchart {
  width: 280px;
  min-height: 300px;
  box-shadow: 5px 5px 10px -2px rgba(33, 33, 33, 0.3);
  /* transform: skew(-1deg, 1deg); */
  transition: transform 0.15s;
  z-index: 1;
}

.flowchart textarea.cnt {
  min-height: 100px;
  outline: none;
  border: none;
  font-size: 1rem;
  line-height: 1.75rem;
}
.p-4 {
  padding: 1rem;
}
.w-full {
  width: 100%;
}
.p-1 {
  padding: 0.25rem;
}
.resize-y {
  resize: vertical;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}
.bg-option {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  margin-right: 2rem;
  height: 1.75rem;
  border-radius: 0.25rem;
}

.button.remove {
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e01c12;
  border-color: #b30000;
  font-weight: bolder;
  font-family: Helvetica, Arial, sans-serif;
}

.button.remove:hover {
  background-color: #ef0005;
  cursor: pointer;
}

.floating__btn {
  background-color: #172b4d !important;
  border-radius: 50%;
  border: none !important;
  // box-shadow: 0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%);
  z-index: 998;
  outline: none;
}
.float-left {
  float: left;
}
.w-16 {
  width: 4rem;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
.fixed {
  position: fixed;
}
.m-4 {
  margin: 1rem;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.h-16 {
  height: 4rem;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
.cursor-pointer {
  cursor: pointer;
}
.absolute {
  position: absolute;
}
.mt-0 {
  min-height: 25%;
}
#editorControlListLeftFlowchart {
  /* height: 550px; */
  overflow: scroll;
  width: 65px;
  height: calc(100vh - 80px);
}
.share-modal {
  max-width: 50%;
}
.doc-meta:hover {
  background-color: #f8f5fa;
}
.doc-meta {
  background-color: transparent;
  padding: 12px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-header {
  background-color: #dadce0;
}
div[data-oembed-url] > div > iframe {
  height: auto !important;
  width: auto !important;
}
div[data-oembed-url] > div {
  padding-bottom: 150px !important;
}
.editControlForm {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  /* height:290px; */
  /* height: calc(100% - 320px); */
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNew {
  width: calc(100% - 320px);
  margin-left: 110px;
  margin-right: 120px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.card.shadow8 {
  padding: 6px;
  /* width: 70%; */
  margin: auto;
  border: 1px solid #8080803b;
  // box-shadow: 0 0 2rem 0 rgb(136 152 170 / 20%)
}
.new-flowchart {
  font-weight: 700;
  text-align: center;
  /* margin-bottom: -20px; */
  width: calc(100% - 130px);
}
.draft-flowchart {
  font-weight: 700;
  text-align: center;
  width: calc(100% - 70px);
}
.btn-meta-action {
  background-color: inherit;
  border-color: transparent;
  color: inherit;
  z-index: 1001;
  top: -45px;
}
.meta {
  display: block;
}
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
em {
  display: none;
}
.tooltip1 {
}
.tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -270px;
  margin-top: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.tooltiptextimg {
  visibility: hidden;
  width: 450px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: -285px;
  margin-top: -107px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptextimg::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip1:hover .tooltiptextimg {
  visibility: visible;
  opacity: 1;
}
.input-type-numeric {
  visibility: visible;
}
.input-type-text {
  visibility: visible;
}
.radio-buttons {
  margin: 10px 10px;
}
.modal-dialog {
  max-width: 560px;
}
.custom {
  padding: 10px;
  border: 1px solid #aaaaaa;
}
button.delete {
  margin: 5px;
}
.form {
  margin: 12px;
}
/* .form-control-alternative {
  border: 1px solid #333;
  color: #000;
}
.form-control-alternative:focus {
  border-color: #000;
  color: #000;
} */
.btn {
  border-radius: 20px;
  width: 130px;
}
.btn-default.disabled,
.btn-default:disabled {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  cursor: not-allowed;
}


@media only screen and (max-width: 1024px) {
  .arrow-bottom {
    right: 35px !important;
}
.flowchart{
  width: 110px;
}
}
@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
