<template>
  <div>
    <div class="row">
      <div class="title_heading">
        {{ templateType ="document" }}
      </div>
      <div id="myDocument" v-if="!isSharedPage">
          <!-- <button class="btnn" :class="{btnactive:docStatusType === 'golive'}">
            <a href="/golive"><i class="ni ni-user-run i-golive"></i><span style="margin-left: 5px;">BATCH RUNS</span></a>
          </button> -->
        <a href="/golive">
          <button class="btnn" :class="{btnactive:docStatusType === 'golive'}">
            <i class="ni ni-user-run i-golive"></i><span style="margin-left: 5px;">BATCH RUNS</span>
          </button>
        </a>
        <router-link  :to="'/new'">
          <button  class="btnn btnwidth" :class="{btnactive:docStatusType === 'new'}" id="newButton">
            NEW
          </button>
        </router-link>
        <a href="/documents/draft">
          <button class="btnn btnwidth" :class="{btnactive:docStatusType === 'draft'}" id="draftButton">
            <!-- <a href="/documents/draft">DRAFTS</a> -->
            DRAFTS
          </button>
        </a>
        <a href="/documents/approved">
          <button class="btnn btnwidth" :class="{btnactive:docStatusType === 'approved'}" id="approvedButton">
            APPROVED
          </button>
        </a>
        <a href="/documents/archived">
          <button class="btnn btnwidth" :class="{btnactive:docStatusType === 'archived'}" id="archivedButton">
            ARCHIVED
          </button>
        </a>
        <a href="/documents/shared">
          <button class="btnn btnwidth" :class="{btnactive:docStatusType === 'shared'}" id="sharedButton">
            SHARED
          </button>
        </a>
      </div>
    </div>
  </div>
  
</template>
<script>
// import Vue from "vue";

export default {
  name: "document-header",
  data() {
    return {
      //docStatusType: "draft"
      templateType: "",
      industryItemType: "",
      industryItemID: "",
      isSharedPage: false,
      // activeBtn: "draft",
    };
  },
  beforeMount(){
    console.log("test");
    this.docStatusType = this.$route.query.docStatusType;
    if(!this.docStatusType && this.$route.path) {
      var routePath = this.$route.path;
      this.docStatusType = routePath.substring(routePath.lastIndexOf('/')+1)
    }
    this.templateType = this.$route.query.templateType;
    this.industryItemType = this.$route.query.industryItemType;
    this.industryItemID = this.$route.query.industryItemID;
    if (window.location.href.toLowerCase().includes('/shareddocument')){
      this.isSharedPage = true;
    }
  },
  methods: {
    onHeaderClick(activeBtn) {
      document.getElementById("myDocument").getElementsByClassName("btnactive")[0].classList.remove("btnactive");
      document.getElementById(activeBtn+"Button").classList.add("btnactive");
      return true;
    },
    openShare() {
      var header = document.getElementById("myDocument");
      var btns = header.getElementsByClassName("btnn");
      for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
        var current = document.getElementsByClassName("btnactive");
        current[0].className = current[0].className.replace("btnactive", "");
        this.className += "btnactive";
        });
      }
    },
    isMobileandTab() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
      }
    }
  }
};
</script>
<style>
.btnn{
  border: none;
  outline: none;
  padding: 10px 16px;
  background-color: #f1f1f1;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  transition: all .15s ease!important;
  letter-spacing: .025em!important;
  font-size: .775rem!important;
  box-shadow: 0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)!important;
  font-weight: 500!important;
  border: 1px solid #a2ccf9!important;
  padding: 0.625rem 1.25rem!important;
  line-height: 1.5!important;
  border-radius: 0.375rem!important;
  color: #172b4d!important;
  border-color: #dadce0!important;
}
.btnn.btnwidth {
  width: 130px;
}
.btnn a{
  color: #000;
}.btnn a:hover{
  color: #000;
}
.btnactive, .btnn:hover {
  background-color: #2dce89;
}
.col-sm1{
  padding:5px;
}
.col-sm2{
  padding-left: 10px;
}
.btn{
  width:130px;
}
.btnm{
  width:100px;
}
.custom-font{
  font-size: 8px;
} 
.btn-green{
   background-color: #2dce89 !important;
}
.btn-green:hover{
   background-color: #2dce89 !important;
}
.i-golive
{
  color:#08215d;
}
.title_heading {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: #00aff0;
  border-radius: 10px;
  /* border-radius: 5px 20px; */
  padding: 8px 15px;
  margin: 25px 5px 5px 18px;
}
/* .title_heading.center-heading {
  text-transform: uppercase;
  color: #fff;
  font-weight: 800;
  background: #00aff0;
  border-radius: 5px 20px;
  padding: 8px;
} */
#myDocument{
  margin-top: 25px;
  margin-left: 15px;
}
</style>
