<template>
  <div>
    <div style="clear: both; height: 270px">
      <form-header
        :docStatusType="docStatusType"
        :isForms="isForms"
        :industryItemType="industryItemType"
        :industryItemID="industryItemID"
      ></form-header>
      <div class="container-fluid">
        <div class="row">
        <div class="formbatch-header" v-if="formsTemp.title">
          {{ stripHTML(formsTemp.title) + " - " + formsTemp.batchNumber }}
        </div>
    </div>
        <div class="col editControlParent"></div>
        <div class="col editorFormBatch">
          <div class="card shadow3">
            <div class="contacts"></div>
            <div class="card-body">
              <div
                v-if="formsTemp.title"
                v-html="formsTemp.title"
                class="form-control-alternative"
                v-on:click="handleOverlaysClick"
              ></div>
              <div v-if="!formsTemp.title">
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                  v-on:click="handleOverlaysEdit"
                ></div>
              </div>
              <div class="sec-body" style="display: block">
                <div
                  v-for="(section, sec_index) in formsTemp.sections"
                  :key="section.uid"
                >
                  <div
                    :id="section.uid"
                    :data-secid="section.uid"
                    class="step-body"
                  >
                    <div class="section">
                      <label class="form-control-label">{{
                        "section" + (sec_index + 1) + " : "
                      }}</label>
                    </div>
                    <div class="sec-body" style="display: block">
                      <div
                        v-for="(step, step_index) in section.steps"
                        :key="step.uid"
                      >
                        <div
                          :id="step.uid"
                          :data-secid="section.uid"
                          class="step-body"
                        >
                          <label class="form-control-label">Form:</label>
                          <label class="form-control-label">{{
                            sec_index + 1 + "." + (step_index + 1)
                          }}</label>
                          <div class="table">
                            <button
                              class="btn btn-secondary"
                              style="width: 140px !important;"
                              @click="addRows(section, sec_index, step, step_index, $event)"
                            >
                              Add New Row
                            </button>
                            <table
                              class="table table-responsive"
                              cellspacing="2"
                              cellpadding="5"
                              id="data_table"
                              border="1"
                              style="margin-top: 10px"
                            >
                            <thead>
                              <tr>
                                <!-- <th scope="col" class="fontsize">Id</th> -->
                                <td scope="row" class="fontsize" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">{{ meta_index + 1 }}</td>
                              </tr>
                              <tr>
                                <td class="fontsize" v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">
                                    <span>
                                      <input
                                        type="text"
                                        class="fontsize"
                                        v-model="meta.description"
                                        style="border: none; outline: none;"
                                        readonly
                                      />
                                    </span>
                                </td>
                              </tr>
                              <tr>
                              <td v-for="(meta, meta_index) in step.meta" :key="meta_index.uid">
                                <input type="text" class="fontsize" v-model="meta.text" placeholder="Type Here"/>
                                <input type="checkbox" class="fontsize" v-model="meta.isChecked"
                                :disabled="meta.isChecked"
                                style="margin-left: 20px; height: 10px;"
                                @click="executeDate(meta)"
                                >
                                <br />
                                <span style="font-size: 12px;" v-if="meta.isChecked">{{meta.executedBy + " - on " + formatDate(meta.executedTime) }}</span>
                              </td>
                              </tr>
                              <tr v-for="(metaRow, metaRow_index) in step.metaRows" :key="metaRow_index">
                                <td v-for="row in metaRow" :key="row.id">
                                  <input v-bind:id="`text_${row.id}`" type="text" class="fontsize" placeholder="Type here"  
                                  v-model="row.text"/>
                                  <input v-bind:id="`checkbox_${row.id}`" type="checkbox" class="fontsize" v-model="row.checked"
                                  style="margin-left: 20px; height: 10px;"
                                  :disabled="row.executed"
                                  @click="executeDate(row)"
                                  />
                                  <br />
                                  <span style="font-size: 12px;" v-if="row.checked">{{row.executedBy + " - on " + formatDate(row.executedTime) }}</span>
                                </td>
                              </tr>
                            </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="step-action-btn-group"
                      style="
                        float: right;
                        padding-left: 2px;
                        margin-left: 5px;
                        color: #2dce89;
                      "
                    >
                      <div style="float: right">
                        <label for="checkbox" style="font-size: 14px"
                          >Executed</label
                        >
                        <input
                          type="checkbox"
                          id="checkbox"
                          style="margin-left: 5px"
                          v-model="section.executed"
                          :disabled="section.executed"
                          @click="executeStep($event, section)"
                        />
                        <span class="user-audit" v-if="section.executed">{{
                          section.executedBy +
                          " - on " +
                          formatDate(section.executedTime)
                        }}</span
                        ><br />
                        <label
                          for="checkbox"
                          style="margin-left: 5px; font-size: 14px"
                          >Verified</label
                        >
                        <input
                          type="checkbox"
                          id="checkbox"
                          style="margin-left: 5px"
                          v-model="section.approved"
                          :disabled="section.approved"
                          @click="approveStep($event, section)"
                        />
                        <span class="user-audit" v-if="section.approved">{{
                          section.approvedBy +
                          " - on " +
                          formatDate(section.approvedTime)
                        }}</span
                        ><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col editRightParent"
          v-if="this.formsTemp.status1 != 'archived'"
        >
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="saveDocument()">SAVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">REVIEW</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">COMMENT</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <a href="#!" class="btn btn-info" style="display: none">SHARE</a>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--   <a href="#!" class="btn btn-info">CHECK</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="archiveBatch($event, step)">
                FINISH
              </button>
              <!-- <a href="#!" class="btn btn-info" @click="archiveBatch($event, step)">FINISH</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{this.formsTemp}} -->
    <!-- Begin: Image Upload Batch Document -->
    <div v-if="showImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Image" }}
                  <button
                    type="button"
                    class="close"
                    @click="showImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Image {{ ImageVarName }}:</span>
                  <input
                    type="file"
                    id="file"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="file"
                    name="file-to-upload"
                    v-on:change="handleFileUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addImage($event)"
                    >Add Image</base-button
                  >
                  <p v-if="filespath">
                    <img
                      style="width: 100px; height: 100px; margin-bottom: 10px"
                      :src="filespath"
                      alt=""
                    />
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Image Upload Document -->

    <!-- Begin: QR Image Upload Batch Document -->
    <div v-if="showQRImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload QR Code" }}
                  <button
                    type="button"
                    class="close"
                    @click="showQRImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload QR Code {{ QRImageVarName }}:</span>
                  <p class="error">{{ error }}</p>
                  <qrcode-stream
                    style="width: 70%"
                    :camera="camera"
                    @decode="onDecode"
                    @init="onInit"
                  ></qrcode-stream>
                  <p class="decode-result">
                    Result: <b>{{ result }}</b>
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="ReplaceImg('QR')"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showQRImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: QR Image Upload Document -->

    <!-- Begin: Video Image Upload Batch Document -->
    <div v-if="showVideoBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Video" }}
                  <button
                    type="button"
                    class="close"
                    @click="showVideoBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Video {{ VideoVarName }}:</span>
                  <input
                    type="file"
                    id="filevideo"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="filevideo"
                    name="file-to-upload"
                    v-on:change="handleFileVideoUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addVideo($event)"
                    >Add Video</base-button
                  >

                  <p v-if="videopath">
                    <video width="320" height="240" controls>
                      <source :src="videopath" :type="videotype" />
                      Your browser does not support the video tag.
                    </video>
                  </p>

                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Video Image Upload Document -->

    <!-- Begin: Data Tred Batch Document -->
    <div v-if="showDataTredBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Data Trending" }}
                  <button
                    type="button"
                    class="close"
                    @click="showDataTredBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Data Trending</span>

                  <div class="table-responsive">
                    <base-table
                      class="table align-items-center table-flush"
                      :thead-classes="table - equ"
                      tbody-classes="list"
                      :data="documentdataTred"
                    >
                      <template slot="columns">
                        <th>Batch No</th>
                        <th>Variable Name</th>
                        <th>Value</th>
                      </template>

                      <template slot-scope="{ row }">
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.batchno }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.varname }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.varvalue }}</span>
                          </div>
                        </td>
                      </template>
                    </base-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Data Tred Document -->

    <!-- Begin: Cross Reference Batch Document -->
    <div v-if="showCrossRefBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Cross Reference" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCrossRefBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label
                    class="form-control-label"
                    v-html="documentcrossRef"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Cross Reference Document -->
  </div>
</template>
<script>
/* eslint-disable no-console */
// import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";

// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import formHeader from "./formHeader";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { formHeader, QrcodeStream },
  data() {
    return {
      rows: false,
      batchID: "",
      isForms: "",
      docStatusType: "",
      industryItemType: "",
      industryItemID: "",
      formsTemp: {},
      documentdataTred: [],
      documentcrossRef: "",
      temptag: [],
      tagjson: {},
      timermm: "00",
      timerss: "00",
      timerStart: false,
      spanVisible: false,
      oldstring: [],
      showImageBatchModal: false,
      showDataTredBatchModal: false,
      showCrossRefBatchModal: false,
      VideoVarName: "",
      videopath: "",
      videotype: "",
      videopathold: "",
      videotypeold: "",
      showVideoBatchModal: false,
      ImageVarName: "",
      ImageSrc: "",
      showQRImageBatchModal: false,
      QRImageVarName: "",
      QRImageSrc: "",
      result: "",
      oldString: "Upload QR Code",
      error: "",
      camera: "auto",
      files: "",
      filespath: "",
      tempstep: null,
      authCookie: null,
      updateSecId: "",
      updateStepId: "",
      type: [
        { id: "23", val: "Text" },
        { id: "13", val: "Number" },
        { id: "4", val: "Image" },
        { id: "5", val: "Video" },
      ],
    };
  },
  beforeMount() {
    this.batchID = this.$route.query.batchID;
    this.docStatusType = this.$route.query.docStatusType;
    this.isForms = this.$route.query.isForms;
    this.industryItemType = this.$route.query.industryItemType;
    this.industryItemID = this.$route.query.industryItemID;
  },
  mounted() {
    if (this.batchID) {
      axios
        .get(
          "/api/formsBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.batchID
        )
        .then((response) => {
          console.log(response);
          this.formsTemp = response.data.item;
          this.formsTemp.title = this.formsTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");
          this.usagelogtagTemp = {};

          //steplock
          this.steplock();
        })
        .catch((reason) => {
          console.log(reason);
          this.formsTemp = [];
          this.usagelogtagTemp = {};
        });
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    // this.authCookie = JSON.parse(authCookieObj.substring(2));
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token").substring(2).split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  beforeCreate() {
    //let recaptchaScript = document.createElement("script");
    //recaptchaScript.setAttribute("src", "ckeditor.js");
    //document.head.appendChild(recaptchaScript);
  },
  methods: {
    removeBorder(){
      var input = document.getElementsByClassName("fontsize");
      input.style.border = "none";
    },
    executeDate(row){
      this.$set(row, "executed", true);
      this.$set(row, "executedBy", this.authCookie.userName);
      this.$set(row, "executedTime", new Date());
    },
    addRow(section, sec_index, step) {
      if(step.rows){
        // var row = {};
      var table = document.getElementById("data_table");
      var row1 = table.insertRow(table.rows.length);
      var row2 = table.insertRow(table.rows.length);
      var cell;
      for(var i=0;i<step.meta.length;i++){
          cell = row1.insertCell(0);
          cell.innerHTML = "<input type='text' placeholder='Type Here' class='fontsize' id='input' v-model='description'>";
      }
      this.$set(step.rows, step.rows.length, cell.innerHTML);
      for(var j=0; j<step.meta.length; j++){
        cell = row2.insertCell(0);
        cell.innerHTML = "<input type='checkbox' class='fontsize' id='checkbox'>";
      }
      this.$set(step.rows, step.rows.length, cell.innerHTML);
      }
      // if (step.rows) {
      //   var row = {};
      // }
      // this.$set(step.rows, row);
      //   step.rows.push({
      //   id: uuidv4(),
      //   data: [{
      //     id: uuidv4(),
      //     description: '',
      //   }],
      // })
    },
    addRows(section, sec_index, step) {
      if (!step.metaRows) {
        this.$set(step, "metaRows", []);
      }
      var metaRow = [];
      for(var i=0; i < step.meta.length; i++) {
          metaRow.push({
          id: uuidv4(),
          metaId: step.meta[i].id,
          text: '',
          checked: '',
        });
      }
      this.$set(step.metaRows, step.metaRows.length, metaRow);
    },
    deleteRow(step, meta, meta_index) {
      step.meta.splice(meta_index, 1);
    },
    edit(obj) {
      this.$set(obj, "editmode", true);
      console.log("edit");
    },
    save(obj) {
      this.$set(obj, "editmode", false);
    },
    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      await this.timeout(3000);
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    turnCameraOff() {
      this.camera = "off";
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    steplock() {
      //steplock
      var steplockfound = false;
      var executedstap = false;
      var stepdeti;
      var stepdetj;
      var disarray = [];
      var i = 0;
      var totalsec = this.formsTemp.sections;
      if (!totalsec) {
        return;
      }
      for (i = 0; i < totalsec.length; i++) {
        var j = 0;
        var eachstep = totalsec[i].steps;
        for (j = 0; j < eachstep.length; j++) {
          //var v1 = eachstep[j].description.toString();
          var dom = document.createElement("div");
          dom.innerHTML = eachstep[j].description;
          var imgtag = dom.getElementsByTagName("img");
          if (imgtag != undefined && imgtag.length > 0) {
            var ki;
            for (ki = 0; ki < imgtag.length; ki++) {
              //console.log(imgtag[ki]);
              var imgtagalt = imgtag[ki].getAttribute("alt");
              //console.log(imgtagalt);
              if (imgtagalt.toString() == "StepLock") {
                steplockfound = true;
                stepdeti = i;
                stepdetj = j;
                if (
                  eachstep[j].executed != undefined &&
                  eachstep[j].executed == true
                ) {
                  executedstap = true;
                }
                //console.log((i+1) +'.'+ (j+1));
                //console.log(steplockfound);
              }
            }
          }
          if (steplockfound == true) {
            if (i == stepdeti) {
              if (j > stepdetj) {
                //console.log('desable');
                //console.log((i+1) +'.'+ (j+1));
                disarray.push(i + 1 + "." + (j + 1));
              }
            } else if (i > stepdeti) {
              //console.log('desable');
              //console.log((i+1) +'.'+ (j+1));
              disarray.push(i + 1 + "." + (j + 1));
            }
          }
        }
      }

      //find div
      //console.log('disarray');
      //console.log(disarray);
      if (disarray.length > 0) {
        setTimeout(function () {
          var cuslabel = document.querySelectorAll("label.form-control-label");
          //console.log(cuslabel);
          var li;
          for (li = 0; li < cuslabel.length; li++) {
            var ji;
            for (ji = 0; ji < disarray.length; ji++) {
              if (
                cuslabel[li].innerHTML.toString() == disarray[ji].toString()
              ) {
                //console.log(cuslabel[li].innerHTML.toString());
                //console.log(cuslabel[li].parentElement.setAttribute("class","custom-disable"));
                if (executedstap == true) {
                  if (
                    cuslabel[li].parentElement.getAttribute("class") !=
                    undefined
                  ) {
                    if (
                      cuslabel[li].parentElement
                        .getAttribute("class")
                        .toString() == "custom-disable"
                    ) {
                      cuslabel[li].parentElement.removeAttribute("class");
                    }
                  }
                } else {
                  cuslabel[li].parentElement.setAttribute(
                    "class",
                    "custom-disable"
                  );
                }
              }
            }
          }
        }, 100);
      }
    },
    previewImage(e) {
      console.dir(e);
      let app = this;
      var sec = app.formsTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (step && step[0]) {
        if (!step[0].meta) {
          step[0].meta = [];
        }
        for (var j = 0; j < step[0].meta.length; j++) {
          if (step[0].meta[j].type == "Image") {
            step[0].meta[j].imagePath = app.filespath;
            app.$set(step[0], 0);
            app.showImageBatchModal = false;
          }
        }
        app.$set(step[0], 0);
        }
      }
      app.showImageBatchModal = false;
    },
    previewVideo(e) {
      console.dir(e);
      let app = this;
      var sec = app.formsTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (step && step[0]) {
        if (!step[0].meta) {
          step[0].meta = [];
        }
        for (var j = 0; j < step[0].meta.length; j++) {
          if (step[0].meta[j].type == "Video") {
            step[0].meta[j].videoUrl = app.videopath;
            app.$set(step[0], 0);
            app.showVideoBatchModal = false;
          }
        }
        app.$set(step[0], 0);
        }
      }
      app.showVideoBatchModal = false;
    },
    crossref(e, section) {
      console.log(e);
      console.log(section);
      this.documentcrossRef = "No Data Found";
      var dom = document.createElement("div");
      dom.innerHTML = section.description;
      var atag = dom.getElementsByTagName("a");
      if (atag != undefined && atag.length > 0) {
        var ki;
        for (ki = 0; ki < atag.length; ki++) {
          if (
            atag[ki].innerHTML
              .toString()
              .indexOf("ross Reference / Document Title =", 0) > 0
          ) {
            var altarray = atag[ki].innerHTML.toString().split("/");
            if (altarray != undefined && altarray.length > 0) {
              var DocTitle = altarray[1].split("=")[1].toString().trim();
              var secid = altarray[2].split("=")[1].toString().trim();
              var stepid = altarray[3].split("=")[1].toString().trim();
              console.log(DocTitle);
              secid = secid - 1;
              stepid = stepid.split(".")[1] - 1;
              console.log(secid);
              console.log(stepid);
            }

            axios
              .get(
                "/api/organizations/" +
                  this.authCookie.orgs[0].orgID +
                  "/crossref/" +
                  DocTitle
              )
              .then((response) => {
                this.documentcrossRef =
                  response.data.item.sections[secid].description;
                console.log(this.documentcrossRef);
                this.showCrossRefBatchModal = true;
              })
              .catch((reason) => {
                console.log(reason);
                this.documentcrossRef = "No Data Found";
              });
          }
        }
      }
    },
    UploadVideo(e, section) {
      console.log(e);
      console.log(section);
      this.showVideoBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
    },
    UploadQR(e, section) {
      console.log(e);
      console.log(section);
      this.tempstep = section;
      var dom = document.createElement("div");
      dom.innerHTML = section.description;
      var imgtag = dom.getElementsByTagName("img");
      if (imgtag != undefined && imgtag.length > 0) {
        var ki;
        for (ki = 0; ki < imgtag.length; ki++) {
          var imgtagalt = imgtag[ki].getAttribute("alt");
          var altarray = imgtagalt.split("_");
          var varName = null;
          if (altarray != undefined && altarray.length > 0) {
            if (altarray[0] == "qrImage") {
              varName = altarray[1];
              this.QRImageVarName = varName;
              this.QRImageSrc = imgtagsrc;
              this.showQRImageBatchModal = true;
              this.turnCameraOn();
              this.result = "";
            }
          }
          var imgtagsrc = imgtag[ki].getAttribute("src");
          //console.log(imgtagalt);
        }
      }
    },
    UploadImg(e, section) {
      this.showImageBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      console.dir(e);
      console.dir(section);
    },
    handleFileUploads() {
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    handleFileVideoUploads() {
      this.files = this.$refs.filevideo.files[0];
      //console.log(this.files);
    },
    addVideo(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadVideosFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          //app.formsTemp = response.data.item;
          app.videopath = "";
          app.videotype = "";
          var path = response.data.fileUrl;
          //console.log(response.data.fileUrl);
          //console.log(response.data.fileName);
          //console.log(response.data.uploaded);
          //var type = response.data.fileName.split(".")[1];
          //if(type == "mp4")
          //{
          app.videopath = path.toString();
          app.videotype = response.data.type.toString();
          console.log(app.videopath);
          console.log(app.videotype);
          //}
          //else
          //{
          //app.filespath = path.toString();
          //}

          alert("Video Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
          //app.formsTemp = [];
        });
    },
    addImage(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          app.filespath = "";
          var path = response.data.fileUrl;
          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    formulaCalculate(e, section) {
      console.log(e);
      console.log(section);
      //var newstring = null;
      //console.log('old');
      //console.log(this.oldstring);
      if (this.oldstring != null && this.oldstring.length > 0) {
        var i;
        for (i = 0; i < this.oldstring.length; i++) {
          section.description = section.description
            .toString()
            .replace(this.oldstring[i], "");
        }
      }
      var dom = document.createElement("div");
      dom.innerHTML = section.description;
      var atag = dom.getElementsByTagName("a");
      if (atag != undefined && atag.length > 0) {
        var ki;
        for (ki = 0; ki < atag.length; ki++) {
          console.log(atag[ki].innerHTML);
          var text = atag[ki].innerHTML.toString().split("=");
          if (text != undefined && text[1] != undefined) {
            var ResultText = "Result : " + text[1].toString();
            while (text[1].toString().search("{") > 0) {
              var varname = text[1]
                .toString()
                .substring(
                  text[1].toString().search("{") + 1,
                  text[1].toString().search("}")
                );
              var orgtext = text[1]
                .toString()
                .substring(
                  text[1].toString().search("{"),
                  text[1].toString().search("}") + 1
                );
              //console.log(varname);
              //console.log(orgtext);
              var val1 = prompt("Enter Value for " + varname, "");
              text[1] = text[1].toString().replace(orgtext, val1);
              ResultText = ResultText.toString().replace(orgtext, val1);
            }
            //this.$set(step, "formulaText", text[1]);
            console.log(text[1]);
            var ResultVal = eval(text[1]);
            //console.log(ResultVal);
            this.oldstring[ki] = "<br>" + ResultText + " = " + ResultVal;
            //console.log('new');
            //console.log(this.oldstring);
            section.description = section.description
              .toString()
              .replace(
                atag[ki].innerHTML.toString(),
                atag[ki].innerHTML.toString() + this.oldstring[ki]
              );
            //step.description = step.description + ResultText + " = " + ResultVal;
            //step.description = step.description.toString().replace(atag[ki].innerHTML.toString(),ResultVal);
          }
        }
      }
      //step.formulafound = false;
    },
    startTimer(e, section) {
      this.timerStart = true;
      this.spanVisible = true;
      this.timermm = 0;
      this.timerss = 0;
      this.$set(section, "timermm", this.timermm);
      this.$set(section, "timerss", this.timerss);
      this.$set(section, "timerStart", this.timerStart);
      this.$set(section, "spanVisible", this.spanVisible);
      console.log(e);
      //console.log("Start Timer");
      //console.log(step);
      this.countDownTimer(section);
    },
    stopTimer(e, section) {
      console.log(e);
      //console.log(step);
      this.timerStart = false;
      section.timerStart = false;
      //console.log(this.timermm + ' : ' + this.timerss);
      this.countDownTimer(section);
    },
    countDownTimer(section) {
      //console.log('countDownTimer');
      //console.log(step);
      //console.log(step.timerStart);
      if (section.timerStart) {
        setTimeout(() => {
          section.timerss += 1;
          if (section.timerss == 60) {
            section.timerss = 0;
            section.timermm += 1;
          }
          this.countDownTimer(section);
        }, 1000);
      }
    },
    DataTrend(e, section, sec_index) {
      console.log(e);
      console.log(section);
      console.log(sec_index);
      // console.log(step_index);
      console.log(this.formsTemp.batchID);
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.formsTemp.batchID
        )
        .then((response) => {
          console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = "";
          this.documentdataTred = [];

          var i;
          for (i = 0; i < temp.length; i++) {
            var j;
            batchNo = temp[i].batchNumber;
            for (
              j = 0;
              j < temp[i].sections[sec_index].dataTrandings.length;
              j++
            ) {
              temptable = {};
              temptable.batchno = batchNo;
              temptable.varname =
                temp[i].sections[sec_index].dataTrandings[j].varname;
              temptable.varvalue =
                temp[i].sections[sec_index].dataTrandings[j].varvalue;
              this.$set(
                this.documentdataTred,
                this.documentdataTred.length,
                temptable
              );
            }
          }

          this.showDataTredBatchModal = true;
        })
        .catch((reason) => {
          console.log(reason);
          this.documentdataTred = [];
        });
    },
    setValue(e, section) {
      console.dir(e);
      console.log(section);
      let app = this;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = app.formsTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (step && step[0]) {
        if (!step[0].meta) {
          step[0].meta = [];
        }
        var pValText = prompt("Please Enter Text");
        for (var j = 0; j < step[0].meta.length; j++) {
          if (step[0].meta[j].type == "Text") {
            step[0].meta[j].text = pValText;
            app.$set(step[0], 0);
          }
        }
        app.$set(step[0], 0);
        }
      }
    },
    setNumber(e, section) {
      console.dir(e);
      console.log(section);
      let app = this;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = app.formsTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        var step = sec[0].steps.filter((y) => y.uid == app.updateStepId);
        if (step && step[0]) {
        if (!step[0].meta) {
          step[0].meta = [];
        }
        var pValText = prompt("Please Enter Number");
        for (var j = 0; j < step[0].meta.length; j++) {
          if (step[0].meta[j].type == "Number") {
            step[0].meta[j].number = pValText;
            app.$set(step[0], 0);
          }
        }
        app.$set(step[0], 0);
        }
      }
    },
    executeStep(e, section) {
      //usagelog
      //alert('Title ' + this.formsTemp.title);
      //alert('Batch Number ' + this.formsTemp.batchNumber);
      //var el = e.target.parentElement.parentElement.querySelector(".form-control-label");
      var el = e.target.parentElement.parentElement.querySelectorAll(
        "label.form-control-label"
      );
      //var el = el1.getElementsByClassName(".form-control-label");
      var steptext;
      if (el != undefined && el[0] != undefined && el[1] != undefined) {
        steptext = el[0].innerText.toString() + el[1].innerText.toString();
        //alert(el[0].innerText.toString() + el[1].innerText.toString());
      }
      //alert(steptext);
      var tagel = e.target.parentElement.parentElement.querySelector(
        ".form-control-alternative"
      );
      var u = undefined;
      if (tagel != null) {
        u = tagel.getElementsByTagName("u");
      }
      //console.log(u);
      var i;
      if (u != undefined) {
        //var temptag = [];
        for (i = 0; i < u.length; i++) {
          var itag = u[i].getElementsByTagName("em");
          if (itag[0] != undefined && itag[0].innerText != undefined) {
            u[i].setAttribute("id", itag[0].innerText.toString().split(",")[0]);
            var tagid = itag[0].innerText.toString().split(",")[0];
            var tagname = itag[0].innerText.toString().split(",")[2];
            //alert(tagid + ': ' + tagname );
            //var tagjson = {};
            this.tagjson.title = this.formsTemp.title;
            this.tagjson.batchNumber = this.formsTemp.batchNumber;
            this.tagjson.orgID = this.authCookie.orgs[0].orgID;
            this.tagjson.stepNo = steptext;
            this.tagjson.equID = tagid;
            this.tagjson.equName = tagname;
            this.tagjson.executedBy = this.authCookie.userName;
            this.tagjson.executedTime = new Date();
            //console.log(tagjson);
            this.temptag.push(this.tagjson);
          }
        }
      }

      console.log(section);
      if (section.timer != undefined) {
        section.description =
          section.description +
          " Timer(MM:SS) = " +
          section.timermm +
          " : " +
          section.timerss;
        this.$set(section, "timer", false);
      }
      if (section.inputfound != undefined) {
        this.$set(section, "inputfound", false);
      }
      if (section.qrimagefound != undefined) {
        this.$set(section, "qrimagefound", false);
      }
      if (section.videofound != undefined) {
        this.$set(section, "videofound", false);
      }
      if (section.mediaimagefound != undefined) {
        this.$set(section, "mediaimagefound", false);
      }
      if (section.formulafound != undefined) {
        this.$set(section, "formulafound", false);
      }
      this.$set(section, "executed", true);
      this.$set(section, "executedBy", this.authCookie.userName);
      this.$set(section, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "executedTime", new Date());
      console.log(section);
    },
    approveStep(e, section) {
      this.$set(section, "approved", true);
      this.$set(section, "approvedBy", this.authCookie.userName);
      this.$set(section, "approvedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "approvedTime", new Date());
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach((sec) => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach((step) => {
          this.deleteAttr(step);
        });
      }
    },
    archiveBatch() {
      let app = this;
      this.deleteAttr(this.formsTemp);
      this.formsTemp.status1 = "archived";
      axios
        .put("/api/formsBatch", this.formsTemp)
        .then(function (response) {
          console.log(response);
          alert("Batch archived successfully!");
        })
        .catch(function (error) {
          console.log(error);
          app.formsTemp = [];
        });
    },
    saveDocument() {
      this.steplock();
      let app = this;
      this.deleteAttr(this.formsTemp);
      if (!this.formsTemp.batchID) {
        axios
          .post("/api/formsBatch", app.formsTemp)
          .then(function (response) {
            console.log(response);
            app.formsTemp = response.data.item;
            alert("Document Created Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.formsTemp = [];
          });
      } else {
        app.formsTemp.updatedBy =
          app.formsTemp.updatedBy || this.authCookie.userName;
        axios
          .put("/api/formsBatch", app.formsTemp)
          .then(function (response) {
            console.log(response);
            app.formsTemp = response.data.item;
            alert("Document Saved Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.formsTemp = [];
          });
      }

      console.log(this.temptag);
      var c;
      for (c = 0; c < this.temptag.length; c++) {
        axios
          .post("/api/addusagelog", this.temptag[c])
          .then(function (response) {
            console.log(response);
            //app.usagelogtagTemp = response.data.item;
            //alert("Usage Log Added Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            //this.usagelogtagTemp = {};
          });
      }
      this.temptag = [];
      this.tagjson = {};
    },
    handleOverlaysClick(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);
      }
    },
    handleOverlaysEdit(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);

        var pValTime = prompt(
          "Attach Time needed for a particular STEP",
          e.target.innerText
        );
        if (!pValTime) return;

        e.target.innerText = pValTime;
      }
    },
    getId() {
      return uuidv4();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
    },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
button.delete {
  margin: 5px;
}
.fontsize{
  font-size: 10px;
}
.btn-default{
    color: #fff;
    background-color: #172b4d;
    border-color: #172b4d;
}
#editorControlListLeftForms {
  height: calc(100vh - 80px);
  overflow: scroll;
  width: 65px;
}
.btn{
  width: 130px;
}
.user-audit {
  margin-left: 5px;
  color: darkgray !important;
}
.editControlParent {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorFormBatch {
  width: calc(100% - 90px);
  margin-left: -5px;
  // margin-right: 120px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.sec-toggle {
  background-color: #0001;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadow3 {
  border: 1px solid #dadce0 !important;
  padding: 6px;
  // margin-top: 20px;
}
.formbatch-header {
  font-weight: 700;
  // top: -100px;
  // position: relative;
  // left: 110px;
  text-align: center;
  font-size: 20px;
  // background-color: #ffff;
  // margin-right: 95px;
  // padding-left: 10px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // border-radius: 0.375rem;
  width: calc(100% - 180px);
}
.custom-disable {
  opacity: 0.4;
}
.custom-disable input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] {
  display: inline;
}
.custom-disable button {
  display: none;
}
button {
  display: inline;
}
.btn-default1 {
  margin-left: 5px;
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  // box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}

.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}
.ag-theme-balham .ag-cell {
  align-items: center;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value span {
  height: 20px;
}
.ag-theme-balham .ag-cell {
  line-height: 15px !important;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid transparent;
  padding-left: 11px;
  padding-right: 11px;
  display: -webkit-inline-box !important;
}

@media only screen and (max-width: 1024px) {
  #data_table{
    display: block;
    overflow-x: auto;
  }
}

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
