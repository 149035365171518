<template>
  <div>
    <div style="clear: both; height: 270px">
      <pdf-header
        :docStatusType="docStatusType"
        :ispdf="ispdf"
        :industryItemType="industryItemType"
        :industryItemID="industryItemID"
      ></pdf-header>
      <div class="container-fluid">
        <div class="row">
          <div class="pdf-header" v-if="pdfTemp.title">
            {{ stripHTML(pdfTemp.title) + " - " + pdfTemp.batchNumber }}
          </div>
        </div>
        <button class="btn-default1 btn-sm btn-step-action1 view-batch-data"
        v-if="pdfTemp && pdfTemp.sections && showViewBatchDataButton()"
        @click="viewList()"
        >View Batch Data</button>


        <div class="col editControlPdf"></div>
        <div class="col editorPdf">
          <div class="card shadow4">
            <div class="contacts"></div>
            <div class="card-body">
              <div
                v-if="pdfTemp.title"
                v-html="pdfTemp.title"
                class="form-control-alternative"
                v-on:click="handleOverlaysClick"
              ></div>
              <div v-if="!pdfTemp.title">
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                  v-on:click="handleOverlaysEdit"
                ></div>
              </div>
              <div class="sec-body" style="display: block">
                <div
                  v-for="(section, sec_index) in pdfTemp.sections"
                  :key="section.uid"
                >
                  <div
                    :id="section.uid"
                    :data-secid="section.uid"
                    class="step-body"
                  >
                    <div class="section" style="margin-top: 50px">
                      <p
                        v-if="section.notApplicable"
                        style="color: red; margin-left: 20px"
                      >
                        Not Applicable
                      </p>
                      <!-- <label class="form-control-label" style="margin: 7px">{{
                        "Page" + (sec_index + 1) + " : "
                      }}</label> -->
                      <div
                        class="row"
                        style="
                          float: right;
                          margin-top: -53px;
                          margin-bottom: -10px;
                        "
                      >
                        <div style="float: right; color: rgb(45, 206, 137)">
                          <label
                            for="checkbox"
                            v-if="section.notApplicable"
                            style="font-size: 14px; color: red"
                            >NA</label
                          >
                          <label
                            for="checkbox"
                            v-else
                            style="font-size: 14px; color: rgb(45, 206, 137)"
                            >NA</label
                          >
                          <input
                            type="checkbox"
                            id="checkbox"
                            style="margin-left: 5px"
                            v-model="section.notApplicable"
                            :disabled="section.notApplicable"
                            @click="notApplicableSection($event, section)"
                          />
                          <span
                            class="user-audit"
                            v-if="section.notApplicable"
                            >{{
                              section.notApplicableBy +
                              " - on " +
                              formatDate(section.notApplicableTime)
                            }}</span
                          ><br />
                          <label
                            for="checkbox"
                            style="margin-left: 5px; font-size: 14px"
                            >Executed</label
                          >
                          <input
                            type="checkbox"
                            id="checkbox"
                            style="margin-left: 5px"
                            v-model="section.executed"
                            :disabled="section.executed"
                            @click="executeStep($event, section, sec_index)"
                          />
                          <span class="user-audit" v-if="section.executed">{{
                            section.executedBy +
                            " - on " +
                            formatDate(section.executedTime)
                          }}</span
                          ><br />
                          <label
                            for="checkbox"
                            style="margin-left: 5px; font-size: 14px"
                            >Verified</label
                          >
                          <input
                            type="checkbox"
                            id="checkbox"
                            style="margin-left: 5px"
                            v-model="section.approved"
                            :disabled="section.approved"
                            @click="approveStep($event, section, sec_index)"
                          />
                          <span class="user-audit" v-if="section.approved">{{
                            section.approvedBy +
                            " - on " +
                            formatDate(section.approvedTime)
                          }}</span
                          ><br />
                        </div>
                      </div>
                      <div class="items-dragging">
                        <div id="drag" class="col" style="display: flex">
                          <div
                            :id="section.uid"
                            class="form-control-alternative textarea pdf-step pdf-preview-batch"
                            @blur="onFocushandle(section, $event)"
                            v-if="section.pdfupload"
                          >
                            <div
                              v-for="(meta, meta_index) in section.meta"
                              :key="meta_index.uid"
                            >
                              <div
                                class="app-header"
                                v-if="meta && meta.type == 'pdf'"
                              >
                                <span v-if="meta.showAllPages">
                                  {{ meta.pageCount }} page(s)
                                </span>
                                <label class="right"> Show all pages </label>
                              </div>
                              <div class="app-content">
                                <vue-pdf-embed
                                  v-if="meta && meta.type == 'pdf'"
                                  ref="pdfRef"
                                  :source="meta.pdfPath"
                                  :page="meta.page"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="data"
                          class="col"
                          style="display: flex"
                          v-for="(meta, meta_index) in section.meta"
                          :key="meta_index.uid"
                        >
                          <div
                            class="form-control-alternative textarea pdf-step pdf-drag-batch"
                            v-if="meta.addblankpage"
                          >
                            <div
                              v-for="(
                                metadata, metadata_index
                              ) in meta.metadata"
                              :key="metadata_index.uid"
                            >
                              <div
                                class="step-action-btn-group"
                                style="
                                  float: right;
                                  padding-left: 2px;
                                  margin-left: 5px;
                                  color: #2dce89;
                                "
                              >
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.datatrendfound
                                  "
                                  @click="
                                    DataTrend(
                                      $event,
                                      meta,
                                      metadata,
                                      sec_index,
                                      meta_index,
                                      metadata_index
                                    )
                                  "
                                >
                                  Data Trend
                                </button>
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="section.crossreffound"
                                  @click="crossref($event, section)"
                                >
                                  Cross Reference</button
                                ><br
                                  v-if="
                                    section.crossreffound &&
                                    !section.videofound &&
                                    !section.qrimagefound &&
                                    !section.mediaimagefound &&
                                    !section.formulafound &&
                                    !section.timer &&
                                    !section.inputfound
                                  "
                                />
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.type == 'mediaVideo' &&
                                    !section.executed
                                  "
                                  @click="UploadVideo($event, section)"
                                >
                                  Upload Video</button
                                ><br
                                  v-if="
                                    metadata.videofound &&
                                    !metadata.qrimagefound &&
                                    !metadata.mediaimagefound &&
                                    !metadata.formulafound &&
                                    !metadata.timer &&
                                    !metadata.inputfound
                                  "
                                />
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.type == 'mediaQRcode' &&
                                    !section.executed
                                  "
                                  @click="UploadQR($event, section)"
                                >
                                  Upload QR Code</button
                                ><br
                                  v-if="
                                    metadata.qrimagefound &&
                                    !metadata.mediaimagefound &&
                                    !metadata.formulafound &&
                                    !metadata.timer &&
                                    !metadata.inputfound
                                  "
                                />
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.type == 'mediaImage' &&
                                    !section.executed
                                  "
                                  @click="UploadImg($event, section)"
                                >
                                  Upload Image</button
                                ><br
                                  v-if="
                                    metadata.mediaimagefound &&
                                    !metadata.formulafound &&
                                    !metadata.timer &&
                                    !metadata.inputfound
                                  "
                                />
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.type == 'formula' &&
                                    !section.executed
                                  "
                                  @click="
                                    formulaCalculate(
                                      $event,
                                      section,
                                      meta,
                                      metadata
                                    )
                                  "
                                >
                                  Calculate</button
                                ><br
                                  v-if="
                                    metadata.formulafound &&
                                    metadata.type == 'formula'
                                  "
                                />
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.type == 'timer' &&
                                    !section.executed
                                  "
                                  @click="
                                    startTimer($event, section, meta, metadata)
                                  "
                                >
                                  Start Timer
                                </button>
                                <span
                                  v-if="
                                    metadata.type == 'timer' &&
                                    section.meta[0].metadata.spanVisible &&
                                    !section.executed
                                  "
                                  >{{
                                    " " +
                                    section.meta[0].metadata.timermm +
                                    " : " +
                                    section.meta[0].metadata.timerss +
                                    " "
                                  }}</span
                                >
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.type == 'timer' &&
                                    !section.executed
                                  "
                                  @click="
                                    stopTimer($event, section, meta, metadata)
                                  "
                                >
                                  Stop Timer</button
                                ><br
                                  v-if="metadata.timer && !metadata.inputfound"
                                />
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.inputfound && !section.executed
                                  "
                                  @click="
                                    setValue($event, section, meta, metadata)
                                  "
                                >
                                  Enter Data</button
                                ><br v-if="metadata.inputfound" />
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="metadata.mcq && !section.executed"
                                  @click="selectOption(metadata)"
                                >Select Option</button><br v-if="metadata.mcq">
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="metadata.skillfound"
                                  @click="skillTrend($event,meta,metadata,sec_index,meta_index,metadata_index)"
                                >Skill Trend</button>
                                <button
                                  class="btn-default1 btn-sm btn-step-action1"
                                  v-if="
                                    metadata.type == 'table' &&
                                    !section.executed
                                  "
                                  @click="
                                    addRows($event, section, meta, metadata)
                                  "
                                >
                                  Add Rows
                                </button>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'table'"
                              >
                                <ag-grid-vue
                                  style="height: 200px"
                                  class="ag-theme-balham addrows"
                                  :columnDefs="metadata.columnDefs"
                                  :rowData="metadata.rowData"
                                  rowSelection="multiple"
                                  rowHeight="50"
                                  id="myForm"
                                  editable="true"
                                  pagination="true"
                                  paginationPageSize="10"
                                  floatingFilter="true"
                                >
                                </ag-grid-vue>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'grid'"
                              >
                                <grid-layout
                                :layout.sync="layout"
                                :col-num="colNum"
                                :row-height="30"
                                :is-draggable="draggable"
                                :is-resizable="resizable"
                                :vertical-compact="true"
                                :use-css-transforms="true"
                              >
                              <grid-item v-for="item in metadata.grids"
                                  :static="item.static"
                                  :x="item.x"
                                  :y="item.y"
                                  :w="item.w"
                                  :h="item.h"
                                  :i="item.i"
                                  :key="item.i"
                              >
                                <textarea
                                  id="message"
                                  style="width: 90%"
                                  name="message"
                                  v-model="item.textarea"
                                  placeholder="Enter text here..."
                                ></textarea>
                              </grid-item>
                                </grid-layout>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'text'"
                              >
                                <textarea
                                  id="message"
                                  style="width: 100%"
                                  name="message"
                                  v-model="metadata.textarea"
                                  placeholder="Enter text here..."
                                ></textarea>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'image'"
                              >
                                <img
                                  :src="metadata.imagePath"
                                  style="width: 200px"
                                  class="img-fluid"
                                />
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'video'"
                              >
                                <video width="320" height="240" controls>
                                  <source
                                    :src="metadata.videoPath"
                                    :type="videotype"
                                  />
                                </video>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'timer'"
                              >
                                <img
                                  style="width: 50px"
                                  :src="metadata.description"
                                  alt="Timer"
                                  class="u-photo"
                                />
                                <p>{{ metadata.timerValue }}</p>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'formula'"
                              >
                                <p>
                                  <a href="javascript:void(0)"
                                    >Formula {{ metadata.formuladatalabel }} =
                                    {{ metadata.formula }}</a
                                  >
                                </p>
                                <span v-if="metadata.ResultText"
                                  ><a href="javascript:void(0)">{{
                                    metadata.ResultText
                                  }}</a></span
                                >
                              </div>
                              <div
                                class="textdrag-controls"
                                v-if="metadata && metadata.type == 'mediaImage'"
                              >
                                <img
                                  style="width: 50px"
                                  :src="metadata.description"
                                  alt="mediaImage_"
                                  class="u-photo"
                                />
                                <span
                                  id="upload-button"
                                  @click="previewImage($event)"
                                  >Upload Image</span
                                >
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'mediaVideo'"
                              >
                                <span
                                  ><a href="javascript:void(0)"
                                    >Upload Video: {{ metadata.description }}</a
                                  ></span
                                ><br />
                                <span v-if="metadata.videoUrl">
                                  <video width="300px" height="200px" controls>
                                    <source
                                      :src="metadata.videoUrl"
                                      :type="videotype"
                                    />
                                  </video>
                                </span>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="
                                  metadata && metadata.type == 'mediaQRcode'
                                "
                              >
                                <img
                                  style="width: 50px"
                                  :src="metadata.description"
                                  alt="qrImage_"
                                  class="u-photo"
                                />Upload QR Code
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'data-input'"
                              >
                                <span
                                  ><a href="javascript:void(0)"
                                    >{{ metadata.description }}:{{
                                      metadata.value
                                    }}</a
                                  ></span
                                >
                              </div>
                              <div
                                class="drag-controls"
                                v-if="
                                  metadata &&
                                  metadata.type == 'dataNumberTranding' &&
                                  metadata.datatrendfound && !metadata.acceptablerange
                                "
                              >
                                <span
                                  ><a href="javascript:void(0)"
                                    >{{ metadata.description }}:{{
                                      metadata.value
                                    }}[DataTrend]</a
                                  ></span
                                >
                              </div>
                              <div class="drag-controls" v-if="metadata && metadata.type == 'dataNumberTranding' && metadata.datatrendfound && metadata.acceptablerange">
                                  <span v-if="metadata.value != '___' && metadata.value > metadata.maxvalue || metadata.value < metadata.minvalue">
                                    <a href="javascript:void(0)">{{metadata.description}}:<span style="color:red;">{{metadata.value}}</span>[DataTrend] [{{metadata.minvalue}} - {{metadata.maxvalue}}]
                                    <span style="color:red;">outside acceptable range</span>
                                    </a>
                                  </span>
                                <span v-else><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}[DataTrend] [{{metadata.minvalue}} - {{metadata.maxvalue}}]</a></span>
                              </div>
                              <div class="drag-controls" v-if="metadata && metadata.type == 'dataNumberTranding' && !metadata.datatrendfound && !metadata.acceptablerange">
                              <span><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'dataNumberTranding' && !metadata.datatrendfound && metadata.acceptablerange">
                              <span v-if="metadata.value != '___' && metadata.value > metadata.maxvalue || metadata.value < metadata.minvalue">
                                <a href="javascript:void(0)">{{metadata.description}}:<span style="color:red;">{{metadata.value}}</span>
                                [{{metadata.minvalue}} - {{metadata.maxvalue}}]<span style="color:red;">outside acceptable range</span>
                                </a>
                              </span>
                              <span v-else><a href="javascript:void(0)">{{metadata.description}}:{{metadata.value}}[{{metadata.minvalue}} - {{metadata.maxvalue}}]</a></span>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'iot-input'">
                              <p><a href="javascript:void(0)">{{ metadata.description }}</a></p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'mcq-input'">
                              <p><a href="javascript:void(0)">{{ metadata.description }}</a></p>
                              <p v-if="metadata.value != ''">Option Selected:<b style="font-weight:bold;"> {{ metadata.value }} </b></p>
                            </div>
                            <div class="drag-controls" v-if="metadata && metadata.type == 'skill-input'">
                              <img style="width: 50px;" :src="metadata.description"  class="u-photo"/>
                            </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'var-input'"
                              >
                                <p>
                                  <a href="javascript:void(0)"
                                    >[{{ metadata.variableName }},
                                    {{ metadata.value }}]</a
                                  >
                                </p>
                              </div>
                              <div
                                class="drag-controls"
                                v-if="metadata && metadata.type == 'steplock'"
                              >
                                <img
                                  style="width: 50px"
                                  :src="metadata.description"
                                  class="u-photo"
                                />
                              </div>
                              <div class="drag-controls" v-if="metadata && metadata.type == 'verifierInput'">
                                <p><a href="javascript:void(0)">VERIFIER: {{ metadata.description }}</a></p>
                              </div>
                              <div class="drag-controls" v-if="metadata && metadata.type == 'cautionInput'">
                                <p><a href="javascript:void(0)">Caution: {{ metadata.description }}</a></p>
                              </div>
                              <div class="drag-controls" v-if="metadata && metadata.type == 'crossrefInput'">
                                <p><a href="javascript:void(0)">Cross Reference / Document Title = {{ metadata.description }}</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col editRightParent"
          v-if="this.pdfTemp.status1 != 'archived'"
        >
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="saveDocument()">SAVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">REVIEW</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">COMMENT</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <a href="#!" class="btn btn-info" style="display: none">SHARE</a>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--   <a href="#!" class="btn btn-info">CHECK</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="archiveBatch($event, step)">
                FINISH
              </button>
              <!-- <a href="#!" class="btn btn-info" @click="archiveBatch($event, step)">FINISH</a> -->
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <!--    <a href="#!" class="btn btn-info">DELETE</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- {{this.pdfTemp}} -->
    <!-- Begin: Image Upload Batch Document -->
    <div v-if="showImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Image" }}
                  <button
                    type="button"
                    class="close"
                    @click="showImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Image {{ ImageVarName }}:</span>
                  <input
                    type="file"
                    id="file"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="file"
                    name="file-to-upload"
                    v-on:change="handleFileUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addImage($event)"
                    >Add Image</base-button
                  >
                  <p v-if="filespath">
                    <img
                      style="width: 100px; height: 100px; margin-bottom: 10px"
                      :src="filespath"
                      alt=""
                    />
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Image Upload Document -->

    <!-- Begin: QR Image Upload Batch Document -->
    <div v-if="showQRImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload QR Code" }}
                  <button
                    type="button"
                    class="close"
                    @click="showQRImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload QR Code {{ QRImageVarName }}:</span>
                  <p class="error">{{ error }}</p>
                  <qrcode-stream
                    style="width: 70%"
                    :camera="camera"
                    @decode="onDecode"
                    @init="onInit"
                  ></qrcode-stream>
                  <p class="decode-result">
                    Result: <b>{{ result }}</b>
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewQRcode($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showQRImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: QR Image Upload Document -->

    <!-- Begin: Video Image Upload Batch Document -->
    <div v-if="showVideoBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Video" }}
                  <button
                    type="button"
                    class="close"
                    @click="showVideoBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Video {{ VideoVarName }}:</span>
                  <input
                    type="file"
                    id="filevideo"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="filevideo"
                    name="file-to-upload"
                    v-on:change="handleFileVideoUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addVideo($event)"
                    >Add Video</base-button
                  >

                  <p v-if="videopath">
                    <video width="320" height="240" controls>
                      <source :src="videopath" :type="videotype" />
                      Your browser does not support the video tag.
                    </video>
                  </p>

                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Video Image Upload Document -->

    <!-- Begin: Data Tred Batch Document -->
    <div class="overlay-modal" v-if="showDataTredBatchModal"  style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" @click="planetData()">View Graph</button>
      <canvas id="planet-chart"></canvas>

      <div v-if="showDataTredBatchModal">
        <transition name="modal">
          <div class="modal-mask-Batch">
            <div class="modal-wrapper-Batch">
              <div class="modal-dialog share-modal">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "Data Trending" }}
                    <button
                      type="button"
                      class="close"
                      @click="showDataTredBatchModal = false"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <span>Data Trending</span>

                    <div class="table-responsive">
                      <base-table
                        class="table align-items-center table-flush"
                        tbody-classes="list"
                        :data="documentdataTred"
                      >
                        <template slot="columns">
                          <th>Batch No</th>
                          <th>Variable Name</th>
                          <th>Value</th>
                        </template>

                        <template slot-scope="{ row }">
                          <td>
                            <div class="avatar-group">
                              <span class="status">{{ row.batchno }}</span>
                            </div>
                          </td>
                          <td>
                            <div class="avatar-group">
                              <span class="status">{{ row.varname }}</span>
                            </div>
                          </td>
                          <td>
                            <div class="avatar-group">
                              <span class="status">{{ row.varvalue }}</span>
                            </div>
                          </td>
                        </template>
                      </base-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- End: Data Tred Document -->

    <!-- skilltrend -->
    <div class="overlay-modal graph-button" v-if="showSkillTredBatchModal" style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" @click="skillData()">Executed Graph</button>
      <button class="btn-default1 btn-sm btn-step-action1" @click="verifiedSkillData()">Verified Graph</button>
      <canvas id="planet-chart"></canvas>
    
      <div v-if="showSkillTredBatchModal">
          <transition name="modal">
            <div class="modal-mask-Batch">
              <div class="modal-wrapper-Batch">
                <div class="modal-dialog share-modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      {{ "Skill Trending" }}
                      <button
                        type="button"
                        class="close"
                        @click="showSkillTredBatchModal=false">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <span>Skill Trending</span>
                        
                      <div class="table-responsive">
                          <base-table
                            class="table align-items-center table-flush"
                            tbody-classes="list"
                            :data="pdfSkill"
                          >
                            <template slot="columns">
                              <th>Batch No</th>
                              <th>Executed User</th>
                              <th>Verified User</th>
                            </template>
                            <template slot-scope="{row}">
                              <td>
                                <div class="avatar-group">
                                  <span class="status">{{row.batchno}}</span>
                                </div>
                              </td>
                            <td>
                                <div class="avatar-group" v-for="(executeduser, executeduser_index) in row.executedUsers" :key="executeduser_index">
                                  <span class="status">{{executeduser.executeduser}}</span>
                                </div>
                              </td>
                              <td>
                                <div class="avatar-group" v-for="(verifieduser, verifieduser_index) in row.verifiedUsers" :key="verifieduser_index">
                                  <span class="status">{{verifieduser.verifieduser}}</span>
                                </div>
                              </td>
                            </template>
                          </base-table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
      </div>
    </div>
    <!-- end skill trend -->

    <!-- Begin: Cross Reference Batch Document -->
    <div v-if="showCrossRefBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Cross Reference" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCrossRefBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label
                    class="form-control-label"
                    v-html="documentcrossRef"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Cross Reference Document -->

    <div v-if="showViewListBatchModal">
        <transition name="modal">
          <div class="modal-mask-Batch view-data">
            <div class="modal-wrapper-Batch">
              <div class="modal-dialog share-modal">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "View list of data-inputs and formula" }}
                    <button
                      type="button"
                      class="close"
                      @click="showViewListBatchModal=false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush formula-datainput-table-list"
                          tbody-classes="list"
                          :data="documentviewList"
                        >
                          <template slot="columns">
                            <th>Step No</th>
                            <th>Data Label</th>
                            <th>Data</th>
                          </template>
                          <template slot-scope="{row}" v-if="row.type">
                            <td> {{row.stepno}} </td>
                            <td> {{row.datalabel}} </td>
                            <td> {{row.data}} </td>
                          </template>
                        </base-table>
                    </div>
                    <!-- <i class="fa fa-download"></i> -->
                    <button @click="tableToCSV()"
                    class="btn float-right btn-default btn-sm m-2">
                    Export as CSV</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showViewListBatchModal=false"
                  >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>

  </div>
</template>
<script>
/* eslint-disable no-console */
import { AgGridVue } from "ag-grid-vue";
import axios from "../../../utils/axiosinstance";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import pdfHeader from "./pdfHeader";
import { QrcodeStream } from "vue-qrcode-reader";
import Chart from 'chart.js';
import { GridLayout, GridItem } from "vue-grid-layout";

export default {
  components: { pdfHeader, QrcodeStream, AgGridVue, VuePdfEmbed, GridLayout, GridItem },
  // props: ["docid", "templateType", "docStatusType"],
  data() {
    return {
      layout: [
                { x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), },
            ],
      draggable: true,
      resizable: true,
      colNum: 12,
      batchID: "",
      ispdf: "",
      docStatusType: "",
      industryItemType: "",
      industryItemID: "",
      pdfTemp: {},
      rowData: [],
      inputRow: {},
      gridOptions: null,
      documentdataTred: [],
      showCanvas: false,
      pdfSkill: [],
      documentviewList: [],
      documentcrossRef: "",
      temptag: [],
      tagjson: {},
      timermm: "00",
      timerss: "00",
      timerStart: false,
      spanVisible: false,
      oldstring: [],
      showImageBatchModal: false,
      showDataTredBatchModal: false,
      showSkillTredBatchModal: false,
      showViewListBatchModal: false,
      showCrossRefBatchModal: false,
      VideoVarName: "",
      videopath: "",
      videotype: "",
      videopathold: "",
      videotypeold: "",
      showVideoBatchModal: false,
      ImageVarName: "",
      ImageSrc: "",
      showQRImageBatchModal: false,
      QRImageVarName: "",
      QRImageSrc: "",
      result: "",
      oldString: "Upload QR Code",
      error: "",
      camera: "auto",
      files: "",
      filespath: "",
      tempstep: null,
      authCookie: null,
      updateSecId: "",
      updateStepId: "",
    };
  },
  beforeMount() {
    this.batchID = this.$route.query.batchID;
    this.docStatusType = this.$route.query.docStatusType;
    this.ispdf = this.$route.query.ispdf;
    this.industryItemType = this.$route.query.industryItemType;
    this.industryItemID = this.$route.query.industryItemID;
  },
  mounted() {
    if (this.batchID) {
      axios
        .get(
          "/api/pdfBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.batchID
        )
        .then((response) => {
          console.log(response);
          this.pdfTemp = response.data.item;
          this.pdfTemp.title = this.pdfTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");
          this.usagelogtagTemp = {};

          //steplock
          this.steplock();
        })
        .catch((reason) => {
          console.log(reason);
          this.pdfTemp = [];
          this.usagelogtagTemp = {};
        });
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    // this.authCookie = JSON.parse(authCookieObj.substring(2));
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token").substring(2).split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  beforeCreate() {
    //let recaptchaScript = document.createElement("script");
    //recaptchaScript.setAttribute("src", "ckeditor.js");
    //document.head.appendChild(recaptchaScript);
  },
  methods: {
    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      await this.timeout(3000);
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    turnCameraOff() {
      this.camera = "off";
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    steplock() {
      //steplock
      var steplockfound = false;
      var executedstap = false;
      var stepdeti;
      var stepdetj;
      var disarray = [];
      var i = 0;
      var totalsec = this.pdfTemp.sections;
      if (!totalsec) {
        return;
      }
      for (i = 0; i < totalsec.length; i++) {
        var j = 0;
        var eachstep = totalsec[i].steps;
        for (j = 0; j < eachstep.length; j++) {
          //var v1 = eachstep[j].description.toString();
          var dom = document.createElement("div");
          dom.innerHTML = eachstep[j].description;
          var imgtag = dom.getElementsByTagName("img");
          if (imgtag != undefined && imgtag.length > 0) {
            var ki;
            for (ki = 0; ki < imgtag.length; ki++) {
              //console.log(imgtag[ki]);
              var imgtagalt = imgtag[ki].getAttribute("alt");
              //console.log(imgtagalt);
              if (imgtagalt.toString() == "StepLock") {
                steplockfound = true;
                stepdeti = i;
                stepdetj = j;
                if (
                  eachstep[j].executed != undefined &&
                  eachstep[j].executed == true
                ) {
                  executedstap = true;
                }
                //console.log((i+1) +'.'+ (j+1));
                //console.log(steplockfound);
              }
            }
          }
          if (steplockfound == true) {
            if (i == stepdeti) {
              if (j > stepdetj) {
                //console.log('desable');
                //console.log((i+1) +'.'+ (j+1));
                disarray.push(i + 1 + "." + (j + 1));
              }
            } else if (i > stepdeti) {
              //console.log('desable');
              //console.log((i+1) +'.'+ (j+1));
              disarray.push(i + 1 + "." + (j + 1));
            }
          }
        }
      }

      //find div
      //console.log('disarray');
      //console.log(disarray);
      if (disarray.length > 0) {
        setTimeout(function () {
          var cuslabel = document.querySelectorAll("label.form-control-label");
          //console.log(cuslabel);
          var li;
          for (li = 0; li < cuslabel.length; li++) {
            var ji;
            for (ji = 0; ji < disarray.length; ji++) {
              if (
                cuslabel[li].innerHTML.toString() == disarray[ji].toString()
              ) {
                //console.log(cuslabel[li].innerHTML.toString());
                //console.log(cuslabel[li].parentElement.setAttribute("class","custom-disable"));
                if (executedstap == true) {
                  if (
                    cuslabel[li].parentElement.getAttribute("class") !=
                    undefined
                  ) {
                    if (
                      cuslabel[li].parentElement
                        .getAttribute("class")
                        .toString() == "custom-disable"
                    ) {
                      cuslabel[li].parentElement.removeAttribute("class");
                    }
                  }
                } else {
                  cuslabel[li].parentElement.setAttribute(
                    "class",
                    "custom-disable"
                  );
                }
              }
            }
          }
        }, 100);
      }
    },
    crossref(e, section) {
      console.log(e);
      console.log(section);
      this.documentcrossRef = "No Data Found";
      var dom = document.createElement("div");
      dom.innerHTML = section.description;
      var atag = dom.getElementsByTagName("a");
      if (atag != undefined && atag.length > 0) {
        var ki;
        for (ki = 0; ki < atag.length; ki++) {
          if (
            atag[ki].innerHTML
              .toString()
              .indexOf("ross Reference / Document Title =", 0) > 0
          ) {
            var altarray = atag[ki].innerHTML.toString().split("/");
            if (altarray != undefined && altarray.length > 0) {
              var DocTitle = altarray[1].split("=")[1].toString().trim();
              var secid = altarray[2].split("=")[1].toString().trim();
              var stepid = altarray[3].split("=")[1].toString().trim();
              console.log(DocTitle);
              secid = secid - 1;
              stepid = stepid.split(".")[1] - 1;
              console.log(secid);
              console.log(stepid);
            }

            axios
              .get(
                "/api/organizations/" +
                  this.authCookie.orgs[0].orgID +
                  "/crossref/" +
                  DocTitle
              )
              .then((response) => {
                this.documentcrossRef =
                  response.data.item.sections[secid].description;
                console.log(this.documentcrossRef);
                this.showCrossRefBatchModal = true;
              })
              .catch((reason) => {
                console.log(reason);
                this.documentcrossRef = "No Data Found";
              });
          }
        }
      }
    },
    UploadVideo(e, section) {
      console.log(e);
      console.log(section);
      this.showVideoBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
    },
    UploadQR(e, section) {
      console.log(e);
      console.log(section);
      this.showQRImageBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      this.tempstep = section;
      var dom = document.createElement("div");
      dom.innerHTML = section.description;
      var imgtag = dom.getElementsByTagName("img");
      if (imgtag != undefined && imgtag.length > 0) {
        var ki;
        for (ki = 0; ki < imgtag.length; ki++) {
          var imgtagalt = imgtag[ki].getAttribute("alt");
          var altarray = imgtagalt.split("_");
          var varName = null;
          if (altarray != undefined && altarray.length > 0) {
            if (altarray[0] == "qrImage") {
              varName = altarray[1];
              this.QRImageVarName = varName;
              this.QRImageSrc = imgtagsrc;
              this.showQRImageBatchModal = true;
              this.turnCameraOn();
              this.result = "";
            }
          }
          var imgtagsrc = imgtag[ki].getAttribute("src");
          //console.log(imgtagalt);
        }
      }
    },
    UploadImg(e, section) {
      console.log(e);
      console.log(section);
      this.showImageBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
    },
    handleFileUploads() {
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    handleFileVideoUploads() {
      this.files = this.$refs.filevideo.files[0];
      //console.log(this.files);
    },
    addVideo(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadVideosFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          app.videopath = "";
          app.videotype = "";
          var path = response.data.fileUrl;
          app.videopath = path.toString();
          app.videotype = response.data.type.toString();
          console.log(app.videopath);
          console.log(app.videotype);
          alert("Video Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addImage(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          app.filespath = "";
          var path = response.data.fileUrl;
          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage(e) {
      console.dir(e);
      let app = this;
      var sec = app.pdfTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata[j].mediaimagefound) {
            sec[0].meta[0].metadata[j].imagePath = app.filespath;
            sec[0].meta[0].metadata[j].description = sec[0].meta[0].metadata[
              j
            ].description
              .toString()
              .replace(
                sec[0].meta[0].metadata[j].description,
                sec[0].meta[0].metadata[j].imagePath
              );
            document.getElementById("upload-button").innerHTML = "";
            app.$set(sec[0], 0);
            app.showImageBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
      }
      app.showImageBatchModal = false;
    },
    previewVideo(e) {
      console.dir(e);
      let app = this;
      var sec = app.pdfTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata[j].videofound) {
            sec[0].meta[0].metadata[j].videoUrl = app.videopath;
            app.$set(sec[0], 0);
            app.showVideoBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
      }
      app.showVideoBatchModal = false;
    },
    previewQRcode(e) {
      console.dir(e);
      let app = this;
      var sec = app.pdfTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        for (var j = 0; j < sec[0].meta[0].metadata.length; j++) {
          if (sec[0].meta[0].metadata[j].qrimagefound) {
            app.$set(sec[0], 0);
            app.showQRImageBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
      }
      app.showQRImageBatchModal = false;
    },
    formulaCalculate(e, section, meta, metadata) {
      console.log(e);
      //console.log(section);
      for (var i = 0; i < section.meta.length; i++) {
        for (var j = 0; j < section.meta[i].metadata.length; j++) {
          if (
            section.meta[i].metadata[j].uid == metadata.uid &&
            section.meta[i].metadata[j].formulafound
          ) {
            var ResultValue = eval(section.meta[i].metadata[j].formula);
            //section.meta[i].ResultText = "Result : " + section.meta[i].formula.toString() + " = " + ResultValue;
            this.$set(
              metadata,
              "ResultText",
              "Result : " +
                section.meta[i].metadata[j].formula.toString() +
                " = " +
                ResultValue
            );
          }
        }
      }
    },
    startTimer(e, section) {
      this.timerStart = true;
      this.spanVisible = true;
      this.timermm = 0;
      this.timerss = 0;
      this.$set(section.meta[0].metadata, "timermm", this.timermm);
      this.$set(section.meta[0].metadata, "timerss", this.timerss);
      this.$set(section.meta[0].metadata, "timerStart", this.timerStart);
      this.$set(section.meta[0].metadata, "spanVisible", this.spanVisible);
      console.log(e);
      //console.log("Start Timer");
      //console.log(step);
      this.countDownTimer(section.meta[0].metadata);
    },
    stopTimer(e, section) {
      console.log(e);
      console.log(section);
      this.timerStart = false;
      section.meta[0].metadata.timerStart = false;
      //console.log(this.timermm + ' : ' + this.timerss);
      this.countDownTimer(section.meta[0].metadata);
    },
    countDownTimer(section) {
      //console.log('countDownTimer');
      //console.log(step);
      //console.log(step.timerStart);
      if (section.timerStart) {
        setTimeout(() => {
          section.timerss += 1;
          if (section.timerss == 60) {
            section.timerss = 0;
            section.timermm += 1;
          }
          this.countDownTimer(section);
        }, 1000);
      }
    },
    DataTrend(e, meta, metadata, sec_index, meta_index, metadata_index) {
      console.log(e);
      console.log(metadata);
      console.log(sec_index);
      console.log(meta);
      console.log(meta_index);
      console.log(metadata_index);
      axios
        .get(
          "/api/pdfBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then((response) => {
          console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = "";
          this.documentdataTred = [];

          var i;
          for (i = 0; i < temp.length; i++) {
            var j;
            batchNo = temp[i].batchNumber;
            for (
              j = 0;
              j <
              temp[i].sections[sec_index].meta[meta_index].metadata[
                metadata_index
              ].dataTrandings.length;
              j++
            ) {
              temptable = {};
              temptable.batchno = batchNo;
              temptable.varname =
                temp[i].sections[sec_index].meta[meta_index].metadata[
                  metadata_index
                ].dataTrandings[j].varname;
              temptable.varvalue =
                temp[i].sections[sec_index].meta[meta_index].metadata[
                  metadata_index
                ].dataTrandings[j].varvalue;
              this.$set(this.documentdataTred,this.documentdataTred.length, temptable);
            }
          }

          this.showDataTredBatchModal = true;
        })
        .catch((reason) => {
          console.log(reason);
          this.documentdataTred = [];
        });
    },
    onChangeExecute(section, sec_index) {
    console.log(section);
    console.log(sec_index);
    axios
        .get(
          "/api/pdfBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.pdfSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              batchNo = temp[i].batchNumber;
                for(var j=0;j<temp[i].sections[sec_index].meta[0].metadata.length;j++){
                  for(var k=0;k<temp[i].sections[sec_index].meta[0].metadata[j].skills.length;k++){
                  temptable = {};
                  if (this.pdfTemp.batchNumber == batchNo) {
                    var executedUser = {};
                    executedUser.executeduser = this.authCookie.userName;
                    section.meta[0].metadata[j].skills[k].executedUsers.push(executedUser);
                    section.meta[0].metadata[j].skills[k].batchno = this.pdfTemp.batchNumber;
                    temp[i].sections[sec_index].meta[0].metadata[j].skills[k].executedUsers = section.meta[0].metadata[j].skills[k].executedUsers;
                    temp[i].sections[sec_index].meta[0].metadata[j].skills[k].verifiedUsers = section.meta[0].metadata[j].skills[k].verifiedUsers;
                  }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[sec_index].meta[0].metadata[j].skills[k].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[sec_index].meta[0].metadata[j].skills[k].verifiedUsers;
                this.$set(this.pdfSkill, this.pdfSkill.length, temptable);
              }
                }
              }
        })
        .catch(reason => {
          console.log(reason);
          this.pdfSkill = [];
        });
      this.$set(section, "executed", true);
      this.$set(section, "executedBy", this.authCookie.userName);
      this.$set(section, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "executedTime", new Date());
    },
    onChangeApprove(section,sec_index)
    {
      console.log(section);
      console.log(sec_index);
      axios
        .get(
          "/api/pdfBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.pdfSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              batchNo = temp[i].batchNumber;
              for(var j=0;j<temp[i].sections[sec_index].meta[0].metadata.length;j++){
                  for(var k=0;k<temp[i].sections[sec_index].meta[0].metadata[j].skills.length;k++){
                temptable = {};
                if (this.pdfTemp.batchNumber == batchNo) {
                var verifiedUser = {};
                  verifiedUser.verifieduser = this.authCookie.userName;
                  section.meta[0].metadata[j].skills[k].verifiedUsers.push(verifiedUser);
                  section.meta[0].metadata[j].skills[k].batchno = this.pdfTemp.batchNumber;
                  temp[i].sections[sec_index].meta[0].metadata[j].skills[k].executedUsers = section.meta[0].metadata[j].skills[k].executedUsers;
                  temp[i].sections[sec_index].meta[0].metadata[j].skills[k].verifiedUsers = section.meta[0].metadata[j].skills[k].verifiedUsers;
                }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[sec_index].meta[0].metadata[j].skills[k].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[sec_index].meta[0].metadata[j].skills[k].verifiedUsers;
                  this.$set(this.pdfSkill, this.pdfSkill.length, temptable);
              }
              }
            }
        })
        .catch(reason => {
          console.log(reason);
          this.pdfSkill = [];
        });
      this.$set(section, "approved", true);
      this.$set(section, "approvedBy", this.authCookie.userName);
      this.$set(section, "approvedByOrg",this.authCookie.orgs[0].orgID);
      this.$set(section, "approvedTime", new Date());
    },
    selectOption(metadata){
      var pValText = prompt(" Enter selected option number only", "" );
      if (!pValText) return;
      metadata.value += pValText + " ";
    },
    skillTrend(e, meta, metadata, sec_index, meta_index, metadata_index)
    {
      console.log(e);
      console.log(metadata);
      console.log(sec_index);
      console.log(meta);
      console.log(meta_index);
      console.log(metadata_index);
      console.log(this.batchID);
      axios
          .get(
          "/api/pdfBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
          )
          .then(response => {
            console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = "";
          this.pdfSkill = [];
              var i;
              for(i=0;i<temp.length;i++)
              {
                var j
                batchNo = temp[i].batchNumber;
                for(j=0;j<temp[i].sections.length;j++){
                  for(var k=0;k<temp[i].sections[j].meta[0].metadata.length;k++){
                    for(var l=0;l<temp[i].sections[j].meta[0].metadata[k].skills.length;l++){
                  temptable = {};
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].sections[j].meta[0].metadata[k].skills[l].executedUsers;
                  temptable.verifiedUsers = temp[i].sections[j].meta[0].metadata[k].skills[l].verifiedUsers;
                  this.$set(this.pdfSkill, this.pdfSkill.length, temptable);
                  }
                  }
                }
              }
            this.showSkillTredBatchModal = true;
          })
          .catch(reason => {
            console.log(reason);
            this.pdfSkill = [];
          });
    },
    planetData(){
      // console.log(pdfTemp);
      axios
        .get(
          "/api/pdfBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var planetTemp = response.data.item;
          for(let i = 0; i < planetTemp.sections.length; i++){
            for(let j = 0; j < planetTemp.sections[i].meta[0].metadata.length; j++){
              if(planetTemp.sections[i].meta[0].metadata[j].dataTrandings){
              for(let k = 0; k < planetTemp.sections[i].meta[0].metadata[j].dataTrandings.length; k++){
                var variableBatchno = planetTemp.sections[i].meta[0].metadata[j].dataTrandings[k].batchno;
                var variableName = planetTemp.sections[i].meta[0].metadata[j].dataTrandings[k].varname;
                var variableValue = planetTemp.sections[i].meta[0].metadata[j].dataTrandings[k].varvalue;
                console.log(variableValue);
                this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "line",
                    data: {
                      labels: [variableBatchno],
                      datasets: [
                        {
                          label: variableName,
                          data: [variableBatchno],            
                          borderColor: "#36495d",
                          borderWidth: 2,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                              // fontSize: 40,
                              // max: 100,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Values ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Batch Number ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
                }
              }
            }
          }
        })
        .catch(reason => {
          console.log(reason);
          this.pdfTemp = [];
        });
    },
    showViewBatchDataButton(){
    console.log(this.pdfTemp);
    for( var i = 0; i < this.pdfTemp.sections.length; i++) {
      for( var j = 0; j < this.pdfTemp.sections[i].meta[0].metadata.length; j++ ) {
          console.log(this.pdfTemp.sections[i].meta[0].metadata[j].formulafound);
          if(this.pdfTemp.sections[i].meta[0].metadata[j].formulafound || this.pdfTemp.sections[i].meta[0].metadata[j].datatrendfound || this.pdfTemp.sections[i].meta[0].metadata[j].inputfound){
          return this.pdfTemp.sections[i].meta[0].metadata[j].formulafound || this.pdfTemp.sections[i].meta[0].metadata[j].datatrendfound || this.pdfTemp.sections[i].meta[0].metadata[j].inputfound;
          }
      }
    }
    return false;
    },
    viewList()
    {
      axios
          .get(
            "/api/pdfBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
          .then(response => {
            var temp = response.data.items;
            var temptable = {};
            this.documentviewList = [];
              for(var i=0;i<temp.length;i++){
                for(var j=0;j<temp[i].sections.length;j++){
                for(var k=0;k<temp[i].sections[j].meta[0].metadata.length;k++){
                  temptable = {};
                  if(temp[i].sections[j].meta[0].metadata[k].formulafound){
                  var labelText = temp[i].sections[j].meta[0].metadata[k].formuladatalabel;
                  var formulaResult = temp[i].sections[j].meta[0].metadata[k].ResultText.split(':')[1].split('=')[1];
                  var formula = temp[i].sections[j].meta[0].metadata[k].formulafound;
                  temptable.stepno =  j+1 + "." + (k+1);
                  temptable.datalabel = labelText;
                  temptable.data = formulaResult;
                  temptable.type = formula;
                  }
                  else if(temp[i].sections[j].meta[0].metadata[k].inputfound || temp[i].sections[j].meta[0].metadata[k].datatrendfound){
                    var inputLabel = temp[i].sections[j].meta[0].metadata[k].description;
                    var inputData ;
                    if(temp[i].sections[j].meta[0].metadata[k].inputfound){
                      inputData = temp[i].sections[j].meta[0].metadata[k].value.split('_')[0];
                    }
                    if(temp[i].sections[j].meta[0].metadata[k].datatrendfound){
                      inputData = temp[i].sections[j].meta[0].metadata[k].value;
                    }
                    var inputdata = temp[i].sections[j].meta[0].metadata[k].inputfound || temp[i].sections[j].meta[0].metadata[k].datatrendfound;
                    temptable.stepno =  j+1 + "." + (k+1);
                    temptable.datalabel = inputLabel;
                    temptable.data = inputData;
                    temptable.type = inputdata;
                  }
                  if (temptable && temptable.stepno) {
                  this.$set(this.documentviewList, this.documentviewList.length, temptable);
                  }
                  }              
                }
              }          
            this.showViewListBatchModal = true;
          })
          .catch(reason => {
            console.log(reason);
            this.documentviewList = [];
          });

    },
    tableToCSV(){
    let csv_data = [];
    // let tableList = document.querySelectorAll("table formula-datainput-table-list tr");
    let rows = document.getElementsByTagName('tr');
    for (let i = 0; i < rows.length; i++) {
        let cols = rows[i].querySelectorAll('td,th');
        let csvrow = [];
        for (let j = 0; j < cols.length; j++) {
            csvrow.push(cols[j].innerHTML);
        }
        csv_data.push(csvrow.join(","));
    }
    csv_data = csv_data.join('\n');
    this.downloadCSVFile(csv_data);
  },
  downloadCSVFile(csv_data) {
    var CSVFile = {};
    CSVFile = new Blob([csv_data], {
        type: "text/csv"
    });
      let temp_link = document.createElement('a');
    temp_link.download = "GfG.csv";
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
  },
    skillData(){
      axios
        .get(
            "/api/pdfBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempExecuteObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].sections.length; j++){
            for(let k = 0; k < skillTemp[i].sections[j].meta[0].metadata.length; k++){
              for(let l = 0; l < skillTemp[i].sections[j].meta[0].metadata[k].skills.length; l++){
                var executeduser = skillTemp[i].sections[j].meta[0].metadata[k].skills[l].executedUsers[0].executeduser;
                
                if(tempExecuteObj.get(executeduser)) {
                  let count = tempExecuteObj.get(executeduser);
                  tempExecuteObj.set(executeduser, ++count);
                } else {
                  tempExecuteObj.set(executeduser, 1);
                }                
              }
                }
              }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempExecuteObj.keys()),
                      datasets: [
                        {
                          label: "executedUser",
                          data: Array.from(tempExecuteObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.pdfTemp = [];
        });
    },
    verifiedSkillData(){
      axios
        .get(
            "/api/pdfBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempVerifyObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].sections.length; j++){
            for(let k = 0; k < skillTemp[i].sections[j].meta[0].metadata.length; k++){
              for(let l = 0; l < skillTemp[i].sections[j].meta[0].metadata[k].skills.length; l++){
                var verifieduser = skillTemp[i].sections[j].meta[0].metadata[k].skills[l].verifiedUsers[0].verifieduser;
                if(tempVerifyObj.get(verifieduser)) {
                  let count = tempVerifyObj.get(verifieduser);
                  tempVerifyObj.set(verifieduser, ++count);
                } else {
                  tempVerifyObj.set(verifieduser, 1);
                }                
              }
            }
            }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempVerifyObj.keys()),
                      datasets: [
                        {
                          label: "verifieduser",
                          data: Array.from(tempVerifyObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.pdfTemp = [];
        });
    },
    setValue(e, section, meta, metadata) {
      console.dir(e);
      console.log(section);
      let app = this;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = app.pdfTemp.sections.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        if (!sec[0].meta[0].metadata) {
          sec[0].meta[0].metadata = [];
        }
        var VarName = metadata.description;
        console.log(metadata.description);
        var pValText = prompt("Please Enter Value for " + VarName + ":", "");
        if (!pValText) return;
        if (metadata.type == "data-input") {
          metadata.value = pValText + "_____";
        } 
        else if (
          metadata.type == "dataNumberTranding" &&
          metadata.datatrendfound
        ) {
          metadata.value = pValText;
          if (
            metadata.dataTrandings != undefined &&
            metadata.dataTrandings.length > 0
          ) {
            var j;
            for (j = 0; j < metadata.dataTrandings.length; j++) {
              if (metadata.dataTrandings[j].varname == VarName) {
                metadata.dataTrandings[j].varvalue = pValText;
                metadata.dataTrandings[j].batchno = this.pdfTemp.batchNumber;
              }
            }
          }
        } 
        else if (
          metadata.type == "dataNumberTranding" &&
          !metadata.datatrendfound) {
          metadata.value = pValText;
        }
        app.$set(sec[0], 0);
      }
    },
    addRows(e, section, meta, metadata) {
      console.dir(e);
      console.log(section);
      // var x = document.createElement("INPUT");
      //         x.setAttribute("type", "text");
      //         x.setAttribute("name", "textInput");
      //         x.setAttribute("value", "");
      //         document.getElementById("myForm").appendChild(x);
      let app = this;
      var data = {};
      for (var k = 0; k < metadata.columnDefs.length; k++) {
        if (metadata.columnDefs[k].field) {
          data[metadata.columnDefs[k].field] = "";
        }
      }
      metadata.rowData.push(data);
      app.$set(section, 0);
    },
    executeStep(e, section, sec_index) {
      console.log(section);
      for (var j = 0; j < section.meta[0].metadata.length; j++) {
        if (section.meta[0].metadata[j].timer) {
          // section.meta[j].executed = true;
          section.meta[0].metadata[j].description;
          section.meta[0].metadata[j].timerValue =
            " Timer(MM:SS) = " +
            section.meta[0].metadata.timermm +
            " : " +
            section.meta[0].metadata.timerss;
          this.$set(section.meta[0].metadata[j], "timer", false);
        }
        if(section.meta[0].metadata[j].skillfound){
            this.onChangeExecute(section, sec_index);
        }
        if (section.meta.inputfound != undefined) {
          this.$set(section.meta, "inputfound", false);
        }
        if (section.qrimagefound != undefined) {
          this.$set(section, "qrimagefound", false);
        }
        if (section.videofound != undefined) {
          this.$set(section, "videofound", false);
        }
        if (section.mediaimagefound != undefined) {
          this.$set(section, "mediaimagefound", false);
        }
        if(section.mcq != undefined){
        this.$set(section, "mcq", false);
        }
        // if (section.meta[j].formulafound) {
        //   this.$set(section.meta[j], "formulafound", false);
        // }
        // }
      }
      this.$set(section, "executed", true);
      this.$set(section, "executedBy", this.authCookie.userName);
      this.$set(section, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "executedTime", new Date());
      console.log(section);
    },
    approveStep(e, section, sec_index) {
      for (var j = 0; j < section.meta[0].metadata.length; j++) {
        if(section.meta[0].metadata[j].skillfound){
          this.onChangeApprove(section, sec_index);
        }
      }
      this.$set(section, "approved", true);
      this.$set(section, "approvedBy", this.authCookie.userName);
      this.$set(section, "approvedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "approvedTime", new Date());
    },
    notApplicableSection(e, section) {
      this.$set(section, "notApplicable", true);
      this.$set(section, "notApplicableBy", this.authCookie.userName);
      this.$set(section, "notApplicableByOrg", this.authCookie.orgs[0].orgID);
      this.$set(section, "notApplicableTime", new Date());
    },
    sectionToggle(e) {
      var secBody = e.target.parentElement.querySelector(".sec-body");
      if (secBody.style.display && secBody.style.display == "none") {
        secBody.style.display = "block";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "none";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "block";
      } else {
        secBody.style.display = "none";
        e.target.parentElement.querySelector(".ctrl-collapse").style.display =
          "block";
        e.target.parentElement.querySelector(".ctrl-expand").style.display =
          "none";
      }
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["sections"]) {
        obj["sections"].forEach((sec) => {
          this.deleteAttr(sec);
        });
      }
      if (obj["steps"]) {
        obj["steps"].forEach((step) => {
          this.deleteAttr(step);
        });
      }
    },
    archiveBatch() {
      let app = this;
      this.deleteAttr(this.pdfTemp);
      this.pdfTemp.status1 = "archived";
      axios
        .put("/api/pdfBatch", this.pdfTemp)
        .then(function (response) {
          console.log(response);
          alert("Batch archived successfully!");
        })
        .catch(function (error) {
          console.log(error);
          app.pdfTemp = [];
        });
    },
    saveDocument() {
      this.steplock();
      let app = this;
      this.deleteAttr(this.pdfTemp);
      if (!this.pdfTemp.batchID) {
        axios
          .post("/api/pdfBatch", app.pdfTemp)
          .then(function (response) {
            console.log(response);
            app.pdfTemp = response.data.item;
            alert("Document Created Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.pdfTemp = [];
          });
      } else {
        app.pdfTemp.updatedBy =
          app.pdfTemp.updatedBy || this.authCookie.userName;
        axios
          .put("/api/pdfBatch", app.pdfTemp)
          .then(function (response) {
            console.log(response);
            app.pdfTemp = response.data.item;
            alert("Document Saved Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.pdfTemp = [];
          });
      }
    },
    handleOverlaysClick(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);
      }
    },
    handleOverlaysEdit(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);

        var pValTime = prompt(
          "Attach Time needed for a particular STEP",
          e.target.innerText
        );
        if (!pValTime) return;

        e.target.innerText = pValTime;
      }
    },
    // getEditorInstance(el) {
    //   if (window.CKEDITOR.instances[el.id]) {
    //     return window.CKEDITOR.instances[el.id];
    //   }

    //   if (
    //     el["title"] &&
    //     el["title"].split(",").length == 2 &&
    //     window.CKEDITOR.instances[el["title"].split(",")[1].trim()]
    //   ) {
    //     return window.CKEDITOR.instances[el["title"].split(",")[1].trim()];
    //   }
    //   return null;
    // },
    // registerEditor(e) {
    //   if (window.CKEDITOR.instances[e.target.id]) {
    //     return;
    //   }

    //   if (!e.target["title"] || e.target["title"].split(",").length != 2) {
    //     console.dir("unregistered editor: " + e.target.id);
    //     window.CKEDITOR.inline(e.target.id);
    //     return;
    //   }

    //   var editorId = e.target["title"].split(",")[1].trim();
    //   if (!window.CKEDITOR.instances[editorId]) {
    //     console.dir("unregistered editor: " + editorId);
    //     window.CKEDITOR.inline(editorId);
    //   }
    // },
    getId() {
      return uuidv4();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
  },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;
.view-batch-data{
  margin-left: 59rem !important;
  margin-bottom: 10px;
}
.overlay-modal{
    position: fixed;
    z-index: 9998;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1206062b;
}
#planet-chart{
  max-width: 500px;
  margin: 1.75rem auto;
}
#editorControlListLeftPdfIcons {
  height: calc(100vh - 200px);
  overflow: scroll;
  width: 65 px;
} 
.items-dragging {
  display: inline-flex;
  width: 100%;
}
.drag-controls {
  padding-bottom: 10px;
}
.pdf-step:hover {
  background-color: #f8f5fa;
}
.form-control-alternative.textarea.pdf-step.pdf-drag-batch {
  width: 100%;
  border: 2px solid #8080804a;
}
.pdf-step {
  background-color: transparent;
  padding: 12px;
  min-height: 800px;
  // width: 100%;
}
.btn {
  width: 130px;
}
.form-control-alternative.textarea.pdf-step.pdf-preview-batch {
  // width: 460px;
  border: 2px solid #8080804a;
}
#upload-pdf {
  display: flex;
  margin-bottom: 10px;
}
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
  padding: 3px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555555b8;
  color: #ddd;
}

.app-content {
  padding: 10px 10px;
}
.right {
  float: right;
}
.user-audit {
  margin-left: 5px;
  color: darkgray !important;
}
.editControlPdf {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorPdf {
  // width: calc(100% - 320px);
  // margin-left: 70px;
  // flex-basis: auto;
  margin-right: 120px;
}
button.btn-default1.btn-sm.btn-step-action1.btn-hidden {
  visibility: hidden;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.sec-toggle {
  background-color: #0001;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadow4 {
  border: 1px solid #dadce0 !important;
  padding: 6px;
  width: 90%;
  // margin-top: 60px;
}
.pdf-header {
  font-weight: 700;
  // top: -100px;
  // position: relative;
  // margin: auto;
  text-align: center;
  font-size: 20px;
  // background-color: #ffff;
  // margin-right: 95px;
  // padding-left: 10px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // border-radius: 0.375rem;
  width: calc(100% - 135px);
}
.custom-disable {
  opacity: 0.4;
}
.custom-disable input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] {
  display: inline;
}
.custom-disable button {
  display: none;
}
button {
  display: inline;
}
.btn-default1 {
  margin-left: 5px;
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  // box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.data-table {
  margin-top: 28px;
}
// .textarea{
//   margin-bottom: 15px;
// }
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}

.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}
.ag-theme-balham .ag-cell {
  align-items: center;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value span {
  height: 20px;
}
.ag-theme-balham .ag-cell {
  line-height: 15px !important;
  padding-left: 12px;
  padding-right: 12px;
  // border: 1px solid transparent;
  padding-left: 11px;
  padding-right: 11px;
  display: -webkit-inline-box !important;
}
// .ag-theme-balham .addrows .ag-row {
//   border-bottom: 2px solid #808080f5 !important;
// }
.ag-header-cell-label .addrows .ag-header-cell-text {
  font-size: 15px !important;
}

@media only screen and (max-width: 1024px) {
  .vue-pdf-embed {
    width: 70%;
    overflow: hidden;
  }
  .card.shadow4 {
    border: 1px solid #dadce0 !important;
    padding: 6px;
    width: calc(100% -155px);
  }
  .pdf-header {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    width: calc(100% - 0px);
  }
  .pdf-step {
    background-color: transparent;
    padding: 12px;
    min-height: 800px;
    width: 100%;
  }
}

// grid layout css

.layoutJSON {

    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>
